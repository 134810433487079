import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit  } from "@angular/core";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { Validators, FormBuilder } from '@angular/forms';
import { ModalService } from '../../../../shared/services/modal.service';
import { PagedItems } from "../../../../view-model/PagedItems";
import { ProdutoPPBVM } from "../../../../view-model/ProdutoPPBVM";

@Component({
	selector: 'app-modal-vincular',
	templateUrl: './modal-vincular.component.html'
})
export class ModalVincular implements OnInit {

	parametros: any = {};

	idPPB: number;
	idGrupo : number = 0;
	idGrupoSelecionado : number = 0;
	descricaoGrupoSelecionado : string = "";
	_flagGrupoNaoSelecionado : boolean = false;

	//----GRID ITENS-----
	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	grid: any = { sort: {} };
	//--------------------
	fromSelectDropDown : boolean = false;
	flagSelecionouGrupo : boolean = false;
	_errorNenhumGrupoSelecionado : boolean = false;
	@Input() habilitarInputs: boolean;
	@Output() grupoSelecionado : EventEmitter<any> = new EventEmitter();
    tituloGrid = "Escolher Grupo do Produto";

  @Output() fecharEmit : EventEmitter<any> = new EventEmitter();
  posicao :number;
	servico = "GrupoPPB";

    @ViewChild('appModalVincular') appModalVincular : any;
	@ViewChild('appModalVincularBackground') appModalVincularBackground : any;

	constructor(
		private modal : ModalService,
		private applicationService: ApplicationService,
		private fb: FormBuilder,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
    }

	onChangeSort($event: any) {
		this.grid.sort = $event;
		this.buscar();
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
		this.buscar();
	}

	onChangePage($event: any) {
		this.grid.page = $event;
		this.buscar();
	}

    ngOnInit() {
	}

	async abrir(idPPB : number, idProduto: number, posicao?: number, fromSelectDropDown?: boolean){

		if(fromSelectDropDown)
			this.fromSelectDropDown = true;

    this.posicao = posicao;
		var produtoPPB : any = await this.applicationService.get("PpbVinculados/BuscarProdutoPPB", {
			id: idProduto,
			idPPB: idPPB
		}).toPromise();

		if(produtoPPB != null){
			this.idGrupo = produtoPPB.idGrupo;
		}

		this._flagGrupoNaoSelecionado = false;
		this.idGrupoSelecionado = 0;
		this.listar(idPPB);
		this.idPPB = idPPB;

    this.appModalVincularBackground.nativeElement.style.display = 'block';
		this.appModalVincular.nativeElement.style.display = 'block';
    }

    fechar(){
		if(this.fromSelectDropDown && !this.flagSelecionouGrupo)
		{
      this._errorNenhumGrupoSelecionado = false;
      this.fecharEmit.emit(this.posicao);
      this.descricaoGrupoSelecionado = "";
			this.appModalVincularBackground.nativeElement.style.display = 'none';
      this.appModalVincular.nativeElement.style.display = 'none';
		}
		else
		{
			this.fromSelectDropDown = false;
			this.flagSelecionouGrupo = false;
			this._errorNenhumGrupoSelecionado = false;
			this.idGrupo = 0;
			this.idGrupoSelecionado = 0;
			this.descricaoGrupoSelecionado = "";
			this.appModalVincularBackground.nativeElement.style.display = 'none';
      this.appModalVincular.nativeElement.style.display = 'none';
		}
  }

	buscar(){
	}

	validar()
	{
		if(!this.idGrupoSelecionado){
			this._errorNenhumGrupoSelecionado = true;
		} else {
			this.flagSelecionouGrupo = true;
			var obj : any = {}
			obj.idGrupo = this.idGrupoSelecionado;
			obj.descGrupoPPB = this.descricaoGrupoSelecionado;
			obj.idPPB = this.idPPB;
			obj.isChecked = true;
			this.grupoSelecionado.emit(obj);
			this.fechar();
			this.modal.infoSucess("Operação Realizada com Sucesso", "Success");
		}
	}

	listar(idPPB : number){

		this.parametros.idPPB = idPPB;
		this.parametros.page = this.grid.page || 1;
        this.parametros.size = this.grid.size || 10;
        this.parametros.sort = this.grid.sort.field || 'DescricaoGrupo';
        this.parametros.reverse = this.grid.sort.reverse;
		this.applicationService.get(this.servico, this.parametros).subscribe((retorno : PagedItems) => {
			if(retorno){

				if(this.idGrupo){
					this.lista = this.checkarItem(retorno.items);
				} else {
					this.lista = retorno.items;
				}
				this.total = retorno.total;

			} else {
				this.lista = {}
				this.total = null;
			}
		});

	}

	limparChecks(){
		this.idGrupoSelecionado = 0;
		this.descricaoGrupoSelecionado = null;
		this._flagGrupoNaoSelecionado = false;

		this.lista.forEach(element => {
			element.isChecked = false;
		});
	}

	checkarItem(lista : any){
		var indice = 0;
		lista.forEach(element => {
			if(element.idGrupo == this.idGrupo){
				lista[indice].isChecked = true;
			}
			indice++;
		});
		return lista;
	}

}
