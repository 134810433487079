import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from '../../shared/services/application.service';
import { PssService } from '../../shared/services/pss.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html'
})

export class MenuComponent implements OnInit {

	public Pss : any = [];
	Cadastros = [];
	Externo = [];
	MeusDados = [];
	MinhasSolicitacoes = [];
	MeusProtocolos = [];
	Protocolo = [];
	Diligencias = [];
	Inscricoes = [];
	DadosCadastrais = [];
	Perfil = [];
	UsuarioPapel = [];
	Parametros = [];
	Agendamento = [];

	usuario!: string;
	cpfcnpj!: string;

	constructor(
		private applicationService: ApplicationService,
		private pssService: PssService,
	) { }

	ngOnInit() {

		if (environment.developmentMode) {
			this.montarMenuMock();
		} else {
			this.applicationService.get('Menu').subscribe((result: any) => {
				if (result && result.length > 0)

					this.Pss = result;
				for (var i = 0; i < this.Pss.length; i++) {
					this.Pss[i].nome = this.Pss[i].nome.split("/")[1];
				}
			});
		}

		this.applicationService.get("UsuarioLogado").subscribe((result: any) => {
			this.usuario = result.usuNomeUsuario;
			this.cpfcnpj = result.usuCpfCnpj;
		});
	}

	montarMenuMock() {

		this.Pss.push(
      {
			nome: 'Consulta Cadastramento',
			id: 1,
			funcoesSistema: [
				{
					nome: 'Produtos Padronizados',
					url: '/manter-produto-padrao',
					descricao: 'Manter Processo Produtivo Padrão'
				},
				{
					nome: 'Subsetores',
					url: '/manter-subsetor',
					descricao: 'Manter Subsetor'
				},
				{
					nome: 'Unidades',
					url: '/manter-unidade',
					descricao: 'Manter Unidade'
				},
				{
					nome: "Códigos NCM",
					url: '/manter-ncm',
					descricao: 'Manter NCM'
				}
			]
      }
      ,
      {
        nome: 'Relatórios',
        id: 2,
        funcoesSistema: [
          {
            nome: 'Produto Padronizado',
            url: '/relatorio-produto-padronizado',
            descricao: 'Produto Padronizado'
          },
          {
            nome: 'Tipos de um Produto Padronizado',
            url: '/relatorio-tipos-produto-padronizado',
            descricao: 'Tipos de um Produto Padronizado'
          },
		  {
            nome: 'Consultar Alterações',
            url: '/consultar-alteracoes',
            descricao: 'Consultar Alterações'
          }
        ]
      }
    );


	}

	recuperarMenu() {
		var self = this;
		this.applicationService.get('Menu').subscribe((result: any) => {
			if (result && result.length > 0)
				var novoMenu = [];
			novoMenu = JSON.parse(result.replace(/\\"/g, '"'));
			for (var i = 0; i < novoMenu.length; i++) {
				novoMenu[i].nome = novoMenu[i].nome.split("/")[1];
			}
			this.Pss = novoMenu;

		});
	}

	trackElement(index: number, element: any) {
		return element ? element.id : null;
	}
}
