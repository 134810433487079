import { Injectable } from '@angular/core';
import { ExtractNumberService } from './extract-number.service';
import { MessagesService } from './messages.service';
import { ModalService } from "../services/modal.service";
import moment = require('moment');
//import { debug } from 'util';

@Injectable()
export class ValidationService {
	constructor(
		private modal : ModalService,
		private extractNumber: ExtractNumberService,
		private msg: MessagesService) { }

    form(id : any) {
		if (!id || !document.forms || !document.forms[id] || !document.forms[id].reportValidity) { return true; }
		const form = document.forms[id];
		return form.reportValidity();
	}

	cpf(cpf : any) {
		cpf = this.extractNumber.extractNumbers(cpf);
		let numeros, digitos, soma, i, resultado, digitos_iguais, retorno = true;
		digitos_iguais = 1;
		if (cpf.length < 11) {
			retorno = false;
		}
		for (i = 0; i < cpf.length - 1; i++) {
			if (cpf.charAt(i) != cpf.charAt(i + 1)) {
				digitos_iguais = 0;
				break;
			}
		}
		if (!digitos_iguais) {
			numeros = cpf.substring(0, 9);
			digitos = cpf.substring(9);
			soma = 0;
			for (i = 10; i > 1; i--) {
				soma += numeros.charAt(10 - i) * i;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(0)) {
				retorno = false;
			}
			numeros = cpf.substring(0, 10);
			soma = 0;
			for (i = 11; i > 1; i--) {
				soma += numeros.charAt(11 - i) * i;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(1)) {
				retorno = false;
			}
		} else {
			retorno = false;
		}

		if (!retorno) {
			this.modal.infoError(this.msg.CPF_INVALIDO, "Informação");
			return false;
		}

		return true;
	}

	cnpj(cnpj : any) {
		cnpj = this.extractNumber.extractNumbers(cnpj);

		let retorno = true;

		if (cnpj == '') { retorno = false; }

		if (cnpj.length != 14) {
			retorno = false;
		}

		// Elimina CNPJs invalidos conhecidos
		if (cnpj == '00000000000000' ||
			cnpj == '11111111111111' ||
			cnpj == '22222222222222' ||
			cnpj == '33333333333333' ||
			cnpj == '44444444444444' ||
			cnpj == '55555555555555' ||
			cnpj == '66666666666666' ||
			cnpj == '77777777777777' ||
			cnpj == '88888888888888' ||
			cnpj == '99999999999999') {
			retorno = false;
		}

		// Valida DVs
		let tamanho = cnpj.length - 2;
		let numeros = cnpj.substring(0, tamanho);
		const digitos = cnpj.substring(tamanho);
		let soma = 0;
		let pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) {
				pos = 9;
			}
		}
		let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(0)) {
			retorno = false;
		}

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) {
				pos = 9;
			}
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(1)) {
			retorno = false;
		}

		if (!retorno) {
			this.modal.infoError(this.msg.CNPJ_INVALIDO, "Informação");
			return false;
		}

		return true;
	}

	chaveAcesso(_chave: string) : boolean {

		let soma = 0; // Vai guardar a Soma
		let mod = -1; // Vai guardar o Resto da divisão
		let dv = -1;  // Vai guardar o DigitoVerificador
		let pesso = 2; // vai guardar o peso de multiplicacao
		let chave = _chave.substring(0, 43);
		let digitoInformado = parseInt(_chave.substring(43));

		//percorrendo cada caracter da chave da direita para esquerda para fazer os calculos com o peso
		for (let i = chave.length - 1; i != -1; i--) {
			let ch = parseInt(chave[i]);
			soma += ch * pesso;
			//sempre que for 9 voltamos o peso a 2
			if (pesso < 9) {
				pesso += 1;
			}
			else {
				pesso = 2;
			}
		}

		//Agora que tenho a soma vamos pegar o resto da divisão por 11
		mod = soma % 11;
		//Aqui temos uma regrinha, se o resto da divisão for 0 ou 1 então o dv vai ser 0
		if (mod == 0 || mod == 1) {
			dv = 0;
		}
		else {
			dv = 11 - mod;
		}

		if (dv != digitoInformado) {
			this.modal.infoError(this.msg.CHAVE_ACESSO_INVALIDA, "Informação");
			return false;
		}

		return true;

	}

	/*
	*	
		retornará uma Data em string com o formato que o usuario Passou por parametro Ex.: DD/MM/YYYYY, YYYY/MM/DD, DD-MM-YYYYY, YYYY-MM-DD.
		Permitindo corrigir ou nao a data em JS.
	*	
	*/
	getDateWhithChangeFormat(formato : string, data : Date, corrigeData? : boolean){
		let dataParametro = new Date(data);
		let dia = corrigeData ? dataParametro.getDate()+1 : dataParametro.getDate();
		let mes = corrigeData ? dataParametro.getMonth()+1 : dataParametro.getMonth();
		let ano = corrigeData ? Number(dataParametro.getFullYear()) : dataParametro.getFullYear();
		let dataString = ano + "/" + mes + "/" + dia;
		let dataFormatada = new Date(dataString);
		return moment(dataFormatada).format(formato);	
	}

	
	validaDataInicialMaiorDataFinal(dataInicio : string, dataFim : string){
		var retorno = moment(dataInicio).isAfter(dataFim); 
		return retorno;
	}

	validaDataMenorDataVigente(data : string){
		var dataAtualCorrigida = this.getDateWhithChangeFormat('YYYY-MM-DD', new Date(),  true);
		var retorno = moment(data).isBefore(dataAtualCorrigida); 
		return retorno;
	}
	
}
