import {
  Component,
  ElementRef,
  Injectable,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '../../shared/services/application.service';
import { MessagesService } from '../../shared/services/messages.service';
import { ModalService } from '../../shared/services/modal.service';
import { ValidationService } from '../../shared/services/validation.service';
import { PagedItems } from '../../view-model/PagedItems';
import { ChangeIniciaPaginacao } from '../../shared/services/changeIniciaPaginacao.service';
import { UnidadeMedidaVM } from '../../view-model/UnidadeMedidaVM';
import { InforFiltroRelatorioGrid } from '../../shared/services/pdf.service';
import { LocalStorageFiltroPesquisaService, StoragePesquisaPaginasEnum } from '../../shared/services/localStorageFiltroPesquisa.service';

declare var html2pdf: any;

@Component({
  selector: 'manter-unidade',
  templateUrl: './manter-unidade.component.html',
})
@Injectable()
export class ManterUnidadeComponent implements OnInit {
  form = this;
  grid: any = { sort: { field:"numeroCodigo"} };
  parametros: UnidadeMedidaVM = new UnidadeMedidaVM();
  ocultarFiltros: boolean = false;
  isBuscaSalva: boolean = false;
  flagAlterouFiltro: boolean = false;
  servico = 'ManterUnidade';
  objetoConsulta: any = ({} = null);
  eUsuarioSuframa: boolean;

  constructor(
    private changeIniciaPaginacao: ChangeIniciaPaginacao,
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private modal: ModalService,
    private msg: MessagesService,
    private router: Router,
    private route: ActivatedRoute,
    private validation: ValidationService,
    private localStorageFiltroPesquisas:LocalStorageFiltroPesquisaService
  ) {
    if (
      sessionStorage.getItem(this.router.url) == null &&
      sessionStorage.length > 0
    ) {
      sessionStorage.clear();
    }
  }

  ngOnInit() {
    this.limpar();
    // this.parametros.situacao = 99;
    // this.buscar();
    this.eUsuarioSuframa = JSON.parse(localStorage.getItem('usuarioSuframa'));
    console.log('suframa', this.eUsuarioSuframa);
    this.buscarInformacoesStorage()
  }

  buscarInformacoesStorage(){
    const key = StoragePesquisaPaginasEnum.Unidade;
    this.localStorageFiltroPesquisas.limparStorageExceto(key);
    const storage = this.localStorageFiltroPesquisas.obter(key);
    if(storage &&
      (storage.numeroCodigo != null ||
        storage.descricao != null ||
        storage.situacao != null
      )){
        this.parametros = storage;
        this.buscar()
    }

  }
  salvarDadoFiltroNoStorage(){
    let salvar = false;
    if((this.parametros.numeroCodigo || "").length){
      salvar = true;
    }
    if((this.parametros.descricao||"").length){
      salvar = true;
    }
    if(this.parametros.situacao != 99){
      salvar = true;
    }
    if(salvar){
      this.localStorageFiltroPesquisas.salvar(StoragePesquisaPaginasEnum.Unidade,this.parametros);
    }

  }

  onChangeSort($event: any) {
    this.grid.sort = $event;
    this.buscar();
  }

  onChangeSize($event: any) {
    this.grid.size = $event;
    this.buscar();
  }

  onChangePage($event: any) {
    this.grid.page = $event;
    this.buscar();
  }

  salvar() {
    this.router.navigate(['manter-unidade/incluir']);
  }

  buscar() {
    if (this.flagAlterouFiltro) {
      this.parametros.page = 1;
      this.parametros.size = 10;
      this.grid.page = 1;
      this.grid.size = 10;
      //reinicia a paginacao dentro de app-grid
      this.changeIniciaPaginacao.Reinicia(true);
    } else {
      this.parametros.page = this.grid.page;
      this.parametros.size = this.grid.size;
    }
    this.parametros.sort = this.grid.sort.field;
    this.parametros.reverse = this.grid.sort.reverse || false;
    this.flagAlterouFiltro = false;
    this.applicationService
      .get(this.servico, this.parametros)
      .subscribe((response: PagedItems) => {
        if (response.total != null && response.total > 0) {
          this.salvarDadoFiltroNoStorage()
          this.grid.lista = response.items;
          this.grid.total = response.total;
          this.prencheParametrosDeExportacao();
        } else {
          this.grid = { sort: {} };
          this.modal.infoError('Nenhum Registro Encontrado', 'Informação');
        }
      });
  }

  obterDsSelecionadoDropdown(id: string) {
    let selected = $(`#${id} option:selected`).text();
    if (selected.includes('Selecione uma opção')) {
      return null;
    }
    return selected;
  }

  prencheParametrosDeExportacao() {
    this.parametros.exportarListagem = true;
    this.parametros.exportarPdf = true;
    this.parametros.servico = this.servico;
    this.parametros.page = 1;
    this.parametros.size = 100000;
    this.parametros.titulo = 'Relatório de Unidades';
    this.parametros.sort = this.grid.sort.field;

    this.parametros.informacoesExtrasRelatorio = [
      {
        label: 'Código',
        text: this.parametros.numeroCodigo,
      },
      {
        label: 'Descrição',
        text: this.parametros.descricao,
      },
      {
        label: 'Situação',
        text: this.obterDsSelecionadoDropdown('situacao'),
      },
    ] as Array<InforFiltroRelatorioGrid>;

    this.parametros.columns = ['Código', 'Sigla', 'Descrição', 'Situação'];

    this.parametros.fields = [
      'numeroCodigo',
      'sigla',
      'descricao',
      'desSituacao',
    ];

    this.parametros.width = {
      0: { cellWidth: 100 },
      1: { cellWidth: 120 },
      2: { cellWidth: 120 },
      3: { cellWidth: 120 },
    };
  }

  limpar() {
    this.parametros = new UnidadeMedidaVM();
    this.parametros.situacao = 99;
    this.grid = { sort: { field:"numeroCodigo"} };

  }
}
