<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
  data-railOpacity="0.2">

  <div class="row m-b-md">
    <div class="col-lg-12">
      <h1 class="m-b-xs text-black">Subsetores</h1>
      <!-- <small>Subsetores</small> -->
    </div>
  </div>

   <div class="e row">

    <!-- <h2>Em Implementação ... </h2> -->
    <div class="col-lg-12">
      <section class="panel panel-default">
        <header class="panel-heading FixPanelHeading">
          <div class="pull-right">
            <button *ngIf="eUsuarioSuframa" (click)="salvar()" class="btn btn-primary btn-sm"><i class="fa fa-plus"></i> Novo</button>
          </div>
          <h2 class="panel-title h5">{{ !ocultarFiltros ? 'Filtros de Pesquisa' : 'Exibir Filtros' }}</h2>
        </header>
        <form class="form" role="form">
          <article class="panel-body" *ngIf="!ocultarFiltros">
            <div class="row form-group">
              <div class="col-lg-5 text-nowrap">
                <label for="codigo">Código:</label>
                <input type="text" name="codigo" onlyNumber="true"  id="codigo" class="form-control"
                  [(ngModel)]="parametros.numeroCodigo" (change)="parametros.flagAlterouFiltro = true"/>
              </div>
              <div class="col-lg-5 text-nowrap">
                <label for="codigo">Descrição:</label>
                <input type="text" name="desc" id="desc" class="form-control" [(ngModel)]="parametros.descSubsetor"
                  maxlength="20" (change)="parametros.flagAlterouFiltro = true"/>
              </div>
              <div class="col-lg-5" style="margin-top: 15px;">
                <label for="descricao" class="">Situação:</label>
                <select name="situacao" id="situacao" class="form-control" (change)="parametros.flagAlterouFiltro = true" [(ngModel)]="parametros.situacao">
                  <option value="99" ng-reflect-value="">Todos</option>
                  <option value="1">Ativos</option>
                  <option value="0">Inativos</option>
                </select>
              </div>

            </div>
          </article>

          <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
            <div class="pull-right">
              <button
                *ngIf="!ocultarFiltros"
                type="button"
                (click)="buscar()"
                class="btn btn-sm btn-primary a">
                  <span class="fa fa-search m-r-xs"></span>Buscar
              </button>
              <button
                *ngIf="!ocultarFiltros"
                type="button"
                (click)="limpar()"
                class="btn btn-sm btn-default">
                  <span class="fa fa-eraser m-r-xs"></span>Limpar
              </button>
              <button
                type="button"
                class="f btn btn-sm btn-default"
                (click)="ocultarFiltros ? ocultarFiltros = false : ocultarFiltros = true"
                style="margin-left: -1px;">
                  <span class="fa fa-magic m-r-xs"></span>
                  {{ !ocultarFiltros ? 'Ocultar Filtros' : 'Exibir Filtros' }}
              </button>
            </div>
          </footer>
        </form>
      </section>
    </div>
  </div>


  <div class="row" id="grid" *ngIf="grid.total>0 || grid.total!=null">
    <div class="col-lg-12">
      <app-grid-manter-subsetor
        [(lista)]="grid.lista"
        [(total)]="grid.total"
        [(size)]="grid.size"
        [(page)]="grid.page"
        [(parametros)]="parametros"
        [(eUsuarioSuframa)]="eUsuarioSuframa"
        (onChangeSort)="onChangeSort($event)"
        (onChangeSize)="onChangeSize($event)"
        (onChangePage)="onChangePage($event)"
        (emitAtualizaListagem)="buscar()">
      </app-grid-manter-subsetor>
    </div>
  </div>

</div>
