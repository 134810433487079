export class ProdutoTipoNcmVM {

    unidadeMedida : any = {};
    produtoTipo : any = {};

    id : number;
    idProdutoTipo : number;
    numeroCodigo : string;
    descricaoProdutoTipoNcm : string;
    idUnidadeMedida : number;
    statusNcm : number;
    descricaoJustificativa : string;
    idProduto : number;
}