import { EmitirRelatorioProdutoPadronizadoComponent } from './relatorio-produto-padronizado/emitir-relatorio-produto-padronizado.component';
import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../shared/services/application.service";
import { MessagesService } from "../../shared/services/messages.service";
import { ModalService } from "../../shared/services/modal.service";
import { ValidationService } from "../../shared/services/validation.service";
import { ConsultarProdutoVM } from "../../view-model/ConsultarProdutoVM";
import { PagedItems } from "../../view-model/PagedItems";
import { ChangeIniciaPaginacao } from "../../shared/services/changeIniciaPaginacao.service";
import { ProdutoVM } from "../../view-model/ProdutoVM";
import { InforFiltroRelatorioGrid } from '../../shared/services/pdf.service';

declare var html2pdf: any;

@Component({
  selector: "app-relatorio-produto-padronizado",
  templateUrl: "./relatorio-produto-padronizado.component.html"
})

@Injectable()
export class RelatorioProdutoPadronizadoComponent implements OnInit {
  form = this;
  grid: any = { sort: {} };
  tituloGrid = "Relatório Produto Padrão"
  parametros: ProdutoVM = new ProdutoVM();
  flagAlterouFiltro: boolean = false;
  ocultarFiltros: boolean = false;
  isBuscaSalva: boolean = false;
  servico = "RelatorioProdutoPadrao";
  objetoExportacao: any = {};
  objeto: any = {};
  parametrosExportacao: any = {};
  exibeRelatorio: boolean = false;
  listaUnidadeDeMedida = new Array<any>();
  parametrospp: ProdutoVM = new ProdutoVM;
  ocultarGrid: boolean = true;
  eUsuarioSuframa:boolean;

  @ViewChild('appRelatorioEmitirTipoProdutoPadrao') appEmitirRelatorioProdutoPadrao: EmitirRelatorioProdutoPadronizadoComponent;
  @ViewChild("relatorioPDF") htmlData: ElementRef;
  @ViewChild("Ncm") Ncm;
  @ViewChild("subSetor") SubSetor;
  @ViewChild("BaseLegal") BaseLegal;


  constructor(
    private changeIniciaPaginacao: ChangeIniciaPaginacao,
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private modal: ModalService,
    private msg: MessagesService,
    private router: Router,
    private route: ActivatedRoute,
    private validation: ValidationService
  ) {
    if (sessionStorage.getItem(this.router.url) == null && sessionStorage.length > 0){
      sessionStorage.clear();
    }

    this.eUsuarioSuframa =JSON.parse(localStorage.getItem("usuarioSuframa"));
  }

  ngOnInit() {
    this.startFiltros();
    this.ocultarGrid = true;
  }

  onChangeSort($event: any) {
    this.grid.sort = $event;
    this.buscar()
  }

  onChangeSize($event: any) {
    this.grid.size = $event;
    this.buscar()
  }

  onChangePage($event: any) {
    this.grid.page = $event;
    this.buscar()
  }

  buscar() {


    if (this.parametros.sitBemInformativa == null) {
      this.modal.infoError("Campos obrigatorios não preenchidos", "Atenção")
    }

    if (this.parametros.sitComponente == null) {
      this.modal.infoError("Campos obrigatorios não preenchidos", "Atenção")
    }

    if (this.parametros.statusSubsetorSetor == null) {
      this.modal.infoError("Campos obrigatorios não preenchidos", "Atenção")
    }

     if(this.parametros.idCoeficienteReducao== null){
      this.modal.infoError("Campos obrigatorios não preenchidos","Atenção")
     }

    if(this.parametros.sitProduto== null){
      this.modal.infoError("Campos obrigatorios não preenchidos","Atenção")
    }

    this.objeto = {
      idCoeficienteReducao : this.parametros.idCoeficienteReducao,
      statusSubsetorSetor : this.parametros.statusSubsetorSetor,
      idSubSetor : this.parametros.idSubsetor,
      descSubSetor : this.SubSetor.getSelectedItem() ? this.SubSetor.getSelectedItem().text : "Todos",
      ncm : this.parametros.ncm,
      descBaseLegalExistente : this.parametros.descBaseLegalExistente,
      descricaoBaseLegal : this.parametros.descricaoBaseLegal,
      sitBemInformativa : this.parametros.sitBemInformativa,
      sitComponente : this.parametros.sitComponente,
      sitProduto : this.parametros.sitProduto,
      sort : this.grid.sort.field || "Id",
      page :this.grid.page || 1,
  		reverse : this.grid.sort.reverse,
      size : this.grid.size || 10
    }
		this.flagAlterouFiltro = false;

    this.applicationService.get(this.servico, this.objeto).subscribe((result :PagedItems) =>{
      if (result != null && result.total != null && result.total > 0) {
        this.grid.lista = result.items;
        this.grid.total = result.total;
        this.prencheParametrosDeExportacao();
        this.ocultarGrid = false;
      } else {
        this.grid = { sort: {} };
        this.modal.infoError("Não há Produto para os filtros selecionados.", "Observação");
      }
    })
  }

  buscarLista() {
    this.appEmitirRelatorioProdutoPadrao;
    this.applicationService.get(this.servico + '/Listar', this.objeto).subscribe((result: any) => {
      if (result) {
        this.objetoExportacao = result;
        this.parametrosExportacao = this.objeto;
        this.exibeRelatorio = true;
        this.appEmitirRelatorioProdutoPadrao.start();
        this.appEmitirRelatorioProdutoPadrao.prencheParametrosDeExportacao();
      } else {
        this.exibeRelatorio = false;
      }
    })
  }
  gerarRelatorio() {
    this.buscarLista();
  }

  AlteraNcm($event) {
    if (this.Ncm.model.text != null && this.Ncm.model.text != undefined && this.Ncm.model.text != "") {
      let codigo = this.Ncm.model.text.split(" | ")[0];
      let descricao = this.Ncm.model.text.split(" | ")[1];
      this.parametros.ncm = codigo;
      this.parametros.descricaoNcm = descricao;
    }
  }
  AlteraBaseLegal($event) {
    if (this.BaseLegal.model.text != null && this.BaseLegal.model.text != undefined && this.BaseLegal.model.text != "") {
      let descricao = this.BaseLegal.model.text.split(" | ")[0];
      this.parametros.descricaoBaseLegal = descricao;
      this.parametros.descBaseLegalExistente = $event;
    }
  }
  obterDsSelecionadoDropdown(id:string){
    let selected = $( `#${id} option:selected` ).text();
    if(selected.includes("Selecione uma opção")){
      return null;
    }
    return selected;
  }
  obterDsBaseLegal(){
    const baseLegal = this.BaseLegal.getSelectedValue()
    if(!baseLegal){
      return null;
    }
    return baseLegal.text
  }
  obterDsNcm(){
    const ncm = this.Ncm.getSelectedValue()
    if(!ncm){
      return null;
    }
    return ncm.text
  }
  prencheParametrosDeExportacao() {
    this.parametros.exportarListagem = true;
    this.parametros.exportarPdf = true;
    this.parametros.servico = this.servico;
    this.parametros.titulo = "Relatório Produto Padronizado"


    this.parametros.informacoesExtrasRelatorio = [
      {
        label:"Bens de Informática",
        text: this.obterDsSelecionadoDropdown("benscapital")
      },
      {
        label:"Componente",
        text:this.obterDsSelecionadoDropdown("componente")
      },
      {
        label:"Subsetor",
        text:this.obterDsSelecionadoDropdown("drop-list-subsetor")
      },
      {
        label:"NCM",
        text:this.obterDsNcm()
      },
      {
        label:"CRII",
        text:this.obterDsSelecionadoDropdown("crii")
      },
      {
        label:"Base Legal",
        text:this.obterDsBaseLegal()
      },
      {
        label:"Situação",
        text:this.obterDsSelecionadoDropdown("situacao")
      }
    ] as Array<InforFiltroRelatorioGrid>;

    let objeto2 = {
      idCoeficienteReducao: this.parametros.idCoeficienteReducao,
      statusSubsetorSetor: this.parametros.statusSubsetorSetor,
      idSubSetor: this.parametros.idSubsetor,
      descSubSetor: this.SubSetor.getSelectedItem() ? this.SubSetor.getSelectedItem().text : "Todos",
      ncm: this.parametros.ncm,
      descBaseLegalExistente: this.parametros.descBaseLegalExistente,
      descricaoBaseLegal: this.parametros.descricaoBaseLegal,
      sitBemInformativa: this.parametros.sitBemInformativa,
      sitComponente: this.parametros.sitComponente,
      sitProduto: this.parametros.sitProduto,
      sort: this.grid.sort.field,
      page: 0,
      reverse: this.grid.sort.reverse,
      size: 100000,
    }

    this.applicationService.get(this.servico, objeto2).subscribe((result: PagedItems) => {
      if (result != null && result.total != null && result.total > 0) {
        for (var i = 0; i < result.items.length; i++) {
          result.items[i].situacao = result.items[i].sitProduto == 2 ? "ATIVO" : "INATIVO";
        }
        this.parametros.lista = result.items;
      }
    })

    this.parametros.columns = [
      "Código",
      "Produto",
      "Situação",
    ];

    this.parametros.fields = [
      "numeroCodigo",
      "descProduto",
      "situacao"
    ];

    this.parametros.width = {
      0: { cellWidth: 100 },
      1: { cellWidth: 500 },
      2: { cellWidth: 150 }
    };
  }

  limparFiltros() {
    this.ocultarGrid = true;
    this.objeto.descricaoBaseLegal = null;
    this.parametros = new ProdutoVM();
    this.startFiltros();
    this.Ncm.clear();
    this.BaseLegal.clear();
    this.parametros.ncm = null;
    this.parametros.descricaoBaseLegalExistente;
  }

  startFiltros() {
    this.parametros.sitBemInformativa = 99;
    this.parametros.sitComponente = 99;
    this.parametros.sitProduto = 99;
    this.parametros.statusSubsetorSetor = 99;
    this.parametros.idCoeficienteReducao = 99;
  }

}

