import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse
} from "@angular/common/http";
import { Injectable, NgModule } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

import { ModalService } from "../services/modal.service";
import { GeneratorService } from "../services/generator.service";
import { LoadingService } from "../services/loading.service";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
	baseUrl: string;

	constructor(
		private modal : ModalService,
		private generator: GeneratorService,
		private loadingService: LoadingService,
		private router: Router
	) {
		this.baseUrl = window.location.href.split("#")[0];
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		/*const timeoutLoading = setTimeout(x => {
			this.loadingService.show();
		}, 1000);*/
		const timeoutLoading = setTimeout((x:any) => {
			this.loadingService.show();
		}, 100);

		const dupReq = req.clone({
			headers: req.headers.set("FrontGuid", this.generator.guid())
		});

		return next.handle(dupReq).pipe(
			tap(
				(success: any) => { },
				(error:any) => {
					if (error) {
						const message = error.error || error.statusText || error.message;
	
						if (error.status === 302) {
							this.modal.error(error.error.message, "Error").subscribe(result => {
								if (result) {
									this.router.navigate([error.error.url]);
									//window.location.reload();
								}
							});
						}
	
						if (error.status === 400 || error.status === 401) {
							 this.modal.error(message, error.status);
						}
	
						if (!environment.production && error.status == 500) {	
							this.modal.error(message, 'Error - 500')	
								.subscribe(isConfirmado => {	
									console.error(message);	
									//window.location.reload();	
								});	
						}	

						// if (!environment.production && error.status === 500) {
						// 	const str = error.error;
						// 	const msgTratada = str.substring(0, str.indexOf("StackTrace"));
	
						// 	this.modal.error(msgTratada, "erro 500").subscribe(isConfirmado => {
						// 		window.location.reload();
						// 	});
						// }
	
						if (error.status === 0) {
						   window.location.reload();
						}
					}
	
					clearTimeout(timeoutLoading);
					this.loadingService.hide();
				},
				() => {
					clearTimeout(timeoutLoading);
					this.loadingService.hide();
				}
			)
		);
	}
}

@NgModule({
	providers: [
		GeneratorService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpsRequestInterceptor,
			multi: true
		}
	]
})
export class InterceptorsModule { }
