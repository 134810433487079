import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ApplicationService } from '../../../shared/services/application.service';
import { PagedItems } from '../../../view-model/PagedItems';
import { Excel2Service } from '../../services/excel2.service';
import { PDFService } from '../../services/pdf.service';
import { EnumTipoExportacaoGrid } from '../../enums/EnumTipoExportacaoGrid';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
})
export class GridComponent implements OnInit {
  @Input() isHideCabecalho = false;
  @Input() isHidePaginacao = false;
  @Input() setPage: number;
  @Input() isShowPanel: boolean = true;
  @Input() lista: Array<any>;
  @Input() page: number;
  @Input() size: number;
  @Input() sorted: string;
  @Input() titulo: string = '';
  @Input() total: number;
  @Input() parametros: any = {};
  @Output() onChangePage: EventEmitter<any> = new EventEmitter();
  @Output() onChangePageNcm: EventEmitter<any> = new EventEmitter();
  @Output() onChangeSize: EventEmitter<any> = new EventEmitter();
  @Output() onChangeSort: EventEmitter<any> = new EventEmitter();

  listaRelatorio: Array<any> = [];
  constructor(
    private applicationService: ApplicationService,
    private excelService: Excel2Service,
    private pdfService: PDFService
  ) {}

  ngOnInit() {
    this.page = this.page || 1;
    this.size = this.size || 10;
  }

  changeSize($event) {
    this.page = 1;
    this.onChangeSize.emit($event);
  }

  changeSort($event) {
    this.parametros.sort = $event;
    this.onChangeSort.emit($event);
    this.changePage(this.page);
  }

  changePage($event) {
    this.page = $event;
    this.onChangePage.emit($event);
  }

  habExportar() {
    if (this.parametros.exportacaoConfigs) {
      if (this.parametros.exportacaoConfigs.habExportar) {
        return true;
      }
    }

    return false;
  }
  habExportarExterno() {
    if (this.parametros.exportacaoConfigs) {
      if (this.parametros.exportacaoConfigs.exportarPdfExterno) {
        return true;
      }
    }
    return false;
  }
  getTipoExportacaoPdf() {
    if (this.parametros.exportacaoConfigs) {
      if (this.parametros.exportacaoConfigs.tipoExportacaoPdf) {
        return this.parametros.exportacaoConfigs.tipoExportacaoPdf;
      }
    }

    return EnumTipoExportacaoGrid.PDF_PADRAO;
  }


  exportaExcelPadrao(columns: any, rows, filename,titulo:string = "") {
    var excel: any = [];
    excel.push(columns);
    for (var i = 0; i < rows.length; i++) {
      excel.push(rows[i]);
    }
    this.excelService.exportAsExcelFile(excel, filename,titulo);
  }


  /*

  */
 /**
  * @description função recursiva que busca os dados de forma paginada separando e agregando a lista (se puder melhorar melhore! )
  */
  buscarListaRelatorio(list=[],size:number,page:number){
    return new Observable((subscriber)=>{
      let parms = {
        ...this.parametros,
        size:size,
        page: page,
      };
      this.applicationService
      .get(this.parametros.servico,parms ).subscribe((result:PagedItems)=>{
        if(result.items.length>0){
          list = list.concat(result.items);
          this.buscarListaRelatorio(list,size,page+1).subscribe((r)=>subscriber.next(r))
        }else{
          subscriber.next(list)
        }


      });
    });
  }

  buscarDadosListagemRelatorio(): Observable<any> {
    return new Observable<any>((subscriber) => {
      if (this.parametros.lista) {
        this.lista = this.parametros.lista;
        subscriber.next(this.parametros.lista);
      } else {
        // this.parametros.sort = this.parametros.ignorarNull
        //   ? this.parametros.sort
        //   : null;

        //GANBIARRA
        // ideal seria usar stream

        this.buscarListaRelatorio([],1000,1).subscribe(
          (result)=>{
            subscriber.next(result);
          }
        )
      }
    });
  }
  buscarDadosParaRelatorio(): Observable<any> {
    //DADOS DE LISTAGEM
    return new Observable<any>((subscriber) => {
      this.buscarDadosListagemRelatorio().subscribe((lista) => {
        subscriber.next({
          lista,
        });
      });
    });
  }
  organizarLinhas() {
    let rows = Array<any>();

    for (var i = 0; i < this.lista.length; i++) {
      let r = Array<any>();

      let valor: any;
      for (var j = 0; j < this.parametros.fields.length; j++) {
        var item = this.parametros.fields[j].split('|');
        valor =
          item.length > 0
            ? Object.values(this.lista)[i][item[0].trim()]
            : Object.values(this.lista)[i][this.parametros.fields[j].trim()];
        if (this.parametros.fields[j].trim() == 'status')
          r[j] = valor == 1 ? 'ATIVO' : 'INATIVO';
        else {
          r[j] = valor;
        }
      }

      rows.push(r);
    }

    return rows;
  }

  exportar(tipo) {
    this.parametros.page = 1;
    this.parametros.size = 10000000;
    //filename
    var file =
      this.parametros.tituloArquivo ||
      window.location.href.split('#')[1].replace('/', '');

    this.buscarDadosParaRelatorio().subscribe(({ lista }) => {
      this.lista = lista;
      const rows = this.organizarLinhas();
      switch (tipo) {
        case EnumTipoExportacaoGrid.PDF_PADRAO:
          this.pdfService.exportAsPDFFile(
            this.parametros.columns,
            rows,
            this.parametros.width,
            file,
            this.parametros.titulo,
            this.parametros.informacoesExtrasRelatorio
          );
          break;
        case EnumTipoExportacaoGrid.EXCEL:
          this.exportaExcelPadrao(this.parametros.columns, rows, file,this.parametros.titulo);
          break;

        // case EnumTipoExportacaoGrid.PDF_WINDOW_PRINTER:
        // 	this.pdfService.exportAsPdfWindowPrint()
        default:
          this.pdfService.exportAsPDFFile(
            this.parametros.columns,
            rows,
            this.parametros.width,
            file,
            this.parametros.titulo,
            this.parametros.informacoesExtras
          );
          break;
      }
    });
  }
}
