<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
    data-railopacity="0.2">

    <div class="row m-b-md">
        <div class="col-lg-12">
            <app-titulo>Consultar Alterações</app-titulo>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <section class="panel panel-default">
                <header class="panel-heading">
                    <h2 class="panel-title h5">Filtros de Pesquisa</h2>
                </header>
                <article *ngIf="!ocultarFiltros" class="panel-body">
                    <form class="form" role="form">
                        
                            <div class="row form-group" style="margin-top: 7px;">
                              
                              <div class="col-lg-5">
                                <label for="descricao" class="">Produtos Padronizados:</label>
                                <input [disabled]="!dadosPequisa.check" type="text" class="form-control" name ="dscProduto" [(ngModel)]="dadosPequisa.dscProduto">
                              </div>
                              
                              <div class="col-lg-5">
                                <label for="descricao2" class="">Unidades:</label>
                                <input [disabled]="!dadosPequisa.check2" type="text" class="form-control" name ="dscUnidade" [(ngModel)]="dadosPequisa.dscUnidade">
                              </div>

                            </div>
                            <div class="row form-group">
                              <div class="col-lg-5">
                                  <div class="col-lg-3">
                                    <input type="radio" name="rdTpDscPro" value="1" [(ngModel)]="dadosPequisa.check" (change)="checkFiltroProdutos($event);">
                                    <label for="radio">Todos</label><br>
                                  </div>
                                  <div class="col-lg-3">
                                    <input type="radio" name="rdTpDscPro" value="2" [(ngModel)]="dadosPequisa.check" (change)="checkFiltroProdutos($event);">
                                    <label for="radio">Código</label><br>
                                  </div>
                                  <div class="col-lg-4">
                                    <input type="radio" name="rdTpDscPro" value="3" [(ngModel)]="dadosPequisa.check" (change)="checkFiltroProdutos($event);">
                                    <label for="radio">Descrição</label>
                                  </div>     
                              </div>          
                              <div class="col-lg-5">
                                <div class="col-lg-3">
                                  <input type="radio" name="rdTpDscUni" value="1" [(ngModel)]="dadosPequisa.check2" (change)="checkFiltroUnidade($event);">
                                  <label for="radio">Todos</label><br>
                                </div>
                                <div class="col-lg-3">
                                  <input type="radio" name="rdTpDscUni" value="2" [(ngModel)]="dadosPequisa.check2" (change)="checkFiltroUnidade($event);">
                                  <label for="radio">Código</label><br>
                                </div>
                                <div class="col-lg-4">
                                  <input type="radio" name="rdTpDscUni" value="3" [(ngModel)]="dadosPequisa.check2" (change)="checkFiltroUnidade($event);">
                                  <label for="radio">Descrição</label>
                                </div>     
                            </div>                      
                            </div>

                            <div class="row form-group" style="margin-top: 7px;">
                              <div class="col-lg-5">
                                <label for="descricao" class="">Subsetores:</label>
                                <input [disabled]="!dadosPequisa.check3" type="text" class="form-control" name ="dscSub" [(ngModel)]="dadosPequisa.dscSub">
                              </div>
                              <div class="col-lg-5">
                                <label for="descricao2" class="">Códigos NCM:</label>
                                <input [disabled]="!dadosPequisa.check4" type="text" class="form-control" name ="dscNcm" [(ngModel)]="dadosPequisa.dscNcm">
                              </div>
                              
                            </div>

                            <div class="row form-group">
                              <div class="col-lg-5">
                                  <div class="col-lg-3">
                                    <input type="radio" name="rdTpDscSub" value="1" [(ngModel)]="dadosPequisa.check3" (change)="checkFiltroSubsetor($event);">
                                    <label for="radio">Todos</label><br>
                                  </div>
                                  <div class="col-lg-3">
                                    <input type="radio" name="rdTpDscSub" value="2" [(ngModel)]="dadosPequisa.check3" (change)="checkFiltroSubsetor($event);">
                                    <label for="radio">Código</label><br>
                                  </div>
                                  <div class="col-lg-4">
                                    <input type="radio" name="rdTpDscSub" value="3" [(ngModel)]="dadosPequisa.check3" (change)="checkFiltroSubsetor($event);">
                                    <label for="radio">Descrição</label>
                                  </div>     
                              </div>          
                              <div class="col-lg-5">
                                <div class="col-lg-3">
                                  <input type="radio" name="rdTpDscNcm" value="1" [(ngModel)]="dadosPequisa.check4" (change)="checkFiltroNcm($event);">
                                  <label for="radio">Todos</label><br>
                                </div>
                                <div class="col-lg-3">
                                  <input type="radio" name="rdTpDscNcm" value="2" [(ngModel)]="dadosPequisa.check4" (change)="checkFiltroNcm($event);">
                                  <label for="radio">Código</label><br>
                                </div>
                                <div class="col-lg-4">
                                  <input type="radio" name="rdTpDscNcm" value="3" [(ngModel)]="dadosPequisa.check4" (change)="checkFiltroNcm($event);">
                                  <label for="radio">Descrição</label>
                                </div>     
                            </div>                      
                            </div>

                           
                            <div class="row form-group">
                              <div class="col-lg-5" style="margin-top: 7px;">
                                <label for="periodo" class="">Período:</label>
                                <div class="input-group" style="display: flex;">
                                  <input 
                                  #dataInicial
                                  (blur)="validarDatas()"
                                  type="date" 
                                  name="dataInicial"
                                  id="dataInicial" 
                                  class="form-control" 
                                  [(ngModel)]="dadosPequisa.dataPeriodoStringInicio" 
                                  (change)="isAlterouConsulta = true;">
  
                                  <span style="padding-right: 11px; padding-top: 8px; padding-left: 10px;">a</span>
                                 
                                  <input
                                  #dataFinal
                                  (blur)="validarDatas()"
                                  type="date" 
                                  name="dataFinal"
                                  id="dataFinal"
                                  class="form-control"
                                  [(ngModel)]="dadosPequisa.dataPeriodoStringFim" 
                                  (change)="isAlterouConsulta = true;">
                                </div>
                              </div>
                              <div class="col-lg-5" style="margin-top: 7px;">
                                <label for="anoBase" class="">Responsável:</label>
                                <input type="text" class="form-control" name ="anoBase" [(ngModel)]="dadosPequisa.responsavel">
                              </div>  
                            </div>
                           
                    </form>
                </article>

                <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
                    <div class="pull-right">
                        <button *ngIf="!ocultarFiltros" class="btn btn-sm btn-primary" type="submit" disableOnClick
                            (click)="validarBusca()">
                            <i class="fa fa-search"></i> Buscar
                        </button>
                        <button *ngIf="!ocultarFiltros" #btnlimpar class="btn btn-sm btn-default" type="reset"
                            (click)="limpar()">
                            <i class="fa fa-eraser"></i> Limpar
                        </button>
                        <button type="button" class="btn btn-sm btn-default"
                            (click)=" ocultarFiltros ? ocultarFiltros = false : ocultarFiltros = true "
                            style="margin-left: -1px;">
                            <span class="fa fa-magic m-r-xs"></span>
                            {{ ocultarFiltros ? 'Exibir Filtros' : 'Ocultar Filtros' }}
                        </button>
                    </div>
                </footer>
            </section>
        </div>
    </div>
</div>
<div *ngIf="exibirGrid == true">
    <app-grid-consultar-alteracoes
        [(lista)]="grid.lista" 
        [(total)]="grid.total"
        [(size)]="grid.size" 
        [(page)]="grid.page" 
        [(parametros)]="parametros"
        (onChangeSort)="onChangeSort($event)"
        (onChangeSize)="onChangeSize($event)"
        (onChangePage)="onChangePage($event)"
        (atualizarListagem)="buscar()">
    </app-grid-consultar-alteracoes>
</div>