<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px" data-railopacity="0.2" id="relatorioPDF" #relatorioPDF>
	<div class="row m-b-md">
		<div class="col-sm-6">
			<app-titulo>Consultar PPB</app-titulo>
			<app-sub-titulo>Consultar</app-sub-titulo>
		</div>
	</div> 
	<div class="row">
		<div class="col-md-12">
			<section class="panel panel-default">
				<header class="panel-heading">
					<div class="pull-right">
                        <a 
                           (click)="gerarNovoPPB()"
                            class="btn btn-primary btn-sm">
                                <i class="fa fa-plus"></i> Novo
                        </a>                            
                        <a 
                           (click)="gerarPDF(relatorioPDF)"
                            class="btn btn-primary btn-sm">
                                <i class="fa fa-plus"></i> Gerar PDF
                        </a>                            
                    </div>				
					<h2 class="panel-title h5">Filtros de Pesquisa</h2>
				</header>
				<article *ngIf="!ocultarFiltros" class="panel-body" >
					<form id="formBusca" class="form form-horinzontal" role="form" #formBusca="ngForm" ngNativeValidate>
                       
                        <article class="panel-body">

                        <div class="row form-group">
                            <div class="col-lg-4">
                                <label for="descricao" class="">Tipo de Publicação:</label>
                                <select 
                                    name="tipo-publicacao-solicitacoes-ppb" 
                                    id="tipo-publicacao-solicitacoes-ppb" 
                                    class="form-control" 
                                    [(ngModel)]="parametros.TipoPublicacao">
                                        <option value="0">Selecione uma opção ...</option>
                                        <option value="1">FIXAÇÃO</option>
                                        <option value="2">ALTERAÇÃO PARA REVOGAÇÃO</option>
                                        <option value="3">ALTERAÇÃO PARCIAL</option>
                                        <option value="4">AGREGAÇÃO</option>
                                </select>
                            </div>
                            
                            <div class="col-lg-4">
                                <label for="descricao" class="">Tipo de Documento:</label>
                                <select    
                                    *ngIf="listaTipoDocumento.length"                     
                                    class="form-control" 
                                    [(ngModel)]="parametros.TipoDocumento" 
                                    name="tipo-de-Documento"
                                    id="tipo-de-Documento">
                                        <option 
                                            value="0" 
                                            >
                                            Selecione uma opção ...
                                        </option>
                                        <option
                                            *ngFor="let item of listaTipoDocumento"
                                            [ngValue]="item.id">
                                            {{ item.descricao }}
                                        </option>
                                </select>
                            </div>
                            <div class="col-lg-4">
                                <label for="situacao" class="">Situação:</label>
                                <select name="situacao" id="situacao" class="form-control" [(ngModel)]="parametros.Situacao">
                                    <option value="0" ng-reflect-value="">Selecione...</option>
                                    <option value="1">Em Elaboração</option>
                                    <option value="2">Vigente</option>
                                    <option value="3">Vigência Futura</option>                                                    
                                    <option value="4">Revogado</option>
                                </select>
                            </div>
                            							 
                        </div>

                        <!-- ############################################################################################## -->
                        <!-- ############## ADICIONAR ESSE CAMPO NA PRÓXIMA SPRINT QUE FOR TRABALHAR COM PRODUTO ########## -->
                        <!-- ############################################################################################## -->
                        
                        <!--  PORTARIA MCT N° 1, 2 DE AGOSTO DE 2021
                        <div class="row form-group">
                            <div class="col-sm-9">
                                <label for="descricao" class="">Produto para Vincular:</label>
                                <div class="input-group">
                                    <input 
                                        disabled="true"
                                        type="text" 
                                        class="form-control " 
                                        placeholder="Pesquisar Produto">                                             
                                    <span class="input-group-btn">
                                        <input 
                                            disabled="true"
                                            type="text" 
                                            class="form-control" 
                                            maxlength="8" 
                                            placeholder="NCM">
                                        <button
                                            disabled="true" 
                                            class="btn btn-default" 
                                            type="button">                                        
                                            <i class="fa fa-search"></i>Buscar</button>
                                    </span>
                                </div>
                            </div>
                        </div> 
                        -->

                        <div class="row form-group">
                            <div class="col-lg-3 text-nowrap">
                                <label for="codigo">Número:</label>
                                    <input
                                        type="text" 
                                        name="codigo" 
                                        id="codigo" 
                                        (keyup.enter)="buscar(false)"
                                        [(ngModel)]="parametros.Numero"
                                        class="form-control" maxlength="3" />
                            </div>                            
                            <div class="col-lg-2">
                                <label for="descricao" class="">Ano:</label>
                                    <input 
                                        type="text" 
                                        name="ano" 
                                        id="ano"
                                        (keyup.enter)="buscar(false)"
                                        [(ngModel)]="parametros.Ano"
                                        class="form-control" />
                            </div>
                            <div class="col-lg-6 text-nowrap">
                                <label for="codigo">Palavra-chave:</label>
                                <input 
                                    type="text" 
                                    name="palavra" 
                                    id="palavra"
                                    [(ngModel)]="parametros.PalavraChave"
                                    (keyup.enter)="buscar(false)"
                                    class="form-control" 
                                />
                                <div class="radio i-checks radio-sm" style="margin-right: 1px;">
                                    <label class="input-sm" >
                                        <input 
                                            name="radio[custom]" 
                                            type="radio"
                                            [(ngModel)]="parametros.PosicaoPalavra"
                                            value="1"><i></i> Começa por
                                    </label>
                                    <label class="input-sm" >
                                        <input 
                                            name="radio[custom]" 
                                            type="radio" 
                                            [(ngModel)]="parametros.PosicaoPalavra"
                                            value="2"><i></i> Qualquer parte
                                    </label>
                                    <label class="input-sm" >
                                        <input 
                                            name="radio[custom]" 
                                            type="radio" 
                                            [(ngModel)]="parametros.PosicaoPalavra"
                                            value="3"><i></i> Palavra Exata
                                    </label>
                                </div>
                            </div>  
                            
                            <div class="col-lg-4">
								<label for="dataorigem" class="">Data do Cadastro:</label>
								<div class="input-group" style="display: flex;">
									<input #dataInicio
										   type="date"
										   id="dataInicio"
										   name="dataInicio"
										   min="0001-01-01" max="9999-12-31"
										   [(ngModel)]="parametros.dataInicio"
										   class="form-control date"
										   style="width: 50%;"
										   maxlength="10"
										   autocomplete="off"
										   (keyup.enter)="buscar(true)">
									<span class="text-center" style="padding-right: 15px; padding-top: 8px; margin-left: 15px;">a</span>
									<input #dataFim
										   type="date"
										   id="dataFim"
										   name="dataFim"
										   min="0001-01-01" max="9999-12-31"
										   [(ngModel)]="parametros.dataFim"
										   class="form-control date"
										   style="width: 50%;"
										   maxlength="10"
										   autocomplete="off"
										   (keyup.enter)="buscar(true)">
								</div>                                
							</div>
                            <div class="col-lg-4 text-nowrap">
                                <label for="codigo">Mascara:</label>
                                    <input
                                    id="mask" 
                                    name="mask" 
                                    type="text"
                                    [dropSpecialCharacters]="false"
                                    [showMaskTyped]="true"
                                    mask="(000-SSS)"
                                    [(ngModel)]="parametros.NumeroMask"
                                    class="form-control" 
                                     />
                                     <!-- documentation mask: https://www.npmjs.com/package/ngx-mask -->
                            </div>

                            <div class="col-lg-4 text-nowrap">
                                <label for="codigo">Mask Valores:</label>
                                    <input
                                    id="mask" 
                                    name="mask" 
                                    type="text"
                                    mask="separator.2" thousandSeparator="."
                                    [(ngModel)]="parametros.NumeroMask1"
                                    class="form-control" 
                                     />
                                     <!-- documentation mask: https://www.npmjs.com/package/ngx-mask -->
                            </div>
                            		
                        </div>

                        </article>

					</form>
						
				</article>
				<footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
					<div class="pull-right">
						<button 
                            *ngIf="!ocultarFiltros"
                            class="btn btn-sm btn-primary" 
                            type="submit" 
                            disableOnClick 
                            (click)="buscar(false)">
                                <i class="fa fa-search"></i> Buscar
                        </button>
						<button 
                            *ngIf="!ocultarFiltros"
                            #btnlimpar 
                            class="btn btn-sm btn-default" 
                            type="reset" 
                            (click)="limpar()">
                                <i class="fa fa-eraser"></i> Limpar
                        </button>
						<button 
                            type="button" 
                            class="btn btn-sm btn-default" 
                            (click)=" ocultarFiltros ? ocultarFiltros = false : ocultarFiltros = true " 
                            style="margin-left: -1px;">
                                <span class="fa fa-magic m-r-xs"></span>{{ ocultarFiltros ? 'Exibir Filtros' : 'Ocultar Filtros' }}
                        </button>
					</div>
				</footer>
			</section>
		</div>
	</div>
	<div class="row" *ngIf="grid.total > 0" id="grid">
		<div class="col-lg-12">
			<app-grid-manter-ppb 
                [(lista)]="grid.lista"
                [(total)]="grid.total"
                [(size)]="grid.size"
                [(page)]="grid.page"
                [(parametros)]="parametros"
                (onChangeSort)="onChangeSort($event)"
                (onChangeSize)="onChangeSize($event)"
                (onChangePage)="onChangePage($event)">
			</app-grid-manter-ppb>
		</div>
	</div>
</div>