export class ValidacaoMCCPVM{
    id : number;
    pendenciaIdUnidadeMedida : boolean = false;
    pendenciaIdCoeficienteReducao : boolean = false;
    pendenciaIdSubsetor : boolean = false;
    pendenciaNumeroCodigo : boolean = false;
    pendenciaDescProduto : boolean = false;
    pendenciaIdPPB : boolean = false;
    pendenciaSitComponente : boolean = false;
    pendenciaSitBemInformativa : boolean = false;
    pendenciaSitProduto : boolean = false;
    pendenciaDescProtocolo : boolean = false;
    pendenciaDescGrupoPPB: boolean = false;
    pendenciaIdGrupo : boolean = false;
    pendenciaTipoProduto : boolean = false;
    pendenciaNcm : boolean = false;
    _flagExistePendencia : boolean = false;
}