import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../shared/services/application.service';
import { MessagesService } from '../../../shared/services/messages.service';
import { ModalService } from '../../../shared/services/modal.service';
import { Router } from '@angular/router';
import { PPBVM } from '../../../view-model/PPBVM';
import { ValidationService } from '../../../shared/services/validation.service';
import { EnumStatusPPB } from '../../../shared/enums/EnumStatusPPB';
import { MonthPipe } from '../../../shared/pipes/month.pipe';
import { AnexoPPBVM } from '../../../view-model/AnexoPPBVM';

@Component({
	selector: 'app-grid-manter-ppb',
	templateUrl: './grid.component.html'
})

export class GridManterPPBComponent {
	numeroHoje: string = '11111';
	ocultarTituloGrid = true;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,
	) { 
		
	}

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalEtapasCondicoesPPB') appModalEtapasCondicoesPPB;
	@ViewChild('appModalFinalizar') appModalFinalizar;
	@ViewChild('appModalVinculos') appModalVinculos;

	tituloGrid = "PPB"
	servico = "Validar";
	servicoFinalizar = "FinalizarPPB";
	servicoExcluir = '';
	servicoAnexo = 'AnexoPPB';

	changeSize($event) {
		this.onChangeSize.emit($event);
		this.changePage(this.page);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	abrirListagens(idPPB, tipoEtapa){
		this.appModalEtapasCondicoesPPB.abrir(idPPB, tipoEtapa);
	}

	abrirFinalizar(id){
		this.applicationService.get(this.servicoFinalizar, {id: id}).subscribe(response => {
			if(response != null){
				this.modal.infoSucess("Operação realizada com sucesso","Sucesso");
				this.changePage(this.page)
			}else{
				this.modal.infoError("PPB com dados Inconsistentes","Erro");
				this.router.navigate(["solicitacoes-ppb/" + id + "/editar"]); 
			}
		});
	}

	abrirVinculos(idPPB){
		this.appModalVinculos.abrir(idPPB);
	}

	abrirEditar(idPPB : number){
		this.router.navigate(['/solicitacoes-ppb/'+idPPB+'/editar']);
	}

	abrirVisualizar(idPPB : number){
		this.router.navigate(['/solicitacoes-ppb/'+idPPB+'/visualizar']);
	}

	excluir(id: number){
		this.modal.confirmacao("Deseja Excluir o Registro?").subscribe( (isConfirmado : any) => {
		 	if(isConfirmado) {
				this.applicationService.delete(this.servicoExcluir, id).subscribe((response:any) => {
					if(response){
						this.modal.infoSucess("Operação realizada com sucesso","Sucesso");
						this.changePage(this.page)
					}else{
						this.modal.infoError("Não foi possível realizar a exclusão do registro","Erro");
					}
				});
		 	}
		});	
		
	}

	formatDate(date: Date){
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date)
	}

	definirStatus(tipo: number): string{
		if(tipo == EnumStatusPPB.EM_ELABORACAO){
			return "Em Elaboração";
		}

		if(tipo == EnumStatusPPB.REVOGADO){
			return "Revogado";
		}

		if(tipo == EnumStatusPPB.VIGENCIA_FUTURA){
			return "Vigencia Futura";
		}

		if(tipo == EnumStatusPPB.VIGENTE){
			return "Vigente";
		}
	}

	definirVinculo(vinculo: number){
		switch(vinculo){
			case 1:
				return "FIXAÇÃO";
			case 2: 
				return "ALTERAÇÃO PARA REVOGAÇÃO";
			case 3: 
				return "ALTERAÇÃO PARCIAL";
			case 4:
				return "AGREGAÇÃO"
		}
	}
	
	validar(idPPB : number){
		this.router.navigate(["solicitacoes-ppb/" + idPPB + "/validacao"]); 
	}

	buscarAnexo(idPPB: number){
		this.applicationService.get(this.servicoAnexo, {idPPB: idPPB}).subscribe((response: AnexoPPBVM) => {
			if(response != null){
				this.PdfNovaAba(response.objetoArquivo);
			}else{
				this.modal.informacao("O PPB não possui Anexo","Não encontrado !");
			}
		});
	}

	PdfNovaAba(str) {
        var objbuilder = '';
            objbuilder += ('<object width="100%" height="100%" data="data:application/pdf;base64,');
            objbuilder += (str);
            objbuilder += ('" type="application/pdf" class="internal">');
            objbuilder += ('<embed src="data:application/pdf;base64,');
            objbuilder += (str);
            objbuilder += ('" type="application/pdf"  />');
            objbuilder += ('</object>');
            var win = window.open("#","_blank");
            if(!win || win.closed || typeof win.closed=='undefined') 
            { 
                this.modal.infoError("O Anexo não pode ser automaticamente exibido, pois o bloqueador de pop-ups do navegador está ativo.", "Informação");             
            } else{
                var title = "Documento Anexado";
                win.document.write('<html><title>'+ title +'</title><body style="margin-top: 0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">');
                win.document.write(objbuilder);
                win.document.write('</body></html>');
                var layer = jQuery(win.document);   
            }   
    }
}
