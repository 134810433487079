import { Component, Injectable, OnInit, ViewChild } from "@angular/core";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-modal-etapas-condicoes-ppb',
	templateUrl: './modal-etapas-condicoes-ppb.component.html'
})
export class ModalEtapasCondicoesPPBAtuacao implements OnInit {
	exibirGrids: boolean = false;
	idPPB = 1;
	tipoEtapa = 0;
	habilitarInputs = false;
	listaDadosAtuacao: any;
	ocultarInputAnexo = false;
	parametros: any = {};
	isDesabilitado: boolean = true;
	ocultaCombos: boolean = true;

    @ViewChild('appModalEtapasCondicoesPPB') appModalEtapasCondicoesPPB : any;
	@ViewChild('appModalEtapasCondicoesPPBBackground') appModalEtapasCondicoesPPBBackground : any;

	constructor(
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
    }

    ngOnInit() {
	}

	abrir(idPPB:any, tipoEtapa: number){	
		this.idPPB = idPPB;	
		this.tipoEtapa = tipoEtapa;
		this.exibirGrids = true;
        this.appModalEtapasCondicoesPPBBackground.nativeElement.style.display = 'block';
		this.appModalEtapasCondicoesPPB.nativeElement.style.display = 'block';
    }

    fechar(){
		this.exibirGrids = false;
        this.appModalEtapasCondicoesPPBBackground.nativeElement.style.display = 'none';
		this.appModalEtapasCondicoesPPB.nativeElement.style.display = 'none';
    }
}
