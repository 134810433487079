import { PagedItems } from './PagedItems';
export class PPBGrupoVM extends PagedItems{
    id : Number;
    idPPB : Number;
    descricaGrupo : string;
    descricaProdutos : string;
    descricaoMetas : string;
    dataVigenciaInicial : Date;
    dataVigenciaFinal : Date;
    statusGrupo : number;
}

