<app-grid
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

    <div class="table-responsive no-margin-bottom no-border">
      <table class="table">
        <thead class="table-header-color">
            <tr>
                <th class="th-sortable unset-nowrap text-left">
                    <app-ordenacao field="numeroCodigo" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                      Código
                    </app-ordenacao>
                </th>
                <th class="th-sortable unset-nowrap text-left">
                    <app-ordenacao field="descricao" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                        Descrição(Tipo) </app-ordenacao>
                </th>
                <th class="th-sortable unset-nowrap text-left">
                    <app-ordenacao field="statusTipo" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                        Situação(Tipo)
                    </app-ordenacao>
                </th>

            </tr>
        </thead>
        <tbody *ngIf="lista.length > 0">
            <tr style="cursor: pointer" (click)="abrirModalNcms(item)"*ngFor="let item of lista">
                <td class="text-left">{{item.numeroCodigo}}</td>
                <td class="text-left">{{item.descricao}}</td>
                <td class="text-left">{{item.statusTipo == 0 ? 'Inativo' : 'Ativo'}}</td>
           </tr>
        </tbody>
    </table>
    </div>
</app-grid>

<app-modal-ncms
#appModalNcms
[(statusNCM)]="statusNCM"
>
</app-modal-ncms>
