<select #select class="form-control" [(ngModel)]="model" id="drop-list" (change)="onChange(select.value)"
	[attr.required]="required || isRequired ? 'required' : null"
	[attr.disabled]="isDisabled || !list || list.length == 0 ? 'disabled' : null"
	placeholder="Selecione um opção ...">

	<!--
	<option *ngIf="list && list.length > 1" selected="{{ list && list.length > 1 ? 'selected' : '' }}" value="">
		{{placeholder}}
	</option>
	<option *ngFor="let item of list" [value]="item.id">{{item.text}}</option>
	-->
	<option *ngIf="list && list.length > 1" selected="{{ list && list.length > 1 ? 'selected' : '' }}" value="">
		{{placeholder}}
	</option>
	<option *ngFor="let item of list" [value]="item.id" [selected]="list.length == 1 || item.id == model">{{item.text}}</option>
</select>
