import { PagedItems } from "./PagedItems";

export class ConsultarProdutoVM extends PagedItems{
    produto: string;
    formatoBuscaProduto: string;
    tipo: string;
    formatoBuscaTipo: string;
    ncm: string;
    sitProduto: number=99;
    idUnidadeMedida: number = 0;
    descricao: string;
    idPPB: number;
    lista: Array<any>;
}