import { Component, Injectable, Input, OnInit, ViewChild } from "@angular/core";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ActivatedRoute, Router} from "@angular/router";
import { VwMppbPpbEntityVM } from "../../../../view-model/VwMppbPpbEntityVM";
import { ModalService } from "../../../../shared/services/modal.service";
import { trackByHourSegment } from "angular-calendar/modules/common/util";


enum EnumTipoEtapa{
  SemPontuacao = 1,
  PorPontuacao = 2,
  PorGrupo = 3,
  PPBMultiplo = 4
}

@Component({
	selector: 'app-vinculos-ppb',
	templateUrl: './vinculos-ppb.component.html'
})
export class VinculosPPBComponent implements OnInit {


	exibirGrids: boolean = false;
	tipoEtapa = 0;
	habilitarInputs = false;
	listaDadosAtuacao: any;
	ocultarInputAnexo = false;
	parametros: any = {};
	isDesabilitado: boolean = true;
	ocultaCombos: boolean = true;
	desabilitarInputs: boolean = true;
	titulo : string = "";
	_isSempontuacao: boolean = false;
	_isPorPontuacao : boolean = false;
	_isPorGrupo : boolean = false;
	_isPPBMultiplo : boolean = false;
	fromTelaElaboracao : boolean = false;
	buscarVinculos = false;
  _resumoTecnico : string = "";

  infoPpb : VwMppbPpbEntityVM = new VwMppbPpbEntityVM();


  @Input() _idPpb : number;

	constructor(
    private modal : ModalService,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute
	) {
  }

    ngOnInit() {
      this.buscarInfoPpb(this._idPpb)
	}

  buscarInfoPpb(idPPB: number){
    this._idPpb = idPPB;
		this.applicationService.get("BuscarInfoPpb",this._idPpb).subscribe((result:VwMppbPpbEntityVM) => {
      if(result){
        this.infoPpb = result;
        this._resumoTecnico = result.descricaResumoTecnico;
        this.tipoEtapa = result.tipoEtapa;
        this.titulo = result.descricaoTitulo;
        if(this.infoPpb.tipoEtapa > 0){
          if(this.infoPpb.tipoEtapa  == EnumTipoEtapa.SemPontuacao){
            this._isSempontuacao = true;
          } else if(this.infoPpb.tipoEtapa  == EnumTipoEtapa.PorPontuacao){
            this._isPorPontuacao = true;
          } else if(this.infoPpb.tipoEtapa  == EnumTipoEtapa.PorGrupo){
            this._isPorGrupo = true;
          } else if(this.infoPpb.tipoEtapa  == EnumTipoEtapa.PPBMultiplo){
            this._isPPBMultiplo = true;
          }
        }
        this.exibirGrids = true;
      }
    });
  }

}
