<div class="row form-group" style="margin-left: 2px;">
    <div>
       
        <app-grid-metas-ppb
            [(lista)]="grid.lista"
            [(listaAntigaComparacao)]="listaAntigaComparacao"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            [(idPPB)]="idPPB"
            [(desabilitarInputs)]="habilitarInputs"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)"
            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
            (atualizarListagem)="buscar()"
            (listaAnterior)="salvarListaAnterior($event)"
            [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
            [(isRetificacao)]="isRetificacao"
            [(_isPorPontuacao)]="_isPorPontuacao"
            [(_isPorGrupo)] = "_isPorGrupo"
            [_exibirCheck]="!_isListarVinculados"
            #appGridMetasPPB
        >
        </app-grid-metas-ppb>
    </div>

</div>