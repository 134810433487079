import { PagedItems } from "./PagedItems";

export class subsetorVM extends PagedItems{
	id?: number;
	numeroCodigo?: number;
	descSubsetor: string;
	situacao :number
	justificativaHistorico: string
	descricaoAcao: string
	descricaoJustificativa: string;	
}
export class SubsetorAlteracoesVM{    
    subsetorAntes: subsetorVM = new subsetorVM();
    subsetorDepois: subsetorVM = new subsetorVM();
    numeroUsuario: string;
    nomeUsuario: string;
    dataHoraString: string;
}