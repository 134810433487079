
<div class="row form-group" style="margin-left: 2px;">

    <div>

        <div [ngClass]="(path == 'finalizar' && validacao.pendenciaNcm) ? 'pendencia-validacao' : '' ">
            <a
                *ngIf="habilitarInputs"
                style="margin-right: 10px; margin-top: 5px;"
                class="btn btn-primary btn-sm pull-right"
                data-toggle="tooltip"
                title=""
                (click)="incluir()"
                data-original-title="">
                    <i class="fa fa-plus"></i> Incluir NCM
            </a>

            <app-grid-ncm
                [(habilitarAlteracaoStatus)]="habilitarAlteracaoStatus"
                [(idTipoProduto)]="idTipoProduto"
                (atualizaListagem)="atualizaListagem($event)"
                [(habilitarInputs)]="habilitarInputs"
                [(lista)]="grid.lista"
                [(total)]="grid.total"
                [(size)]="grid.size"
                [(page)]="grid.page"
                [(parametros)]="parametros"
                (onChangeSort)="onChangeSort($event)"
                (onChangeSize)="onChangeSize($event)"
                (onChangePage)="onChangePage($event)">
            </app-grid-ncm>
        </div>
    </div>

</div>

<app-modal-manter-ncm
    #appModalMnaterNcm
    [(idTipoProduto)]="idTipoProduto"
    (atualizaListagem)="atualizaListagem($event)"
    [(habilitarInputs)]="habilitarInputs">
</app-modal-manter-ncm>
