
<div class="row form-group" style="margin-left: 2px;">	
 
    <div class="row form-group" *ngIf="habilitarInputs">
        <div class="col-lg-12">
            <a 
                style="margin-right: 10px; margin-bottom: -10px;"
                class="btn btn-primary btn-sm pull-right" 
                data-toggle="tooltip" 
                title="" 
                (click)="incluirEtapaPPB()"
                data-original-title="">
                    <i class="fa fa-plus"></i> Incluir na Lista
            </a>
        </div>
    </div>

    <div [ngClass]="(path == 'validacao' && validacao.pendenciaEtapaPPB) ? 'pendencia-validacao' : '' ">
        <app-grid-etapas-ppb        
            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
            [(gruposPPB)] = "gruposPPB"
            [(infoGrupos)]="infoGrupos"
            [(habilitarInputs)] = "habilitarInputs"
            (atualizaListagemGrid) = "atualizaListagem($event)" 
            [(_isSempontuacao)] = "_isSempontuacao"
            [(_isPorPontuacao)] = "_isPorPontuacao"
            [(_isPorGrupo)] = "_isPorGrupo"
            [(lista)] = "grid.lista"
            [(total)] = "grid.total"
            [(size)] = "grid.size"
            [(page)] = "grid.page"
            [(parametros)]="parametros"
            (onChangeSort) = "onChangeSort($event)"
            (onChangeSize) = "onChangeSize($event)"
            (onChangePage) = "onChangePage($event)">
        </app-grid-etapas-ppb>
    </div>
    
</div>

<app-modal-incluir-etapas-ppb
    [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
    [(_isPorPontuacao)] = "_isPorPontuacao"
    (atualizaListagem)="atualizaListagem($event)"  
    #appModalIncluirEtapasPPB
    [(habilitarInputs)]="habilitarInputs">
</app-modal-incluir-etapas-ppb>