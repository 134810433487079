import { RequestResponseVM } from './../../../view-model/RequestResponseVM';
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "../../../shared/services/application.service";
import { MessagesService } from "../../../shared/services/messages.service";
import { ModalService } from "../../../shared/services/modal.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { ProdutoVM } from "../../../view-model/ProdutoVM";
import { UnidadeMedidaVM } from "../../../view-model/UnidadeMedidaVM";
import { ViewUnidadeMedidaModulosEntityVM } from "../../../view-model/ViewUnidadeMedidaModulosEntityVM";

@Component({
	selector: 'app-grid-manter-unidade',
	templateUrl: './grid.component.html'
})

export class GridManterUnidadeComponent {

	ocultarTituloGrid = true;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,

	) {

	}

	disable : boolean =false

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;
	@Input() eUsuarioSuframa:boolean = true;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() emitEvent: EventEmitter<any> = new EventEmitter();

	tituloGrid = "Resultado da Pesquisa"
	servico = "ManterUnidade";

	@ViewChild("appModalJustificativaUnidade") appModalJustificativaUnidade: any;

	@ViewChild("appModalHistoricoUnidade") appModalHistoricoUnidade: any;

	changeSize($event) {
    this.page = 1;
		this.onChangeSize.emit($event);
	}

	changeSort($event) {
    this.page = 1;
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	atualizaListagem($event){
		this.emitEvent.emit($event);
	}

	alterarStatusUnidade(operacao: string, item: UnidadeMedidaVM){

		if(operacao == 'Inativar'){

			let obj : ViewUnidadeMedidaModulosEntityVM = new ViewUnidadeMedidaModulosEntityVM();
			obj.codigo = item.numeroCodigo;

			var dscPendencia = "";
			var dscMeaap = " * MEAAP (Módulo de Elaboração, Apresentação e Análise de Projetos) ";

			this.applicationService.get(this.servico+"/ValidarUnidadeVinculadaEmOutrosModulos" , obj).subscribe( (result : ViewUnidadeMedidaModulosEntityVM) => {

				if(result != null)
				{
					if(result.isUsadaMeaap == 1)
					{
						if(result.isUsadaMeaap == 1)
						dscPendencia += dscMeaap;

						this.modal.confirmacao("PREZADO(A), A UNIDADE QUE VOCÊ ESTA DESATIVANDO ESTA SENDO UTILIZADA NOS SEGUINTES MODULOS INTEGRADOS DA ÁREA DE PROJETOS: " + dscPendencia +
											   ". AO DESATIVAR, ESSES MÓDULOS PODERÃO NÃO CONTAR COM ESSE REGISTRO EM SUAS RESPECTIVAS FUNCIONALIDADES. DESEJA CONFIRMAR A OPERAÇÃO? ")
											.subscribe((isConfirmado: any) => {
								if(isConfirmado){
									this.appModalJustificativaUnidade.abrir(operacao,item);
								}
							}
						);
					}
					else{
						dscPendencia += dscMeaap;
						this.modal.confirmacao("PREZADO(A), A UNIDADE QUE VOCÊ ESTA DESATIVANDO FICARÁ INDISPONÍVEL PARA OS SEGUINTES MODULOS INTEGRADOS DA ÁREA DE PROJETOS: " + dscPendencia +
												". AO DESATIVAR, ESSES MÓDULOS PODERÃO NÃO CONTAR COM ESSE REGISTRO EM SUAS NOVAS INCLUSÕES. DESEJA CONFIRMAR A OPERAÇÃO? ")
								  .subscribe((isConfirmado: any) => {
										if(isConfirmado){
											this.appModalJustificativaUnidade.abrir(operacao,item);
										}
								  });
					}

				} else {
					this.modal.infoError(" Falha ao fazer Validação se a Unidade esta sendo utilizada em outros Módulos, não sera possível desativar a Unidade ... ", "Error");
					return
				}
			});

		} else{
			this.appModalJustificativaUnidade.abrir(operacao,item);
		}
	}
	abrirHistorico(item: UnidadeMedidaVM){
		this.appModalHistoricoUnidade.abrir(item)
    }

	// Excluir
	excluirProduto(id: number){

		this.modal.confirmacao("Deseja Realmente Excluir a Unidade?").subscribe( (isConfirmado : any) =>{
			if (isConfirmado) {
				this.applicationService.delete(this.servico, id).subscribe(
					(response: RequestResponseVM) => {
						if(response.statusResponse == 200){
							this.modal.infoSucess("Operação realizada com Sucesso","Success");
						}else{
              //Não é Possível Deletar, Pois o Registro Possui Vínculo Com Outras Tabelas
							this.modal.infoError(response.textResponse, "Error");
						}
						this.onChangePage.emit(this.page);
					}
				);
			}
		});
	}

}
