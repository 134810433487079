/* IMPORTAÇÃO DE MODULOS */
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from "ngx-mask";
import { SharedModule } from "../../shared/shared.module";

import { ManterSubsetorComponent } from "./manter-subsetor.component"
import { IncluirSubsetorComponent } from "./incluir-subsetor/incluir-subsetor.component";
import { GridManterSubsetorComponent } from "./grid/grid.component";
import { ModalJustificativaComponent } from "./modal-justificativa/modal-justificativa.component";
import { ModalHistoricoSubsetorComponent } from "./modal-historico/modal-historico.component";
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		SharedModule,
		AngularEditorModule,
		NgbModule,
		NgxMaskModule.forChild()
	],
	declarations: [
        ManterSubsetorComponent,
		IncluirSubsetorComponent,
		GridManterSubsetorComponent,
		ModalJustificativaComponent,
		ModalHistoricoSubsetorComponent
		
	],
})
export class ManterSubsetorModule {}