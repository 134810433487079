import { PagedItems } from "./PagedItems";
import { UnidadeMedidaVM } from "./UnidadeMedidaVM";

export class NcmEntityVM extends PagedItems{
    unidadeMedida: UnidadeMedidaVM = new UnidadeMedidaVM();
    id : number;
    numeroCodigo: string;
    descricaoNcm: string;
    idUnidadeMedida: number;
    statusNcm: number;
    aliquotaII: number;
    aliquotaIPI: number;
    aliquotaIIVigenciaInicial: Date;
    aliquotaIIVigenciaFinal: Date;
    aliquotaIPIVigenciaInicial: Date;
    aliquotaIPIVigenciaFinal: Date;
    aliquotaIIVigenciaInicialString: string;
    aliquotaIIVigenciaFinalString: string;
    aliquotaIPIVigenciaInicialString: string;
    aliquotaIPIVigenciaFinalString: string;

    descricaoJustificativa: string;
}