<app-grid
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

    <div class="table-responsive no-margin-bottom no-border">
        <table class="table">
            <thead class="table-header-color">
                <tr>
                    <th class="th-100 text-left">Ações</th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="NumeroCodigo"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Código
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="DescricaoNcm"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Descrição
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="UnidadeMedida.Descricao"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Unidade
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="AliquotaII"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Alíquota II
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="AliquotaIIVigenciaInicial"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Data Início
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="AliquotaIIVigenciaFinal"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Data Final
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="AliquotaIPI"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Alíquota IPI
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="AliquotaIPIVigenciaInicial"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Data Início
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="AliquotaIPIVigenciaFinalString"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Data Final
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao
                            field="StatusNcm"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                                Situação
                        </app-ordenacao>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="total>0">
                <tr *ngFor="let item of lista">
                    <td class="text-center" *ngIf="eUsuarioSuframa">
                        <a routerLink="/solicitacoes-ncm/{{item.id}}/visualizar"
                            class="btn btn-default btn-sm" data-toggle="tooltip" title="Visualizar"
                            data-original-title="Visualizar">
                            <i class="fa fa-file-text-o"></i>
                        </a>
                        <a class=" btn btn-default btn-sm " data-toggle="tooltip" title="Histórico"
                            data-original-title="Histórico" (click)="abrirHistorico(item)">
                            <i class="i i-history"></i>
                        </a>
                        <a routerLink="/solicitacoes-ncm/{{item.id}}/editar"
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip" title="Alterar" data-original-title="Alterar">
                            <i class="fa fa-pencil"></i>
                        </a>
                        <a  *ngIf="item.statusNcm == 1"(click)="alterarStatusNcm('Inativar', item)"
                            class=" btn btn-rounded btn-sm btn-icon btn-danger" data-toggle="tooltip" title="Inativar"
                            data-original-title="Inativar">
                            <i class="fa fa-times"></i>

                        </a>
                        <a *ngIf="!item.statusNcm" (click)="alterarStatusNcm('Ativar', item)"
                            class=" btn btn-rounded btn-sm btn-icon btn-primary" data-toggle="tooltip" title="Ativar"
                            data-original-title="Ativar">
                            <i class="fa fa-check"></i>
                        </a>
                    </td>
                    <td class="text-center" *ngIf="!eUsuarioSuframa">
                      <a routerLink="/solicitacoes-ncm/{{item.id}}/visualizar"
                          class="btn btn-default btn-sm" data-toggle="tooltip" title="Visualizar"
                          data-original-title="Visualizar">
                          <i class="fa fa-file-text-o"></i>
                      </a>

                  </td>
                    <td class="text-left">{{item.numeroCodigo == null? 'Não disponível' : item.numeroCodigo}}</td>
                    <td class="text-left">{{item.descricaoNcm}}</td>
                    <td class="text-left">{{item.unidadeMedida.sigla}}</td>
                    <td class="text-left">{{item.aliquotaII}}</td>
                    <td class="text-left">{{item.aliquotaIIVigenciaInicialString}}</td>
                    <td class="text-left">{{item.aliquotaIIVigenciaFinalString}}</td>
                    <td class="text-left">{{item.aliquotaIPI}}</td>
                    <td class="text-left">{{item.aliquotaIPIVigenciaInicialString}}</td>
                    <td class="text-left">{{item.aliquotaIPIVigenciaFinalString}}</td>
                    <td class="text-left" *ngIf="!item.statusNcm" ><b style="color:red;">Inativo</b></td>
                    <td class="text-left" *ngIf="item.statusNcm==1" ><b style="color:green;">Ativo</b></td>
                </tr>
            </tbody>
        </table>
    </div>
</app-grid>

<app-modal-justificativa-ncm
    #appModalJustificativaNcm
    (atualizarListagem)="buscar()"
></app-modal-justificativa-ncm>
<app-modal-historico-ncm
    #appModalHistoricoNcm
></app-modal-historico-ncm>
