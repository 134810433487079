<div #appModalManterNcm class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll; overflow-y: auto;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Incluir NCM no Tipo Selecionado</h4>
            </div>

            <div class="modal-body" style="overflow-y: auto;">

                <div class="col-lg-12">
                    <form class="form form-horinzontal needs-validation" role="form" novalidate>
 
                        <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">
                            <div class="col-sm-8">
                                <label for="descricao" class="">Pesquisar NCM:</label>
                                <div>
                                    <app-drop-list-select2
                                        [InputValue]="parametros.descricaoProdutoTipoNcm"
                                        id="base-legal"
                                        name="base-legal" 
                                        #Ncm
                                        (valorModificado)="parametros.numeroCodigo = $event;"                             
                                        servico="Ncm"
                                        placeholder="Insira o Código ou a Descrição"
                                        selMinimumInputLength="0"
                                        [(ngModel)]="parametros.numeroCodigo">
                                    </app-drop-list-select2>
                                </div>

                                <div class="alert-danger" *ngIf=" _flagNcmNaoInformada ">                                       
                                    <div>                  
                                        <button type="button" class="close" (click)=" _flagNcmNaoInformada = false; ">
                                            <b style="color:black;">&times;</b>
                                        </button>                           
                                        <b style="margin-left: 15px;">Informe a NCM</b>
                                    </div>
                                </div>

                                <div class="alert-danger" *ngIf=" _flagNcmJaCadastrada ">                                       
                                    <div>                  
                                        <button type="button" class="close" (click)=" _flagNcmJaCadastrada = false; ">
                                            <b style="color:black;">&times;</b>
                                        </button>                           
                                        <b style="margin-left: 15px;"> Essa NCM já esta vinculada ao Tipo Produto </b>
                                    </div>
                                </div>

                            </div>
                        </div>		
                    </form>  
                </div>
                            
                
            </div>
             
            <div class="modal-footer" style="padding: 10px 15px;">                
                <button
                    id="fechar-ncm"
                    name="fechar-ncm"
                    (click)="fechar()"
                    class="btn btn-sm btn-default" 
                    data-dismiss="modal">
                        <i class="fa fa-times"></i> Fechar
                </button>
                <button 
                    id="salvar-ncm"
                    name="salvar-ncm"
                    data-toggle="tooltip" 
                    title="salvar"
                    data-original-title="salvar"                        
                    (click)="validar()"
                    type="submit"
                    class="btn btn-sm btn-primary" 
                    data-dismiss="modal" 
                    data-toggle="modal" 
                    data-backdrop="static" 
                    data-keyboard="false">
                        <i class="fa fa-save m-r-xs"></i> Salvar
                </button>
            </div>

        </div>

    </div>
</div>
<div #appModalManterNcmBackground class="modal-backdrop fade in" style="display: none;"></div>