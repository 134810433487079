<div id="conteudoProdutoPadronizadoPdf" class="panel-body" class="col-lg-12" style="font-size: 12px;" *ngIf="!esconderRelatorio">
	<div>
		<div class="row">
			<div class="col-md-12">
				<div class="form-group m-n text-center">
					<img src="../../../../../assets/images/brasao.jpeg" alt="Logo Brasao"
						 style="width: 9%;" />
				</div>
			</div>
		</div>

		<div class="form-group m-n text-center">
			<h5>Superintendência da Zona Franca de Manaus</h5>
			<h5>SUFRAMA</h5>
		</div>

		<div class="form-group m-n text-center">
			<label class="control-label">RELATÓRIO DE PRODUTO PADRONIZADO</label>
		</div>
	</div>
  <br/>
  <div class="form-group">
    <section>
      <div class="row form-group">
        <span class="col-lg-12">BASE LEGAL: {{parametrosFormatado.descBaseLegalExistente}}</span>
      </div>
      <div class="row form-group">
        <span class="col-lg-3">BEM DE INFORMÁTICA: {{parametrosFormatado.sitBemInformativa}}</span>
        <span class="col-lg-6">COMPONENTE: {{parametrosFormatado.sitComponente}}</span>
        <span class="col-lg-3">SUBSETOR: {{test}}</span>
      </div>
      <div class="row form-group">
        <span class="col-lg-3">CRI: {{parametrosFormatado.idCoeficienteReducao}}</span>
        <span class="col-lg-6">NCM: {{parametrosExportacao.descricaoNcm}}</span>
        <span class="col-lg-3">SITUAÇÃO: {{parametrosFormatado.sitProduto}}</span>
      </div>
    </section>
  </div>

    <!-- ##################################################### -->
	<!-- ##################### GRUPOS PPB #################### -->
    <!-- ##################################################### -->

	<section>
		<div>
        <table class="table table-sm table-striped">
            <thead class="table-header-color">
                <tr>
                    <th class="th-sortable unset-nowrap text-left">Código</th>
                    <th class="th-sortable unset-nowrap text-left">Produto</th>
                </tr>
            </thead>
            <tbody >
                <tr *ngFor="let item of objetoExportacao">
                    <td class="text-left">
                      {{item.numeroCodigo}}
                    </td>
                    <td class="text-left" >
                      {{item.descProduto}}
                    </td>
                </tr>
            </tbody>
        </table>
		</div>
	</section>


<!-- FIM CONTEUDO RELATORIO PDF -->
