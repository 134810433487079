<app-grid
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">



    <div class="radio i-checks radio-sm" style=" margin-left: 10px;margin-right: 1px; margin-top: -10px;">
      <label class="input-sm" >
          <input
              name="radio-todos"
              id="radio-todos"
              type="radio"
              (change)="filtrarLista()"
              [(ngModel)]="parametros.statusTipo"
              value="3"><i></i> <b>TODOS</b>
      </label>
      <label class="input-sm" >
          <input
              (change)="filtrarLista()"
              name="radio-ativo"
              id="radio-ativo"
              type="radio"
              [(ngModel)]="parametros.statusTipo"
              value="1"><i></i> <b>ATIVOS</b>
      </label>
      <label class="input-sm" >
          <input
              (change)="filtrarLista()"
              name="radio-inativo"
              id="radio-inativo"
              type="radio"
              [(ngModel)]="parametros.statusTipo"
              value="2"><i></i> <b>INATIVOS</b>
      </label>
  </div>

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
			<thead class="table-header-color">
				<tr>
					<th class="th-100 text-left" *ngIf="habilitarInputs">Ações</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="NumeroCodigo" [sorted]="" (onChangeSort)="changeSort($event)">Código</app-ordenacao>
					</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="Descricao" [sorted]="" (onChangeSort)="changeSort($event)">Descrição </app-ordenacao>
					</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="TipoAgregacao" [sorted]="" (onChangeSort)="changeSort($event)">Agregação</app-ordenacao>
					</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="SubSetor.DescSubsetor" [sorted]="" (onChangeSort)="changeSort($event)">Subsetor de Destino</app-ordenacao>
					</th>
                    <th class="th-100 text-left" *ngIf="!habilitarInputs">
                        <app-ordenacao field="StatusTipo" [sorted]="" (onChangeSort)="changeSort($event)">Status</app-ordenacao>
                    </th>
				</tr>
			</thead>
			<tbody *ngIf="total > 0">
				<tr *ngFor="let item of lista">
                    <td class="text-left" *ngIf="habilitarInputs">
                        <a
                            *ngIf=" !habilitarAlteracaoStatus || (path =='editar' && habilitarAlteracaoStatus)"
                            (click)="abrirModal(item)"
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            title="Alterar"
                            data-original-title="Alterar">
                                <i class="fa fa-pencil"></i>
                        </a>
                        <a
                            *ngIf=" !habilitarAlteracaoStatus "
                            (click)="excluir(item)"
                            class="btn btn-danger btn-sm"
                            data-toggle="tooltip"
                            title="Excluir"
                            data-original-title="Excluir">
                                <i class="fa fa-trash-o"></i>
                        </a>
                        <a
                            *ngIf="(path =='editar' && habilitarAlteracaoStatus) "
                            (click)="excluirTipoAtivo(item)"
                            class="btn btn-danger btn-sm"
                            data-toggle="tooltip"
                            title="Excluir"
                            data-original-title="Excluir">
                                <i class="fa fa-trash-o"></i>
                        </a>
                        <a
                            (click)="changeStatus('Ativar', item)"
                            *ngIf=" item.existeNcmVinculada  && item.statusTipo === 0 && path != 'visualizar' "
                            class=" btn btn-rounded btn-sm btn-icon btn-danger"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Inativo">
                            <i class="fa fa-times"></i>
                        </a>
                        <a
                            (click)="changeStatus('Inativar', item)"
                            *ngIf=" item.existeNcmVinculada  && item.statusTipo === 1 && path != 'visualizar' "
                            class="btn btn-rounded btn-sm btn-icon btn-primary"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Ativo">
                            <i class="fa fa-check"></i>
                        </a>
                    </td>
					<td class="text-left">
                        <input
                            name="radio-select-tipo-produto"
                            type="radio"
                            [attr.checked]="item.id === itemJaCheckado ? true : null"
                            value="1"
                            (click)="sendAtualizacaoNcm(item.id)">
                        {{ item.numeroCodigo }}
                    </td>
                    <td class="text-left"> {{ item.descricao }}  </td>
                    <td class="text-left"> {{ definirTipoAgregacao(item.tipoAgregacao) }} </td>
                    <td class="text-left"> {{ item.subSetor.descSubsetor ? item.subSetor.descSubsetor : '--' }} </td>
                    <td *ngIf="!habilitarInputs" class="text-left"> {{ item.statusTipo == 1 ? 'ATIVO': 'INATIVO' }}  </td>
                    <!--
                    <td class="text-left"> 999999 </td>
                    <td class="text-left"> Exportação </td>
                    -->
				</tr>
			</tbody>
		</table>
	</div>
</app-grid>

<app-modal-justificativa-produto
    (changeOperation)="recebeAlteracaoJustificativa($event)"
    [(data)]="_dataJustificativa"
    #modalJustificativa>
</app-modal-justificativa-produto>

<app-modal-manter-tipo-produto
    (atualizaListagem)="receberAtualizacaoListagem($event)"
    [(idProduto)]="idProduto"
    #modalManterTipoproduto
    [(habilitarInputs)]="habilitarInputs">
</app-modal-manter-tipo-produto>
