import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit  } from "@angular/core";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { ValidationService } from "../../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { Validators, FormBuilder } from '@angular/forms';
import { ModalService } from '../../../../../shared/services/modal.service';
import { ProdutoTipoNcmVM } from '../../../../../view-model/ProdutoTipoNcmVM';
import { ChangeNcmService } from '../../../../../shared/services/changeListagemNcm.service';
import { ChangeListagemTipoProdutoService } from '../../../../../shared/services/changeListagemTipoProduto.service';

@Component({
	selector: 'app-modal-manter-ncm',
	templateUrl: './modal-manter-ncm.component.html'
})
export class ModalManterNcm implements OnInit {

	parametros: ProdutoTipoNcmVM = new ProdutoTipoNcmVM();
	_flagNcmNaoInformada : boolean = false;
	_flagNcmJaCadastrada : boolean = false;

	@Input() habilitarInputs: boolean;
	@Input() idTipoProduto : number;
	@Output() atualizaListagem: EventEmitter<any> = new EventEmitter();

    @ViewChild('appModalManterNcm') appModalManterNcm : any;
	@ViewChild('appModalManterNcmBackground') appModalManterNcmBackground : any;
	@ViewChild('Ncm') Ncm;
	
	servico = 'Ncm';

	constructor(
		private modal : ModalService,
		private applicationService: ApplicationService,
		private ChangeNcmService: ChangeNcmService,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute,
		private ChangeListagemTipoProdutoService : ChangeListagemTipoProdutoService
	) {
    }

    ngOnInit() {
	}

	abrir(operation : string, id? : number){	

		this.iniciarFlags();
			
		if(operation != 'new')
			this.buscar(id);

        this.appModalManterNcmBackground.nativeElement.style.display = 'block';
		this.appModalManterNcm.nativeElement.style.display = 'block';
    }

    fechar(){
        this.appModalManterNcmBackground.nativeElement.style.display = 'none';
		this.appModalManterNcm.nativeElement.style.display = 'none';
    }

	buscar(id : number){
		this.applicationService.get(this.servico, id).subscribe( (result : ProdutoTipoNcmVM ) => {
			this.parametros = result;	
		});
	}

	validar(){

		if(!this.parametros.numeroCodigo){
			this._flagNcmNaoInformada = true;
			return false;
		}

		this.parametros.numeroCodigo = this.Ncm.model.text.split(" | ")[0].replace(" ", "");
		this.parametros.descricaoProdutoTipoNcm = this.Ncm.model.text.split(" | ")[1].replace(" ", "");
		this.parametros.idProdutoTipo = this.idTipoProduto;

		this.salvar();
	}

	salvar(){
		this.applicationService.post(this.servico, this.parametros).subscribe( (result : boolean) => {
			if(result){
				this.ChangeNcmService.atualizaListagemNcm(this.idTipoProduto);
				this.ChangeListagemTipoProdutoService.atualizaListagemPPB(true);
				this.fechar();		
				this.modal.infoSucess("Operação realizada com Sucesso", "Success");
			} else {
				this._flagNcmJaCadastrada = true;
				this._flagNcmNaoInformada = false;
				return false;
			}			
		});
	}

	iniciarFlags(){
		this.Ncm.clear();
		this._flagNcmJaCadastrada = false;
		this._flagNcmNaoInformada = false;
		this.parametros = new ProdutoTipoNcmVM();
	}

	

}
