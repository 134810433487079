import { Component, EventEmitter, Injectable, OnInit, Output, Input, ViewChild } from "@angular/core";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { ValidationService } from "../../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { CondicionantesPPBComponent } from "../condicionantes-ppb.component";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EnumRestResponse } from '../../../../../shared/enums/EnumRestResponse';
import { ModalService } from '../../../../../shared/services/modal.service';
import { Validators, FormBuilder } from '@angular/forms';
import { CondicionanteVM } from "../../../../../view-model/CondicionanteVM";

@Component({
	selector: 'app-modal-incluir-condicionantes-ppb',
	templateUrl: './modal-incluir-condicionantes-ppb.component.html'
})
export class ModalIncluirCondicionantesPPBAtuacao implements OnInit {

	listaDadosAtuacao: any;
	ocultarInputAnexo = false;
	parametros: CondicionanteVM = new CondicionanteVM();
	isDesabilitado: boolean = true;
	ocultaCombos: boolean = true;
	servico = "CondicionantesPPB";
	flagCadastroContinuo : boolean = false;

	_errorDataInicioNaoPreenchida : boolean = false;
	_errorDataFinalMenorDataCorrente : boolean = false;
    _errorDataFinalMaiorInicial : boolean = false;

	_flagDesabilitaTopico : boolean = false;

	_idPPBRota : number;

	name = 'Angular 12.1.2';
	htmlContent = '';
	flagHabilitaComponenteTextual : boolean = true;

	@Input() dataVigenciaInicialPPB : string;
    @Output() atualizaListagem: EventEmitter<any> = new EventEmitter();
    @ViewChild('appModalIncluirCondicionantesPPB') appModalIncluirCondicionantesPPB : any;
	@ViewChild('appModalIncluirCondicionantesPPBBackground') appModalIncluirCondicionantesPPBBackground : any;

	constructor(
		private modal : ModalService,
		private CondicionantesPPBComponent: CondicionantesPPBComponent,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private fb: FormBuilder,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
    }

		/***********************************************/
		condicionateForm = this._initializeValidations();
		get topico(){
			return this.condicionateForm.get('topico');
		}
		get descricaocondicionante(){
			return this.condicionateForm.get('condicionante');
		}
		get dataInicial(){
			return this.condicionateForm.get('dataInicial');
		}
		get dataFinal(){
			return this.condicionateForm.get('dataFinal');
		}
		_initializeValidations(){
			return this.fb.group({
				topico : ['', null],
				descricaocondicionante : ['', null],
				dataInicial : [ '', [Validators.required]],
				dataFinal : [ '', null],
				condicionante : [ '', null]
			});
		}
		/************************************************/

	config: AngularEditorConfig = {
		editable: this.flagHabilitaComponenteTextual,
		height: '15rem',
		minHeight: '5rem',
		placeholder: 'Enter text here...',
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};

    ngOnInit() {
	}

	public abrir(idPPB : number, operation : string, idCondicionante? : number){	

		this.parametros.idPPB = idPPB;

		if(operation == "edit"){
			this.buscar(idCondicionante);
		} else {
			this.parametros.dataInicioVigenciaString = this.dataVigenciaInicialPPB;
		}

        this.appModalIncluirCondicionantesPPBBackground.nativeElement.style.display = 'block';
		this.appModalIncluirCondicionantesPPB.nativeElement.style.display = 'block';
    }


    fechar(){
		this.limparVariaveis();
        this.appModalIncluirCondicionantesPPBBackground.nativeElement.style.display = 'none';
		this.appModalIncluirCondicionantesPPB.nativeElement.style.display = 'none';
    }

	validar(){

		var dataInicioString = this.condicionateForm.get('dataInicial').value;
		var dataFimString = this.condicionateForm.get('dataFinal').value;

		if(!dataInicioString){

			this._errorDataInicioNaoPreenchida = true;
			this.condicionateForm.get('dataInicial').reset();
			this.condicionateForm.get('dataFinal').reset();

			return false;
		} else{
			this._errorDataInicioNaoPreenchida = false;
		}
		

		if(dataInicioString && dataFimString){

			if(this.validation.validaDataMenorDataVigente(dataFimString)){

				this._errorDataFinalMenorDataCorrente = true;
				this.condicionateForm.get('dataInicial').reset();
				this.condicionateForm.get('dataFinal').reset();
	
				return false;
			} else {
				this._errorDataFinalMenorDataCorrente = false;
			}

			if(this.validation.validaDataInicialMaiorDataFinal(dataInicioString , dataFimString) == true){

				this._errorDataFinalMaiorInicial = true;
				this.condicionateForm.get('dataInicial').reset();
				this.condicionateForm.get('dataFinal').reset();

				return false;
			} else {
				this._errorDataFinalMaiorInicial = false;
			}				
		}	
		
		if(this.parametros.descricaCondicaoString)
			this.parametros.descricaCondicaoString = String(this.parametros.descricaCondicaoString);
	
		this.salvar();
	}

	salvar(){	
		
		var idPPB = this.parametros.idPPB;
		this.applicationService.post(this.servico, this.parametros).subscribe((retorno : EnumRestResponse) => {		
			if(retorno > 0) {				
				this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success");	
				
				if(!this.flagCadastroContinuo){
					this.fechar();
				} else {	
					this.condicionateForm.reset();
					this.limparVariaveis();
					this.parametros.idPPB = idPPB;
				}

				this.atualizaListagem.emit(idPPB);
			}	
		});		
	}

	limparVariaveis(){
		this.parametros = new CondicionanteVM();
		this.condicionateForm.reset();
		this._errorDataFinalMaiorInicial = false;
		this._flagDesabilitaTopico = false;
		this._initializeValidations();
	}

	transformStringToBinary(input : string){
		var bytes = [];
		if(input.length > 0){
			for (var i = 0; i < input.length; i++) {
				var code = input.charCodeAt(i);  
  				bytes = bytes.concat([code]);
			}
		}
		return bytes;
	}

	buscar(idCondicionante : number){
		var objeto : CondicionanteVM = new CondicionanteVM()
		objeto.id = idCondicionante;
		this.applicationService.get(this.servico, objeto).subscribe((result : CondicionanteVM ) => {

			this.parametros = result;

			if(result.statusCondicionante == 2){ //SUBSTITUTO
				this._flagDesabilitaTopico = true;
			}

		});
	}

}
