import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ApplicationService} from '../../services/application.service';
import {ArtigoVM} from "../../../view-model/ArtigoVM";
import {ModalService} from "../../services/modal.service";
import {RequestResponseVM} from "../../../view-model/RequestResponseVM";

@Component({
  selector: 'app-artigo-drop-list-select',
  templateUrl: './artigo-drop-list.component.html',
  styleUrls: ['./artigo-drop-list.component.css'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: ArtigoDropListComponent, multi: true}],
})

export class ArtigoDropListComponent implements OnInit {

  @Input() isDisabled = false;
  @Input() isRequired = false;
  @Input() placeholder: string;
  @Input() servico: string = "ManterProdutoPadrao";
  @Input() selMinimumInputLength: number;
  @Input() isCloseNormal: boolean = false;
  @Input() habilitarInputs: boolean;
  @Input() PpbId: number;
  @Input() tela: string;
  @Input() isMultiplo: boolean;
  @Output() isMultiploChange = new EventEmitter<boolean>();

  @Output()
  onSelected: EventEmitter<any> = new EventEmitter();

  @Output()
  onDelete: EventEmitter<any> = new EventEmitter();

  @ViewChild('valorInput') valorInput;

  artigos: any = new Array<ArtigoVM>();

  @Input()
  artigosSelecionados: Array<ArtigoVM> = new Array<ArtigoVM>();

  parametros: any = {};
  openDrop: boolean = false;
  focused: boolean = false;
  clearValue: boolean = false;

  mensagem: string;
  mensagemError: string;

  constructor(
    private applicationService: ApplicationService,
    private modalService: ModalService,
  ) {

  }

  ngOnInit() {
    this.selMinimumInputLength = this.selMinimumInputLength || 0;
    this.mensagemError = "Nenhum registro encontrado.";
    this.mensagem = this.mensagem || this.getMensagemInformacao();
  }

  getMensagemInformacao() {
    return "Digite e pressione enter";
  }

  reset() {
    this.artigos = null;
  }

  getServiceUrl() {
    return `ArtigosBaseLegal/base-legal`;
  }

  public onClear(limpar: boolean) {
    if (limpar)
      this.valorInput.nativeElement.value = "";
    this.artigos = null;
    this.clearValue = false;
    this.openDrop = false;
    this.focused = false;
    this.mensagem = this.getMensagemInformacao();
  }

  onFocus() {
    this.focused = true;
  }

  onKeyUp() {
    if (this.isDisabled) {
      return;
    }

    if (this.valorInput.nativeElement.value == undefined || this.valorInput.nativeElement.value.length < this.selMinimumInputLength) {
      this.artigos = null;
      this.clearValue = false;
      this.openDrop = false;
      this.mensagem = this.getMensagemInformacao();
      return;
    }

    if (this.valorInput.nativeElement.value.length > 0) {
      this.valorInput.nativeElement.setCustomValidity('');
    }

    this.parametros.DescricaoTopico = this.valorInput.nativeElement.value;
    if (this.valorInput.nativeElement.value.length >= this.selMinimumInputLength) {
      this.callService(this.getServiceUrl());
      this.clearValue = true;
    }
    setTimeout(() => {
      this.valorInput.nativeElement.focus();
    });
  }

  callService(url: string) {

    this.valorInput.nativeElement.disabled = true;

    this.parametros.ppbId = this.PpbId
    this.applicationService.get(url, this.parametros).subscribe(result => {

      this.artigos = result;

      if (result[0] != undefined && this.artigos.length > 0) {
        this.openDrop = true;
        this.mensagem = this.getMensagemInformacao();
      } else {
        this.openDrop = true;
        this.artigos = null;
        this.mensagem = this.mensagemError;
        this.modalService.infoError("Artigos devem ser cadastrados no sistema MPPB para finalizar esta etapa.", "Ação necessária");
      }

      this.valorInput.nativeElement.disabled = false;
      if (this.openDrop) {
        this.valorInput.nativeElement.focus();
      } else {
        this.valorInput.nativeElement.value = "";
        this.artigos = null;
      }
    });
  }

  onSelect(artigo: ArtigoVM) {
    this.openDrop = false;
    this.focused = false;

    var intemJaCadastrado = this.artigosSelecionados.filter(x => x.id == artigo.id).length > 0;
    if (intemJaCadastrado) {
      return;
    }

    this.onSelected.emit(artigo);
  }

  public clear() {
    this.onClear(true);
  }

  OnClickRemoverArtigo(artigoId: number) {

    if (this.tela !== 'visualizar') {
      this.modalService.confirmacao("Deseja realmente excluir este artigo?").subscribe((isConfirmado) => {
        if (isConfirmado) {
          const url = `${artigoId}/Artigo`;
          this.applicationService.deletar(this.servico, url).subscribe((result: RequestResponseVM) => {
            if (result.statusResponse == 200) {
              this.modalService.infoSucess("Operação realizada com Sucesso", "Success");
              this.onDelete.emit(artigoId)
            } else {
              this.modalService.infoError(result.textResponse, "Erro");
              return false;
            }
          });
        }
      })
    }
  }
}
