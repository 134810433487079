import { RequestResponseVM } from '../../../../../../view-model/RequestResponseVM';
import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../../shared/services/application.service';
import { ValidationService } from '../../../../../../shared/services/validation.service';
import { MessagesService } from '../../../../../../shared/services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../../shared/services/modal.service';
//import { PPBGrupoVM } from "../../../../../view-model/PPBGrupoVM";
import { PPBMultiploComponent } from '../ppb-multiplo.component'
//import { ChangeEtapaPPBService } from '../../../../../shared/services/changeEtapaPPB.service';
//import { ItensPPBVM } from '../../../../../view-model/ItensPPBVM';
//import { OrderGrid } from '../../../../../shared/services/orderGridByNumeroSequencia.service';
//import { EnviaParametrosSelecaoItemVM } from '../../../../../view-model/EnviaParametrosSelecaoItemVM';

enum EnumControllPPB {
  OK = 1,
  ERR_DATA_VIGENCIA_VENCIDA = 2,
  ERR_EXISTE_COPIA_EM_ANDAMENTO = 3,
  ERR_ITEM_MARCADO_COMO_EXCLUIDO = 4
}

@Component({
  selector: 'app-grid-ppb-multiplo',
  templateUrl: './grid.component.html'
})

export class GridPPBMultiploComponent {

  ocultarTituloGrid = true;
  path: string;
  _idRota : number;

  constructor(
    //private changeEtapaPPBService: ChangeEtapaPPBService,
    private gruposPPBComponent: PPBMultiploComponent,
    private modal: ModalService,
    private validation: ValidationService,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private msg: MessagesService,
    private router: Router,
    //private ordenarGrid: OrderGrid
  ) {
    this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
    this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
    this._idRota = Number(this.route.snapshot.params['idTipoProduto']);
  }

  @Input()
  lista: any[];

  @Input()
  total: number;

  @Input()
  size: number;

  @Input()
  page: number;

  @Input()
  sorted: string;

  @Input()
  habilitarInputs: boolean;

  @Input()
  exportarListagem: boolean;

  @Input()
  parametros: any = {};

  @Input()
  formPai: any;

  @Input()
  idPPB: any;

  @Input()
  dataVigenciaInicialPPB: string;

  @Input()
  isAlteracaoParaRevogacao: boolean;

  @Input() isRetificacao : boolean;

  @Input()_exibirCheck : boolean = true;
  
  @Input() listaAntigaComparacao: any = {};

  EXCLUIDO: number = 4;
  _idPPBRota: number;

  @Output() listaAnterior : EventEmitter<any> = new EventEmitter();
  @Output() onChangeSort: EventEmitter<any> = new EventEmitter();
  @Output() onChangeSize: EventEmitter<any> = new EventEmitter();
  @Output() onChangePage: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() atualizaListagemGrid: EventEmitter<any> = new EventEmitter();

  servico = "PPBMultiplo";
  tituloGrid = 'PPB Multiplos';

  @ViewChild('appModalIncluirGruposPPB') appModalIncluirGruposPPB: any;
  @ViewChild ('appModalMoverPorPosicao') appModalMoverPorPosicao : any;
  @ViewChild ('appModalHistoricoListagemMultiplos') appModalHistoricoListagemMultiplos : any;


  changeSize($event: any) {
    this.listaAnterior.emit(this.lista);
    this.onChangeSize.emit($event);
  }

  changeSort($event: any) {
    this.sorted = $event.field;
    this.onChangeSort.emit($event);
    this.changePage(this.page);
  }

  changePage($event: any) {
    this.page = $event;
    this.onChangePage.emit($event);
  }

  atualizaListagem($event) {
    this.atualizaListagemGrid.emit($event);
  }

  formatDate(date: Date) {
    return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date);
  }

  // validarSePermiteAbrirModal(item: PPBGrupoVM) {

  //   let serviceObject: ItensPPBVM = new ItensPPBVM();
  //   serviceObject.idItemCorrente = Number(item.id);
  //   serviceObject.idPPBCorrente = Number(this._idPPBRota);
  //   serviceObject.idPPBVinculo = Number(item.idPPB);

  //   this.applicationService.post(this.servico + "/PostValidacaoModalEdicao", serviceObject).subscribe((result: ItensPPBVM) => {
  //     if (result.statusControllModal == EnumControllPPB.OK) {
  //       let obj: PPBGrupoVM = new PPBGrupoVM()
  //       obj.idPPB = Number(this._idPPBRota);
  //       obj.id = result.idItemCorrente;
  //       this.atualizaListagens(obj.idPPB);
  //       this.abrirModal(obj);
  //     } else if (result.statusControllModal == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
  //       this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação").subscribe(() => false);
  //     } else if (result.statusControllModal == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO) {
  //       this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação").subscribe(() => false);
  //     }
  //   });
  // }

  // abrirModal(item: PPBGrupoVM) {
  //   this.appModalIncluirGruposPPB.abrir(item.idPPB, "editar", item.id);
  // }


 

  validacaoIsRetificacao(item : any) : boolean{
    if(this.isRetificacao){
      if(item.idPPB == this.idPPB){
        return true
      }
      else{
        return false
      };
    }
    return true;
    }

   async salvar(){
      this._idRota;
      var obj = {
        ListPBMultiploEntityVm : this.lista,
        IdPpb : this.idPPB,
        IdProduto : this._idRota
      }
      if(this.lista.length > 0 && this.lista != null){
        await this.applicationService.post(this.servico, obj).toPromise();
      };
    }

}
