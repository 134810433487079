import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-modal-justificativa-produto',
	templateUrl: './modal-justificativa.component.html'
})

export class ModalJustificativaProdutoComponent implements OnInit {

	_flagCampoVazio : boolean = false;
	_justificativa : string = "";

	@Input() data: any = {};
	@Output() changeOperation: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalJustificativa') appModalJustificativa : any;
	@ViewChild('appModalJustificativaBackground') appModalJustificativaBackground : any;

	@ViewChild('close') close!: ElementRef;
	@ViewChild('ok') ok!: ElementRef;

	constructor() {}

	ngOnInit() {
		this._flagCampoVazio = false;
		this._justificativa = "";
	}

	abrir(){
		this.appModalJustificativaBackground.nativeElement.style.display = 'block';
		this.appModalJustificativa.nativeElement.style.display = 'block';
	}

	fechar(){
		this.changeOperation.emit(false);
		this.appModalJustificativaBackground.nativeElement.style.display = 'none';
		this.appModalJustificativa.nativeElement.style.display = 'none';
	}

	validar(){
		if(!this._justificativa && this.data.isObrigatorioPreencherCampo){
			this._flagCampoVazio = true;
			return false;
		}
		
		var object : any = {};
		object.txt = this._justificativa; 
		object.objetoAlteracao = this.data.objetoAlteracao;
		object.tipoOperacao = "ok";

		this.changeOperation.emit(object);

		this._justificativa = "";
		this.appModalJustificativaBackground.nativeElement.style.display = 'none';
		this.appModalJustificativa.nativeElement.style.display = 'none';
	}


}
