<div #appModalVinculos class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">PPB Vinculados</h4>
            </div>

            <div class="modal-body"  style="overflow-y: auto; max-height: calc(100vh - 210px)">

                <form>
                    <div class="row form-group" style="margin-left: 2px;">                        
                        <div class="col-sm-12">
                            <div class="dd nestable" id="nestable2" data-group="1">
                                <ol #lista class="dd-list" >
                                    <li *ngIf="listaPortaria && listaPortaria.length !== 0" class="dd-item" data-id="10" >
                                        <div class="dd-handle bg-info" style="background-color: #00aeef;">                        
                                            DOCUMENTOS VINCULADOS
                                            <app-collapse-row-button 
                                                #collapse1
                                                style="cursor: pointer;"  
                                                target="{{'portaria-' + 1}}"
                                                >
                                            </app-collapse-row-button>
                                        </div>

                                        <div *ngIf="listaPortaria && listaPortaria.length !== 0">
                                            <div class="dd-list" id="{{ 'portaria-' + 1 }}" style="display:none">

                                                <div *ngFor="let item of listaPortaria">
                                                    <div data-id="11" colspan="6" style="margin-top: 4px;">
                                                        <div
                                                            style="height: 35px;"
                                                            [ngClass]=
                                                            "
                                                            ((item.ppb.statusPPB === 2 || item.ppb.statusPPB === 3) && item.ppb.tipoPublicacao !== 4) ? 'bg-info' : 
                                                            (item.ppb.statusPPB === 4) ? 'bg-danger' : 
                                                            ((item.ppb.statusPPB === 2 || item.ppb.statusPPB === 3) && item.ppb.tipoPublicacao === 4) ? 'bg-warning' : '' 
                                                            " 
                                                            >
                                                            <span style="margin-left: 5px;">{{item.ppb.descricaoTitulo}}</span>
                                                        </div>
                                                    </div>                                                    
                                                </div>

                                            </div>
                                        </div>

                                    </li>
                                    
                                    <li *ngIf="listaAgregacao && listaAgregacao.length !== 0" class="dd-item dd3-item" data-id="15">
                                        <div class="dd3-handle">
                                            <i class="fa fa-navicon bg-warning"></i>
                                        </div>
                                        <div class="dd3-content bg-warning">                        
                                            AGREGAÇÃO
                                            <app-collapse-row-button 
                                                #collapse2
                                                style="cursor: pointer;"  
                                                target="{{'desag-' + 1}}"
                                            >
                                            </app-collapse-row-button>
                                        </div>
                                        <div *ngIf="listaAgregacao && listaAgregacao.length !== 0">
                                            <div class="dd-list" id="{{ 'desag-' + 1 }}" style="display:none" *ngFor="let item of listaAgregacao">
                                                
                                                <div *ngFor="let item of listaAgregacao">
                                                    <div data-id="11" colspan="6" style="margin-top: 4px;">
                                                        <div
                                                            style="height: 35px;"
                                                            [ngClass]=
                                                            "
                                                            ((item.ppb.statusPPB === 2 || item.ppb.statusPPB === 3) && item.ppb.tipoPublicacao !== 4) ? 'bg-info' : 
                                                            (item.ppb.statusPPB === 4) ? 'bg-danger' : 
                                                            ((item.ppb.statusPPB === 2 || item.ppb.statusPPB === 3) && item.ppb.tipoPublicacao === 4) ? 'bg-warning' : '' 
                                                            ">
                                                            <span style="margin-left: 5px;">{{item.ppb.descricaoTitulo}}</span>
                                                        </div>
                                                    </div>
                                                </div>                                           
                                            </div>
                                        </div>
                                    </li>

                                    
                                </ol>
                            </div>
                        </div>
                    </div>
            
                    <div class="row form-group" style="margin-left: 2px;">                         
                        <div class="row" *ngIf="listaResumoTecnico.total > 0">
                            <div class="col-lg-12">
                                <section style="margin-right: 15px;" class="panel panel-default">                                    
                                    <app-grid-vinculos-ppb 
                                    [(lista)]="listaResumoTecnico.items"
                                    [(total)]="listaResumoTecnico.total"
                                    [(size)]="listaResumoTecnico.size"
                                    [(page)]="listaResumoTecnico.page"
                                    [(parametros)]="parametros"
                                    (onChangeSort)="onChangeSort($event)"
                                    (onChangeSize)="onChangeSize($event)"
                                    (onChangePage)="onChangePage($event)">
                                    </app-grid-vinculos-ppb>
                                </section> 
                            </div>
                        </div>                       
                    </div>

                </form>

            </div>
           
            <div class="modal-footer" style="padding: 10px 15px;">
                <a 
                    (click)="fechar()"
                    class="btn btn-sm btn-default" 
                    data-dismiss="modal">
                        <i class="fa fa-long-arrow-left"></i> Voltar
                </a>
            </div>
        </div>
    </div>
</div>
<div #appModalVinculosBackground class="modal-backdrop fade in" style="display: none;"></div>