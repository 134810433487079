import { RequestResponseVM } from '../../../../../../view-model/RequestResponseVM';
import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../../shared/services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../../shared/services/modal.service';
//import { ItensPPBVM } from '../../../../../view-model/ItensPPBVM';
//import { MetaVM } from '../../../../../view-model/MetaVM';
//import { OrderGrid } from '../../../../../shared/services/orderGridByNumeroSequencia.service';
//import { EnviaParametrosSelecaoItemVM } from '../../../../../view-model/EnviaParametrosSelecaoItemVM';

enum EnumControllPPB{
	OK = 1,
	ERR_DATA_VIGENCIA_VENCIDA = 2,
	ERR_EXISTE_COPIA_EM_ANDAMENTO = 3,
	ERR_ITEM_MARCADO_COMO_EXCLUIDO = 4
}

@Component({
	selector: 'app-grid-metas-ppb',
	templateUrl: './grid.component.html'
})

export class GridMetasPPBComponent {

    tituloGrid = "Metas do PPB";
	servico = "MetasPPB";
	_idRota : number;
    @Input()
		lista!: any[];
	@Input()
		total: number;
	@Input()
		size!: number;
	@Input()
		page!: number;
	@Input()
		sorted!: string;
    @Input()
		parametros: any = {};
	@Input()
		idPPB: number;
  @Input()
		_isPorGrupo: boolean;
	@Input()
		desabilitarInputs: boolean = false;
	@Input()
		dataVigenciaInicialPPB : string;
	@Input()
		isAlteracaoParaRevogacao : boolean;
	@Input()
		_isPorPontuacao : boolean;

	@Input() isRetificacao : boolean;

	@Input()_exibirCheck : boolean = true;

	@Input() listaAntigaComparacao: any = {};

	EXCLUIDO : number = 4;
	_idPPBRota : number;
  _ativaTextArea : boolean = true;
  

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();

	@Output() atualizarListagem : EventEmitter<any> = new EventEmitter();

	@Output() listaAnterior : EventEmitter<any> = new EventEmitter();
  @Output() abrirModalOrdenacao : EventEmitter<any> = new EventEmitter();
  @Output() abrirModalHistorico : EventEmitter<any> = new EventEmitter();

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private route: ActivatedRoute,
		private msg: MessagesService,
		//private ordenarGrid: OrderGrid
	) {
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
		this._idRota = Number(this.route.snapshot.params['idTipoProduto']);
	}

	changeSize($event) {
		this.listaAnterior.emit(this.lista);
		this.onChangeSize.emit($event);
	}

  changeSort($event) {
    this.sorted = $event.field;
    this.onChangeSort.emit($event);
  }

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	changeListagem(){
		this.atualizarListagem.emit();
	}

	atualizaListagem($event){
		this.atualizarListagem.emit($event);
	}

	limitarDescricao(desc: string){
		var limit = desc.substring(0, 30);
		return limit + '...';
	}

	async salvar(){
		this._idRota;
		var obj = {
			ListMetasPPBVM : this.lista,
			IdPpb : this.idPPB,
			IdProduto : this._idRota
		}
		if(this.lista.length > 0 && this.lista != null){
			await this.applicationService.post(this.servico, obj).toPromise();
		};
	}

	// validarSePermiteAbrirModal(item : MetaVM){

	// 	let serviceObject : ItensPPBVM = new ItensPPBVM();
	// 	serviceObject.idItemCorrente = Number(item.id);
	// 	serviceObject.idPPBCorrente = Number(this._idPPBRota);
	// 	serviceObject.idPPBVinculo = Number(item.idPPB);

	// 	this.applicationService.post(this.servico+"/PostValidacaoModalEdicao", serviceObject).subscribe((result : ItensPPBVM) => {

	// 		if(result.statusControllModal == EnumControllPPB.OK){
	// 			this.changeListagem();
	// 			this.appModalIncluirMetasPPB.abrir(this.idPPB, result.idItemCorrente);
	// 		} else if(result.statusControllModal == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
	// 			this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação").subscribe( () => false);
	// 		} else if(result.statusControllModal == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO){
	// 			this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação").subscribe( () => false);
	// 		}
	// 	});
	// }


  validacaoIsRetificacao(item : any) : boolean{
	if(this.isRetificacao){
		if(item.idPPB == this.idPPB){
			return true
		}
		else{
			return false
		};
	}
	return true;
  }

}
