import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-taxa-texto',
	templateUrl: './tacha-item.component.html'
})

export class TaxaTextoComponent {

    constructor() {
	}

	@Input() texto : any;
    @Input() indicadorParaTaxarItem : any;

}
