import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../shared/services/application.service";
import { MessagesService } from "../../shared/services/messages.service";
import { ModalService } from "../../shared/services/modal.service";
import { ValidationService } from "../../shared/services/validation.service";
import { ChangeIniciaPaginacao } from "../../shared/services/changeIniciaPaginacao.service";
import { PagedItems } from "../../view-model/PagedItems";
import { subsetorVM } from "../../view-model/SubsetorVM";
import { InforFiltroRelatorioGrid } from "../../shared/services/pdf.service";
import { LocalStorageFiltroPesquisaService, StoragePesquisaPaginasEnum } from "../../shared/services/localStorageFiltroPesquisa.service";

declare var html2pdf: any;

@Component({
	selector: "manter-subsetor",
	templateUrl: "./manter-subsetor.component.html"
})

@Injectable()
export class ManterSubsetorComponent implements OnInit {
    form = this;
	grid: any = { sort: {field:"numeroCodigo"} };
	parametros : subsetorVM = new subsetorVM();
	ocultarFiltros: boolean = false;
	isBuscaSalva: boolean = false;
	servico = 'Subsetor';
	objetoConsulta: any = {} = null;
	objetoSalvar = new subsetorVM()
  eUsuarioSuframa:boolean;

	constructor(
		private changeIniciaPaginacao : ChangeIniciaPaginacao,
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute,
		private validation: ValidationService,
    private localStorageFiltroPesquisas:LocalStorageFiltroPesquisaService
	) {
		if (sessionStorage.getItem(this.router.url) == null && sessionStorage.length > 0)
			sessionStorage.clear();

      this.eUsuarioSuframa =JSON.parse(localStorage.getItem("usuarioSuframa"));

	}

	ngOnInit() {
		this.parametros.situacao = 99;

    //this.buscar()
    this.buscarInformacoesStorage()
  }

  validarExisteFiltro(obj:any){

  }

  buscarInformacoesStorage(){
    const key = StoragePesquisaPaginasEnum.Subsetores;
    this.localStorageFiltroPesquisas.limparStorageExceto(key);
    const storage = this.localStorageFiltroPesquisas.obter(key);

    if( storage &&storage.flagAlterouFiltro
      ){
        this.parametros = storage;
        this.buscar()
    }

  }
  salvarDadoFiltroNoStorage(){
    let salvar = false;
    if((this.parametros.numeroCodigo || 0)>0){
      salvar = true;
    }
    if((this.parametros.descSubsetor||"").length){
      salvar = true;
    }
    if(this.parametros.situacao != 99){
      salvar = true;
    }
    if(salvar){
      this.localStorageFiltroPesquisas.salvar(StoragePesquisaPaginasEnum.Subsetores,this.parametros);
    }

  }

	onChangeSort($event: any) {
		this.grid.sort = $event;
		this.buscar()
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
		this.buscar()
	}

	onChangePage($event: any) {
		this.grid.page = $event;
		this.buscar()
    }

    salvar(){
		this.router.navigate(['manter-subsetor/incluir'])
    }


	buscar(){

		if (this.parametros.flagAlterouFiltro)
		{
			this.parametros.page = 1;
			this.parametros.size = 10;
			this.grid.page = 1;
			this.grid.size = 10;

		}
		else
		{
			this.parametros.page = this.grid.page;
			this.parametros.size = this.grid.size;
		}

		this.parametros.sort = this.grid.sort.field;
		this.parametros.reverse = this.grid.sort.reverse;
		//this.parametros.flagAlterouFiltro = false;
		this.applicationService.get(this.servico, this.parametros).subscribe((response: PagedItems) => {
			if (response.total != null && response.total > 0) {
        this.salvarDadoFiltroNoStorage()

				this.grid.lista = response.items;
				this.grid.total = response.total;
				this.prencheParametrosDeExportacao();
			}else {
				this.grid = { sort: {} };
				this.modal.infoError("Nenhum Registro Encontrado", "Informação");
			}
		});
	}

  obterDsSelecionadoDropdown(id:string){
    let selected = $( `#${id} option:selected` ).text();
    if(selected.includes("Selecione uma opção")){
      return null;
    }
    return selected;
  }

	prencheParametrosDeExportacao()
	{
		this.parametros.exportarListagem = true;
		this.parametros.exportarPdf = true;
		this.parametros.servico = this.servico;
    	this.parametros.page = 1;
    	this.parametros.size = 100000;
		this.parametros.titulo = "Relatório de Subsetores"
    this.parametros.sort = this.grid.sort.field;


    this.parametros.informacoesExtrasRelatorio = [
      {
        label:"Código",
        text:this.parametros.numeroCodigo
      },
      {
        label:"Descrição",
        text:this.parametros.descSubsetor
      },
      {
        label:"Situação",
        text:this.obterDsSelecionadoDropdown("situacao")
      }
    ] as Array<InforFiltroRelatorioGrid>;

		this.parametros.columns = [
			"Código",
			"Descrição",
			"Situação"
		];

		this.parametros.fields = [
			"numeroCodigo",
			"descSubsetor",
			"desSituacao"
		];

		this.parametros.width = {
			0: { cellWidth: 100 },
			1: { cellWidth: 220 },
			2: { cellWidth: 220 }
		};
	}

    limpar(){
		this.parametros = new subsetorVM();
		this.parametros.situacao = 99;
		this.grid = { sort: {field:"numeroCodigo"} }
    }

}

