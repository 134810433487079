<div class="table-responsive no-margin-bottom no-border">
  <table class="table">
    <thead class="table-header-color">
      <tr>
        <th class="th-100 text-left">
          <span>Ações</span>
        </th>
        <th class="unset-nowrap text-left">
          <span>Código Tipo</span>
        </th>
        <th class="unset-nowrap text-left">
          <span>Descrição</span>
        </th>
        <th class="unset-nowrap text-left">
          <span>Agregação</span>
        </th>
        <th class="unset-nowrap text-left">
          <span>Subsetor de Destino</span>
        </th>
        <th class="unset-nowrap text-left">
          <span>Status</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoTipo of lista.listaProdutoTipo; let i = index">
        <tr [ngStyle]="{'color':produtoTipo.isProdutoTipoNovo == true ? 'red': ''}">
          <td>
            <button title="Exibir Ncm" class="btn btn-outline-primary" data-toggle="collapse" [attr.data-target]="'#col1-row' + i" ><span style="color: black;">+</span></button>
          </td>
          <td [ngStyle]="{'color':produtoTipo.isCodigoProdutoTipoAlterado == true ? 'red': ''}">{{produtoTipo.numeroCodigo}}</td>
          <td [ngStyle]="{'color':produtoTipo.isDescProdutoTipoAlterado == true ? 'red': ''}">{{produtoTipo.descricao}}</td>
          <td [ngStyle]="{'color':produtoTipo.isAgregacaoProdutoTipoAlterado == true ? 'red': ''}">{{produtoTipo.descricaoTipoAgregacao}}</td>
          <td [ngStyle]="{'color':produtoTipo.isSubSetorProdutoTipoAlterado == true ? 'red': ''}">{{produtoTipo.descricaoSubSetor}}</td>
          <td [ngStyle]="{'color':produtoTipo.isProdutoTipoNovo == true ? 'red': ''}">{{produtoTipo.statusTipo == 1 ? "Ativo" : "Inativo"}}</td>
        </tr>
        <tr>
          <td colspan="6" style="padding: 0;">
            <div class="collapse mb-0" [id]="'col1-row' + i">
              <table class="table">
                <thead class="table-header-color" >
                  <tr>
                    <th>Código NCM</th>
                    <th>Descrição</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let produtoTipoNcm of produtoTipo.listaProdutoTipoNcm; let i = index">
                    <tr [ngStyle]="{'color':produtoTipoNcm.isProdutoTipoNcmNovo == true ? 'red': ''}">
                      <td>{{produtoTipoNcm.numeroCodigo}}</td>
                      <td>{{produtoTipoNcm.descricaoProdutoTipoNcm}}</td>
                      <td>{{produtoTipoNcm.statusNcm == 1 ? "Ativo" : "Inativo"}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

