import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../shared/services/application.service';
import * as html2pdf from 'html2pdf.js';
import { UnidadeMedidaAlteracoesVM } from '../../../view-model/UnidadeMedidaVM';
import { NcmAlteracoesVM } from '../../../view-model/NcmVM';
import { SubsetorAlteracoesVM } from '../../../view-model/SubsetorVM';

@Component({
    selector: 'app-relatorio-alteracoes',
	templateUrl: './relatorio.component.html'
})
export class RelatorioAlteracoesComponent implements OnInit {
	produto : any;
  unidade: UnidadeMedidaAlteracoesVM = new UnidadeMedidaAlteracoesVM();
	subsetor : SubsetorAlteracoesVM = new SubsetorAlteracoesVM();
  ncm : NcmAlteracoesVM = new NcmAlteracoesVM();
	ocultaRelatorio : boolean = true;
	idRelatorio : string = "";
	nomeArquivo : string = "";

    constructor(
        private applicationService: ApplicationService,
    ) {

    }

    ngOnInit(): void {
	}

	public abrirRelatorioProdutos(produtos : any) {

		this.idRelatorio = "produto";
		this.nomeArquivo = "Relatório Alterações Produto";
    this.produto = produtos;
		this.gerarRelatorio();
	}

	public abrirRelatorioUnidade(unidades : UnidadeMedidaAlteracoesVM) {
		this.idRelatorio = "unidade";
		this.nomeArquivo = "Relatório Alterações Unidade";
        this.unidade = unidades;
        this.ocultaRelatorio = false;
		this.gerarRelatorio();
	}

	public abrirRelatorioSubsetor(subsetor : SubsetorAlteracoesVM) {
		this.idRelatorio = "subsetor";
		this.nomeArquivo = "Relatório Alterações Subsetor";
        this.subsetor = subsetor;
        this.ocultaRelatorio = false;
		this.gerarRelatorio();
	}

	public abrirRelatorioNcm(ncm : NcmAlteracoesVM) {
		this.idRelatorio = "ncm";
		this.nomeArquivo = "Relatório Alterações Ncm";
        this.ncm = ncm;
        this.ocultaRelatorio = false;
		this.gerarRelatorio();
	}

	gerarRelatorio()
	{
		new Promise (resolve=>{
				setTimeout(() => {
			const elements = document.getElementById(this.idRelatorio);
			const options = {
			margin: [0.5, 0.1, 0.0, 0.0],
			filename: this.nomeArquivo,
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: {
				scale: 2,
				dpi: 300,
				letterRendering: true,
				useCORS: true
			},
			jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
			pagebreak: {
				before: [
				],
				after: [
				]
			}
			};
			html2pdf().from(elements).set(options).toPdf().get('pdf').save();
			resolve('ok');
				}, 2000)
			});
			new Promise (resolve=>{
				setTimeout(() => {
				resolve('ok');
				this.ocultaRelatorio = true;
				}, 3000)
			});
	}

}




