import { PagedItems } from './PagedItems';
export class CondicionantesPPBVM extends PagedItems{
    id : number;
    idPPB  : number;
    descricaTopico : string;
    descricaCondicao : string;
    dataVigenciaInicial : Date;
    dataVigenciaFinal : Date;
    statusCondicionante : number;
}
