import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "../../../shared/services/application.service";
import { MessagesService } from "../../../shared/services/messages.service";
import { ModalService } from "../../../shared/services/modal.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { ProdutoVM } from "../../../view-model/ProdutoVM";
import { UnidadeMedidaVM } from "../../../view-model/UnidadeMedidaVM";
import { ViewUnidadeMedidaModulosEntityVM } from "../../../view-model/ViewUnidadeMedidaModulosEntityVM";


@Component({
	selector: 'app-grid-relatorio-produto-tipo-padrao',
	templateUrl: './grid.component.html'
})

export class GridRelatorioProdutoTipoPadraoComponent {

	ocultarTituloGrid = true;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,

	) {

	}

	disable : boolean =false

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;
  @Input() statusNCM : any

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() emitEvent: EventEmitter<any> = new EventEmitter();

	tituloGrid = "Resultado da Pesquisa"
	servico = "ManterUnidade";
  @ViewChild("appModalNcms") appModalNcms: any;


	changeSize($event) {
		this.onChangeSize.emit($event);
		this.changePage(this.page);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	atualizaListagem($event){
		this.emitEvent.emit($event);
	}


  abrirModalNcms(item){
    this.appModalNcms.abrir(item);
  }
}
