import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from '../../../shared/services/application.service';
import { subsetorVM } from '../../../view-model/SubsetorVM';
import { ModalService } from '../../../shared/services/modal.service';

@Component({
	selector: 'app-modal-justificativa-subsetor',
	templateUrl: './modal-justificativa.component.html'
})

export class ModalJustificativaComponent implements OnInit {

	_flagCampoVazio : boolean = false;
	_justificativa : string = "";
	objeto : subsetorVM
	parametros: subsetorVM

	_portaria: string = "";

	_pendenciaJustificativa: boolean = false;
	_pendenciaPortaria: boolean = false;

	servico = 'Subsetor';
	@Input() data: any = {};
	@Output() changeOperation: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalJustificativa') appModalJustificativa ;
	@ViewChild('appModalJustificativaBackground') appModalJustificativaBackground ;



	constructor(private applicationService: ApplicationService,
		        private modal : ModalService) {

	}

	ngOnInit() {
		this._flagCampoVazio = false;
		this._justificativa = "";
		this._portaria = "";

		this._pendenciaJustificativa = false;
		this._pendenciaPortaria = false;
	}

	abrir(item){
		this.objeto= item;
		this._justificativa = "";
		this._portaria = "";

		this._pendenciaJustificativa = false;
		this._pendenciaPortaria = false;

		this.appModalJustificativaBackground.nativeElement.style.display = 'block';
		this.appModalJustificativa.nativeElement.style.display = 'block';
	}

	fechar(){

		this.appModalJustificativaBackground.nativeElement.style.display = 'none';
		this.appModalJustificativa.nativeElement.style.display = 'none';
	}

	validar(){

		this._pendenciaJustificativa = false;
		this._pendenciaPortaria = false;

		if(!this._justificativa || this._justificativa.trim().length == 0){
			this._pendenciaJustificativa = true;
			return false;
		}
		if(!this._portaria || this._portaria.trim().length == 0){
			this._pendenciaPortaria = true;
			return false;
		}

		this.objeto.justificativaHistorico= "Portaria: "+this._portaria+" - "+this._justificativa;
		if(this.objeto.situacao==1){
			this.objeto.situacao=0;
			this.objeto.descricaoAcao ="ALTERAÇÃO: Status De: ATIVO Para: INATIVO"
		}
		else{
			this.objeto.situacao=1;
			this.objeto.descricaoAcao="ALTERAÇÃO: Status De: INATIVO Para: ATIVO"
		}

		this.applicationService.put(this.servico, this.objeto).subscribe(( result : any)=>{
			if(result){
				this.modal.infoSucess("Operação realizada com sucesso","Sucesso");
				this.changeOperation.emit(true);
				this.appModalJustificativaBackground.nativeElement.style.display = 'none';
				this.appModalJustificativa.nativeElement.style.display = 'none';
				this._justificativa = "";
			}
		})

	}


}
