import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../shared/services/modal.service';
import { CondicionanteVM } from '../../../../../view-model/CondicionanteVM';
import { EnumRestResponse } from '../../../../../shared/enums/EnumRestResponse';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { ItensPPBVM } from '../../../../../view-model/ItensPPBVM';

enum EnumControllPPB{
	OK = 1,
	ERR_DATA_VIGENCIA_VENCIDA = 2,
	ERR_EXISTE_COPIA_EM_ANDAMENTO = 3,
	ERR_ITEM_MARCADO_COMO_EXCLUIDO = 4
}


@Component({
	selector: 'app-grid-condicionantes-ppb',
	templateUrl: './grid.component.html'
})

export class GridCondicionantesPPBComponent {

	ocultarTituloGrid = true;
	path : string;
	flagHabilitaComponenteTextual : boolean = false;

	constructor(
		private validation : ValidationService,
		private route: ActivatedRoute,
		private modal : ModalService,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private router: Router,
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
	}

	_idPPBRota : number;

	@Input() dataVigenciaInicialPPB : string;
	@Input() lista!: any[];
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() habilitarInputs: boolean;
	@Input() exportarListagem!: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() isUsuarioInterno: boolean = false;
	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() atualizaListagemGrid: EventEmitter<any> = new EventEmitter();

	servico = "CondicionantesPPB";
	tituloGrid = 'Condicionantes do PPB';

	@ViewChild('appModalIncluirCondicionantesPPB') appModalIncluirCondicionantesPPB : any;

	config: AngularEditorConfig = {
		height: '700px',
		minHeight: '100px',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};

	changeSize($event : any) {
		this.onChangeSize.emit($event);
	}

	changeSort($event : any) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event : any) {
		this.page = $event;
		this.onChangePage.emit($event);

	}

	validarSePermiteAbrirModal(item : any){

		let serviceObject : ItensPPBVM = new ItensPPBVM();
		serviceObject.idItemCorrente = Number(item.id);
		serviceObject.idPPBCorrente = Number(this._idPPBRota);
		serviceObject.idPPBVinculo = Number(item.idPPB);

		this.applicationService.post(this.servico+"/ValidacaoAbrirModalEdicaoCondicionantes", serviceObject).subscribe((result : ItensPPBVM) => {

			if(result.statusControllModal == EnumControllPPB.OK){

				let obj : any = {};
				obj.idPPB = Number(this._idPPBRota);
				obj.id = result.idItemCorrente;
				this.atualizaListagem(obj.idPPB);
				this.abrirCondicionantes(obj);

			} else if(result.statusControllModal == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
				this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação");
				return false;
			} else if(result.statusControllModal == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO){
				this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação");
				return false;
			}
		});
	}

	excluir(item : any){
		// if(item.dataVigenciaInicial){
		// 	var dataInicio = item.dataInicioVigenciaString.replace("A partir de ", "");
		// 	if(this.validation.validaDataMenorDataVigente(dataInicio)){
		// 		this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação");
		// 		return false;
		// 	}
		// }
		this.modal.confirmacao("Deseja Excluir o Registro?").subscribe( (isConfirmado : any) => {
			if(isConfirmado) {
				this.apagarBackEnd(item);
			}
		});
	}

	apagarBackEnd(item: any){

		let serviceObject : ItensPPBVM = new ItensPPBVM();
		serviceObject.idItemCorrente = Number(item.id);
		serviceObject.idPPBCorrente = Number(this._idPPBRota);
		serviceObject.idPPBVinculo = Number(item.idPPB);

		this.applicationService.post(this.servico+"/PostApagarCondicionante", serviceObject).subscribe( (result : EnumControllPPB) => {
			if(result == EnumControllPPB.OK){
				this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(() => {
					this.atualizaListagem(serviceObject.idPPBCorrente);
				});
			}
			else if(result == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
				this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação");
				return false;
			} else if(result == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO) {
				this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação");
				return false;
			}
			else if(result == EnumControllPPB.ERR_ITEM_MARCADO_COMO_EXCLUIDO) {
				this.modal.informacao("Item Ja Está Marcado Como excluído!", "Informação");
				return false;
			} else {
				this.modal.infoError("Falha ao Apagar Registro!", "Error");
				return false;
			}
		});
	}

	abrirCondicionantes(item : CondicionanteVM){
		this.appModalIncluirCondicionantesPPB.abrir(item.idPPB, "edit", item.id);
	}

	atualizaListagem($event){
		this.atualizaListagemGrid.emit($event);
	}

}
