<app-grid [(isHidePaginacao)]="!_exibirCheck" [(isHideCabecalho)]="!_exibirCheck" [(titulo)]="tituloGrid" [(page)]="page" [(size)]="size" [(total)]="total" [(parametros)]="parametros"
  (onChangeSize)="changeSize($event)" (onChangePage)="changePage($event)">

  <div class="table-responsive no-margin-bottom no-border">
    <table class="table table">
      <thead class="table-header-color">
        <tr>
          <th class="th-sortable unset-nowrap text-left" *ngIf="_exibirCheck">
          </th>
          <th class="th-sortable unset-nowrap text-left">
            PPB
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Vigência
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Documento
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Produto
          </th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let item of lista; let j = index">
          <td class="text-center" *ngIf="_exibirCheck">
            <input  type="checkbox" 
            name="check"
            [(ngModel)]="item.isChecked" 
            id="check">
          </td>

          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <p style="white-space: pre-line; width: 250px;">
              <app-taxa-texto
                [(texto)]="item.descricao"
                [backgroundAmarelo]="item.idPPB != idPPB"
                [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
              </app-taxa-texto>
            </p>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.dataVigenciaConcatenada"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.descricaoDocumento"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.descricaProdutos"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-grid>