import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AngularEditorModule } from '@kolkov/angular-editor';
//import { DropListSelect2Component } from '../../shared/components/drop-list-2/drop-list-2.component';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RelatorioProdutoPadronizadoComponent } from './relatorio-produto-padronizado.component';
import { GridRelatorioProdutoPadronizadoComponent } from './grid/grid.component'
import { EmitirRelatorioProdutoPadronizadoComponent } from './relatorio-produto-padronizado/emitir-relatorio-produto-padronizado.component'
import {PopoverModule} from "ngx-bootstrap/popover";
@NgModule({
  declarations: [
    RelatorioProdutoPadronizadoComponent,
    GridRelatorioProdutoPadronizadoComponent,
    EmitirRelatorioProdutoPadronizadoComponent,

  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        AngularEditorModule,
        NgbModule,
        PopoverModule
    ]
})
export class RelatorioProdutoPadronizadoModule { }
