<div #appModalHistorico class="modal modal-wide-2 fade in centro" tabindex="-1" role="dialog" aria-hidden="false"
  style="display: auto; overflow: scroll; overflow-y: auto;">

  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title" id="incluir_etapas">Histórico do Produto Padronizado</h4>
      </div>

      <div class="modal-body">
        <form>

          <div class="row form-group" style="margin-left: 2px;" *ngIf="data != null">
            <div class="col-lg-2">
              <label for="descricao" class="">Código: {{data.numeroCodigo}}</label>
            </div>
            <div class="col-lg-8">
              <label for="codigo">Descrição: {{data.descProduto}}</label>
            </div>
            <!-- <div class="col-lg-2 pull-right">
              <a class="btn btn-primary btn-sm" id="btnRelatorio" (click)="gerarRelatorio()">
                <i class="fa fa-plus"></i> Emitir Relatorio
              </a>
            </div> -->

          </div>

          <app-grid 
            [(lista)]="lista"
            [(titulo)]="tituloGrid"
            [(page)]="grid.page"
            [(size)]="grid.size"
            [(total)]="total"
            [(parametros)]="parametros"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)"
            (onChangeSort)="onChangeSort($event)">

            <div class="table-responsive no-margin-bottom no-border">
                <table class="table table-striped">
                    <thead class="table-header-color">
                        <tr>
                            <th class="th-100 text-left">Data/Hora</th>
                            <th class="th-100 text-left">Login</th>
                            <th class="th-100 text-left">Responsável</th>
                            <th class="th-100 text-left">Ação</th>
                            <th class="th-100 text-left">Justificativa</th>
                        </tr>
                    </thead>
                    <tbody>				
                        <tr *ngFor="let item of lista ; let i = index ;" [attr.data-index]="i">
                            <td class="text-left">{{item.dataAcaoString}}</td>
                            <td class="text-left">{{item.numeroUsuario}}</td>
                            <td class="text-left">{{item.nomeUsuario}}</td>
                            <td class="text-left">
                              <ul *ngIf=" item.objetoDescricao ">
                                <li *ngFor="let item of item.objetoDescricao">
                                    {{item ? item : ''}}
                                </li>
                            </ul>
                            </td>
                            <td class="text-left">{{ item.descricaoJustificativa ? item.descricaoJustificativa : '--' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </app-grid>
                    


        </form>
      </div>

      <div class="modal-footer" style="padding: 10px 15px;">
        <a (click)="fechar()" class="btn btn-sm btn-default" data-dismiss="modal"><i class="fa fa-times"></i> Cancelar</a>
       
      </div>
    </div>
  </div>
</div>

<div #appModalHistoricoBackground class="modal-backdrop fade in" style="display: none;"></div>