<app-grid 
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table table">
			<thead class="table-header-color">
				<tr>
					<th class="th-100 text-left" *ngIf="habilitarInputs">
                        Ações
                    </th>
					<th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DescricaGrupo" [sorted]="sorted" (onChangeSort)="changeSort($event)"> 
                            Grupo
                        </app-ordenacao>                        
                    </th>					
					<th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DataInicioVigenciaString" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                            Vigência
                        </app-ordenacao>                        
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DescricaoDocumento" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                            Documento
                        </app-ordenacao>                        
                    </th>
					<th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DescricaProdutos" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                            Produto
                        </app-ordenacao>                        
                    </th>	
				</tr>
			</thead>
			<tbody>				
				
                <tr *ngFor="let item of lista">               
                    <td class="text-left" *ngIf="habilitarInputs"  [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">                        
                        <a 
                            *ngIf="!item.indicadorParaTaxarItem"
                            (click)="validarSePermiteAbrirModal(item)"
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip" 
                            title="Alterar" 
                            data-original-title="Alterar">
                                <i class="fa fa-pencil"></i>
                        </a>                                               
                        <a 
                           *ngIf="!item.indicadorParaTaxarItem"
                            (click)="excluir(item)"
                            class="btn btn-danger btn-sm" 
                            data-toggle="tooltip" 
                            title="Excluir" 
                            data-original-title="Excluir">
                                <i class="fa fa-trash-o"></i>
                        </a>
                    </td>
					<td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.descricaGrupo" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.dataInicioVigenciaString" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>                       
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.descricaoDocumento" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>       
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">                        
                        <app-taxa-texto 
                            [(texto)]="item.descricaProdutos" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
				</tr>
			</tbody>
		</table>
	</div>
</app-grid>

<app-modal-incluir-grupos-ppb   
    #appModalIncluirGruposPPB
    (atualizaListagem)="atualizaListagem($event)"
    [(idPPB)]="idPPB">
</app-modal-incluir-grupos-ppb>
