<app-grid
    [(isHidePaginacao)]="!_exibirCheck"
    [(isHideCabecalho)]="!_exibirCheck"
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
      <thead class="table-header-color">
        <tr>
          <th class="th-sortable unset-nowrap text-left" *ngIf="_exibirCheck">
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Condicionante
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Data Vigência
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Descrição da Condicionante
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Documento
          </th>
        </tr>
      </thead>
			<tbody *ngFor=" let item of lista ; let i = index ; " [attr.data-index]="i">
          <tr>
              <td class="text-center" *ngIf="_exibirCheck">
                <input  type="checkbox" 
                name="check"
                [(ngModel)]="item.isChecked" 
              id="check">
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
                <p style="white-space: pre-line; width: 200px;">
                  <app-taxa-texto
                      [(texto)]="item.descricaTopico"
                      [backgroundAmarelo]="item.idPPB != idPPB"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
                </p>
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
                  <app-taxa-texto
                      [(texto)]="item.dataInicioVigenciaString"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
                  <span *ngIf="!item.descricaCondicaoString"> -- </span>
                  <a
                      *ngIf="item.descricaCondicaoString"
                      style="color: blue;"> Clique para Visualizar
                  </a>
                  <app-collapse-row-button
                      style="cursor: pointer;"
                      *ngIf="item.descricaCondicaoString"
                      target="{{'object-' + i}}">
                  </app-collapse-row-button>
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
                  <app-taxa-texto
                      [(texto)]="item.descricaoDocumento"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
          </tr>
          <td colspan="6" id="{{ 'object-' + i }}" style="display:none" >
              <div class="row" style="margin-left: 30px; height: 200px; overflow: scroll;" *ngIf="item.descricaCondicaoString">

                  <legend style="margin-top: 15px;">Descrição da Condicionante</legend>
                  <angular-editor [(ngModel)]="item.descricaCondicaoString" [config]="config"></angular-editor>

              </div>
          </td>
			</tbody>
		</table>
	</div>
</app-grid>