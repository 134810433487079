<div #appModalIncluirSubsetor class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true"
  data-distance="0" data-size="10px" data-railOpacity="0.2">

  <div class="row m-b-md">
    <div class="col-lg-12">
      <h1 class="m-b-xs text-black">{{tituloPagina()}}</h1>
      <!-- <small>Incluir </small> -->
    </div>
  </div>

  <section class="panel panel-default">
    <header class="panel-heading">
      <div class="pull-right">
        <a (click)="voltar()" class="btn btn-default btn-sm">
          <i class="fa fa-arrow-left"></i> Voltar
        </a>
        <a *ngIf=" path != 'visualizar' "  (click)="validar()" class="btn btn-primary btn-sm">
          <i class="fa fa-save"></i> Salvar
        </a>
      </div>
      <h2 class="panel-title h5">Formulário</h2>
    </header>

    <div class="panel-body">
      <div class="row form-group">
        <div class="col-lg-12">

          <div class="row form-group">

            <div class="col-lg-3">
              <label for="descricao">Código:</label>
              <input [attr.disabled]="!_habilitaInputs ? true : null" type="text" onlyNumber="true" name="codigo"
                [(ngModel)]="objetoSalvar.numeroCodigo" id="codigo" class="form-control" maxlength="5" value=""
                [disabled]="desabilitarCodigo ? true : null" />
            </div>

            <div class="col-lg-11" style="margin-top: 15px;">
              <label for="codigo" class="required">Descrição:</label>
              <textarea class="form-control ng-pristine ng-valid ng-touched" id="Objetivo" style="resize: none;"
                [attr.disabled]="!_habilitaInputs ? true : null" [(ngModel)]="objetoSalvar.descSubsetor" maxlength="250"
                (change)="alterouItem = true;"
                rows="2">
              </textarea>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>
  <p *ngIf="habInforCamposObrigatorios()" style="color: red">Os campos com o sinal (*) são de preenchimento obrigatório.</p>


</div>
