import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../../shared/services/application.service";
import { MessagesService } from "../../../shared/services/messages.service";
import { ModalService } from "../../../shared/services/modal.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { ProdutoVM } from "../../../view-model/ProdutoVM";
import { ValidacaoMCCPVM } from "../../../view-model/ValidacaoMCPPVM";
import { AlteracaoProdutoVM } from "../../../view-model/AlteracaoProdutoVM";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { PPBVM } from "../../../view-model/PPBVM";
import { ProdutoPPBVM } from "../../../view-model/ProdutoPPBVM";
import { NcmEntityVM } from "../../../view-model/NcmEntityVM";


declare var html2pdf: any;

@Component({
	selector: "app-solicitacoes-ncm",
	templateUrl: "./solicitacoes-ncm.component.html"
})

@Injectable()
export class SolicitacoesNCMComponent implements OnInit {

	desabilitarCodigo : boolean = true;
    form = this;
	grid: any = { sort: {} };
	parametros : NcmEntityVM = new NcmEntityVM();
	ocultarFiltros: boolean = false;
	isBuscaSalva: boolean = false;
	servico = 'ManterNcm';
	path : string = '';
	objetoConsulta: any = {} = null;
	_idRota : number = 0;
	_idTipoProduto : number = null;
	_habilitaInputs : boolean = true;
	_flagHabilitaAlteracaoStatus : boolean = false;
	_produtoFoiFinalizado : boolean = false;
	_documentosRelacionados : string =  "";//new Array<string>();
	_existsGrupo : boolean = false;
	_flagExibeNcm : boolean = false;
	listaTipoDocumento = new Array<any>();
	disable :boolean= false
	disableEditar: boolean= false;
	_dataJustificativa : any = {};
	usuarioAlterouItem : boolean = false;
	_mostrarDocumentosRelacionados = false;

	baseLegalSis: string;
	linhasBaseLegalSis: string;

	objetoValidacao : ValidacaoMCCPVM = new ValidacaoMCCPVM();

	constructor(
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute,
		private validation: ValidationService

	) {
		if (sessionStorage.getItem(this.router.url) == null && sessionStorage.length > 0)
			sessionStorage.clear();

		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;

		if(this.path != "novo"){
			this._idRota = Number(this.route.snapshot.params['id']);
			this.buscar(this._idRota);

			this._mostrarDocumentosRelacionados = true;
		}

		if(this.path == "editar"){
			//this.disable = true;
			this.disableEditar = true;
		}

		if(this.path == "visualizar")
			this._habilitaInputs = false;
	}

	ngOnInit() {
		if(this.path == 'visualizar'){
			this.disable = true;
		}
		if(this.path == 'editar'){
			this.buscar(this._idRota);
		}

	}

	onChangeSort($event: any) {
		this.grid.sort = $event;

	}

	onChangeSize($event: any) {
		this.grid.size = $event;

	}

	onChangePage($event: any) {
		this.grid.page = $event;

	}

	validar() {
		if(!this.parametros.numeroCodigo || this.parametros.numeroCodigo.length < 8){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Código (deve conter exatamente 8 dígitos)</b>", "Atenção");
			return false;
		}

		if(!this.parametros.descricaoNcm){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Descrição</b>", "Atenção");
			return false;
		}

		if(!this.parametros.idUnidadeMedida){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Unidade</b>", "Atenção");
			return false;
		} else{
			this.parametros.idUnidadeMedida = Number(this.parametros.idUnidadeMedida);
		}

	// 	if(!this.parametros.aliquotaII){
	// 		this.modal.infoError("Campo Obrigatório não Informado: <b>Alíquota II</b>", "Atenção");
	// 		return false;
	// 	}else if(this.validarPorcentagem(1)==false){
    //   this.modal.infoError("Campo AliquotaII aceita somente valores entre 0.00 e 100.00 ","Atenção")
    //   return false
    // }else{
	// 		this.parametros.aliquotaII = Number(this.parametros.aliquotaII);
	// 	}

		if(!this.parametros.aliquotaIIVigenciaInicialString){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Data Início Alíquota II</b>", "Atenção");
			return false;
		}

		if(!this.parametros.aliquotaIIVigenciaFinalString){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Data Final Alíquota II</b>", "Atenção");
			return false;
		}

		if(this.validation.validaDataInicialMaiorDataFinal(this.parametros.aliquotaIIVigenciaInicialString , this.parametros.aliquotaIIVigenciaFinalString) == true){
			this.modal.infoError("A Data Inicial não pode ser Superior a Data Final - <b>Alíquota II</b>", "Atenção");
			return false;
		}

	// 	if(!this.parametros.aliquotaIPI){
	// 		this.modal.infoError("Campo Obrigatório não Informado: <b>Alíquota IPI</b>", "Atenção");
	// 		return false;
	// 	} else if(this.validarPorcentagem(2)==false){
    //   this.modal.infoError("Campo AliquotaIPI aceita somente valores entre 0.00 e 100.00 ","Atenção")
    //   return false
    // }else {
	// 		this.parametros.aliquotaIPI = Number(this.parametros.aliquotaIPI);
	// 	}

		if(!this.parametros.aliquotaIPIVigenciaInicialString){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Data Início Alíquota IPI</b>", "Atenção");
			return false;
		}

		if(!this.parametros.aliquotaIPIVigenciaFinalString){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Data Final Alíquota IPI</b>", "Atenção");
			return false;
		}

		if(this.validation.validaDataInicialMaiorDataFinal(this.parametros.aliquotaIPIVigenciaInicialString , this.parametros.aliquotaIPIVigenciaFinalString) == true){
			this.modal.infoError("A Data Inicial não pode ser Superior a Data Final - <b>Alíquota IPI</b>", "Atenção");
			return false;
		}

		if(this.path == 'editar' && this.usuarioAlterouItem){
			this.validarAltercao();
		}else{
			this.salvar(this.parametros);
		}
    }

	formatarData(data: string){
		var dataCompleta = data.split("-");
		var ano = Number(dataCompleta[0]);
		var mes = Number(dataCompleta[1]);
		var dia = Number(dataCompleta[2]);

		var dataFormatada = ano+'-'+mes+'-'+dia
		return new Date(dataFormatada);
	}



	salvar(obj: NcmEntityVM){
		this.applicationService.post(this.servico,this.parametros).subscribe((response: number) => {
			if(response == 200){
				this.modal.infoSucess("Operação Realizada com Sucesso!","Sucess");
				this.router.navigate(['/manter-ncm']);
			} else {
				this.modal.infoError("Atenção, já Existe um registro com o mesmo <b>Código</b> cadastrado.","Sucesso");
				return false;
			}
		});
	}

    buscar(id: any){
		this.applicationService.get(this.servico,id).subscribe((result:NcmEntityVM)=>{
			if(result){
				this.parametros = result;
			}
		});
	}

    voltar(){
		this.router.navigate(['/manter-ncm']);
	}

	validarAltercao(){
		this.modal.justificativa("Descrição da justificativa de alteração").subscribe((justificativa: any) => {

			if(justificativa){
				this.parametros.descricaoJustificativa = justificativa;
				this.applicationService.put(this.servico+"/ValidarAlteracao",this.parametros).subscribe((response: number) => {
					if(response == 200){
						this.modal.infoSucess("Operação Realizada com Sucesso!","Sucess");
						this.router.navigate(['/manter-ncm']);
					} else {
						this.modal.infoError("Ocorreu um erro ao alterar o resgistro.","Sucesso");
						return false;
					}
				});
			}
		});
	}

  validarPorcentagem(id){
    if(id==1){
    if(this.parametros.aliquotaII>100.00){
      this.parametros.aliquotaII=0.00
    return false
    }else{
      this.parametros.aliquotaII =  Number(Number(this.parametros.aliquotaII).toFixed(2))

      return true

  }
}
  else if(id==2){
    if(this.parametros.aliquotaIPI>100.00){
      this.parametros.aliquotaIPI=0.00

      return false
    }
    else{
      this.parametros.aliquotaIPI =  Number(Number(this.parametros.aliquotaIPI).toFixed(2))
      return true
    }
  }

  }
}

