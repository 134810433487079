
import { ModalService } from '../../shared/services/modal.service';
import { MessagesService } from "../../shared/services/messages.service";
import { ValidationService } from "../../shared/services/validation.service";
import { ApplicationService } from "../../shared/services/application.service";
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeIniciaPaginacao } from '../../shared/services/changeIniciaPaginacao.service';
import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { ConsultarAlteracoesVM } from '../../view-model/ConsultarAlteracoesVM';
import { PagedItems } from '../../view-model/PagedItems';

enum EnumFiltroPesquisa {
  Produtos = 1,
  Unidades = 2,
  Subsetores = 3,
  Ncm = 4
}

@Component({
	selector: "app-consultar-alteracoes",
	templateUrl: "./consultar-alteracoes.component.html"
})

@Injectable()
export class ConsultarAlteracoesComponent implements OnInit {

  grid : any = { sort: {} };
  ocultarFiltros: boolean = false;
  servico = 'ConsultarAlteracoes'
  dadosPequisa : ConsultarAlteracoesVM = new ConsultarAlteracoesVM();
  parametros : ConsultarAlteracoesVM = new ConsultarAlteracoesVM();
  exibirGrid: boolean = false;
  isAlterouConsulta : boolean = false;
  flagAlterouFiltro: boolean = false;

  parametrosGrid: any = {};

  @ViewChild("dataInicio") dataInicial: ElementRef;
	@ViewChild("dataFim") dataFinal: ElementRef;

  constructor(
    private changeIniciaPaginacao : ChangeIniciaPaginacao,
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private modal: ModalService,
    private msg: MessagesService,
    private router: Router,
    private route: ActivatedRoute,
    private validation: ValidationService) {

    }

  ngOnInit(): void {
    this.grid.lista = [];
  }

  checkFiltroProdutos(event:any){
    this.dadosPequisa.check2 = null;
    this.dadosPequisa.check3 = null;
    this.dadosPequisa.check4 = null;
    this.dadosPequisa.dscUnidade = null;
    this.dadosPequisa.dscNcm = null;
    this.dadosPequisa.dscSub = null;

  }

  checkFiltroUnidade(event:any){
    this.dadosPequisa.check = null;
    this.dadosPequisa.check3 = null;
    this.dadosPequisa.check4 = null;
    this.dadosPequisa.dscProduto = null;
    this.dadosPequisa.dscNcm = null;
    this.dadosPequisa.dscSub = null;

  }

  checkFiltroSubsetor(event:any){
    this.dadosPequisa.check = null;
    this.dadosPequisa.check2 = null;
    this.dadosPequisa.check4 = null;
    this.dadosPequisa.dscProduto = null;
    this.dadosPequisa.dscUnidade = null;
    this.dadosPequisa.dscNcm = null;

  }

  checkFiltroNcm(event:any){
    this.dadosPequisa.check = null;
    this.dadosPequisa.check2 = null;
    this.dadosPequisa.check3 = null;
    this.dadosPequisa.dscProduto = null;
    this.dadosPequisa.dscUnidade = null;
    this.dadosPequisa.dscSub = null;

  }

  validarDatas() {
		if (this.dadosPequisa.dataPeriodoStringInicio && this.dadosPequisa.dataPeriodoStringFim) {
			let dataInicio = this.dadosPequisa.dataPeriodoStringInicio
				? new Date(this.dadosPequisa.dataPeriodoStringInicio)
				: undefined;
			let dataFim = this.dadosPequisa.dataPeriodoStringFim
				? new Date(this.dadosPequisa.dataPeriodoStringFim)
				: undefined;
			if (dataInicio > dataFim) {
				this.modal
					.error('Data inicial não pode ser maior que a final.', "Atenção")
					.subscribe(() => {
						this.dadosPequisa.dataPeriodoStringInicio = null;
						this.dadosPequisa.dataPeriodoStringFim = null;
						this.dadosPequisa.dataInicial.nativeElement.focus();
					});
				return;
			}
		}
  }

  validarBusca(){
    if(!this.dadosPequisa.check && !this.dadosPequisa.check2 && !this.dadosPequisa.check3 && !this.dadosPequisa.check4){
        this.modal.infoError("Necessário marcar no mínimo um filtro para realizar a busca","Atenção");
        return false;
    }

    this.parametros.dataPeriodoStringInicio = this.dadosPequisa.dataPeriodoStringInicio;
    this.parametros.dataPeriodoStringFim = this.dadosPequisa.dataPeriodoStringFim;
    this.parametros.responsavel = this.dadosPequisa.responsavel;

    if(this.dadosPequisa.check){
      this.servico = 'ConsultarAlteracoes/GetProduto';

      if(this.dadosPequisa.check == 1){
        this.parametros.codigo = null;
        this.parametros.descricao = null;
      }

      if(this.dadosPequisa.check == 2){
        if(!this.dadosPequisa?.dscProduto){
          this.modal.infoError("Necessário informar o <b>Código</b> para a busca","Atenção");
          return false;
        }
        this.parametros.codigo = this.dadosPequisa.dscProduto;
        this.parametros.descricao = null;
      }

      if(this.dadosPequisa.check == 3){
        if(!this.dadosPequisa?.dscProduto){
          this.modal.infoError("Necessário informar a <b>Descrição</b> para a busca","Atenção");
          return false;
        }
        this.parametros.descricao = this.dadosPequisa.dscProduto;
        this.parametros.codigo = null;
      }
      this.buscar();
    }

    if(this.dadosPequisa.check2){
      this.servico = 'ConsultarAlteracoes';

      if(this.dadosPequisa.check2 == 1){
        this.parametros.codigo = null;
        this.parametros.descricao = null;
      }

      if(this.dadosPequisa.check2 == 2){
        if(!this.dadosPequisa.dscUnidade){
          this.modal.infoError("Necessário informar o <b>Código</b> para a busca","Atenção");
          return false;
        }
        this.parametros.codigo = this.dadosPequisa.dscUnidade;
        this.parametros.descricao = null;
      }

      if(this.dadosPequisa.check2 == 3){
        if(!this.dadosPequisa.dscUnidade){
          this.modal.infoError("Necessário informar a <b>Descrição</b> para a busca","Atenção");
          return false;
        }
        this.parametros.descricao = this.dadosPequisa.dscUnidade;
        this.parametros.codigo = null;
      }
      this.buscar();
    }

    if(this.dadosPequisa.check3){
      this.servico = 'ConsultarAlteracoes/GetSubsetor';

      if(this.dadosPequisa.check3 == 1){
        this.parametros.codigo = null;
        this.parametros.descricao = null;
      }

      if(this.dadosPequisa.check3 == 2){
        if(!this.dadosPequisa.dscSub){
          this.modal.infoError("Necessário informar o <b>Código</b> para a busca","Atenção");
          return false;
        }
        this.parametros.codigo = this.dadosPequisa.dscSub;
        this.parametros.descricao = null;
      }

      if(this.dadosPequisa.check3 == 3){
        if(!this.dadosPequisa.dscSub){
          this.modal.infoError("Necessário informar a <b>Descrição</b> para a busca","Atenção");
          return false;
        }
        this.parametros.descricao = this.dadosPequisa.dscSub;
        this.parametros.codigo = null;
      }

      this.buscar();
    }
    if(this.dadosPequisa.check4){
      this.servico = 'ConsultarAlteracoes/GetNcm';
      if(this.dadosPequisa.check4 == 1){
        this.parametros.codigo = null;
        this.parametros.descricao = null;
      }

      if(this.dadosPequisa.check4 == 2){
        if(!this.dadosPequisa.dscNcm){
          this.modal.infoError("Necessário informar o <b>Código</b> para a busca","Atenção");
          return false;
        }
        this.parametros.codigo = this.dadosPequisa.dscNcm;
        this.parametros.descricao = null;
      }

      if(this.dadosPequisa.check4 == 3){
        if(!this.dadosPequisa.dscNcm){
          this.modal.infoError("Necessário informar a <b>Descrição</b> para a busca","Atenção");
          return false;
        }
        this.parametros.descricao = this.dadosPequisa.dscNcm;
        this.parametros.codigo = null;
      }

      this.buscar();
    }
  }

  buscar(){
    if (this.flagAlterouFiltro) {
      this.parametros.page = 1;
      this.parametros.size = 10;
      this.grid.page = 1;
      this.grid.size = 10;
      //reinicia a paginacao dentro de app-grid
      this.changeIniciaPaginacao.Reinicia(true);
    } else {
      this.parametros.page = this.grid.page;
      this.parametros.size = this.grid.size;
    }
    this.parametros.sort = this.grid.sort.field;
    this.parametros.reverse = this.grid.sort.reverse || false;
    this.flagAlterouFiltro = false;
    this.exibirGrid = true;
    this.applicationService.get(this.servico, this.parametros)
      .subscribe((response: ConsultarAlteracoesVM) => {
        if (response.total != null && response.total > 0) {
          this.grid.lista = response.items;
          this.grid.total = response.total;
          this.prencheParametrosDeExportacao();
        } else {
          this.grid = { sort: {} };
          this.modal.infoError('Nenhum Registro Encontrado', 'Informação');
        }
      });
  }

  prencheParametrosDeExportacao(){
    this.parametros.exportarListagem = true;
    this.parametros.exportarPdf = true;
    this.parametros.servico = this.servico;
    if(this.servico == 'ConsultarAlteracoes/GetProduto'){
      this.parametros.titulo = "Consultar Alterações | Produtos Padronizados"
    }
    if(this.servico == 'ConsultarAlteracoes'){
      this.parametros.titulo = "Consultar Alterações | Unidades"
    }
    if(this.servico == "ConsultarAlteracoes/GetNcm"){
      this.parametros.titulo = "Consultar Alterações | NCM"
    }
    if(this.servico == 'ConsultarAlteracoes/GetSubsetor'){
      this.parametros.titulo = "Consultar Alterações | Subsetor"
    }

    this.parametros.columns = [
                                "Código", "Descrição", "Login", "Responsável", "Data/Hora	"
                              ];

    this.parametros.fields = [
                                "codigo", "descricao", "login", "responsavel", "dataString"
                            ];
    this.parametros.width = {
                                0: { columnWidth: 100 }, 1: { columnWidth: 300 }, 2: { columnWidth: 80 }, 3: { columnWidth: 120 }, 4: { columnWidth: 150 }
                            };
  }


  onChangeSort($event: any) {
    this.grid.sort = $event;
  }

  onChangeSize($event: any) {
    this.grid.size = $event;
  }

  onChangePage($event: any) {
    this.grid.page = $event;
    this.buscar();
  }

  limpar() {
		this.dadosPequisa = new ConsultarAlteracoesVM();
    this.parametros =  new ConsultarAlteracoesVM();
    this.exibirGrid = false;
	}

}
