<div #appModalManterTipoProduto class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll; overflow-y: auto;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Incluir Tipo de Produto</h4>
            </div>

            <div class="modal-body" style="overflow-y: auto;">

                <form (ngSubmit)="validar()" class="form form-horinzontal needs-validation" role="form">

                    <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">
                        <div class="col-lg-3">
                            <label>Código:</label>
                            <input
                                type="text"
                                class="form-control"
                                id="topico-etapa"
                                style="width: 100%;"
                                maxlength="3"
                                onlyNumber="true"
                                name="topico-etapa"
                                [(ngModel)]="parametros.numeroCodigo"
                            />
                            <div class="alert-danger" *ngIf=" _flagCodigoNaoInformado ">
                                <div>
                                    <button type="button" class="close" (click)=" _flagCodigoNaoInformado = false; "><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;">Informe o Código</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <label>Descrição:</label>
                            <textarea
                                class="form-control"
                                id="descricao-etapa"
                                name="descricao-etapa"
                                rows="4"
                                maxlength="300"
                                style="resize: none;"
                                [(ngModel)]="parametros.descricao">
                            </textarea>
                            <div class="alert-danger" *ngIf=" _flagDescricaoNaoInformada ">
                                <div>
                                    <button type="button" class="close" (click)=" _flagDescricaoNaoInformada = false; "><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;">Informe a Descrição</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">
                        <div class="col-lg-3">
                            <label>Agregação:</label>
                            <select
                                name="combobox-agregacao"
                                id="combobox-agregacao"
                                class="form-control"
                                [(ngModel)]="parametros.tipoAgregacao">
                                    <option value="1">BEM FINAL</option>
                                    <option value="2">SUBCONJUNTO</option>
                                    <option value="3">COMPONENTE</option>
                                    <option value="4">OUTRO INSUMO</option>
                                    <option value="5">MATERIAL DE EMBALAGEM</option>
                            </select>
                            <div class="alert-danger" *ngIf=" _flagAgregacaoNaoInformada ">
                                <div>
                                    <button type="button" class="close" (click)=" _flagAgregacaoNaoInformada = false; "><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;">Informe a Agregação</b>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-7">
                            <label >Subsetor de Destino:</label>
                            <app-drop-list
                                id="drop-list-subsetor"
                                name="drop-list-subsetor"
                                servico="SubSetor"
                                [(ngModel)]="parametros.idSetor">
                            </app-drop-list>
                            <div class="alert-danger" *ngIf=" _flagSubSetorNaoInforamdo ">
                                <div>
                                    <button type="button" class="close" (click)=" _flagSubSetorNaoInforamdo = false; "><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;">Informe o Subsetor</b>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>

            </div>

            <div class="modal-footer" style="padding: 10px 15px;">
                <button
                    id="fechar-modal-etapa"
                    name="fechar-modal-etapa"
                    (click)="fechar()"
                    class="btn btn-sm btn-default"
                    data-dismiss="modal">
                        <i class="fa fa-times"></i> Fechar
                </button>
                <button
                    id="salvar-modal-etapa"
                    name="salvar-modal-etapa"
                    data-toggle="tooltip"
                    title="salvar"
                    data-original-title="salvar"
                    (click)="validar()"
                    type="submit"
                    class="btn btn-sm btn-primary"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-backdrop="static"
                    data-keyboard="false">
                        <i class="fa fa-save m-r-xs"></i> Salvar
                </button>
            </div>

        </div>

    </div>
</div>
<div #appModalManterTipoProdutoBackground class="modal-backdrop fade in" style="display: none;"></div>
