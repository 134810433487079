import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { NcmEntityVM } from '../../../../view-model/NcmEntityVM';
import { ModalService } from '../../../../shared/services/modal.service';
import { ApplicationService } from '../../../../shared/services/application.service';
import { ProdutoHistoricoVM } from '../../../../view-model/ProdutoHistoricoVM';

@Component({
	selector: 'app-modal-justificativa-ncm',
	templateUrl: './modal-justificativa.component.html'
})

export class ModalJustificativaNCMComponent implements OnInit {

	_flagCampoVazio : boolean = false;
	_justificativa : string = "";
	_portaria: string = "";

	_pendenciaJustificativa: boolean = false;
	_pendenciaPortaria: boolean = false;

	@Input() data: any = {};
	@Output() changeOperation: EventEmitter<any> = new EventEmitter();
	@Output() atualizarListagem: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalJustificativaNcm') appModalJustificativaNcm : any;
	@ViewChild('appModalJustificativaNcmBackground') appModalJustificativaNcmBackground : any;

	@ViewChild('close') close!: ElementRef;
	@ViewChild('ok') ok!: ElementRef;

	operacao: string;
	parametros: NcmEntityVM = new NcmEntityVM();
	servico = "ManterNcm";

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService) {
			
		}

	ngOnInit() {
		this._flagCampoVazio = false;
		this._justificativa = "";
		this._portaria = "";

		this._pendenciaJustificativa = false;
		this._pendenciaPortaria = false;
	}

	abrir(operacao: string, objeto: NcmEntityVM){
		this._justificativa = "";
		this._portaria = "";

		this._pendenciaJustificativa = false;
		this._pendenciaPortaria = false;
		
		this.operacao = operacao;
		this.parametros = objeto;
		this.appModalJustificativaNcmBackground.nativeElement.style.display = 'block';
		this.appModalJustificativaNcm.nativeElement.style.display = 'block';
	}

	fechar(){
		this.changeOperation.emit(false);
		this.appModalJustificativaNcmBackground.nativeElement.style.display = 'none';
		this.appModalJustificativaNcm.nativeElement.style.display = 'none';
	}

	validar(){
		this._pendenciaJustificativa = false;
		this._pendenciaPortaria = false;

		if(!this._justificativa || this._justificativa.trim().length == 0){
			this._pendenciaJustificativa = true;
			return false;
		}
		if(!this._portaria || this._portaria.trim().length == 0){
			this._pendenciaPortaria = true;
			return false;
		}
		this.salvar();
	}
	
	salvar(){
		var objeto = new ProdutoHistoricoVM();

		objeto.descricaoJustificativa = "Portaria: "+this._portaria+ " - " +this._justificativa
		objeto.idNcm = this.parametros.id
		objeto.statusAtual = (this.operacao=="Inativar")? 0:1
		objeto.statusAnterior = (this.operacao=="Inativar")? 1:0
		objeto.descricaoAcao = (objeto.statusAtual==0)? "ALTERAÇÃO: Status De: ATIVO Para: INATIVO":"ALTERAÇÃO: Status De: INATIVO Para: ATIVO";
		this.applicationService.post(this.servico+"/PostJustificativa",objeto).subscribe((result: number)=>{
			if(result == 200){
				this.modal.infoSucess("Operação realizada com sucesso","Sucesso");
				this.fechar();
				this.atualizarListagem.emit();
				this._justificativa = "";
			}
		});
	}
}
