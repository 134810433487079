<app-grid
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
			<thead class="table-header-color">
				<tr>
					<th class="th-100 text-left">Ações</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="NumeroCodigo" [sorted]="sorted" (onChangeSort)="changeSort($event)">Código</app-ordenacao>
					</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="DescProduto" [sorted]="sorted" (onChangeSort)="changeSort($event)">Descrição </app-ordenacao>
					</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="SitProduto" [sorted]="sorted" (onChangeSort)="changeSort($event)">Situação</app-ordenacao>
					</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="CoeficienteReducao.Descricao" [sorted]="sorted" (onChangeSort)="changeSort($event)">Coef. de Redução</app-ordenacao>
					</th>
                    <th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="UnidadeMedida.Descricao" [sorted]="sorted" (onChangeSort)="changeSort($event)">Unidade</app-ordenacao>
					</th>
                    <th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="SitComponente" [sorted]="sorted" (onChangeSort)="changeSort($event)">Componente</app-ordenacao>
					</th>
                    <th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="SitBemInformativa" [sorted]="sorted" (onChangeSort)="changeSort($event)">Bem de Informática</app-ordenacao>
					</th>
				</tr>
			</thead>
			<tbody *ngIf="total > 0">
				<tr *ngFor="let item of lista">
                    <td class="text-center" *ngIf="eUsuarioSuframa">
                        <a
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            data-placement="right"
                            (click)="abrirListagem(item.id, item.descProduto)"
                            title="Listagem de Bases Legais"
                            data-original-title="Listagem de Bases Legais">
                                <i class="fa fa-list-ul"></i>
                        </a>
                        <a
                            routerLink="/solicitacoes-produto-padrao/{{item.id}}/visualizar"
                            *ngIf="item.sitProduto == 2 || item.sitProduto == 3 || item.sitProduto == 4"
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            title="Visualizar"
                            data-original-title="Visualizar">
                                <i class="fa fa-file-text-o"></i>
                        </a>
                        <a
                            *ngIf="item.sitProduto == 2 || item.sitProduto == 3 || item.sitProduto == 4"
                            class=" btn btn-default btn-sm "
                            data-toggle="tooltip"
                            title="Histórico"
                            data-original-title="Histórico"
                            (click)= "abrirHistorico(item)">
                                <i class="i i-history"></i>
                        </a>
                        <a
                            routerLink="/solicitacoes-produto-padrao/{{item.id}}/editar"
                            *ngIf="item.sitProduto == 1 || item.sitProduto == 2"
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            title="Alterar"
                            data-original-title="Alterar">
                                <i class="fa fa-pencil"></i>
                        </a>
                        <a
                            *ngIf="item.sitProduto == 2"
                            (click)="change('Inativar', item)"
                            class=" btn btn-rounded btn-sm btn-icon btn-primary"
                            data-toggle="tooltip"
                            title="Inativar"
                            data-original-title="Inativar">
                                <i class="fa fa-check"></i>
                        </a>
                        <a
                            *ngIf="item.sitProduto == 1"
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            title="Finalizar"
                            (click) = "abrirFinalizar(item.id)"
                            data-original-title="Finalizar">
                            <i class="i i-paperplane"></i>
                        </a>
                        <a
                            *ngIf="item.sitProduto == 1"
                            (click)="excluirProduto(item.id)"
                            class="btn btn-danger btn-sm"
                            data-toggle="tooltip"
                            title="Excluir"
                            data-original-title="Excluir">
                                <i class="fa fa-trash-o"></i>
                        </a>
                        <a
                            *ngIf="item.sitProduto == 3"
                            (click)="AtivarReativarProduto('Ativar', item)"
                            class=" btn btn-rounded btn-sm btn-icon btn-danger"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Reativar">
                                <i class="fa fa-times"></i>
                        </a>
                        <a
                            *ngIf="item.sitProduto == 4"
                            (click)="AtivarReativarProduto('Ativar', item)"
                            class=" btn btn-rounded btn-warning btn-sm btn-icon btn-primary"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Reativar">
                                <i class="fa fa-check"></i>
                        </a>
                    </td>
                    <td class="text-center" *ngIf="!eUsuarioSuframa">
                      <a
                          routerLink="/solicitacoes-produto-padrao/{{item.id}}/visualizar"
                          *ngIf="item.sitProduto == 2 || item.sitProduto == 3 || item.sitProduto == 4"
                          class="btn btn-default btn-sm"
                          data-toggle="tooltip"
                          title="Visualizar"
                          data-original-title="Visualizar">
                              <i class="fa fa-file-text-o"></i>
                      </a>

                  </td>
					          <td class="text-center">{{item.numeroCodigo == null? 'Não disponível' : item.numeroCodigo}}</td>
                    <td class="text-left">{{item.descProduto}}</td>
                    <td class="text-center">{{item.situacaoProduto}}</td>
                    <td class="text-center">{{item.coeficienteReducao}}</td>
                    <td class="text-center">{{item.siglaUnidadeDeMedida}}</td>
                    <td class="text-center">{{item.situacaoComponente}}</td>
                    <td class="text-center">{{item.situacaoBemInformatica}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</app-grid>
<app-modal-justificativa-produto
    #modalJustificativa
    (changeOperation)="receberAlteracaoJustificativaInativacao($event)"
    [(data)]="_dataJustificativa"
></app-modal-justificativa-produto>

<app-modal-justificativa-produto
    #modalJustificativaAtivacaoReativacao
    (changeOperation)="receberAlteracaoJustificativaAtivacao($event)"
    [(data)]="_dataJustificativa"
></app-modal-justificativa-produto>

<app-modal-historico
    #modalHistorico
></app-modal-historico>

<app-modal-listagem-ppb
    #ModalListagemPPB
></app-modal-listagem-ppb>
