<app-grid 
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
			<thead class="table-header-color">
				<tr>
					<th class="th-100 text-left">Ações</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="StatusPPB" [sorted]="sorted" (onChangeSort)="changeSort($event)">Situação</app-ordenacao>
					</th>					
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="DescricaoTitulo" [sorted]="sorted" (onChangeSort)="changeSort($event)">Documento</app-ordenacao>
					</th>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="DataPublicacao" [sorted]="sorted" (onChangeSort)="changeSort($event)">Publicação</app-ordenacao>
					</th>						
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="DataInicioVigencia" [sorted]="sorted" (onChangeSort)="changeSort($event)">Início Vigência</app-ordenacao>
					</th>		
                    <th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="TipoPublicacao" [sorted]="sorted" (onChangeSort)="changeSort($event)">Tipo de Vínculo</app-ordenacao>
					</th>	
                    <th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="DescricaoTitulo" [sorted]="sorted" (onChangeSort)="changeSort($event)">Documento de Refêrencia</app-ordenacao>
					</th>	
				</tr>
			</thead>
			<tbody>				
				<tr *ngFor="let item of lista">
                    <td class="text-left">
                        <a 
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip" 
                            (click)="abrirListagens(item.id, item.tipoEtapa)"
                            title="Listagens" 
                            data-original-title="Listagens">
                                <i class="fa fa-list-ul"></i>
                        </a>
                        <a  
                            (click)="buscarAnexo(item.id)"                          
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip" 
                            title="Abrir PPB" 
                            data-original-title="Abrir PPB">
                                <i class="i i-publish"></i>
                        </a>
                        <a 
                            *ngIf="item.statusPPB == 1"
                            (click)="abrirEditar(item.id)"
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip" 
                            title="Alterar" 
                            data-original-title="Alterar">
                                <i class="fa fa-pencil"></i>
                        </a>
                        <a 
                            *ngIf="item.statusPPB == 1"
                            (click)="excluir(item.id)"
                            class="btn btn-danger btn-sm" 
                            data-toggle="tooltip" 
                            title="Excluir" 
                            data-original-title="Excluir">
                                <i class="fa fa-trash-o"></i>
                        </a>
                        <a 
                            *ngIf="item.statusPPB == 1"
                            (click)="validar(item.id)"
                            title="Validar" 
                            data-original-title="Validar"
                            data-toggle="tooltip" 
                            class="btn btn-default btn-sm">
                                <i class="fa fa-check-square-o"></i>
                        </a>
                        <a 
                            *ngIf="item.statusPPB == 2 || item.statusPPB == 3 || item.statusPPB == 4"
                            routerLink="/solicitacoes-ppb/{{item.id}}/visualizar"
                            title="Visualizar" 
                            data-original-title="Visualizar"
                            data-toggle="tooltip" 
                            class="btn btn-default btn-sm">
                                <i class="fa fa-file-text-o"></i>
                        </a>
                        <a 
                            *ngIf="item.statusPPB == 1"
                            (click)="abrirFinalizar(item.id)"
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip" 
                            title="Finalizar" 
                            data-original-title="Finalizar">
                                <i class="i i-paperplane"></i>
                        </a>
                        <a 
                            *ngIf="item.statusPPB == 2 || item.statusPPB == 3 || item.statusPPB == 4"
                            (click)="abrirVinculos(item.id)"
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip" 
                            title="Vínculos" 
                            data-original-title="Vínculos">
                                <i class="fa fa-link "></i>
                        </a>
                    </td>
					<td class="text-left">{{ definirStatus(item.statusPPB) }}</td>
                    <td class="text-left">{{ item.descricaoTitulo }}</td>
                    <td class="text-left">{{ numeroHoje | mask: '(000) 000-0000'}}</td>
                    <!-- <td class="text-left">{{ item.dataPublicacaoString }}</td> -->
                    <td class="text-left">{{ item.dataInicioVigenciaString }}</td>
                    <td class="text-left">{{ definirVinculo(item.tipoPublicacao) }}</td>
                    <td class="text-left">{{ item.descricaoPPBReferencia }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</app-grid>

<app-modal-etapas-condicoes-ppb #appModalEtapasCondicoesPPB></app-modal-etapas-condicoes-ppb>
<app-modal-finalizar #appModalFinalizar></app-modal-finalizar>
<app-modal-vinculos #appModalVinculos></app-modal-vinculos>