import { Component, Injectable, OnInit, Pipe, ViewChild } from "@angular/core";
import { PagedItems } from "../../../view-model/PagedItems";
import { MessagesService } from "../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../shared/services/validation.service";
import { ApplicationService } from "../../../shared/services/application.service";
import { ModalService } from '../../../shared/services/modal.service';
import { EnumRestResponse } from '../../../shared/enums/EnumRestResponse';
import { SolicitarCadastroPPBVM } from '../../../view-model/SolicitarCadastroPPBVM';
import { PPBVM } from '../../../view-model/PPBVM';
import moment = require("moment");
import * as $ from 'jquery';
import { NgLocalization } from "@angular/common";
import { ValidacaoMPPBVM } from "../../../view-model/ValidacaoMPPBVM";

enum EnumTipoEtapa {
	SEM_PONTUACAO = 1 , 
	POR_PONTUACAO = 2 , 
	POR_GRUPO = 3
}

@Component({
	selector: "app-controle-solicitacoes",
	templateUrl: "./controle-solicitacoes-ppb.component.html"
})

@Injectable()
export class ControleSolicitacoesComponent implements OnInit {

	objetoExportacao : any = {};
    mostraComponenteRelatorio: boolean = false;

	/*****DATAS DO PPB*********************************/
	_dataDocumento : string;
	_dataPublicacao : string;
	_dataVigencia : string;
	_dataVigenciaParams : string; //data que sera passada para etapa e condicionates.
	/**************************************************/

	/***PROPRIEDADES REFERENTES AO CONTROLE DE ANEXO***/
	limiteArquivo = 10485760; // 10MB
	temArquivo : boolean = false;
	filetype: string;
	filesize: number;
	types = ['application/pdf'];
	/**************************************************/

	/*******************FLAGS GRID*********************/
	_isSempontuacao : boolean = false;
	_isPorPontuacao : boolean = false;
	_isPorGrupo : boolean = false;
	/**************************************************/

	/*****************TIPO PUBLICAÇÃO******************/
	FIXACAO : number = 1;
	ALTERACAO_REVOGACAO : number = 2; 
	ALTERACAO_PARCIAL : number = 3;
	AGREGACAO : number= 4;
	/**************************************************/
	
	objetoValidacao : ValidacaoMPPBVM = new ValidacaoMPPBVM();

	form = this;
	path : string;
	grid: any = { sort: {} };
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;
	htmlContent = '';
	parametros : SolicitarCadastroPPBVM = new SolicitarCadastroPPBVM();
	habilitarInputs: boolean = true;
	idPPB : number;

	//********SERVICOS*********************************
	servicoCadastroPPB = 'SolicitarCadastroPPB';
	servicoCadastroPPBAnexo = 'AnexoPPB';

	listaTipoDocumento : any = {};
	
	@ViewChild('DocumentoReferencia') DocumentoReferencia : any;
	@ViewChild('appModalEscolherOrgaos') appModalEscolherOrgaos : any;
	@ViewChild('appRelatorioConsolidado') appRelatorioConsolidado : any;
	@ViewChild('dataInicioVigencia') dataInicioVigencia;
	@ViewChild('dataDocumento') dataDocumento;
	@ViewChild('arquivo') arquivo;
	@ViewChild('titulo') titulo;	
	@ViewChild('tipoDocumento') tipoDocumento;		
	@ViewChild('radioSemPontuacao') radioSemPontuacao;
	@ViewChild('radioPorPontuacao') radioPorPontuacao; 
	@ViewChild('radioPorGrupo')  radioPorGrupo;

	constructor(
        private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
        private validation: ValidationService,
        private router: Router
	) { 

		$("div.id_100 select").val("undefined"); //seleciona item padrão no combobox Tipo de Documento.	
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;			
	}

	ngOnInit() { 
		this.preencheComboboxTipoDocumento();	
		if(this.path != 'novo'){
			this.idPPB = Number(this.route.snapshot.params['idPpb']);
			this.buscar(this.idPPB);

			if(this.path == 'visualizar'){
				this.habilitarInputs = false;
			}

			if(this.path == 'validacao'){
				this.validacaoPPB();
			}
		}	
	}

	validacaoPPB(){
		this.applicationService.get("Validar", this.idPPB).subscribe( (response: ValidacaoMPPBVM) => {
           if(response._flagExistePendencia){				
				this.modal.infoError("PPB com dados Inconsistentes! Favor verificar.", "Informação");
				//exite pendendia! entao sera o reponse dentro do objeto objetoValidacao
				this.objetoValidacao = response;
		   }else{
				this.modal.sucesso("PPB Apto para Finalização!", "Sucesso").subscribe( () => this.voltar() );
		   }
		});
	}

	onChangeSort($event : any) {
		this.grid.sort = $event;
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
	}

	onChangePage($event : any) {
		this.grid.page = $event;
    }

    voltar(){
		this.router.navigate(['/manter-ppb']);
    }

	validar(){

		if(!this.parametros.ppbVM.tipoPublicacao){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Tipo Publicação</b>", "Atenção");
			return false;
		} else {
			this.parametros.ppbVM.tipoPublicacao = Number(this.parametros.ppbVM.tipoPublicacao);
		}
		
		if(this.parametros.ppbVM.tipoPublicacao != 1 && !this.parametros.idPPBDocumentoSelecionado){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Documento de Referência</b>", "Atenção");
			return false;
		}

		if(!this.parametros.dataPublicacaoString){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Data de Publicação</b>", "Atenção");
			return false;
		}

		if(!this.parametros.ppbVM.idTipoDocumento){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Tipo de Documento</b>", "Atenção");
			return false;
		}

		if(!this.parametros.ppbVM.numeroDocumento){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Número</b>", "Atenção")
			return false;;
		}

		if(!this.parametros.dataDocumentoString){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Data Documento</b>", "Atenção");
			return false;
		}

		var descricaoTitulo = document.getElementById('descricao-titulo').innerHTML;
		if(descricaoTitulo.length){
			this.parametros.ppbVM.descricaoTitulo = descricaoTitulo;
		}	 		

		if(this.parametros.ppbVM.tipoEtapa)
			this.parametros.ppbVM.tipoEtapa = Number(this.parametros.ppbVM.tipoEtapa);

		this.salvar();
	}
	
	buscar(idPPB : number){	

		var objeto : PPBVM = new PPBVM();
		objeto.id = idPPB;

		this.applicationService.get(this.servicoCadastroPPB, objeto).subscribe((retorno : SolicitarCadastroPPBVM) => {				
			if (retorno) {

				this.parametros = retorno;

				retorno.ppbAnexoVM.objetoArquivo != null ? this.temArquivo = true : this.temArquivo = false;				

				if(this.parametros.dataDocumentoString) 
					this._dataDocumento = this.parametros.dataDocumentoString;									

				if(this.parametros.dataPublicacaoString) 
					this._dataPublicacao = this.parametros.dataPublicacaoString;				

				if(this.parametros.dataInicioVigenciaString) {
					this._dataVigencia = this.parametros.dataInicioVigenciaString;
					this._dataVigenciaParams = this.parametros.dataInicioVigenciaString;
				}
								
					
				if(this.parametros.ppbVM.tipoEtapa > 0){
					this.parametros.ppbVM.tipoEtapa = String(this.parametros.ppbVM.tipoEtapa);
					this.validaTipoEtapa(Number(this.parametros.ppbVM.tipoEtapa));
				}				
			} 
		});
	}

	salvar(){
		this.applicationService.post(this.servicoCadastroPPB, this.parametros).subscribe((idPPb : number) => {				
			if (idPPb) {
				if(this.path == 'novo'){
					this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(()=>this.router.navigate(['solicitacoes-ppb/'+idPPb+'/editar']));
				} else {
					this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(()=>this.buscar(idPPb));
				}				
			} 
		});
	}

	onFileChange(event) {
		let reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {		
			let file = event.target.files[0];
			reader.readAsDataURL(file);
			this.filetype = file.type;
			this.filesize = file.size;
			if(this.types.includes(this.filetype)) {
				if(this.filesize < this.limiteArquivo){
					this.temArquivo = true;
					reader.onload = () => {
						this.parametros.flagIsNovoAnexo = true;
						this.parametros.ppbAnexoVM.descricaoArquivo = file.name;
						this.parametros.ppbAnexoVM.objetoArquivo = (reader.result as string).split(',')[1];
					};
				} else {
					this.modal.infoError(this.msg.ANEXO_ACIMA_DO_LIMITE.replace('($)','10'),'Atenção');
					this.limparAnexoPdf();
				}
			} else {
				this.modal.infoError(this.msg.FAVOR_SELECIONAR_UM_ARQUIVO_NO_FORMATO_PDF,'Atenção');
				this.limparAnexoPdf();
			}
		}else{
			this.temArquivo = false;
		}
	}

	validaTipoEtapa(tipoEtapa : number){
		switch(tipoEtapa){
			case EnumTipoEtapa.SEM_PONTUACAO :
				this._isSempontuacao = true;				
				this._isPorPontuacao = false;
				this._isPorGrupo = false;
				break;
			case EnumTipoEtapa.POR_PONTUACAO :
				this._isSempontuacao = false;				
				this._isPorPontuacao = true;
				this._isPorGrupo = false;				
				break;	
			case EnumTipoEtapa.POR_GRUPO :
				this._isSempontuacao = false;				
				this._isPorPontuacao = false;
				this._isPorGrupo = true;
				break;	
		}		
	}

	limparAnexoPdf() {
		this.temArquivo = false;
		this.arquivo.nativeElement.value = '';
		this.parametros.ppbAnexoVM.objetoArquivo = null;
		this.parametros.ppbAnexoVM.descricaoArquivo = null;
	}

	baixarAnexo() {
		if (this.parametros.ppbAnexoVM.objetoArquivo) {
			const hashPDF = this.parametros.ppbAnexoVM.objetoArquivo;
			const linkSource = 'data:' + 'application/pdf' + ';base64,' + hashPDF;
			const downloadLink = document.createElement('a');
			const fileName = this.parametros.ppbAnexoVM.descricaoArquivo;
			document.body.appendChild(downloadLink);
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.target = '_self';
			downloadLink.click();
		} else {
			this.modal.infoError('Erro ao baixar arquivo.', 'Informação');
			return false;
		}
	}

	confirmarExclusao(){
		this.modal.confirmacao("Deseja Excluir o Anexo?").subscribe((isConfirmado : any) => {
			 if(isConfirmado) {
				if(this.parametros.flagIsNovoAnexo){
					this.limparAnexoPdf();
				} else {					
					this.excluirAnexoBackEnd();
				} 	
			 }						  
		});	
	}
	
	excluirAnexoBackEnd(){
		this.applicationService.delete(this.servicoCadastroPPBAnexo, this.idPPB).subscribe((retorno : EnumRestResponse) => { 
			if(retorno == EnumRestResponse.OK){
				this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success");
				this.buscar;
				this.temArquivo = false;
				this.parametros.flagIsNovoAnexo = true;
			} else {
				this.modal.infoError("Não foi Possível Excluir Anexo - ERRO 500.", this.msg.ERRO_500);
				return false;
			}			
		});
	}

	abrirModalEscolhaOrgaos(){
		this.appModalEscolherOrgaos.abrir();
	}

	changeOrgaosPPB($event){
		this.parametros.ppbVM.descricaOrgao = $event;
	}

	selectTipoDocumento($event){
		var indiceSelecionado = this.tipoDocumento.nativeElement.options.selectedIndex;
		if(indiceSelecionado >= 0)
			this.parametros.descricaoTipoDocumento =  this.tipoDocumento.nativeElement.options[indiceSelecionado].label;	
	}

	preencheComboboxTipoDocumento(){
		this.applicationService.get("DocumentoMppb").subscribe((retorno : any) => {				
			if (retorno != null) {
				this.listaTipoDocumento = retorno;
			}
		});
	}

	transformDataToStringFormat(data : Date){
		return this.validation.getDateWhithChangeFormat('YYYY-MM-DD' , data);
	}

	mostraDataTitulo(data : string){
		//return this.validation.getDateWhithChangeFormat('DD/MM/YYYY' , new Date(data), true);
		return new Date(data).getDate()+1 +" DE "+ this.transformaMes(new Date(data).getMonth()+1) +" DE "+
		+ new Date(data).getFullYear() 
	}

	transformDataDocumento() {	
		this.parametros.dataDocumentoString = this._dataDocumento;
		this.mostraDataTitulo(this.parametros.dataDocumentoString);
	}
	
	transformDataPublicacao(){
		this.parametros.dataPublicacaoString = this._dataPublicacao;
	}

	transformDataVigencia(){
		this.parametros.dataInicioVigenciaString = this._dataVigencia;
	}

	
	transformaMes(mes: number): string{
		switch (mes) {
			case 1:
				
				return 'JANEIRO';

			case 2:
				return 'FEVEREIRO';

			case 3:
				return 'MARÇO';

			case 4:
				return 'ABRIL';

			case 5:
				return 'MAIO';

			case 6:
				return 'JUNHO';

			case 7:
				return 'JULHO';

			case 8:
				return 'AGOSTO';

			case 9:
				return 'SETEMBRO';

			case 10:
				return 'OUTUBRO';

			case 11:
				return 'NOVEMBRO';

			case 12:
				return 'DEZEMBRO';
		}
	}

	validaVirgularTipoDocumento(){
		if(!this.parametros.ppbVM.descricaOrgao && !this.parametros.ppbVM.numeroDocumento && !this._dataDocumento){
			return this.parametros.descricaoTipoDocumento;
		} else {
			return this.parametros.descricaoTipoDocumento + ",";
		}
	}

	validaVirgularDescricaOrgao(){
		if(!this.parametros.ppbVM.numeroDocumento && !this._dataDocumento){
			return this.parametros.ppbVM.descricaOrgao;
		} else {
			return this.parametros.ppbVM.descricaOrgao + ",";
		}
	}

	validaVirgularNumeroDocumento(){
		if(!this._dataDocumento){
			return this.parametros.ppbVM.numeroDocumento;
		} else {
			return this.parametros.ppbVM.numeroDocumento + ",";
		}
	}

	limparTipoDocumento(){
		$("div.id_100 select").val("undefined");
		this.parametros.ppbVM.idTipoDocumento = null;
		this.parametros.descricaoTipoDocumento = null;
	}

	limparDataDocumento(){
		this.parametros.dataDocumentoString = null;
		this._dataDocumento = null;
	}

    exportarPDF(){
		this.mostraComponenteRelatorio = true;
		this.buscarDadosExportacao(); 
	}

	BaixouPdf($event){		
		this.mostraComponenteRelatorio = $event;
		this.objetoExportacao = null;
	}
	
	buscarDadosExportacao()
	{
		this.applicationService.get("RelatorioListagensPPB", this.idPPB).subscribe((result:any) => {
			this.objetoExportacao = result;
			this.appRelatorioConsolidado.geraRelatorioConsolidado(); 
		});
	}

}