<div
    #appModalDetalhePPB
    class="modal modal-wide-2 fade in"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
    style="display: auto; overflow: scroll;"
    id="modal-top-historico-listagens">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Listagens do PPB</h4>
            </div>

            <div class="modal-body">

                <div class="pull-right">
                    <a (click)="fechar()" class="btn btn-sm btn-default" style="margin-top: -100px;" data-dismiss="modal">
                        <i class="fa fa-arrow-left"></i> Voltar
                    </a>
                </div>

              <div class="row form-group">
                  <div class="col-sm-12">
                    <label for="descricao-titulo-ppb">Descrição Documento:</label>
                    {{titulo}}
                  </div>
              </div>


            <div class="row form-group">
                <div class="col-lg-12">
                    <label for="resumo-tecnico" class="required">Resumo Técnico:</label>
                    <textarea
                        [(ngModel)]="_resumoTecnico"
                        class="form-control ng-pristine ng-valid ng-touched"
                        maxlength="2000"
                        name="resumo-tecnico-solicitacoes-ppb"
                        id="resumo-tecnico-solicitacoes-ppb"
                        rows="5"
                        disabled
                        style="resize: none;">
                    </textarea>
                </div>
            </div>

            <app-ppb-multiplo
                *ngIf="exibirGrids && _isPPBMultiplo"
                [habilitarInputs]="false"
                [(idPPB)]="_idPpb"
                [_fromTelaElaboracao]="false"
                #appPPBMultiplo>
            </app-ppb-multiplo> 

            <app-etapas-ppb
                *ngIf="exibirGrids"
                [(_isSempontuacao)]="_isSempontuacao"
                [(_isPorPontuacao)]="_isPorPontuacao"
                [(_isPorGrupo)]="_isPorGrupo"
                [(_isPPBMultiplo)]="_isPPBMultiplo"
                [habilitarInputs]="false"
                [(idPPB)]="_idPpb"
                [buscarVinculos]="true"
                [_fromTelaElaboracao]="false"
                #appEtapasPPB
            >
            </app-etapas-ppb>

            <app-condicionantes-ppb
                *ngIf="exibirGrids"
                [habilitarInputs]="false"
                [(idPPB)]="_idPpb"
                [buscarVinculos]="true"
                [(validacao)]="objetoValidacao"
                [_fromTelaElaboracao]="false"
                #appCondicionantesPPB>
            </app-condicionantes-ppb>

            
    
            <app-metas-ppb
                *ngIf="exibirGrids && ( _isPorPontuacao || _isPorGrupo ) "
                [(idPPB)]="_idPpb"
                [(_isPorPontuacao)]="_isPorPontuacao"
                [habilitarInputs]="false"
                [(validacao)]="objetoValidacao"
                [buscarVinculos]="true"
                [_fromTelaElaboracao]="false"
                #appMetasPPB>
            </app-metas-ppb>

            </div>

            

            <div class="modal-footer" style="padding: 10px 15px;">
                <a (click)="salvar()" class="btn btn-primary btn-sm" id="btn salvar" data-dismiss="modal">
                    <i class="fa fa-save"></i> Salvar
                </a>
                <a (click)="fechar()" class="btn btn-sm btn-default" data-dismiss="modal">
                    <i class="fa fa-arrow-left"></i> Voltar
                </a>
            </div>

        </div>

    </div>
</div>
<div #appModalDetalhePPBackground class="modal-backdrop fade in" style="display: none;"></div>