import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';

//import { forEach } from '@angular/router/src/utils/collection';

import { filter } from 'rxjs/operators'; @Injectable()

export class Excel2Service {



	constructor() {



	} static toExportFileName(excelFileName: string): string {

		return `${excelFileName}_listagem_${new Date().getTime()}.xlsx`;

	} public exportAsExcelFile(json: any[], excelFileName: string, tituloExcel:string = ""): void {
		var tam = json[0].length - 1;

		var coluna;

    if(tituloExcel == ""){
      tituloExcel = excelFileName;
    }
		var titulo = tituloExcel;

    if (tam == 0) {

			coluna = 'A';

		}

		if (tam == 1) {

			coluna = 'B';

		}

		if (tam == 2) {

			coluna = 'C';

		}

		if (tam == 3) {

			coluna = 'D';

		}

		if (tam == 4) {

			coluna = 'E';

		}

		if (tam == 5) {

			coluna = 'F';

		}

		if (tam == 6) {

			coluna = 'G';

		}

		if (tam == 7) {

			coluna = 'H';

		}

		if (tam == 8) {

			coluna = 'I';

		}

		if (tam == 9) {

			coluna = 'J';

		}

		if (tam == 10) {

			coluna = 'L';

		} for (let i = 0; i <= titulo.length; i++) {

			if (titulo[i] == "-") {

				titulo = titulo.replace('-', ' ');

			}

		} var ws = XLSX.utils.json_to_sheet(json);



		/* merge cells A1:columns */

		//var merge = { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } };

		var merge = XLSX.utils.decode_range("A1:" + coluna + "1"); // this is equivalent

		if (!ws['!merges']) ws['!merges'] = [];

		ws['!merges'].push(merge);



		//Deleta o conteúdo que está na coluna C1 e substitui pelo incremento 'excelFileName'

		delete ws['A1'].w; ws['A1'].v = titulo; const worksheet: XLSX.WorkSheet = ws; const workbook: XLSX.WorkBook = { Sheets: { 'Registros': worksheet }, SheetNames: ['Registros'] };

		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });



		XLSX.writeFile(workbook, Excel2Service.toExportFileName(excelFileName));

	}
}
