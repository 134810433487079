export class VwMppbPpbEntityVM
{
    id : number;
    dataInicioVigencia : any;
    dataPublicacao : any;
    descricaoTitulo : string;
    numeroDocumento : number;
    dataDocumento : any;
    descricaOrgao : string;
    descricaResumoTecnico : string;
    documento: string;
    idTipoDocumento : number;
    tipoEtapa : any;
    statusPPB : number;
    tipoPublicacao : number;
    descricaoDocReferencia : string;
    statusPosterior : number;
    dataDocumentoString : string;
    dataInicioVigenciaString : string;
    dataPublicacaoString : string;
    dscricaoPPBReferencia : string;
}
