

export class ViewNcmModulosEntityVM {

    idNcm : number;

    codigoNcm : string;

    isUsadaMEAAP : number;

    isUsadaMCI : number;

    isUsadaMppb: number;
}