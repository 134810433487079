import { PPBVM } from './PPBVM';
import { PPBVinculoVM } from './PPBVinculoVM';
import { PPBAnexoVM } from './PPBAnexoVM';

export class SolicitarCadastroPPBVM extends PPBAnexoVM {
    
    ppbVM : PPBVM = new PPBVM();
    ppbVinculoVM : PPBVinculoVM = new PPBVinculoVM();
    ppbAnexoVM : PPBAnexoVM = new PPBAnexoVM();
    idPPBDocumentoSelecionado : number;
    descricao : string;
    adicionarAnexo : boolean;
    descricaoTipoDocumento : string;
    flagIsNovoAnexo : boolean = true;

    dataPublicacaoString : string;
    dataDocumentoString : string;
    dataInicioVigenciaString : string;
}