import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from "../../../shared/services/application.service";
import { PagedItems } from '../../../view-model/PagedItems';
import { NcmEntityVM } from '../../../view-model/NcmEntityVM';
import { ProdutoHistoricoVM } from '../../../view-model/ProdutoHistoricoVM';
import { subsetorVM } from '../../../view-model/SubsetorVM';
import { UnidadeMedidaVM } from '../../../view-model/UnidadeMedidaVM';

@Component({
	selector: 'app-modal-historico-unidade',
	templateUrl: './modal-historico.component.html'
})

export class ModalHistoricoUnidadeComponent implements OnInit {

	//----GRID ITENS-----
	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	grid: any = { sort: {} };
	tituloGrid = "Registros"
    //--------------------a
    
	parametros: ProdutoHistoricoVM = new ProdutoHistoricoVM();
	servico = "ProdutoHistorico";

	@Input() data: any = {} = null;
	@Output() changeOperation: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalHistoricoUnidade') appModalHistoricoUnidade : any;
	@ViewChild('appModalHistoricoUnidadeBackground') appModalHistoricoUnidadeBackground : any;

	constructor(
		private applicationService : ApplicationService
	) {}

	ngOnInit() {

	}

	onChangeSort($event: any) {
		this.grid.sort = $event;
		this.listar();
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
		this.listar();
	}

	onChangePage($event: any) {
		this.grid.page = $event;
		this.listar();
	}

	abrir(objeto : UnidadeMedidaVM){
		this.data = objeto;
		this.parametros.idUnidadeMedida = objeto.id;
		this.listar();
		this.appModalHistoricoUnidadeBackground.nativeElement.style.display = 'block';
		this.appModalHistoricoUnidade.nativeElement.style.display = 'block';
	}

	fechar(){
		this.data = null;
		this.lista = {}

		this.size = 10;
		this.page = 1;

		this.grid.page = 1;
		this.grid.size = 10;	
		this.parametros = new ProdutoHistoricoVM();

		this.total = null;
		this.appModalHistoricoUnidadeBackground.nativeElement.style.display = 'none';
		this.appModalHistoricoUnidade.nativeElement.style.display = 'none';
	}

	listar(){		
		this.parametros.page = this.grid.page ? this.grid.page : 1;
		this.parametros.size = this.grid.size ? this.grid.size : 10;
		this.parametros.sort = this.grid.sort.field;
		this.parametros.reverse = this.grid.sort.reverse;		
		this.applicationService.get(this.servico+"/GetHistorico", this.parametros).subscribe((retorno : PagedItems) => {				
			if(retorno){			
				this.lista = retorno.items;				
				this.total = retorno.total;
				this.preencherParametrosExportacao();
			} else {				
				this.lista = {}
				this.total = null;
			}
		});	
	}

	preencherParametrosExportacao() {
		this.parametros.exportarListagem = true;
		this.parametros.exportarPdf = true;
		this.parametros.servico = this.servico;
    	this.parametros.page = 1;
    	this.parametros.size = 100000;		
		this.parametros.titulo = "Relatório Histórico de Unidade de Medida"

		this.parametros.columns = [
			"Data/Hora",
			"Login",
			"Responsável",
			"Ação",
			"Justificativa"
		];

		this.parametros.fields = [
			"dataAcaoString",
			"numeroUsuario",
			"nomeUsuario",
			"descricaoAcao",
			"descricaoJustificativa"
		];

		this.parametros.width = {
			0: {  cellWidth: 100  }, 
			1: {  cellWidth: 110 },
			2: {  cellWidth: 125  }, 
			3: {  cellWidth: 250  },
			4: {  cellWidth: 175  }
		};        
		// this.btnRelatorio.exportar(1);
	}	


}
