export enum EnumPerfil {
	pessoaJuridica = 1,
	pessoaFisica = 2,
	pessoaJuridicaAuditor = 3,
	pessoaJuridicaTransportador = 4,
	pessoaJuridicaRemetente = 5,
	pessoaFisicaPreposto = 6,
	pessoaFisicaConsultor = 7,
	pessoaFisicaTransportador = 8,
	coordenadorPosto = 9,
	coordenadorGeral = 10,
	superintendenteAdjunto = 11,
	pessoaJuridicaDestinatario = 12,
	coordenadorCodoc = 13,
	coordenadorCovis = 14,
	coordenadorDescentralizado = 15,
	vistoriador = 16,
	preposto = 17,
	atendimento = 18
}

