<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
    data-railopacity="0.2" id="relatorioPDF" #relatorioPDF>
    <div class="row m-b-md">
        <div class="col-sm-6">
            <app-titulo>Produtos Padronizados</app-titulo>
            <!-- <app-sub-titulo>Produto Padronizado</app-sub-titulo> -->
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <section class="panel panel-default">
                <header class="panel-heading">
                    <div class="pull-right">
                        <a *ngIf="eUsuarioSuframa" class="btn btn-primary btn-sm" (click)="criarNovo()">
                            <i class="fa fa-plus"></i> Novo
                        </a>
                    </div>
                    <h2 class="panel-title h5">Filtros de Pesquisa</h2>
                </header>
                <article *ngIf="!ocultarFiltros" class="panel-body">
                    <form id="formBusca" class="form form-horinzontal" role="form" #formBusca="ngForm" ngNativeValidate>

                        <article class="panel-body">

                            <div class="row form-group">
                                <div class="col-lg-5 text-nowrap">
                                    <label for="produto">Produto Padronizado:</label>
                                    <input
                                        type="text"
                                        name="produto"
                                        id="produto"
                                        class="form-control"
                                        [(ngModel)]="parametros.produto"
                                        (change)="flagAlterouFiltro = true" />
                                    <div class="radio i-checks radio-sm" style="margin-right: 1px;">
                                        <label class="input-sm">
                                            <input
                                                id="radio-produto-codigo"
                                                name="radio-produto"
                                                type="radio"
                                                [(ngModel)]="parametros.formatoBuscaProduto"
                                                (change)="flagAlterouFiltro = true"
                                                value="codigo">
                                                <i></i> Código
                                        </label>
                                        <label class="input-sm">
                                            <input
                                                id="radio-produto-descricao"
                                                name="radio-produto"
                                                type="radio"
                                                [(ngModel)]="parametros.formatoBuscaProduto"
                                                (change)="flagAlterouFiltro = true"
                                                value="descricao">
                                                <i></i> Descrição
                                        </label>
                                    </div>
                                </div>

                            </div>

                            <div class="row form-group">
                                <div class="col-lg-5">
                                    <label for="situacao" class="">Situação:</label>
                                    <select
                                        name="situacao"
                                        id="situacao"
                                        class="form-control"
                                        [(ngModel)]="parametros.sitProduto"
                                        (change)="flagAlterouFiltro = true"
                                    >
                                        <option value="99">Todos</option>
                                        <option value="1">Em Elaboração</option>
                                        <option value="2">Ativos</option>
                                        <option value="3">Inativos</option>
                                        <option value="4">Inativos para novos projetos</option>
                                    </select>
                                </div>

                                <div class="col-lg-6">
                                    <label for="unidadeDeMedida" class="">Unidade de Medida:</label>
                                    <app-drop-list
                                        (change)="flagAlterouFiltro = true"
                                        id="unidade"
                                        name="unidade"
                                        servico="UnidadeMedida"
                                        [(ngModel)]="parametros.idUnidadeMedida">
                                    </app-drop-list>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div style="margin-top: 1px;" class="col-sm-5">
                                    <label for="descricao">Base Legal</label>
                                        <app-drop-list-select2
                                            #BaseLegal
                                            [InputValue]="parametros.descricaoTitulo"
                                            id="documentoReferencia-solicitacoes-ppb"
                                            name="documentoReferencia-solicitacoes-ppb"
                                            (valorModificado)="parametros.idPPB = $event; flagAlterouFiltro = true"
                                            servico="BaseLegal"
                                            placeholder="Pesquisar Base Legal"
                                            selMinimumInputLength="0"
                                            [(ngModel)]="parametros.idPPB">
                                        </app-drop-list-select2>
                                </div>

                                <div class="col-sm-6">
                                    <label for="ncm" class="">NCM</label>
                                    <app-drop-list-select2
                                        #Ncm
                                        id="documentoReferencia-solicitacoes-ppb"
                                        name="documentoReferencia-solicitacoes-ppb"
                                        (valorModificado)="AlteraNcm($event)"
                                        servico="Ncm"
                                        placeholder="Pesquisar NCM"
                                        selMinimumInputLength="0">
                                    </app-drop-list-select2>
                                </div>
                            </div>
                        </article>
                    </form>
                </article>
                <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
                    <div class="pull-right">
                        <button
                            *ngIf="!ocultarFiltros"
                            class="btn btn-sm btn-primary"
                            type="submit"
                            (click)="buscar()"
                            disableOnClick>
                                <i class="fa fa-search"></i> Buscar
                        </button>
                        <button
                            *ngIf="!ocultarFiltros"
                            (click)="limpar()"
                            #btnlimpar
                            class="btn btn-sm btn-default"
                            type="reset">
                                <i class="fa fa-eraser"></i> Limpar
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm btn-default"
                            (click)=" ocultarFiltros ? ocultarFiltros = false : ocultarFiltros = true "
                            style="margin-left: -1px;">
                            <span class="fa fa-magic m-r-xs"></span>{{'Ocultar Filtros' }}
                        </button>
                    </div>
                </footer>
            </section>
        </div>
    </div>
    <div class="row" *ngIf="grid.total > 0" id="grid">
        <div class="col-lg-12">
            <app-grid-manter-produto-padrao
                [(lista)]="grid.lista"
                [(total)]="grid.total"
                [(size)]="grid.size"
                [(page)]="grid.page"
                [(parametros)]="parametros"
                [(eUsuarioSuframa)]="eUsuarioSuframa"
                (onChangeSort)="onChangeSort($event)"
                (onChangeSize)="onChangeSize($event)"
                (onChangePage)="onChangePage($event)">
			</app-grid-manter-produto-padrao>
        </div>
    </div>
</div>

<app-modal-verificar-inconssistencia
(changeOperation)="buscar()"
(changeOperation)="inconsistenciaVisualizada()"
#appModalVerificarInconssistencia>
</app-modal-verificar-inconssistencia>
