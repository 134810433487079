import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit  } from "@angular/core";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { ValidationService } from "../../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { Validators, FormBuilder } from '@angular/forms';
import { ModalService } from '../../../../../shared/services/modal.service';
import { ProdutoTipoVM } from '../../../../../view-model/ProdutoTipoVM';

@Component({
	selector: 'app-modal-manter-tipo-produto',
	templateUrl: './modal-manter-tipo-produto.component.html'
})
export class ModalManterTipoProduto implements OnInit {

	parametros: ProdutoTipoVM = new ProdutoTipoVM();

	_flagCodigoNaoInformado : boolean = false;
	_flagDescricaoNaoInformada : boolean = false;
	_flagAgregacaoNaoInformada : boolean = false;
	_flagVendaDiretaInformada : boolean = false;
	_flagSubSetorNaoInforamdo : boolean = false;

	@Input() habilitarInputs: boolean;
	@Input() idProduto: number;
	@Output() atualizaListagem: EventEmitter<any> = new EventEmitter();

    @ViewChild('appModalManterTipoProduto') appModalManterTipoProduto : any;
	@ViewChild('appModalManterTipoProdutoBackground') appModalManterTipoProdutoBackground : any;

	servico: string = 'TipoProduto';

	constructor(
		private modal : ModalService,
		private applicationService: ApplicationService,
		private fb: FormBuilder,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
    }

	/************PROPRIEDAS FORM GROUP*************/
	formGroup = this._initializeValidations();
	get codigo(){
		return this.formGroup.get('codigo');
	}
	get descricao(){
		return this.formGroup.get('descricao');
	}
	get agregacao(){
		return this.formGroup.get('agregacao');
	}
	get vendaDireta(){
		return this.formGroup.get('vendaDireta');
	}
	get submeter(){
		return this.formGroup.get('subsetor');
	}
	_initializeValidations(){
		return this.fb.group({
			codigo : ['', null],
			descricao : ['', null],
			agregacao : [ '', null],
			vendaDireta : [ '', null],
			subsetor : ['' , null]
		});
	}
	/**********************************************/

    ngOnInit() {
	}

	abrir(operation : string, id? : number){
		this.limparFlags();

		if(operation != "novo")
			this.buscar(id);

        this.appModalManterTipoProdutoBackground.nativeElement.style.display = 'block';
		this.appModalManterTipoProduto.nativeElement.style.display = 'block';
    }

    fechar(){
        this.appModalManterTipoProdutoBackground.nativeElement.style.display = 'none';
		this.appModalManterTipoProduto.nativeElement.style.display = 'none';
    }

	buscar(id : number){
		this.applicationService.get(this.servico, id).subscribe((result: ProdutoTipoVM)=>{
			if(result){
			  this.parametros = result;

			  if(result.tipoAgregacao != null)
			  	this.parametros.tipoAgregacao = String(result.tipoAgregacao);

			  if(result.idSetor != null)
			  	this.parametros.idSetor = String(result.idSetor);
			}
	  });
	}

	validar(){

		if(!this.parametros.numeroCodigo){
			this._flagCodigoNaoInformado = true;
			return false;
		}

		if(!this.parametros.descricao){
			this._flagDescricaoNaoInformada = true;
			return false;
		}

		if(!this.parametros.tipoAgregacao){
			this._flagAgregacaoNaoInformada = true;
			return false;
		} else{
			this.parametros.tipoAgregacao = Number(this.parametros.tipoAgregacao);
		}


    if(!this.parametros.idSetor){
      this._flagSubSetorNaoInforamdo = true;
      return false;
    }
    else{
      this.parametros.idSetor = Number(this.parametros.idSetor);
    }


		this.parametros.statusVendaDireta = this.parametros.statusVendaDireta ? 1 : 0;
		this.parametros.idProduto = this.idProduto;

		this.salvar();
	}

	salvar(){
		this.applicationService.post(this.servico, this.parametros).subscribe((result: any)=>{
              if(result == 200){
				this.atualizaListagem.emit(true);
				this.fechar();
				this.modal.infoSucess("Operação realizada com Sucesso", "Success");
			  }else if(result == 0){
				this.fechar();
				this.modal.infoError("Atenção, ja Existe um registro com o mesmo <b>Código</b> cadastrado.","Erro");
				return false;
			  }else if(result == 1){
				this.fechar();
				this.modal.infoError("Atenção, ja Existe um registro com a mesma <b>Descrição</b> cadastrada.","Erro");
				return false;
			  }
		});
	}

	limparFlags(){
		this._flagCodigoNaoInformado = false;
		this._flagDescricaoNaoInformada = false;
		this._flagAgregacaoNaoInformada = false;
		this._flagVendaDiretaInformada = false;
		this._flagSubSetorNaoInforamdo = false;
		this.parametros = new ProdutoTipoVM();
		this.parametros.statusVendaDireta = true;
		this.formGroup = this._initializeValidations();
	}

}
