<div class="col-lg-12 text-nowrap" style="padding-left: 0;margin-top: 10px;">

    <app-ppb-multiplo
        *ngIf="exibirGrids && _isPPBMultiplo"
        [habilitarInputs]="false"
        [(idPPB)]="_idPpb"
        [_fromTelaElaboracao]="false"
        [_isListarVinculados]="true"
        #appPPBMultiplo>
    </app-ppb-multiplo> 

    <app-etapas-ppb
        *ngIf="exibirGrids"
        [(_isSempontuacao)]="_isSempontuacao"
        [(_isPorPontuacao)]="_isPorPontuacao"
        [(_isPorGrupo)]="_isPorGrupo"
        [(_isPPBMultiplo)]="_isPPBMultiplo"
        [habilitarInputs]="false"
        [(idPPB)]="_idPpb"
        [buscarVinculos]="true"
        [_fromTelaElaboracao]="false"
        [_isListarVinculados]="true"
        #appEtapasPPB
    >
    </app-etapas-ppb>

    <app-condicionantes-ppb
        *ngIf="exibirGrids"
        [habilitarInputs]="false"
        [(idPPB)]="_idPpb"
        [buscarVinculos]="true"
        [(validacao)]="objetoValidacao"
        [_fromTelaElaboracao]="false"
        [_isListarVinculados]="true"
        #appCondicionantesPPB>
    </app-condicionantes-ppb>

    
    <app-metas-ppb
        *ngIf="exibirGrids && ( _isPorPontuacao || _isPorGrupo ) "
        [(idPPB)]="_idPpb"
        [(_isPorPontuacao)]="_isPorPontuacao"
        [habilitarInputs]="false"
        [(validacao)]="objetoValidacao"
        [buscarVinculos]="true"
        [_fromTelaElaboracao]="false"
        [_isListarVinculados]="true"
        #appMetasPPB>
    </app-metas-ppb>

</div>