import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ModalService } from '../../../../../shared/services/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { ProdutoTipoVM } from '../../../../../view-model/ProdutoTipoVM';
import { ChangeNcmService } from '../../../../../shared/services/changeListagemNcm.service';
import { RequestResponseVM } from '../../../../../view-model/RequestResponseVM';


@Component({
	selector: 'app-grid-tipo-produto',
	templateUrl: './grid-tipo-produto.component.html'
})

export class GridTipoProdutoComponent {

	path : string;

	constructor(
		private ChangeNcmService : ChangeNcmService,
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idRota = Number(this.route.snapshot.params['idTipoProduto']);
	}

	itemJaCheckado : any;

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;
	@Input() habilitarInputs : boolean;
	@Input() idProduto: number;
	@Input() habilitarAlteracaoStatus : boolean = false;

	@Output() atualizaListagem: EventEmitter<any> = new EventEmitter();
	@Output() exibeNcm: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onChangeFiltro: EventEmitter<any> = new EventEmitter();

	tituloGrid = "Tipos"
	servico: string = 'TipoProduto';
	_dataJustificativa : any = {};
	_idRota : number = 0;

	@ViewChild('modalJustificativa') modalJustificativa;
	@ViewChild('modalManterTipoproduto') modalManterTipoproduto;

	changeSize($event) {
		this.onChangeSize.emit($event);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	abrirModal(item){
		this.modalManterTipoproduto.abrir("editar", item.id)
	}

	sendAtualizacaoNcm(idTipoProduto : number){
		this.itemJaCheckado = idTipoProduto;
		this.exibeNcm.emit(true);
		this.ChangeNcmService.atualizaListagemNcm(idTipoProduto);
	}

  filtrarLista(){
    this.onChangeFiltro.emit()
  }

	changeStatus(operacao: string, objetoAlteracao : ProdutoTipoVM){
		this._dataJustificativa.msgModal = 'Deseja ' + operacao + ' o Produto Padrão. Confirme a Operação?'
		this._dataJustificativa.tituloModal = operacao + ' Produto Padrão'
		this._dataJustificativa.maxLengthInput = 1000;
		this._dataJustificativa.labelName = 'Insira a Justificativa da Alteração';
		this._dataJustificativa.isObrigatorioPreencherCampo = true;
		this._dataJustificativa.objetoAlteracao = objetoAlteracao;
		this.modalJustificativa.abrir();
	}

	recebeAlteracaoJustificativa($event){
		if($event){
			$event.objetoAlteracao.statusTipo == 1 ?
				$event.objetoAlteracao.statusTipo = 0 :
					$event.objetoAlteracao.statusTipo = 1;

			$event.objetoAlteracao.descricaoJustificativa = $event.txt;

			this.alterarStatusItem($event.objetoAlteracao);
		}
	}

	alterarStatusItem(objeto : ProdutoTipoVM){
		this.applicationService.post("AlterarStatusTipoProduto", objeto).subscribe((result: boolean)=>{
			if(result){
			  this.modal.infoSucess("Operação realizada com Sucesso", "Success").subscribe( x => {
				  this.atualizaListagem.emit(true);
				  this.ChangeNcmService.atualizaListagemNcm(objeto.id);
			  });
			}
			else
			{
				this.modal.infoError("Para Ativar o Tipo do Produto, é necessário que o mesmo tenha uma NCM vinculada", "Atenção");
				return false;
			}
	  	});
	}

	receberAtualizacaoListagem($event : boolean){
		this.atualizaListagem.emit(true);
	}

	excluir(item){
		this.modal.confirmacao("Deseja confirma a Operação?").subscribe((isConfirmado: any) => {
			if (isConfirmado) {
				this.applicationService.deletar(this.servico, item.id).subscribe( (result : RequestResponseVM) => {
					if(result.statusResponse == 200){
						this.modal.infoSucess("Operação realizada com Sucesso", "Success");
						this.atualizaListagem.emit(true);
						this.ChangeNcmService.atualizaListagemNcm(item.id);
					} else{
            this.modal.infoError(result.textResponse, "Erro");
            return false;
          }
				});
			}
		});
	}

	excluirTipoAtivo(item){
		this.modal.justificativa("Deseja excluir tipo?").subscribe((justificativa: any) => {
			if(justificativa){
				let objeto : ProdutoTipoVM = new ProdutoTipoVM();
				objeto.id = item.id;
				objeto.numeroCodigo = item.numeroCodigo;
				objeto.descricaoJustificativa = justificativa;
				objeto.idProduto = this._idRota;
				objeto.descricao = item.descricao;
				this.applicationService.post(this.servico+"/PostExcluirAtivo", objeto).subscribe((result: any) => {
					if(result.statusResponse == 200){
						this.modal.infoSucess("Operação realizada com Sucesso", "Sucesso");
						this.atualizaListagem.emit(true);
						this.ChangeNcmService.atualizaListagemNcm(item.id);
					}else if(result.statusResponse == 207){
						this.modal.informacaoCiente("Tipo do Produto sendo utilizado em outros Módulos Integrados da área de Projetos e por este motivo não pode ser excluído!", "Atenção");
					}
					 else{
						this.modal.infoError(result.textResponse, "Erro");
						return false;
					}
				});
			}
		});
	}

	definirTipoAgregacao(value: number){
		switch(value){
			case 1:
				return 'BEM FINAL';
			case 2:
				return 'SUBCONJUNTO';
			case 3:
				return 'COMPONENTE';
			case 4:
				return 'OUTRO INSUMO';
			case 5:
				return 'MATERIAL DE EMBALAGEM';
		}
	}

}
