import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { PagedItems } from "../../../../../view-model/PagedItems";
import { ValidationService } from "../../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../../shared/services/application.service";

import { ModalService } from "../../../../../shared/services/modal.service";
import { ActivatedRoute, Router } from "@angular/router";
//import { MetaPPBVM } from "../../../../view-model/MetaPPBVM";

@Component({
	selector: "app-metas-ppb",
	templateUrl: "./metas-ppb.component.html"
})
@Injectable()

export class MetasPPBComponent implements OnInit {


	@Input()
		idPPB: number;

	@Input()
		habilitarInputs: boolean;

	@Input()
		dataVigenciaInicialPPB : string;

	@Input()
		validacao : any = {} = null;

	@Input()
		buscarVinculos: boolean;

	@Input()
		_isPorGrupo: boolean;

	@Input()
		isAlteracaoParaRevogacao : boolean;

	@Input()
		_fromTelaElaboracao : boolean;

	@Input()
		_isPorPontuacao : boolean;

	@Input()
		isRetificacao : boolean;

	@Input() 
		_isListarVinculados : boolean = false;

	@ViewChild('appGridMetasPPB') appGridMetasPPB : any;
	listaAntigaComparacao  = {};
	grid: any = { sort: {} };
	listaAnterior : any = {};
	//parametros = new MetaPPBVM();
	parametros : any = {};
	_idRota : number;

	servico = "MetasPPB";

	constructor(
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private route: ActivatedRoute,
		private router: Router,
	) {
		this._idRota = Number(this.route.snapshot.params['idTipoProduto']);
	}

	ngOnInit() {
		this.buscar();
	}

	onChangeSort($event : any) {
		this.grid.sort = $event;
		this.buscar();
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
    this.grid.page = 1;
		this.buscar();
	}

	async onChangePage($event : any) {
		this.grid.page = $event;
		var gridAlterado = this.validarAlteracaoGrid();
		if(await gridAlterado == 1){
			this.salvar();
		}
		this.buscar();
	}

	async validarAlteracaoGrid(){
		if(this.listaAntigaComparacao != null){
			var obj = {
				ListaAtualCondicionanteVM : this.grid.lista,
				ListaAntigaCondicionanteVM : this.listaAntigaComparacao
			}
			var retorno : any = await this.applicationService.post(this.servico+"/ValidarAlteracaoGrid", obj).toPromise();
			return retorno;
		}else{
			return 0;
		}
	}

	buscar(){

		var consulta = {
			page: this.grid.page || 1,
			size: this.grid.size || 10,
			sort: this.grid.sort.field || "NumeroSequencia",
			reverse: this.grid.sort.reverse,
			idPPB: this.idPPB,
			buscarVinculos: this.buscarVinculos,
			fromTelaElaboracao : this._fromTelaElaboracao
		}

		var vm = {
			idProduto : this._idRota,
			idPpb : this.idPPB,
			MetasPPBVM : consulta
		}

		let endpoint = this._isListarVinculados ? "MetasPPB/GetPaginadoVinculados" : "MetasPPB";
			
		this.applicationService.get(endpoint, vm).subscribe((result: PagedItems) => {
			if(result.total != null && result.total > 0){
				if(this.listaAnterior.length > 0 && this.listaAnterior != null){
					this.listaAnterior.forEach(itemListaAnterior => {
						result.items.forEach(itemNovaLista => {
							if(itemListaAnterior.id == itemNovaLista.id && itemListaAnterior.idPPB == itemNovaLista.idPPB && itemListaAnterior.isChecked != itemNovaLista.isChecked){
								itemNovaLista.isChecked = itemListaAnterior.isChecked;
							}
						});
					});
				}
				this.grid.lista = result.items;
				this.listaAntigaComparacao =JSON.parse(JSON.stringify(result.items));
				this.grid.total = result.total;
			} else {
				this.grid = { sort: {} };
			}
		});
	}

	salvarListaAnterior($event){
		this.listaAnterior = $event;
}

	salvar(){
		this.appGridMetasPPB.salvar();
	}

}
