import { pdfServiceTipoProdutoPadrao } from './../../../shared/services/pdfRelatorioTipoProduto.service';
import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { ApplicationService } from '../../../shared/services/application.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as html2pdf from 'html2pdf.js';
import { Excel2Service } from '../../../shared/services/excel2.service';
import { PDFService } from '../../../shared/services/pdf.service';

@Component({
	selector: 'app-relatorio-emitir-tipo-produto-padrao',
	templateUrl: './relatorio-emitir-tipo-produto-padrao.component.html',
})

@Injectable()
export class RelatorioEmitirTipoProdutoPadraoComponent implements OnInit {

	@Input() listaProdutoTipoPadrao: any = {};
	@Output() DownloadFinalizado: EventEmitter<any> = new EventEmitter();
    terminouConsulta : boolean = true;
    AtivaRelatorio : boolean = true;
    esconder : boolean =true;
    listaExportacao = {};
    parametrosRelatorio: any = {};
    config: AngularEditorConfig = {
		minHeight: '100px',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',


		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};

	constructor(
		private applicationService: ApplicationService,
    private excelService: Excel2Service,
		private pdfServiceTipoProdutoPadrao : pdfServiceTipoProdutoPadrao,
	) { }

	ngOnInit() {

  }

  start(){
    setTimeout(() => {
        this.prencheParametrosDeExportacao();
        this.gerarRelatorio(2);
    }, 4000);
  }

  gerarRelatorio(tipo)
  {

    var lista = this.listaProdutoTipoPadrao.produtoTipoLista;
    let rows = Array<any>();
    var file = window.location.href.split("#")[1].replace("/", "");
    for (var i = 0; i < lista.length; i++)
    {
      let r = Array<any>();
      let valor: any; for (var j = 0; j < this.parametrosRelatorio.fields.length; j++) {
        var item = this.parametrosRelatorio.fields[j].split("|"); valor = item.length > 0 ? Object.values(lista)[i][item[0].trim()] : Object.values(lista)[i][this.parametrosRelatorio.fields[j].trim()];
        if (this.parametrosRelatorio.fields[j].trim() == "statusTipo")
          r[j] = (valor == 1 ? "ATIVO" : "INATIVO");
        else {
          r[j] = valor;
        }
      }
      rows.push(r);
    }
    if(tipo == 2)
    {
      this.pdfServiceTipoProdutoPadrao.exportAsPDFFileTipoProdutoPadrao(this.listaProdutoTipoPadrao, this.parametrosRelatorio.columns, rows, this.parametrosRelatorio.width, file,);
    }
    this.DownloadFinalizado.emit(true);
    this.AtivaRelatorio = true;
  }

  prencheParametrosDeExportacao()
	{
		this.parametrosRelatorio.titulo = "RELATÓRIO DE PRODUTO PADRONIZADO COM TIPOS E NCMs"
		this.parametrosRelatorio.columns = [
									"Código",
									"Tipo Produto",
									"Situação(Tipo)",
									"NCM",
                  "Situação(NCM)"
		];
		this.parametrosRelatorio.fields = [
									"numeroCodigo",
									"descricao",
									"statusTipo",
									"listaNumeroCodigoNCMString",
                  "listaStatusNCMString"
		];
		this.parametrosRelatorio.width = {
									0: { columnWidth: 50 },
									1: { columnWidth: 180 },
									2: { columnWidth: 180 },
									3: { columnWidth: 180 },
                  4: { columnWidth: 180}
		};
	}

}
