import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
//import { DropListSelect2Component } from '../../shared/components/drop-list-2/drop-list-2.component';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RelatorioTipoProdutoPadraoComponent } from './relatorio-tipo-de-produto-padrao';
import { GridRelatorioProdutoTipoPadraoComponent } from './grid/grid.component';
import { RelatorioEmitirTipoProdutoPadraoComponent } from './relatorio-emitir-tipo-produto-padrao/relatorio-emitir-tipo-produto-padrao.component'
import { ModalNcm } from './modal/modal-ncm.component';
@NgModule({
  declarations: [
    RelatorioTipoProdutoPadraoComponent,
    GridRelatorioProdutoTipoPadraoComponent,
    RelatorioEmitirTipoProdutoPadraoComponent,
    ModalNcm

  ],
  imports: [
    CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		SharedModule,
		AngularEditorModule,
		NgbModule
  ]
})
export class RelatorioTipoProdutoPadraoModule { }
