<div *ngIf="!esconder"
	id="conteudoPdf" class="panel-body" class="col-lg-12">
	<div>
		<div class="row">
			<div class="col-md-12">
				<div class="form-group m-n text-center">
					<img src="../../../../../assets/images/brasao.jpeg" alt="Logo Brasao"
						 style="width: 9%;" />
				</div>
			</div>
		</div>

		<div class="form-group m-n text-center">
			<h5>Superintendência da Zona Franca de Manaus</h5>
			<h5>SUFRAMA</h5>
		</div>
    <br>
    <br>

		<div class="form-group m-n text-center">
			<label class="control-label">RELATÓRIO DE PRODUTO PADRONIZADO COM TIPOS E NCMs</label>
		</div>
	</div>
  <br>
  <br>
  <br>
	<section >

		<div style="margin-top: -40px;">
            <div class="table-responsive no-margin-bottom no-border">
                <table class="table table">
                    <thead class="table-header-color">
                        <tr>
                            <th class="th-sortable unset-nowrap text-left">Código</th>
                            <th class="th-sortable unset-nowrap text-left">Tipo Produto</th>
                            <th class="th-sortable unset-nowrap text-left">Situação(Tipo)</th>
                            <th class="th-sortable unset-nowrap text-left">NCM</th>
                            <th class="th-sortable unset-nowrap text-left">Situação(NCM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listaProdutoTipoPadrao">
                            <td class="text-left">
                              {{item.numeroCodigo}}
                            </td>
                            <td class="text-left" >
                              {{item.descricao}}
                            </td>
                            <td class="text-left">
                              {{item.statusTipo == 0 ? "Inativo" : "Ativo"}}
                            </td>
                            <td class="text-left">
                              <div  *ngFor="let itemNcm1 of item.listaNCM" >
                                {{itemNcm1.numeroCodigo}}
                              </div>
                            </td>
                            <td class="text-left">
                              <div  *ngFor="let itemNcm2 of item.listaNCM" >
                                {{itemNcm2.statusNcm}}
                              </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
		</div>
	</section>


<!-- FIM CONTEUDO RELATORIO PDF -->
