import { Component, Injectable, OnInit, ViewChild } from "@angular/core";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-modal-finalizar',
	templateUrl: './modal-finalizar.component.html'
})
export class ModalFinalizar implements OnInit {
	page: number=1;
	size: number=1;
	total: number=1;
	sorted: number=1;

	listaDadosAtuacao: any;
	ocultarInputAnexo = false;
	parametros: any = {};
	isDesabilitado: boolean = true;
	ocultaCombos: boolean = true;

    @ViewChild('appModalFinalizar') appModalFinalizar : any;
	@ViewChild('appModalFinalizarBackground') appModalFinalizarBackground : any;

	constructor(
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
    }

	changeSize($event : any) {
		//this.onChangeSize.emit($event);
		this.changePage(this.page);
	}

	changeSort($event : any) {
		this.parametros.sort = $event;
		//this.onChangeSort.emit($event);
		this.changePage(this.page);

	}

	changePage($event : any) {
		this.page = $event;
		//this.onChangePage.emit($event);

	}

    ngOnInit() {
	}

	abrir(){		
        this.appModalFinalizarBackground.nativeElement.style.display = 'block';
		this.appModalFinalizar.nativeElement.style.display = 'block';
    }

    fechar(){
        this.appModalFinalizarBackground.nativeElement.style.display = 'none';
		this.appModalFinalizar.nativeElement.style.display = 'none';
    }

	
}
