<div #appModalIncluirCondicionantesPPB class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Incluir Condicionantes</h4>
            </div>
            <form (ngSubmit)="validar()" [formGroup]="condicionateForm" class="form form-horinzontal needs-validation" role="form" novalidate>

                <div class="modal-body">

                    <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">                   
                        <div class="col-lg-9">
                            <label>Condicionantes:</label>
                            <angular-editor [(ngModel)]="parametros.descricaCondicaoString" [config]="config" formControlName="condicionante" ></angular-editor>                            
                        </div>
                    </div>	

                    <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">
                        <div class="col-lg-2">
                            <label for="label-topoico-condicionante" >Condicionante:</label>
                            <input 
                                [(ngModel)]="parametros.descricaTopico"
                                type="text" 
                                name="topico-condicionante" 
                                id="topico-condicionante" 
                                class="form-control" 
                                maxlength="20" 
                                formControlName="topico" 
                                [attr.disabled]="_flagDesabilitaTopico ? true : null"
                            />
                        </div>
                        <div class="col-lg-5">
                            <label for="cnpj" class="required">Período da Vigência:</label>
                            <div class="input-group" style="display: flex;">
                                <input 
                                    [(ngModel)] = "parametros.dataInicioVigenciaString"
                                    autocomplete="off" 
                                    class="form-control date ng-pristine ng-valid ng-touched" 
                                    id="dataInicio" 
                                    maxlength="10" 
                                    name="dataInicio" 
                                    style="width: 50%;" 
                                    type="date"
                                    formControlName="dataInicial"  
                                >
                                <span style="padding-right: 11px; padding-top: 8px; padding-left: 10px;">a</span>
                                <input 
                                    [(ngModel)] = "parametros.dataFimVigenciaString"
                                    autocomplete="off" 
                                    class="form-control date ng-pristine ng-valid ng-touched" 
                                    id="dataFim" maxlength="10"
                                    name="dataFim" 
                                    style="width: 50%;" 
                                    type="date" 
                                    formControlName="dataFinal" 
                                >
                            </div>
                            <div *ngIf=" _errorDataInicioNaoPreenchida " class="alert-danger">
                                <b style="margin-left: 15px;">
                                    <button type="button" class="close" (click)=" _errorDataInicioNaoPreenchida = false;"><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;"> Data Inicial é Obrigatória. </b>
                                </b>                                                   
                            </div>
                            <div *ngIf=" _errorDataFinalMaiorInicial " class="alert-danger">
                                <b style="margin-left: 15px;">
                                    <button type="button" class="close" (click)=" _errorDataFinalMaiorInicial = false; "><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;"> Data Final nao pode ser Inferior a Data Inicial. </b>
                                </b>
                            </div>           
    
                            <div *ngIf=" _errorDataFinalMenorDataCorrente " class="alert-danger">
                                <b style="margin-left: 15px;">
                                    <button type="button" class="close" (click)=" _errorDataFinalMenorDataCorrente = false; "><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;"> A Data Final Não pode ser Inferior da Data Corrente! </b>
                                </b>
                            </div>     
                        </div>
                    </div>    

                    <div style="margin-left: 30px; margin-top: 15px;">
                        <input 
                            type="checkbox"
                            (change)="flagCadastroContinuo ? flagCadastroContinuo = false : flagCadastroContinuo = true;">
                        <label for="Pesquisa">Cadastro Contínuo</label>
                    </div>

                </div>
            </form> 

            <div class="modal-footer" style="padding: 10px 15px;">                    
                    <button 
                        (click)="fechar()"
                        class="btn btn-sm btn-default" 
                        data-dismiss="modal">
                            <i class="fa fa-times"></i> Fechar
                    </button>
                    <button 
                        
                    data-toggle="tooltip" 
                    [disabled]="!condicionateForm.valid"
                    title="salvar"
                    data-original-title="salvar"                        
                    (click)="validar()"
                    type="submit"
                    class="btn btn-sm btn-primary" 
                    data-dismiss="modal" 
                    data-toggle="modal" 
                    data-backdrop="static" 
                    data-keyboard="false">
                        <i class="fa fa-save m-r-xs"></i> Salvar
                </button>
            </div>

        </div>

    </div>
</div>
<div #appModalIncluirCondicionantesPPBBackground class="modal-backdrop fade in" style="display: none;"></div>