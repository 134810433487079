<app-grid
  [(isHidePaginacao)]="!_exibirCheck"
  [(isHideCabecalho)]="!_exibirCheck"
  [(titulo)]="tituloGrid"
  [(page)]="page"
  [(size)]="size"
  [(total)]="total"
  [(parametros)]="parametros"
  (onChangeSize)="changeSize($event)"
  (onChangePage)="changePage($event)">

  <div class="table-responsive no-margin-bottom no-border">
    <table class="table table">

      <thead class="table-header-color">
        <tr>
          <th class="th-sortable unset-nowrap text-left" *ngIf="_exibirCheck">
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Pontuação da Meta
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Vigência
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Documento
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Descrição
          </th>
          <th *ngIf="!_isPorPontuacao" class="th-sortable unset-nowrap text-left">
            Grupo
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of lista; let i = index;">
          <td class="text-center" *ngIf="_exibirCheck">
            <input  type="checkbox" 
            name="check"
            [(ngModel)]="item.isChecked" 
            id="check">
          </td>
          <td class="text-left" style="width: 200px;" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.valorMeta"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.descricaoDataVigencia"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.ppb.descricaoTitulo"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.descricaoMeta"
              [backgroundAmarelo]="item.idPPB != idPPB"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td *ngIf="!_isPorPontuacao" class="text-left"
            [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.grupo.descricaGrupo "
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-grid>