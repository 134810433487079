/* IMPORTAÇÃO DE MODULOS */
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from "ngx-mask";
import { NgxCurrencyModule } from "ngx-currency";
import { SharedModule } from "../../shared/shared.module";

import { ManterNCMComponent } from "./manter-ncm.component";
import { SolicitacoesNCMComponent } from "./solicitacoes-ncm/solicitacoes-ncm.component";
import { GridManterNCMComponent } from "./grid/grid.component";
import { ModalJustificativaNCMComponent } from "./solicitacoes-ncm/modal-justificativa/modal-justificativa.component";
import { ModalHistoricoNCMComponent } from "./solicitacoes-ncm/modal-historico/modal-historico.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		SharedModule,
		AngularEditorModule,
		NgbModule,
		NgxMaskModule.forChild(),
		NgxCurrencyModule
	],
	declarations: [
		ManterNCMComponent,
		SolicitacoesNCMComponent,
		GridManterNCMComponent,
		ModalJustificativaNCMComponent,
		ModalHistoricoNCMComponent
	],
})
export class ManterNCMModule {}