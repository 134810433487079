import { PagedItems } from "./PagedItems";
import { ProdutoPPBVM } from "./ProdutoPPBVM";

export class ProdutoVM extends PagedItems{
    id: number;
    idUnidadeMedida: any;
    idCoeficienteReducao: any;
    idSubsetor: any;
    numeroCodigo: any;
    descProduto: string;
    idPPB: number;
    sitComponente: any;
    statusSubsetorSetor: any
    sitBemInformativa: any;
    sitProduto: any;
    descProtocolo: string;
    descGrupoPPB: string;
    idGrupo: number;
    observacao : string;
    numeroDigitos: any;
    descBaseLegalExistente : string;
    descricaoBaseLegal : string;

    formatoBuscaProduto: any
    stringNcm: string;

    documentosVinculados: Array<ProdutoPPBVM>;
    //VinculosPPB : any = {};
}
