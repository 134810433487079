import { PagedItems } from "./PagedItems";

export class ProdutoHistoricoVM extends PagedItems{
    produto : any = {};
    unidade: any = {};
    ncm: any = {};
    subsetor: any = {};
    id : number;
    statusAnterior : number;     
    statusAtual  : number;  
    numeroUsuario : string;
    nomeUsuario : string;
    dataAcao : any;
    descricaoAcao : string;
    idProduto : number;  
    descricaoJustificativa : string;
    idUnidadeMedida: number;
    idNcm: number;
    idSubsetor: number;
}