
<div class="row form-group" style="margin-left: 2px;">
    <app-grid-etapas-ppb
        [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
        [(gruposPPB)] = "gruposPPB"
        [(infoGrupos)]="infoGrupos"
        [(habilitarInputs)] = "habilitarInputs"
        (atualizaListagemGrid) = "atualizaListagem($event)"
        [(_isSempontuacao)] = "_isSempontuacao"
        [(_isPorPontuacao)] = "_isPorPontuacao"
        [(_isPorGrupo)] = "_isPorGrupo"
        [(_isPPBMultiplo)] = "_isPPBMultiplo"
        [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
        [(lista)] = "grid.lista"
        [(listaAntigaComparacao)]="listaAntigaComparacao"
        [(total)] = "grid.total"
        [(size)] = "grid.size"
        [(page)] = "grid.page"
        [(parametros)]="parametros"
        (listaAnterior)="salvarListaAnterior($event)"
        (onChangeSort) = "onChangeSort($event)"
        (onChangeSize) = "onChangeSize($event)"
        (onChangePage) = "onChangePage($event)"
        [(isRetificacao)]="isRetificacao"
        [(idPPB)]="idPPB"
        [_exibirCheck]="!_isListarVinculados"
        #appGridEtapasPPB>
    </app-grid-etapas-ppb>
</div>
