<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
    data-railopacity="0.2">
    <div class="row m-b-md">
        <div class="col-lg-12">
            <app-titulo *ngIf=" path == 'novo' ">Cadastrar NCM</app-titulo>
            <app-titulo *ngIf=" path == 'editar' ">Alterar código NCM</app-titulo>
            <!-- <app-sub-titulo *ngIf=" path == 'novo' "  >Incluir</app-sub-titulo>
            <app-sub-titulo *ngIf=" path == 'editar' "  >Alterar</app-sub-titulo> -->
        </div>
    </div>


    <section class="panel panel-default">
        <header class="panel-heading">
            <div class="pull-right">
                <a
                    id="btn-voltar-solicitacoes-ncm"
                    name="btn-voltar-solicitacoes-ncm"
                    (click)="voltar()"
                    class="btn btn-default btn-sm">
                        <i class="fa fa-arrow-left"></i> Voltar
                </a>
                <a
                    *ngIf=" path != 'visualizar' "
                    id="btn-salvar-solicitacoes-ncm"
                    name="btn-salvar-solicitacoes-ncm"
                    (click)="validar()"
                    class="btn btn-primary btn-sm">
                        <i class="fa fa-save"></i> Salvar
                </a>
            </div>
            <h2 class="panel-title h5">Formulário</h2>
        </header>
        <div class="panel-body">

            <div class="row form-group">
                <div class="col-lg-12">
                    <div class="col-lg-2">
                        <label for="descricao" class="required">Código:
                            <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaNumeroCodigo"
                                style="color: red">*</b>
                        </label>
                        <input
                            type="text"
                            onlyNumber="true"
                            name="codigo"
                            id="codigo"
                            class="form-control"
                            maxlength="8"
                            minlength="8"
                            [(ngModel)]="parametros.numeroCodigo"
                            value="0001"
                            [disabled]="disable ? true : null || disableEditar ? true : null"/>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="col-lg-10" style="margin-top:10px;">
                        <label for="codigo" class="required">Descrição:
                            <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaDescProduto"
                                style="color: red">*</b>
                        </label>
                        <textarea
                            class="form-control ng-pristine ng-valid ng-touched"
                            name="descricao"
                            style="resize: none;"
                            id="descricao"
                            [(ngModel)]="parametros.descricaoNcm"
                            maxlength="250"
                            name="Objetivo"
                            rows="3"
                            (change)="usuarioAlterouItem = true;"
                            [disabled]="disable && path == 'visualizar'? true : null">
                        </textarea>
                    </div>
                    <div class="col-lg-10" style="margin-top:10px;">
                        <label for="descricao" class="required">Unidade:
                            <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaIdUnidadeMedida"
                                style="color: red">*</b>
                        </label>
                        <app-drop-list
                            id="analista"
                            name="analista"
                            servico="UnidadeMedida"
                            (change)="usuarioAlterouItem = true;"
                            [(ngModel)]="parametros.idUnidadeMedida"
                            [isDisabled]="disable ? true : false">
                        </app-drop-list>
                    </div>
                </div>
                <div class="col-lg-12" style="margin-top:10px;">
                    <div class="col-lg-4 ls-prefix-group">
                        <label for="descricao" class="required">Alíquota II (%):
                            <b *ngIf="path == 'finalizar'"
                                style="color: red">*</b>
                        </label>

                        <input unit ="%"
                            (keypress)="validarPorcentagem(1)"
                            type="text"
                            name="aliquota-2"
                            id="aliquota-2"
                            class="form-control"
                            [(ngModel)]="parametros.aliquotaII"
                            [disabled]="disable ? true : null"
                            placeholder="0,00"
                            currencyMask
							[options]="{prefix: '',  thousands: '.', decimal: ',', align: 'left'}"/>


                    </div>
                    <div class="col-lg-3">
                        <label for="descricao" class="required">Data Início:
                            <b *ngIf="path == 'finalizar'"
                                style="color: red">*</b>
                        </label>
                        <input
                            type="date"
                            name="data-inicio-alic-2"
                            id="data-inicio-alic-2"
                            class="form-control"
                            maxlength="10"
                            [(ngModel)]="parametros.aliquotaIIVigenciaInicialString"
                            [disabled]="disable ? true : null" />
                    </div>
                    <div class="col-lg-3">
                        <label for="descricao" class="required">Data Final:
                            <b *ngIf="path == 'finalizar'"
                                style="color: red">*</b>
                        </label>
                        <input
                            type="date"
                            name="data-final-alic-2"
                            id="data-final-alic-2"
                            class="form-control"
                            maxlength="10"
                            [(ngModel)]="parametros.aliquotaIIVigenciaFinalString"
                            [disabled]="disable ? true : null"
                        />
                    </div>
                </div>

                <div class="col-lg-12" style="margin-top:10px;">
                    <div class="col-lg-4 ls-prefix-group">
                        <label for="descricao" class="required">Alíquota IPI (%):
                            <b *ngIf="path == 'finalizar'"
                                style="color: red">*</b>
                        </label>
                        <input
                            type="text"
                            name="alic-ipi"
                            (keypress)="validarPorcentagem(2)"
                            id="alic-ipi"
                            class="form-control"
                            placeholder="0,00"
                            [(ngModel)]="parametros.aliquotaIPI"
                            [disabled]="disable ? true : null"
                            currencyMask
							[options]="{prefix: '',  thousands: '.', decimal: ',', align: 'left'}"/>
                    </div>
                    <div class="col-lg-3">
                        <label for="descricao" class="required">Data Início:
                            <b *ngIf="path == 'finalizar'"
                                style="color: red">*</b>
                        </label>
                        <input
                            type="date"
                            name="data-inic-alic-ipi"
                            id="data-inic-alic-ipi"
                            class="form-control"
                            maxlength="10"
                            [(ngModel)]="parametros.aliquotaIPIVigenciaInicialString"
                            [disabled]="disable ? true : null" />
                    </div>
                    <div class="col-lg-3">
                        <label for="descricao" class="required">Data Final:
                            <b *ngIf="path == 'finalizar'"
                                style="color: red">*</b>
                        </label>
                        <input
                            type="date"
                            name="data-final-alic-ipi"
                            id="data-final-alic-ipi"
                            class="form-control"
                            maxlength="10"
                            [(ngModel)]="parametros.aliquotaIPIVigenciaFinalString"
                            [disabled]="disable ? true : null"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <p style="color: red">Os campos com o sinal (*) são de preenchimento obrigatório.</p>
</div>
