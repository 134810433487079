import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { ApplicationService } from '../../../../shared/services/application.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-relatorio-listagens-ppb',
	templateUrl: './relatorio-listagens-ppb.component.html',
})

@Injectable()
export class RelatorioListagensPPBComponent implements OnInit {
	
    @Input() _isSempontuacao : boolean;
	@Input() _isPorPontuacao : boolean;
	@Input() _isPorGrupo: boolean;	
	@Input() objetoExportacao: any = {};
	@Output() DownloadFinalizado: EventEmitter<any> = new EventEmitter();
	terminouConsulta : boolean = true;
	AtivaRelatorio : boolean = true;

    config: AngularEditorConfig = {
		minHeight: '100px',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};

	constructor(
		private applicationService: ApplicationService
	) { }

	ngOnInit() {}

	public geraRelatorioConsolidado(){

		this.AtivaRelatorio = false;

        setTimeout(() => {
            this.exportPDF();    
        }, 1200);

	}

	exportPDF(){

		new Promise<void>(resolve=>{
			resolve();
		}).then(()=>{

			const elements = document.getElementById('conteudoPdf');
			
			const options = {
				margin: [0.03, 0.03, 0.7, 0.03], // [top, left, bottom, right]
				filename: 'RELATÓRIO CONSOLIDADO DO CREDENCIAMENTO.pdf',
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: {
					scale: 2,
					dpi: 300,
					letterRendering: true,
					useCORS: true
				},
				jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
				pagebreak : { after: ['#grid'] }
			};
			
			

		}).then(()=>{
			setTimeout(() => {
				this.AtivaRelatorio = true;
				this.DownloadFinalizado.emit(false);
			}, 800);
		});
	}

}
