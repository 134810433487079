import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../../shared/services/application.service";
import { MessagesService } from "../../../shared/services/messages.service";
import { ModalService } from "../../../shared/services/modal.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { ProdutoVM } from "../../../view-model/ProdutoVM";
import { ValidacaoMCCPVM } from "../../../view-model/ValidacaoMCPPVM";
import { AlteracaoProdutoVM } from "../../../view-model/AlteracaoProdutoVM";
import { ProdutoPPBVM } from "../../../view-model/ProdutoPPBVM";
import {ArtigoVM} from "../../../view-model/ArtigoVM";


declare var html2pdf: any;

enum EnumStatusProduto {
	EM_ELABORACAO = 1,
	ATIVO = 2,
	INATIVO = 3
}

@Component({
	selector: "app-solicitacoes-produto-padrao",
	templateUrl: "./solicitacoes-produto-padrao.component.html"
})
@Injectable()
export class SolicitacoesProdutoPadraoComponent implements OnInit {

	desabilitarCodigo : boolean = true;
    form = this;
	grid: any = { sort: {} };
	parametros : ProdutoVM = new ProdutoVM();
	ocultarFiltros: boolean = false;
	isBuscaSalva: boolean = false;
	servico = 'ManterProdutoPadrao';
	path : string = '';
	objetoConsulta: any = {} = null;
	_idRota : number = 0;
	_idTipoProduto : number = null;
	_habilitaInputs : boolean = true;
	_flagHabilitaAlteracaoStatus : boolean = false;
	_produtoFoiFinalizado : boolean = false;
	_documentosRelacionados : string =  "";//new Array<string>();
	_existsGrupo : boolean = false;
	_flagExibeNcm : boolean = false;
	listaTipoDocumento = new Array<any>();
	disable :boolean= false
	_dataJustificativa : any = {};
	usuarioAlterouItem : boolean = false;
	_mostrarDocumentosRelacionados = false;
	_mostrarBotaoVincular : boolean = false;

	baseLegalSis: string;
	linhasBaseLegalSis: string;


  objetoValidacao : ValidacaoMCCPVM = new ValidacaoMCCPVM();

	/***********ARRAY DE DOCUMENTOS VINCULADOS********/

	arrayDocumentosVinculados: Array<ProdutoPPBVM> = new Array<ProdutoPPBVM>();
	arrayDocumentosRelacionados: Array<ProdutoPPBVM> = new Array<ProdutoPPBVM>();
	/************ */

	@ViewChild('DocumentoVinculado') DucumentoVinculado;
	@ViewChild('modalJustificativa') modalJustificativa;
	@ViewChild("relatorioPDF") htmlData: ElementRef;
	@ViewChild('appModalVincular') appModalVincular : any;
	@ViewChild('appModalDetalhePPB') appModalDetalhePPB;

	constructor(
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute,
		private validation: ValidationService

	) {
		if (sessionStorage.getItem(this.router.url) == null && sessionStorage.length > 0)
			sessionStorage.clear();

		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;

		if(this.path != "novo"){
			this._idRota = Number(this.route.snapshot.params['idTipoProduto']);
			this.buscar(this._idRota);

			this._mostrarDocumentosRelacionados = true;
			this._mostrarBotaoVincular = true;
		}

		if(this.path == "visualizar"){
			this.disable = true;
			this._habilitaInputs = false;
			this._mostrarBotaoVincular = false;
		}


		if(this.path == "finalizar" || this.path=="novo"){
			this.chamaServicoValidacao();

    }



		this.parametros.numeroDigitos = String(6);
	}

	ngOnInit() {
	}

  habInforCamposObrigatorios(){
    const habs = ['novo','editar','finalizar']
    if(habs.includes(this.path)){
      return true
    }
    return false
  }

  getTituloPagina(){
    switch(this.path){
      case 'novo':
        return "Produto Padronizado";
      case 'editar':
        return "Alterar Produto Padronizado";
      case 'visualizar':
        return "Visualizar Produto Padronizado";
      case 'finalizar':
        return "Produto Padronizado";
      default:
        return "Produto Padronizado";
    }
  }

	onChangeSort($event: any) {
		this.grid.sort = $event;

	}

	onChangeSize($event: any) {
		this.grid.size = $event;

	}

	onChangePage($event: any) {
		this.grid.page = $event;

	}

	validar() {
		this._idRota;

		if(!this.parametros.descProduto){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Descrição</b>", "Campo não Informado");
			return false;
		}

		if(this.arrayDocumentosVinculados.length == 0){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Base Legal</b>", "Campo não Informado");
			return false;
		}

    if(!this.parametros.idUnidadeMedida){
      this.modal.infoError("Campo Obrigatório não Informado: <b>Unidade Medida</b>", "Campo não Informado");
			return false;
    } else{
      this.parametros.idUnidadeMedida = Number(this.parametros.idUnidadeMedida);
    }

		if(!this.parametros.idCoeficienteReducao){
      this.modal.infoError("Campo Obrigatório não Informado: <b>Coeficiente de Redução</b>", "Campo não Informado");
			return false;
    } else{
      this.parametros.idCoeficienteReducao = Number(this.parametros.idCoeficienteReducao);
    }

		if(this.parametros.sitComponente == null || this.parametros.sitComponente == undefined){
      this.modal.infoError("Campo Obrigatório não Informado: <b>Componente</b>", "Campo não Informado");
			return false;
    } else{
      this.parametros.sitComponente = Number(this.parametros.sitComponente);
    }

		if(this.parametros.sitBemInformativa == null || this.parametros.sitBemInformativa == undefined){
      this.modal.infoError("Campo Obrigatório não Informado: <b>Bem de Informática</b>", "Campo não Informado");
			return false;
    }else{
      this.parametros.sitBemInformativa = Number(this.parametros.sitBemInformativa);
    }

		if(!this.parametros.idSubsetor){
      this.modal.infoError("Campo Obrigatório não Informado: <b>Sub Setor</b>", "Campo não Informado");
			return false;
    }else{
      this.parametros.idSubsetor = Number(this.parametros.idSubsetor);
    }

		(this._produtoFoiFinalizado && this.usuarioAlterouItem) ?
			this.abrirModalJustificativa() :
			this.salvar();
	}

	abrirModalJustificativa(){
		this._dataJustificativa.msgModal = 'Deseja Alterar o Produto Padrão? Confirme a Operação.'
		this._dataJustificativa.tituloModal ='Alterar o Produto Padrão'
		this._dataJustificativa.maxLengthInput = 1000;
		this._dataJustificativa.labelName = 'Insira a Justificativa da Alteração';
		this._dataJustificativa.isObrigatorioPreencherCampo = true;
    this.parametros.documentosVinculados = this.arrayDocumentosVinculados;
		this._dataJustificativa.objetoAlteracao = this.parametros;
		this.modalJustificativa.abrir();
	}

	recebeAlteracaoJustificativa($event){
		if($event){

			let obj : AlteracaoProdutoVM = new AlteracaoProdutoVM();
			obj.produtoVM = this.parametros;
			obj.produtoHistoricoVM.descricaoJustificativa = $event.txt;

			this.validarAlteracaoDoUsuario(obj);
		}
	}

	validarAlteracaoDoUsuario(obj : AlteracaoProdutoVM){

		this.applicationService.post(this.servico+"/PostValidacao", obj).subscribe( (idProduto : number) => {
			if(idProduto){
				this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(() => this.buscar(idProduto) );
			}
		});
	}

	salvar(){

		this.numerarSitProduto();
		this.arrayDocumentosRelacionados = new Array<ProdutoPPBVM>();
		this.parametros.documentosVinculados = this.arrayDocumentosVinculados;

		this.applicationService.post(this.servico, this.parametros).subscribe( (idProduto : number) => {
			if(idProduto!=-1){
				if(this.path == 'novo'){
					this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(() => this.router.navigate(['solicitacoes-produto-padrao/'+idProduto+'/editar'] ));
				} else {
					this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(() => this.buscar(idProduto) );
				}
			}else{
				this.modal.infoError("Não é possível cadastrar produtos com descrições iguais","Descrição já existente");
				return false;
			}
		});
	}

	async abrirModalDetalhePpb(itemPpb : any){
		await this.esconderDocumentosVinculados();
		await this.appModalDetalhePPB.abrir(itemPpb.idPPB);
	}

	buscar(idProduto : number){
		this.arrayDocumentosRelacionados = new Array<ProdutoPPBVM>();
		this.applicationService.get(this.servico, idProduto).subscribe( (result : ProdutoVM) => {
			if(result){
				this.parametros = result;
				this.montarBaseLegalSis();

				if(result.documentosVinculados){
					this.arrayDocumentosVinculados = result.documentosVinculados;
				}

				if(this.parametros.idUnidadeMedida)
					this.parametros.idUnidadeMedida = String(this.parametros.idUnidadeMedida);

				if(this.parametros.idCoeficienteReducao)
					this.parametros.idCoeficienteReducao = String(this.parametros.idCoeficienteReducao);

				if(this.parametros.sitComponente != null && this.parametros.sitComponente != undefined)
					this.parametros.sitComponente = String(this.parametros.sitComponente);

				if(this.parametros.sitBemInformativa != null && this.parametros.sitBemInformativa != undefined)
					this.parametros.sitBemInformativa = String(this.parametros.sitBemInformativa);

				if(this.parametros.idSubsetor)
					this.parametros.idSubsetor = String(this.parametros.idSubsetor);

				if(this.parametros.sitProduto == EnumStatusProduto.ATIVO || this.parametros.sitProduto == EnumStatusProduto.INATIVO){
					this._flagHabilitaAlteracaoStatus = true;
					this._produtoFoiFinalizado = true;
					if(this.path == 'visualizar'){
						this.parametros.sitProduto = (this.parametros.sitProduto == EnumStatusProduto.ATIVO)? "ATIVO" : "INATIVO";
					}
				}

				if(this.parametros.numeroDigitos != null && this.parametros.numeroDigitos != undefined)
					this.parametros.numeroDigitos = String(this.parametros.numeroDigitos);


				this._existsGrupo = false;
			}
		});
	}

	numerarSitProduto(){
		switch(this.parametros.sitProduto){
			case this.parametros.sitProduto == EnumStatusProduto.ATIVO:
				this.parametros.sitProduto =  2;
				break;
			case this.parametros.sitProduto == EnumStatusProduto.INATIVO:
				this.parametros.sitProduto = 3;
				break;
			default: break;
		}
	}

	limpar() {
		this.parametros = new ProdutoVM();
	}

	abrirModalVinculo(){
		this.appModalVincular.abrir(this.parametros.idPPB, this.parametros.id);
	}

	abrirModalGrupoPelaListagem(item){
		this.appModalVincular.abrir(item.idPPB, this.parametros.id);
	}


	formatDate(date: Date) {
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date)
	}


	voltar(){
		this.router.navigate(['/manter-produto-padrao']);

	}


	recebeInfoNCM($event){
		this._idTipoProduto = $event;
	}

	buscarDocumentosVinculados(){
		if(this.parametros.idPPB){
			this.applicationService.get("PpbVinculados", this.parametros.idPPB).subscribe( (result : any) => {
				if(result){
					this._documentosRelacionados = result.documentosVinculados;
					this._existsGrupo = result.isPpbPorGrupo;
				}
			});
		}
		else
		{
			this._documentosRelacionados = null;//new Array<string>();
			this.parametros.idPPB = null;
			this.parametros.idGrupo = null;
			this.parametros.descGrupoPPB = null;
			this._existsGrupo = false;
		}
	}

	recebeGrupoSelecionado($event){
		this.usuarioAlterouItem = true;
		this.parametros.idGrupo = $event.idGrupo;
		this.parametros.idPPB = $event.idPPB;
		var item = this.arrayDocumentosVinculados.find(q => q.idPPB == this.parametros.idPPB);
		var index = this.arrayDocumentosVinculados.indexOf(item);
		this.arrayDocumentosVinculados[index].idGrupo = this.parametros.idGrupo;
		//this.parametros.idGrupo = null;
		//this.parametros.idPPB = null;
		this._existsGrupo = false;
		this.DucumentoVinculado.clear();
	}

	chamaServicoValidacao()
	{
		this.applicationService.get("Validar", this._idRota).subscribe((response : ValidacaoMCCPVM) => {
			if(response._flagExistePendencia === false){
				this.chamaServicoFinalizacao();
			}else{
				this.objetoValidacao = response;
				if(response.pendenciaIdGrupo){
					this.modal.infoError("Produto não vinculado aos grupos referentes as Bases Legais selecionadas.","Atenção!");
				}else{
					this.modal.infoError("Registro com dados Inconsistentes","Atenção!");
				}
			}
		});
	}

	chamaServicoFinalizacao(){
		this.applicationService.get("Finalizar", this._idRota).subscribe((response : number) => {
			if(response == 200){
				this.modal.infoSucess("Operação realizada com sucesso","Sucesso");
				this.voltar();
			} else {
				this.modal.infoError("Falha ao Finalizar","Erro 404");
				return false;
			}
		});
	}

	preencheArrayDocsDeReferencia($event){
		if(typeof $event === "number"){

			this.applicationService.get("PpbVinculados/GetValidacaoIsPpbPorGrupo", {idPPB : $event}).subscribe((result : ProdutoPPBVM) => {
				if(result)
				{
					let novoDocumento = new ProdutoPPBVM();
					novoDocumento.idPPB = $event;
					novoDocumento.tituloPPB = this.DucumentoVinculado.model.text;
					novoDocumento.IdProduto = this.parametros.id;
					novoDocumento.idGrupo = this.parametros.idGrupo;
					novoDocumento.isPpbPorGrupo = result.isPpbPorGrupo;
          novoDocumento.isPpbMultiplo = result.isPpbMultiplo

					if(result.isPpbPorGrupo){
						this.appModalVincular.abrir($event, this.parametros.id, this.arrayDocumentosVinculados.length, true);
					}

					if(this.arrayDocumentosVinculados){
						var itemJaVinculado = this.arrayDocumentosVinculados.filter(q => q.idPPB == novoDocumento.idPPB);
            if(itemJaVinculado.length == 0){
							this.arrayDocumentosVinculados = [...this.arrayDocumentosVinculados, novoDocumento];
						}
					}else{
						this.arrayDocumentosVinculados = [...this.arrayDocumentosVinculados, novoDocumento]
					}
				}
			});
		}
	}

	excluirDocumento(index){
		this.modal.confirmacao("Deseja realmente excluir a Base Legal? Todos os artigos ligados a Base Legal também serão apagados.").subscribe((isConfirmado) => {
			if(isConfirmado){
        this._existsGrupo = false;
				if(this.arrayDocumentosVinculados[index].id > 0){
					var base = this.arrayDocumentosVinculados[index];
					var result = this.excluirDocumentoBackEnd(base.id);
					if(result){
						this.modal.infoSucess("Operação realizada com sucesso", "Sucesso");

						var base = this.arrayDocumentosVinculados[index];
						this.excluirDocumentoVinculado(base);

						this.arrayDocumentosVinculados.splice(index, 1);
					}else{
						this.modal.infoError("Erro ao remover a Base Legal","Erro");
					}
				}else{
					var base = this.arrayDocumentosVinculados[index];
					this.excluirDocumentoVinculado(base);
					this.arrayDocumentosVinculados.splice(index, 1);
				}
			}
		});
	}

	async excluirDocumentoBackEnd(id){
    let retorno: any = await this.applicationService.deletar("PpbVinculados", id).toPromise();
    return retorno;
	}

	async mostrarDocumentosVinculados(item: ProdutoPPBVM, indexPrincipal: number){

		var itemJaAberto = this.arrayDocumentosRelacionados.filter(o => o.idPPB == item.idPPB);

		if(itemJaAberto.length == 0){
			var doc = new ProdutoPPBVM()

			var retorno: any = await this.applicationService.get("PpbVinculados", item.idPPB).toPromise();

			doc.idPPB = item.idPPB;
			doc.tituloPPB = item.tituloPPB;
			doc.docsRelacionados = retorno.documentosVinculados;
			doc.count = retorno.count;

			this.arrayDocumentosRelacionados = [...this.arrayDocumentosRelacionados, doc];
			this.arrayDocumentosVinculados[indexPrincipal].isDocsMostrados = true;
		}else{
			var existe = itemJaAberto[0];
			var index = this.arrayDocumentosRelacionados.indexOf(existe);
			this.arrayDocumentosRelacionados.splice(index, 1);
			this.arrayDocumentosVinculados[indexPrincipal].isDocsMostrados = false;
		}
	}

	esconderDocumentosVinculados(){
		var i = 0;
		if(this.arrayDocumentosVinculados.length > 0)
			return new Promise (resolve=>{
				this.arrayDocumentosVinculados.forEach(item => {
					var itemJaAberto = this.arrayDocumentosRelacionados.filter(o => o.idPPB == item.idPPB);
					var existe = itemJaAberto[0];
					var index = this.arrayDocumentosRelacionados.indexOf(existe);
					this.arrayDocumentosRelacionados.splice(index, 1);
					item.isDocsMostrados = false;
					i++;
				})
				resolve(null);
			});
	}

	excluirDocumentoVinculado(item: ProdutoPPBVM){
		var itemJaAberto = this.arrayDocumentosRelacionados.filter(o => o.idPPB == item.idPPB);
		var existe = itemJaAberto[0];
		var index = this.arrayDocumentosRelacionados.indexOf(existe);
		this.arrayDocumentosRelacionados.splice(index, 1);
	}

	montarBaseLegalSis(){
		this.applicationService.get("PpbVinculados/DescricaoPPBs").subscribe((result: any) => {
			this.baseLegalSis = result.text;
			this.linhasBaseLegalSis = result.count;
		});
	}

  OnArtigoSelect(artigo: ArtigoVM, documentoId: number) {
   var indexDocumento = this.arrayDocumentosVinculados.findIndex(x => x.idPPB == documentoId);

   if (this.arrayDocumentosVinculados[indexDocumento].artigos == undefined){
     this.arrayDocumentosVinculados[indexDocumento].artigos = new Array<ArtigoVM>();
   }
    this.arrayDocumentosVinculados[indexDocumento].artigos.push(artigo)
  }

  OnArtigoDelete(artigoId: Number, documentoId: number) {
    var indexDocumento = this.arrayDocumentosVinculados.findIndex(x => x.idPPB == documentoId);
    this.arrayDocumentosVinculados[indexDocumento].artigos = this.arrayDocumentosVinculados[indexDocumento].artigos.filter(x => x.id != artigoId);
  }
}

