<div 
    #appModalEscolherOrgaos 
    class="modal modal-wide-2 fade in"
    tabindex="-1" 
    role="dialog" 
    aria-hidden="false"
    style="display: auto; overflow: scroll;">

    <div class="modal-dialog modal-sm">
        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Escolher Órgãos</h4>
            </div>

            <div class="modal-body" *ngIf="_flagTerminouConsulta">
                <div>
                    <h5> <b>Órgãos:</b> </h5>
                    <div *ngFor="let item of objetoOrgaos; let i = index ;">
                        <input 
                            type="checkbox" 
                            [(ngModel)]="item.isSelecionada" 
                            id="check">                        
                        <label for="label" style="margin-left: 10px;"> {{item.descricaoCompleta}} </label>
                    </div>
                </div>			
            </div>
           
            <div class="modal-footer" style="padding: 10px 15px;">
                <a 
                    (click)="fechar()"
                    class="btn btn-sm btn-default" 
                    data-dismiss="modal">
                        <i class="fa fa-times"></i> Fechar
                </a>                    
                <a 
                    (click)="validar()"
                    class="btn btn-sm btn-primary" 
                    data-dismiss="modal" 
                    data-toggle="modal" 
                    data-backdrop="static" 
                    data-keyboard="false">
                        <i class="fa fa-save m-r-xs"></i> Salvar
                </a>                    
            </div>

        </div>
    </div>

</div>
<div #appModalEscolherOrgaosBackground class="modal-backdrop fade in" style="display: none;"></div>