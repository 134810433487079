<app-grid [(titulo)]="tituloGrid" [(page)]="page" [(size)]="size" [(total)]="total" [(parametros)]="parametros"
  (onChangeSize)="changeSize($event)" (onChangePage)="changePage($event)">

  <div class="table-responsive no-margin-bottom no-border">
    <table class="table">
      <thead class="table-header-color">
        <tr>
          <th class="th-50 text-left">Ações</th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="numeroCodigo" [sorted]="sorted" (onChangeSort)="changeSort($event)">Código
            </app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="descSubsetor" [sorted]="sorted" (onChangeSort)="changeSort($event)">
              Descrição </app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="situacao" [sorted]="sorted" (onChangeSort)="changeSort($event)">
              Situação
            </app-ordenacao>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="total > 0">
        <tr *ngFor="let item of lista">
          <td class="text-center" *ngIf="eUsuarioSuframa">
            <a routerLink="/manter-subsetor/{{item.id}}/visualizar" class="btn btn-default btn-sm" data-toggle="tooltip"
              title="Visualizar" data-original-title="Visualizar">
              <i class="fa fa-file-text-o"></i>
            </a>

            <a class=" btn btn-default btn-sm " data-toggle="tooltip" title="Histórico" data-original-title="Histórico"
              (click)="abrirHistorico(item)">
              <i class="i i-history"></i>
            </a>

            <a routerLink="/manter-subsetor/{{item.id}}/editar" class="btn btn-default btn-sm" data-toggle="tooltip"
              title="Alterar" data-original-title="Alterar">
              <i class="fa fa-pencil"></i>
            </a>

            <!-- <a class=" btn btn-default btn-sm " data-toggle="tooltip" title="Histórico"
                        class="btn btn-danger btn-sm"
                        data-original-title="Excluir" (click)="excluirProduto(item.id)">
                        <i class="fa fa-trash-o"></i>
                        </a> -->
            <a *ngIf="!item.situacao" (click)="abrirJustificativa(item)"
              class=" btn btn-rounded btn-sm btn-icon btn-primary" data-toggle="tooltip" title="Ativar"
              data-original-title="Ativar">
              <i class="fa fa-check"></i>
            </a>
            <a *ngIf="item.situacao==1" (click)="abrirJustificativa(item)"
              class=" btn btn-rounded btn-sm btn-icon btn-danger" data-toggle="tooltip" title="Inativar"
              data-original-title="Inativar">
              <i class="fa fa-times"></i>
            </a>


          </td>
          <td class="text-center" *ngIf="!eUsuarioSuframa">
            <a routerLink="/manter-subsetor/{{item.id}}/visualizar" class="btn btn-default btn-sm" data-toggle="tooltip"
              title="Visualizar" data-original-title="Visualizar">
              <i class="fa fa-file-text-o"></i>
            </a>
          </td>
          <td class="text-left">{{item.numeroCodigo == null? 'Não disponível' : item.numeroCodigo}}</td>
          <td class="text-left">{{item.descSubsetor}}</td>
          <td class="text-left" *ngIf="!item.situacao"><b style="color:red;">Inativo</b></td>
          <td class="text-left" *ngIf="item.situacao==1"><b style="color:green;">Ativo</b></td>
        </tr>
      </tbody>
    </table>
  </div>
</app-grid>

<app-modal-justificativa-subsetor (changeOperation)="atualizaListagem($event)" #appModalJustificativaSubsetor>
</app-modal-justificativa-subsetor>

<app-modal-historico-subsetor #appModalHistoricoSubsetor>
</app-modal-historico-subsetor>
