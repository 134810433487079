import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from "../../../shared/services/application.service";
import { PagedItems } from '../../../view-model/PagedItems';
import { NcmEntityVM } from '../../../view-model/NcmEntityVM';
import { ProdutoHistoricoVM } from '../../../view-model/ProdutoHistoricoVM';
import { subsetorVM } from '../../../view-model/SubsetorVM';
import { UnidadeMedidaVM } from '../../../view-model/UnidadeMedidaVM';

@Component({
	selector: 'app-modal-verificar-inconssistencia',
	templateUrl: './modal-verificar-inconssistencia.component.html'
})

export class ModalVerificarInconssistenciaComponent implements OnInit {

	//----GRID ITENS-----
	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	grid: any = { sort: {} };
    //--------------------a
  servico = "ConsultarProdutoPadrao";
  listaFormatada : any = {};
  flagFinalizouConsulta : boolean = false;
	parametros: ProdutoHistoricoVM = new ProdutoHistoricoVM();

	@Input() data: any = {} = null;
	@Output() changeOperation: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalVerificarInconssistencia') appModalVerificarInconssistencia : any;
	@ViewChild('appModalVerificarInconssistenciaBackground') appModalVerificarInconssistenciaBackground : any;



	constructor(
		private applicationService : ApplicationService
	) {}

	ngOnInit() {

	}

	onChangeSort($event: any) {
		this.grid.sort = $event;
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
	}

	onChangePage($event: any) {
		this.grid.page = $event;
	}

	abrir(){

		this.appModalVerificarInconssistenciaBackground.nativeElement.style.display = 'block';
		this.appModalVerificarInconssistencia.nativeElement.style.display = 'block';
    this.validarIncossistencia();
	}

	fechar(){
    this.appModalVerificarInconssistenciaBackground.nativeElement.style.display = 'none';
		this.appModalVerificarInconssistencia.nativeElement.style.display = 'none';
    this.flagFinalizouConsulta = false;
		this.data = null;
		this.lista = {}
		this.total = null;
    this.changeOperation.emit()
	}
	validarIncossistencia(){
    var objeto = null;
    this.applicationService.get(this.servico+'/Verificar', objeto).subscribe((response: {}) => {
      if(response){
        this.lista = response;
        this.flagFinalizouConsulta = true;
      }else{
        this.fechar();
      }
		});
  }
}
