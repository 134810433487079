<div #appModalIncluirGruposPPB class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Incluir Condicionantes</h4>
            </div> 
            <form (ngSubmit)="validar()" [formGroup]="userprofileForm" class="form form-horinzontal needs-validation" role="form" novalidate>
                <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">
                    <div class="col-lg-11">
                        <label class="">Produtos do Grupo {{parametros.descricaGrupo}}:</label>   
                            <textarea 
                                class="form-control ng-pristine ng-valid ng-touched" 
                                id="produtos-grupo-{{parametros.descricaGrupo}}" 
                                maxlength="2000" 
                                name="produtos-grupo-{{parametros.descricaGrupo}}" 
                                rows="5"
                                style="resize: none;"
                                formControlName="produto"
                                [(ngModel)]="parametros.descricaProdutos"
                                required >
                            </textarea>
                            <div *ngIf="produto.invalid && (produto.dirty || produto.touched)" class="alert-danger">
                                <div *ngIf="produto.errors.required">
                                    <b style="margin-left: 15px;">Campo Obrigatório não Informado</b>
                                </div>
                            </div>
                    </div>                    
                </div>		 
    
                <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">
                    <div class="col-lg-11">
                        <label class="">Metas do Grupo {{parametros.descricaGrupo}}:</label>
                        <textarea                        
                            class="form-control ng-pristine ng-valid ng-touched" 
                            id="metas-grupo-{{parametros.descricaGrupo}}" 
                            maxlength="2000" 
                            id="metas-grupo-{{parametros.descricaGrupo}}" 
                            [(ngModel)]="parametros.descricaoMetas"
                            formControlName="metasGrupo"
                            rows="5"
                            style="resize: none;" >
                        </textarea>
                        <div *ngIf="metasGrupo.invalid && (metasGrupo.dirty || metasGrupo.touched)" class="alert-danger">
                            <div *ngIf="metasGrupo.errors.required">
                                <b style="margin-left: 15px;">Campo Obrigatório não Informado</b>
                            </div>
                        </div>
                    </div>
                </div>	
    
                <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">
                    <div class="col-lg-6">
                        <label for="dataorigem" class="required">Período de Vigência:</label>
                        <div class="input-group" style="display: flex;">
                            <input 
                                #dataRecebimentoInicial
                                type="date"
                                id="dataInicio-grupo" 
                                name="dataInicio-grupo"                           
                                min="0001-01-01" max="9999-12-31"                               
                                class="form-control"
                                style="width: 50%;"
                                [(ngModel)]="parametros.dataInicioVigenciaString"
                                formControlName="dataInicial"      
                            >                            
                                <span class="text-center" style="padding-right: 15px; padding-top: 8px; margin-left: 15px;">a</span>
                            <input 
                                #dataRecebimentoFinal
                                type="date"
                                id="dataFim-grupo"                            
                                name="dataFim-grupo"
                                min="0001-01-01" max="9999-12-31"
                                class="form-control"
                                style="width: 50%;"
                                [(ngModel)]="parametros.dataFimVigenciaString"
                                formControlName="dataFinal"      
                            >   
                        </div>
                        <div *ngIf=" _errorDataInicioNaoPreenchida " class="alert-danger">
                            <b style="margin-left: 15px;">
                                <button type="button" class="close" (click)=" _errorDataInicioNaoPreenchida = false;"><b style="color:black;">&times;</b></button>
                                <b style="margin-left: 15px;"> Data Inicial é Obrigatória. </b>
                            </b>                                                   
                        </div>
                        <div *ngIf=" _errorDataFinalMaiorInicial " class="alert-danger">
                            <b style="margin-left: 15px;">
                                <button type="button" class="close" (click)=" _errorDataFinalMaiorInicial = false; "><b style="color:black;">&times;</b></button>
                                <b style="margin-left: 15px;"> Data Final nao pode ser Inferior a Data Inicial. </b>
                            </b>
                        </div>           

                        <div *ngIf=" _errorDataFinalMenorDataCorrente " class="alert-danger">
                            <b style="margin-left: 15px;">
                                <button type="button" class="close" (click)=" _errorDataFinalMenorDataCorrente = false; "><b style="color:black;">&times;</b></button>
                                <b style="margin-left: 15px;"> A Data Final Não pode ser Inferior da Data Corrente! </b>
                            </b>
                        </div>  

                    </div>
                </div>

                <div class="pull-left" style="margin-left: 35px;">
                    <input 
                        id="check-cadastro-continuo-grupo-ppb"
                        name="check-cadastro-continuo-grupo-ppb"
                        type="checkbox" 
                        (change)="flagCadastroContinuo ? flagCadastroContinuo = false : flagCadastroContinuo = true;">
                    <label for="Pesquisa" >Cadastro Contínuo</label>
                </div>

            </form>
            
                       
            <div class="modal-footer" style="padding: 10px 15px;">
                <div class="pull-right">
                    <button
                        id="fechar-modal-grupo-ppb"
                        name="fechar-modal-grupo-ppb"
                        (click)="fechar()"
                        class="btn btn-sm btn-default" 
                        data-dismiss="modal">
                            <i class="fa fa-times"></i> Fechar
                    </button>   
                    <button 
                        id="salvar-modal-grupo-ppb"
                        name="salvr-modal-grupo-ppb"
                        data-toggle="tooltip" 
                        [disabled]="!userprofileForm.valid"
                        title="salvar"
                        data-original-title="salvar"                        
                        (click)="validar()"
                        type="submit"
                        class="btn btn-sm btn-primary" 
                        data-dismiss="modal" 
                        data-toggle="modal" 
                        data-backdrop="static" 
                        data-keyboard="false">
                            <i class="fa fa-save m-r-xs"></i> Salvar
                    </button>
                </div>                    
            </div>

        </div>

    </div>
</div>
<div #appModalIncluirGruposPPBBackground class="modal-backdrop fade in" style="display: none;"></div>