
import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../shared/services/application.service';
import { MessagesService } from '../../../shared/services/messages.service';
import { ModalService } from '../../../shared/services/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from '../../../shared/services/validation.service';
import { isNgTemplate } from '@angular/compiler';
@Component({
    selector: 'app-grid-consultar-alteracoes',
    templateUrl: './grid.component.html',
})

export class GridConsultarAlteracoes {
    numeroHoje: string = '11111';
    ocultarTituloGrid = false;
    mostraComponenteRelatorio = false;

    constructor(
        private applicationService: ApplicationService,
        private modal: ModalService,
        private msg: MessagesService,
        private router: Router,
        private route: ActivatedRoute,
        private validation: ValidationService,
    ) {

    }

    @ViewChild('appModalHistoricoRdap') appModalHistoricoRdap;

    @Input() lista: any = {};
    @Input() total: number;
    @Input() size: number;
    @Input() page: number;
    @Input() sorted: string;
    @Input() isUsuarioInterno: boolean = false;
    @Input() exportarListagem: boolean;
    @Input() parametros: any = {};
    @Input() formPai: any;
    @Input() somenteLeitura: boolean;

    @Output() onChangeSort: EventEmitter<any> = new EventEmitter();
    @Output() onChangeSize: EventEmitter<any> = new EventEmitter();
    @Output() onChangePage: EventEmitter<any> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() atualizarListagem: EventEmitter<any> = new EventEmitter();


    tituloGrid = "Resultado da Pesquisa"
    servico = "RdapRadiPap";

   // itemProdutos: CertificacaoEntityVM = new CertificacaoEntityVM();
 //   itemSelected: any;

    ngOnInit(): void { }

    changeSize($event) {
        this.onChangeSize.emit($event);
        this.changePage(this.page);
    }

    changeSort($event) {
        this.sorted = $event.field;
        this.onChangeSort.emit($event);
        this.changePage(this.page);
    }

    changePage($event) {
        this.page = $event;
        this.onChangePage.emit($event);
    }



    navigate(item : any, operation : string){
		sessionStorage.removeItem("rota_origem");
		sessionStorage.setItem("rota_origem", this.router.url);
		this.router.navigate(['/visualizar-alteracoes/'+ item.id + "/" +operation]);
    }

}
