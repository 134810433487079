import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertaModel {
	title: string;
	message: string;
}

@Component({
	selector: 'app-modal-justificativa',
	templateUrl: './modal-justificativa.component.html',
})

export class ModalJustificativaComponent implements OnInit {

    justificativa : string = "";
    _isJustificativaVazia : boolean = false;
    event : EventEmitter<any> = new EventEmitter()
    
	constructor(public dialogRef : MatDialogRef<ModalJustificativaComponent>,
				@Inject(MAT_DIALOG_DATA) public data : AlertaModel) { }

	ngOnInit() {}

	confirm() {
        if(!this.justificativa || this.justificativa.trim().length == 0){
            this._isJustificativaVazia = true;
			return false;
        }
		this.dialogRef.close(this.justificativa);
	}

}
