import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ViewsComponentsModule } from "../views-components/views-components.module";
import { IndexComponent } from "./index/index.component";
import { ManterPBModule } from './manter-ppb/manter-ppb.module';
import { ManterProdutoPadraoModule } from "./manter-produto-padrao/manter-produto-padrao.module";
import { SolicitacoesProdutoPadraoComponent } from "./manter-produto-padrao/solicitacoes-produto-padrao/solicitacoes-produto-padrao.component";
import { ManterNCMModule } from "./manter-ncm/manter-ncm.module";
import { ManterSubsetorModule } from "./manter-subsetor/manter-subsetor.module";
import { ManterUnidadeModule } from "./manter-unidade/manter-unidade.module";
import { RelatorioProdutoPadronizadoModule } from "./relatorio-produto-padronizado/relatorio-produto-padronizado.module";
import { RelatorioTipoProdutoPadraoModule } from "./relatorio-tipo-de-produto-padrao/relatorio-tipo-produto-padrao.module";
import { ConsultarAlteracoesModule } from "./consultar-alteracoes/consultar-alteracoes.module";

  @NgModule({
	declarations: [
		IndexComponent,
	],
	exports: [

	],
	imports: [
    RelatorioProdutoPadronizadoModule,
    CommonModule,
		FormsModule,
		ViewsComponentsModule,
		ManterPBModule,
		ManterProdutoPadraoModule,
		ManterNCMModule,
		ManterSubsetorModule,
		ManterUnidadeModule,
    	RelatorioTipoProdutoPadraoModule,
		ConsultarAlteracoesModule
	]
})
export class ViewsModule { }
