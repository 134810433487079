<app-grid 
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
			<thead class="table-header-color">
				<tr>
					<th class="th-100 text-left" *ngIf="habilitarInputs">Ações</th>
					<th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DescricaTopico" [sorted]="sorted" (onChangeSort)="changeSort($event)"> 
                            Condicionante
                        </app-ordenacao>                        
                    </th>		
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DataInicioVigenciaString" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                            Data Vigência
                        </app-ordenacao>                        
                    </th>			
					<th class="th-sortable unset-nowrap text-left">
                        Descrição da Condicionante
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DescricaoDocumento" [sorted]="sorted" (onChangeSort)="changeSort($event)"> 
                            Documento
                        </app-ordenacao>                       
                    </th>                    
				</tr>
			</thead>
			<tbody *ngFor=" let item of lista ; let i = index ; " [attr.data-index]="i">						
                <tr>                               
                    <td class="text-left" *ngIf="habilitarInputs" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                        <a 
                            *ngIf="!item.indicadorParaTaxarItem"
                            (click)="validarSePermiteAbrirModal(item)"
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip" 
                            title="Alterar" 
                            data-original-title="Alterar">
                                <i class="fa fa-pencil"></i>
                        </a>
                        <a 
                            *ngIf="!item.indicadorParaTaxarItem"
                            (click)="excluir(item)"
                            class="btn btn-danger btn-sm" 
                            data-toggle="tooltip" 
                            title="Excluir" 
                            data-original-title="Excluir">
                                <i class="fa fa-trash-o"></i>
                        </a>
                    </td>
					<td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                        <app-taxa-texto 
                            [(texto)]="item.descricaTopico" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                        <app-taxa-texto 
                            [(texto)]="item.dataInicioVigenciaString" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">                        
                        <span *ngIf="!item.descricaCondicaoString"> -- </span>
                        <a 
                            *ngIf="item.descricaCondicaoString" 
                            style="color: blue;"> Clique em Expandir para Visualizar a Condicionante... 
                        </a>
                        <app-collapse-row-button 
                            style="cursor: pointer;" 
                            *ngIf="item.descricaCondicaoString" 
                            target="{{'object-' + i}}">
                        </app-collapse-row-button>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                        <app-taxa-texto 
                            [(texto)]="item.descricaoDocumento" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
				</tr>
                <td colspan="6" id="{{ 'object-' + i }}" style="display:none" >                         
                    <div class="row" style="margin-left: 30px; height: 500px; overflow: scroll;" *ngIf="item.descricaCondicaoString">
                        
                        <legend style="margin-top: 15px;">Descrição da Condicionante</legend>
                        <angular-editor [(ngModel)]="item.descricaCondicaoString" [config]="config"></angular-editor> 

                    </div>
                </td> 
			</tbody>
		</table>
	</div>
</app-grid>

<app-modal-incluir-condicionantes-ppb 
    [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
    #appModalIncluirCondicionantesPPB 
    (atualizaListagem)="atualizaListagem($event)">
</app-modal-incluir-condicionantes-ppb>