<section class="panel panel-default no-margin-bottom">
	<header *ngIf="isShowPanel" class="panel-heading">
		<div *ngIf="parametros.exportarListagem" class="pull-right">
			<div class="pull-right">
				<button type="button" class="btn btn-default btn-sm dropdown-toggle" id="exportar"
					data-toggle="dropdown"><i class="fa fa-download"></i> Exportar <i
						class="fa fa-caret-down"></i></button>
				<ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="exportar">
					<li role="presentation" *ngIf="parametros.exportarPdf">
						<a style="cursor:pointer" role="menuitem" (click)="exportar(1)"><i class="fa fa-file-pdf-o"></i>
							Salvar em PDF</a>
					</li>
					<li role="presentation">
						<a style="cursor:pointer" role="menuitem" (click)="exportar(2)"><i
								class="fa fa-file-excel-o"></i> Salvar em Excel</a>
					</li>
				</ul>
			</div>
		</div>
		<h2 class="panel-title h5">{{ titulo != null ? titulo : 'Registros' }}</h2>

	</header>

	<div *ngIf="!isHideCabecalho">
		<app-grid-cabecalho
			[setPage]="setPage"
			[size]="size"
			(onChangeSize)="changeSize($event)"
			*ngIf="total && (total > 0)">
		</app-grid-cabecalho>
	</div>
	<ng-content></ng-content>
	<div *ngIf="!total || total === 0">
		<p class="text-center font-bold m-t-sm m-b-sm">Nenhum registro encontrado.</p>
	</div>

	<div *ngIf="!isHidePaginacao">
		<footer *ngIf="total && (total > 0)" class="panel-footer"
			style="background-color: white!important; padding-bottom: 8px; padding-top: 8px;">
			<div class="row">
				<div class="col-sm-4" style="background-color: white!important;">
					<small class="text-muted inline m-t-sm m-b-sm">Mostrando
						{{(page*size)-size+1}}-{{(page*size) < total ? (page*size) : total}} de {{total}} itens</small>
				</div>
				<app-paginacao
					[widthPagination]="10"
					[(page)]="page"
					[(size)]="size"
					[(total)]="total"
					[hidden]="false"
					(change)="changePage($event)">
				</app-paginacao>
			</div>
		</footer>
	</div>
	<footer *ngIf="total && (total > 0)" class="panel-footer clearfix" style="padding-top: 18px; padding-bottom: 18px;">
	</footer>
</section>
