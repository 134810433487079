import { ValidationService } from "../../../shared/services/validation.service";
import { ApplicationService } from "../../../shared/services/application.service";
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { UnidadeMedidaAlteracoesVM, UnidadeMedidaVM } from '../../../view-model/UnidadeMedidaVM';
import { subsetorVM, SubsetorAlteracoesVM } from "../../../view-model/SubsetorVM";
import { NcmVM, NcmAlteracoesVM } from "../../../view-model/NcmVM";

enum EnumFiltroDownload {
	Produtos = 1,
	Unidades = 2,
	Subsetores = 3,
	Ncm = 4
}
@Component({
	selector: "app-visualizar-alteracoes",
	templateUrl: "./visualizar-alteracoes.component.html"
})

@Injectable()
export class VisualizarAlteracoesComponent implements OnInit {
  produtoIsCarregado : boolean = false ;
  grid : any = { sort: {} };
  servico = 'ConsultarAlteracoes'
  produtoAlteracoes : any = {};
  unidadeAlteracoes : UnidadeMedidaAlteracoesVM = new UnidadeMedidaAlteracoesVM();
  subsetorAlteracoes : SubsetorAlteracoesVM = new SubsetorAlteracoesVM();
  ncmAlteracoes : NcmAlteracoesVM = new NcmAlteracoesVM();
  path : string;
  idItem: number;

  constructor(
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private router: Router,
    private route: ActivatedRoute,
    private validation: ValidationService)
    {
      this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
    }

  @ViewChild('appRelatorio') appRelatorio;

  ngOnInit(): void {
    if(this.path == 'produto'){
			this.idItem = Number(this.route.snapshot.params['id']);
			this.buscarProduto(this.idItem);
		}
    if(this.path == 'unidade'){
			this.idItem = Number(this.route.snapshot.params['id']);
			this.buscarUnidade(this.idItem);
		}
    if(this.path == 'subsetor'){
			this.idItem = Number(this.route.snapshot.params['id']);
			this.buscarSubsetor(this.idItem);
		}
    if(this.path == 'ncm'){
			this.idItem = Number(this.route.snapshot.params['id']);
			this.buscarNcm(this.idItem);
		}
  }

  buscarUnidade(id: any){
    let objeto = new UnidadeMedidaVM();
    objeto.id = id;
		this.applicationService.get('ConsultarAlteracoes/GetUnidade',objeto).subscribe((result:UnidadeMedidaAlteracoesVM)=>{
			if(result){
				this.unidadeAlteracoes = result;
			}
		});
	}
  buscarProduto(id: any){
	let objeto = new UnidadeMedidaVM();
    objeto.id = id;
		this.applicationService.get('ConsultarAlteracoes/GetProdutoPorId', objeto).subscribe((result:any)=>{
			if(result){
				this.produtoAlteracoes = result;
        this.produtoIsCarregado = true;
			}
		});
	}

  buscarSubsetor(id: any){
    let objeto = new subsetorVM();
    objeto.id = id;
		this.applicationService.get('ConsultarAlteracoes/GetVisualizarSubsetor',objeto).subscribe((result:SubsetorAlteracoesVM)=>{
			if(result){
				this.subsetorAlteracoes = result;
			}
		});
	}

	buscarNcm(id: any){
		let objeto = new NcmVM();
		objeto.id = id;
			this.applicationService.get('ConsultarAlteracoes/GetVisualizarNcm',objeto).subscribe((result:NcmAlteracoesVM)=>{
				if(result){
					this.ncmAlteracoes = result;
				}
			});
		}

  voltar(){
		this.router.navigate(['/consultar-alteracoes']);
	}

	abrirDownload(tipoRelatorio: number){
		switch (tipoRelatorio) {
			case EnumFiltroDownload.Produtos:
				this.abrirDownloadProduto();
				break;
			case EnumFiltroDownload.Unidades:
				this.abrirDownloadUnidades();
			break;
			case EnumFiltroDownload.Subsetores:
				this.abrirDownloadSubsetor();
				break;
			case EnumFiltroDownload.Ncm:
				this.abrirDownloadNcm();
				break;
			default:
				break;
		}
	}

	abrirDownloadProduto() {
		this.appRelatorio.abrirRelatorioProdutos(this.produtoAlteracoes);
	}

	abrirDownloadUnidades() {
		this.appRelatorio.abrirRelatorioUnidade(this.unidadeAlteracoes);
	}

	abrirDownloadSubsetor() {
		this.appRelatorio.abrirRelatorioSubsetor(this.subsetorAlteracoes);
	}

	abrirDownloadNcm() {
		this.appRelatorio.abrirRelatorioNcm(this.ncmAlteracoes);
	}
}
