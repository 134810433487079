<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px" data-railopacity="0.2">
	<div class="row m-b-md">
		<div class="col-lg-12">
			<app-titulo>Solicitar Cadastro de PPB</app-titulo>
			<app-sub-titulo>Manter PPB</app-sub-titulo>
		</div>
	</div>

    <section class="panel panel-default">
        <header class="panel-heading">
            <div class="pull-right">                
                <a 
                    id="btn-voltar-solicitacoes-ppb"
                    name="btn-voltar-solicitacoes-ppb"
                    (click)="voltar()"
                    class="btn btn-default btn-sm">
                        <i class="fa fa-arrow-left"></i>  Voltar
                </a>    
                <a 
                    id="btn-salvar-solicitacoes-ppb"
                    name="btn-salvar-solicitacoes-ppb"
                    *ngIf="habilitarInputs"
                    (click)="validar()"
                    class="btn btn-primary btn-sm">
                        <i class="fa fa-save"></i>  Salvar
                </a>                           
            </div>				
            <h2 class="panel-title h5">Formulário</h2>
        </header>
        <div class="panel-body">
            <div class="row form-group">
                <div class="col-lg-3">
                    <label for="descricao" class="required">Tipo de Publicação: <b *ngIf="path == 'validacao' && objetoValidacao.pendenciaTipoPublicacao" style="color: red">*</b>  </label>
                        <select 
                            name="tipo-publicacao-solicitacoes-ppb" 
                            id="tipo-publicacao-solicitacoes-ppb" 
                            class="form-control" 
                            [attr.disabled]="!habilitarInputs ? true : null"
                            [(ngModel)]="parametros.ppbVM.tipoPublicacao">
                                <option 
                                    value="undefined" 
                                    [selected]="path === 'novo' ? true : null"
                                    disabled>
                                Selecione uma opção ...
                                </option>
                                <option value="1">FIXAÇÃO</option>
                                <option value="2">ALTERAÇÃO PARA REVOGAÇÃO</option>
                                <option value="3">ALTERAÇÃO PARCIAL</option>
                                <option value="4">AGREGAÇÃO</option>
                        </select>
                </div>
                <div style="margin-top: -1px;" class="col-sm-9" *ngIf="parametros.ppbVM.tipoPublicacao && parametros.ppbVM.tipoPublicacao != FIXACAO">
                    <label for="descricao" class="required">Informe o documento de Referência: <b *ngIf="path == 'validacao' && objetoValidacao.pendenciaDocumentoDeReferencia" style="color: red">*</b></label>
                      <!--  <app-drop-list-select2
                            [isDisabled]="!habilitarInputs"
                            [InputValue]="parametros.descricao"
                            #DocumentoReferencia 
                            id="documentoReferencia-solicitacoes-ppb"
                            name="documentoReferencia-solicitacoes-ppb" 
                            (valorModificado)="parametros.idPPBDocumentoSelecionado = $event"                             
                            servico="DocumentoDeReferencia"
                            [placeholder]="habilitarInputs ? 'Selecione o documento ...' : ''"
                            [(ngModel)]="parametros.idPPBDocumentoSelecionado">
                        </app-drop-list-select2> -->
                </div>							           
            </div>
    
            <div class="row form-group">
                <div class="col-lg-3 text-nowrap">
                    <label for="codigo" >Início de Vigência: <b *ngIf="path == 'validacao' && objetoValidacao.pendenciaInicioVigencia" style="color: red">*</b></label>
                    <input 
                        #dataInicioVigencia
                        type="date"
                        id="dataInicioVigencia-solicitacoes-ppb"
                        name="dataInicioVigencia-solicitacoes-ppb"
                        min="0001-01-01" max="9999-12-31"
                        class="form-control date"
                        maxlength="10"
                        [(ngModel)]="_dataVigencia"
                        [attr.disabled]="!habilitarInputs ? true : null"
                        value="{{_dataVigencia}}"
                        (change)="transformDataVigencia()"
                    />
                </div>

                <div class="col-lg-8" *ngIf="!temArquivo">
                    <b>Anexar Arquivo <b *ngIf="path == 'validacao' && objetoValidacao.pendenciaAnexarDocumentoComprobatorio" style="color: red">*</b></b>  <b style="color: red;"> (Somente PDF! Max 10MB) </b> 
                    <div style="margin-top: -6px; margin-left: -10px;">
                        <input                         
                            (change)="onFileChange($event)"
                            accept="application/pdf" 
                            id="arquivo-solicitacoes-ppb" 
                            [attr.disabled]="!habilitarInputs ? true : null"
                            name="arquivo-solicitacoes-ppb" 
                            style="display: block; padding: 15px; width: 100%;" 
                            type="file"
                        />
                    </div>
                </div>

                <div class="col-lg-8" *ngIf="temArquivo">             
                    <b style="color:red;">Nome do arquivo: </b> {{ parametros.ppbAnexoVM.descricaoArquivo }}
                    <div>
                        <button                             
                            name="btn-download-arquivo-solicitacoes-ppb"
                            id="btn-download-arquivo-solicitacoes-ppb"
                            type="button" 
                            class="btn btn-primary-real btn-sm m-r-sm" 
                            data-toggle="tooltip" 
                            title="Baixar"
                            (click)="baixarAnexo()">
                                <i class="fa fa-download"></i>
                        </button>
                        <button 
                            [attr.disabled]="!habilitarInputs ? true : null"
                            name="btn-excluir-arquivo-solicitacoes-ppb"
                            id="btn-excluir-arquivo-solicitacoes-ppb"
                            type="button" 
                            class="btn btn-danger btn-sm" 
                            data-toggle="tooltip" 
                            title="Excluir" 
                            (click)="confirmarExclusao()">
                                <i class="fa fa-trash-o"></i>
                        </button>
                    </div>    
                </div>                
            </div>
    
            <div class="row form-group">
                <div class="col-lg-3 text-nowrap">
                    <label for="codigo" class="required">Data Publicação:</label>
                    <input 
                        #dataPublicacao
                        [attr.disabled]="!habilitarInputs ? true : null"
                        type="date"
                        id="dataPublicacao-arquivo-solicitacoes-ppb"
                        name="dataPublicacao-arquivo-solicitacoes-ppb"
                        min="0001-01-01" max="9999-12-31"
                        class="form-control date"
                        maxlength="10"
                        [(ngModel)]="_dataPublicacao"     
                        value={{_dataPublicacao}}                   
                        autocomplete="off" 
                        (change)="transformDataPublicacao()"/>
                </div>
                <div class="col-lg-9 text-nowrap" >
                    <label for="codigo">Título:</label>
                    <div>
                        <span id="descricao-titulo">
                            {{ parametros.descricaoTipoDocumento ? parametros.descricaoTipoDocumento : ''}}
                            {{ parametros.ppbVM.descricaOrgao ? parametros.ppbVM.descricaOrgao  : ''}}
                            {{ parametros.ppbVM.numeroDocumento ?  ' N° '+parametros.ppbVM.numeroDocumento+', ' : '' }}
                            {{ _dataDocumento ? mostraDataTitulo(_dataDocumento) : '' }}      
                        </span>                                       
                      
                    </div>                 
                </div>								
            </div>
    
            <div class="row form-group" class="id_100" style="margin-left: -15px;">
                <div class="col-lg-3">
                    <label for="descricao" class="required">Tipo de Documento:</label>
                        <a *ngIf="parametros.ppbVM.idTipoDocumento && habilitarInputs" class="pull-right" style="color: red; cursor: pointer;" (click)="limparTipoDocumento()">
                            Limpar
                        </a>
                        <select    
                            (change)="selectTipoDocumento($event)"
                            *ngIf="listaTipoDocumento.length"                     
                            #tipoDocumento 
                            [attr.disabled]="!habilitarInputs ? true : null"
                            class="form-control" 
                            [(ngModel)]="parametros.ppbVM.idTipoDocumento" 
                            name="tipo-de-Documento-arquivo-solicitacoes-ppb"
                            id="tipo-de-Documento-arquivo-solicitacoes-ppb">
                                <option 
                                    value="undefined" 
                                    [selected]="path === 'novo' ? true : null"
                                    disabled>
                                    Selecione uma opção ...
                                </option>
                                <option
                                    *ngFor="let item of listaTipoDocumento"
                                    [ngValue]="item.id">
                                    {{ item.descricao }}
                                </option>
                        </select> 
                </div>
    
                <div class="col-lg-2">
                    <label for="codigo" class="required">Número:</label>
                        <a *ngIf="parametros.ppbVM.numeroDocumento && habilitarInputs" class="pull-right" style="color: red; cursor: pointer;" (click)="parametros.ppbVM.numeroDocumento = ''">
                            Limpar
                        </a>
                    <input 
                        [attr.disabled]="!habilitarInputs ? true : null"
                        onlyNumber="true"
                        type="number" 
                        name="numero-documento-solicitacoes-ppb" 
                        id="numero-documento-solicitacoes-ppb" 
                        class="form-control"
                        [(ngModel)]="parametros.ppbVM.numeroDocumento"
                    />
                </div>
                <div class="col-lg-2 text-nowrap">
                    <label for="codigo" class="required">Data:</label>
                        <a *ngIf="parametros.dataDocumentoString && habilitarInputs" class="pull-right" style="color: red; cursor: pointer;" (click)="limparDataDocumento()" >
                            Limpar
                        </a>
                        <input 
                            [attr.disabled]="!habilitarInputs ? true : null"
                            #dataDocumento
                            type="date"
                            id="data-documento-solicitacoes-ppb"
                            name="data-documento-solicitacoes-ppb"
                            min="0001-01-01" max="9999-12-31"
                            class="form-control date"
                            maxlength="10"
                            [(ngModel)]="_dataDocumento"
                            value="{{ _dataDocumento }}"
                            (change)="transformDataDocumento()"
                        />
                </div>
    
                <div class="col-sm-5">
                    <label for="descricao">Órgãos do PPB:</label>
                        <a *ngIf="parametros.ppbVM.descricaOrgao && habilitarInputs" class="pull-right" style="color: red; cursor: pointer;" (click)="parametros.ppbVM.descricaOrgao = ''">
                            Limpar
                        </a>
                    <div class="input-group">
                            <input 
                                [attr.disabled]="!habilitarInputs ? true : null"
                                type="text"
                                [(ngModel)]="parametros.ppbVM.descricaOrgao" 
                                class="form-control input-sm" 
                                placeholder="n/a"
                                id="orgaos-ppb"
                                name="orgaos-ppb"
                                disabled                                 
                             />
                            <span class="input-group-btn" *ngIf="habilitarInputs">
                                <span 
                                    data-toggle="modal" 
                                    data-backdrop="static" 
                                    data-keyborad="false">
                                        <a
                                            (click)="abrirModalEscolhaOrgaos()"
                                            class="btn btn-default btn-sm" 
                                            data-toggle="tooltip" 
                                            title="Escolher Orgãos" 
                                            data-original-title="Escolher Orgãos">
                                                <i class="fa fa-check-circle-o"></i> Escolher Órgãos
                                        </a>
                                </span>                                
                            </span>
                    </div>
                </div>			  
                                                                                                
            </div>
    
            <div class="row form-group" style="margin-left: 2px;">
                <div class="row form-group">
                    <div style="margin-top: 10px;" class="col-lg-12">
                        <label>Resumo Técnico: <b *ngIf="path == 'validacao' && objetoValidacao.pendenciaResumoTecnico" style="color: red">*</b></label>
                            <textarea 
                                [(ngModel)]="parametros.ppbVM.descricaResumoTecnico"
                                class="form-control ng-pristine ng-valid ng-touched" 
                                maxlength="2000" 
                                name="resumo-tecnico-solicitacoes-ppb" 
                                id="resumo-tecnico-solicitacoes-ppb"
                                rows="5"
                                [attr.disabled]="!habilitarInputs ? true : null"
                                style="resize: none;">
                            </textarea>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-12">            
                <div class="form-group" ng-reflect-klass="form-group" ng-reflect-ng-class="[object Object]">
                    <div class="radio i-checks" style="margin-right: 1px;">
                        <label>
                            <input 
                                #radioSemPontuacao
                                (change)="validaTipoEtapa(1)"
                                [(ngModel)]="parametros.ppbVM.tipoEtapa"
                                name="change-grid-itens-sem-pontuacao" 
                                id="change-grid-itens-sem-pontuacao" 
                                type="radio" 
                                [attr.disabled]="!habilitarInputs ? true : null"
                                value="1"><i></i> Sem Pontuação
                        </label>
                        <label style="margin-left: 15px;">
                            <input 
                                #radioPorPontuacao
                                (change)="validaTipoEtapa(2)"
                                [(ngModel)]="parametros.ppbVM.tipoEtapa"
                                name="change-grid-itens-por-pontuacao" 
                                id="change-grid-itens-por-pontuacao" 
                                type="radio" 
                                [attr.disabled]="!habilitarInputs ? true : null"
                                value="2"><i></i> Por Pontuação
                        </label>
                        <label style="margin-left: 15px;">
                            <input 
                                #radioPorGrupo
                                (change)="validaTipoEtapa(3)"
                                [(ngModel)]="parametros.ppbVM.tipoEtapa"
                                name="change-grid-itens-sem-pontuacao" 
                                id="change-grid-itens-sem-pontuacao" 
                                type="radio" 
                                [attr.disabled]="!habilitarInputs ? true : null"
                                value="3"><i></i> Por Grupo      
                        </label>
                    </div>
                   
                </div>
            </div>

            <div class="row form-group" *ngIf="idPPB > 0">
                <div class="col-lg-12">

                    <app-grupos-ppb 
                        *ngIf="_isPorGrupo || (parametros.ppbVM.tipoPublicacao != FIXACAO)"
                        [(habilitarInputs)]="habilitarInputs" 
                        [(idPPB)]="idPPB"
                        [(validacao)]="objetoValidacao">
                    </app-grupos-ppb>
                   
                    <app-etapas-ppb 
                        [(dataVigenciaInicialPPB)]="_dataVigenciaParams"
                        [(_isSempontuacao)]="_isSempontuacao"
                        [(_isPorPontuacao)]="_isPorPontuacao"
                        [(_isPorGrupo)]="_isPorGrupo"
                        [(habilitarInputs)]="habilitarInputs"
                        [(idPPB)]="idPPB"
                        [(validacao)]="objetoValidacao">
                    </app-etapas-ppb>

                    <app-condicionantes-ppb 
                        [(dataVigenciaInicialPPB)]="_dataVigenciaParams"
                        [(habilitarInputs)]="habilitarInputs"
                        [(idPPB)]="idPPB"
                        [(validacao)]="objetoValidacao">
                    </app-condicionantes-ppb>    

                </div>                
            </div>     
            
            <!-- <button
                *ngIf=" path != 'novo' " 
                class="btn btn-info pull-left" 
                (click)="exportarPDF()">
                    <i class="fa fa-download"></i> Exportar PDF
            </button> -->

        </div>    

    </section>
   
</div>

<app-modal-escolher-orgaos 
    #appModalEscolherOrgaos 
    (finalizouEscolha)="changeOrgaosPPB($event)">
</app-modal-escolher-orgaos>

<app-relatorio-listagens-ppb
  *ngIf="mostraComponenteRelatorio"
  #appRelatorioConsolidado
  
  [(_isSempontuacao)]="_isSempontuacao"
  [(_isPorPontuacao)]="_isPorPontuacao"
  [(_isPorGrupo)]="_isPorGrupo"
  [(objetoExportacao)] = "objetoExportacao"
  (DownloadFinalizado)="BaixouPdf($event)">
</app-relatorio-listagens-ppb>