<div #appModalIncluirEtapasPPB class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll; overflow-y: auto;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Incluir Etapas</h4>
            </div>

            <div class="modal-body" style="overflow-y: auto;">

                <form (ngSubmit)="validar()" [formGroup]="formGroup" class="form form-horinzontal needs-validation" role="form" novalidate>

                    <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">
                        <div class="col-lg-3">
                            <label>Etapa:</label>
                            <input 
                                type="text"
                                class="form-control" 
                                id="topico-etapa" 
                                style="width: 100%;"
                                maxlength="20"
                                name="topico-etapa" 
                                formControlName="topico"
                                [(ngModel)]="parametros.descricaTopico"
                                [attr.disabled]="_flagDesabilitaTopico ? true : null"
                            />
                        </div>
                        <div class="col-lg-9">
                            <label>Descrição da Etapa:</label>
                            <textarea 
                                class="form-control" 
                                id="descricao-etapa"                            
                                name="descricao-etapa" 
                                rows="4"
                                style="resize: none;"
                                formControlName="etapa"
                                [(ngModel)]="parametros.descricaEtapa">
                            </textarea>
                        </div>
                    </div>		 
                                
                    <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">

                        <div class="col-lg-6">
                            <label for="cnpj" class="required">Período da Vigência:</label>
                            <div class="input-group" style="display: flex;">
                                <input 
                                    autocomplete="off" 
                                    class="form-control date ng-pristine ng-valid ng-touched" 
                                    id="dataInicio-etapas" 
                                    maxlength="10" 
                                    name="dataInicio-etapas" 
                                    style="width: 50%;" 
                                    type="date" 
                                    ng-reflect-maxlength="10" 
                                    ng-reflect-name="dataInicio"
                                    formControlName="dataInicial"   
                                    [(ngModel)]="parametros.dataInicioVigenciaString">
                                        <span style="padding-right: 11px; padding-top: 8px; padding-left: 10px;">a</span>
                                <input 
                                    autocomplete="off" 
                                    class="form-control date ng-pristine ng-valid ng-touched" 
                                    id="dataFim-etapas" 
                                    maxlength="10"
                                    name="dataFim-etapas" 
                                    style="width: 50%;" 
                                    type="date" 
                                    ng-reflect-maxlength="10" 
                                    ng-reflect-name="dataFim"
                                    formControlName="dataFinal"  
                                    [(ngModel)]="parametros.dataFimVigenciaString">
                            </div>
                            <div *ngIf=" _errorDataInicioNaoPreenchida " class="alert-danger">
                                <b style="margin-left: 15px;">
                                    <button type="button" class="close" (click)=" _errorDataInicioNaoPreenchida = false;"><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;"> Data Inicial é Obrigatória. </b>
                                </b>                                                   
                            </div>
                            <div *ngIf=" _errorDataFinalMaiorInicial " class="alert-danger">
                                <b style="margin-left: 15px;">
                                    <button type="button" class="close" (click)=" _errorDataFinalMaiorInicial = false; "><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;"> Data Final nao pode ser Inferior a Data Inicial. </b>
                                </b>
                            </div>           
    
                            <div *ngIf=" _errorDataFinalMenorDataCorrente " class="alert-danger">
                                <b style="margin-left: 15px;">
                                    <button type="button" class="close" (click)=" _errorDataFinalMenorDataCorrente = false; "><b style="color:black;">&times;</b></button>
                                    <b style="margin-left: 15px;"> A Data Final Não pode ser Inferior da Data Corrente! </b>
                                </b>
                            </div>  
                        </div>

                            <div class="col-lg-2" *ngIf="_isPorPontuacao">
                                <input         
                                    id="checkbox-etp-pontuacao"
                                    name="checkbox-etp-pontuacao"               
                                    formControlName="checkEmPontuacao"
                                    type="checkbox" 
                                    [(ngModel)]="_flagMostraTxtPontuacao">
                                <label for="EtapaPontuacao"> Etapa com Pontuação </label>
                            </div>

                            <div class="col-lg-3" *ngIf="_flagMostraTxtPontuacao == true">
                                <label for="codigo" >Pontuação:</label>
                                <input 
                                    onlyNumber="true" 
                                    name="valor-pontuacao-etapa" 
                                    id="valor-pontuacao-etapa"
                                    class="form-control" 
                                    formControlName="pontuacao" 
                                    placeholder="9999999"
                                    ng-reflect-maxlength="7" 
                                    maxlength="7"
                                    [(ngModel)]="parametros.valorPontuacao"
                                />
                                    <div class="alert-danger" *ngIf="_flagMostraTxtPontuacao && (!formGroup.get('pontuacao').value)">                                       
                                        <div>                                            
                                            <b style="margin-left: 15px;">Informe a Pontuação</b>
                                        </div>
                                    </div>
                            </div>  

                    </div>      
                    
                    <div class="form-group" style="margin-left: 30px; margin-top: 15px;">
                        <div *ngIf="_flagTrouxeItensGrupo">
                            <div *ngFor=" let item of parametros.gruposEtapaPPB; let i = index; " [attr.data-index]="i">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <label for="codigo" >Pontuação da Etapa no Grupo {{ parametros.gruposEtapaPPB[i].letraGrupo }}:</label> 
                                        <input 
                                            onlyNumber="true"                                           
                                            type="number"   
                                            id="valor-pontuacao-grupo-{{i}}"     
                                            name="valor-pontuacao-grupo-{{i}}"             
                                            [(ngModel)]="parametros.gruposEtapaPPB[i].totalPontuacao" 
                                            [ngModelOptions]="{standalone: true}"
                                            class=" form-control " 
                                            placeholder="9999999"
                                            maxlength="7"
                                            ng-reflect-maxlength="7"                             
                                        />                                        
                                    </div>                                   
                                </div> 
                            </div>

                        </div> 
                    </div>

                    <div class="pull-left">
                        <input           
                            id="cadastro-continuo-etapa-ppb"        
                            name="cadastro-continuo-etapa-ppb" 
                            type="checkbox" 
                            (change)="flagCadastroContinuo ? flagCadastroContinuo = false : flagCadastroContinuo = true;">
                        <label for="Pesquisa">Cadastro Contínuo</label>
                    </div>
                
                </form>                
                
            </div>
             
            <div class="modal-footer" style="padding: 10px 15px;">                
                <button
                    id="fechar-modal-etapa"
                    name="fechar-modal-etapa"
                    (click)="fechar()"
                    class="btn btn-sm btn-default" 
                    data-dismiss="modal">
                        <i class="fa fa-times"></i> Fechar
                </button>
                <button 
                    id="salvar-modal-etapa"
                    name="salvar-modal-etapa"
                    data-toggle="tooltip" 
                    [disabled]="(!formGroup.valid) || ( _flagMostraTxtPontuacao && (!formGroup.get('pontuacao').value) )"
                    title="salvar"
                    data-original-title="salvar"                        
                    (click)="validar()"
                    type="submit"
                    class="btn btn-sm btn-primary" 
                    data-dismiss="modal" 
                    data-toggle="modal" 
                    data-backdrop="static" 
                    data-keyboard="false">
                        <i class="fa fa-save m-r-xs"></i> Salvar
                </button>
            </div>

        </div>

    </div>
</div>
<div #appModalIncluirEtapasPPBBackground class="modal-backdrop fade in" style="display: none;"></div>