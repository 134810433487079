import { RequestResponseVM } from './../../../view-model/RequestResponseVM';
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "../../../shared/services/application.service";
import { MessagesService } from "../../../shared/services/messages.service";
import { ModalService } from "../../../shared/services/modal.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { ProdutoVM } from "../../../view-model/ProdutoVM";

@Component({
	selector: 'app-grid-manter-subsetor',
	templateUrl: './grid.component.html'
})

export class GridManterSubsetorComponent {

	ocultarTituloGrid = true;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,

	) {

	}

	disable : boolean =false

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;
  @Input() eUsuarioSuframa:boolean = true;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@Output() emitAtualizaListagem: EventEmitter<any> = new EventEmitter();

	tituloGrid = "Resultado da Pesquisa"
	servico = "Subsetor";

	@ViewChild("appModalJustificativaSubsetor") appModalJustificativaSubsetor: any;

	@ViewChild("appModalHistoricoSubsetor") appModalHistoricoSubsetor: any;

	changeSize($event) {
		this.onChangeSize.emit($event);
		this.changePage(this.page);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	abrirJustificativa(item){
		this.appModalJustificativaSubsetor.abrir(item);
	}
	abrirHistorico(item){
		this.appModalHistoricoSubsetor.abrir(item)
	}
	// Excluir
	excluirProduto(id: number){
			this.modal.confirmacao("Deseja realmente excluir o Produto ?").subscribe( (isConfirmado : any) =>{
			if (isConfirmado) {
				this.applicationService.delete(this.servico, id).subscribe(
					(response: RequestResponseVM) => {
						if(response.statusResponse == 200){
							this.modal.infoSucess("Operação realizada com Sucesso","Success");
              this.onChangePage.emit(this.page);
						}else{
							this.modal.infoError(response.textResponse , "Error");
							return false;
						}
					}
				);
			}
		});
	}

	atualizaListagem($event)
	{
		this.emitAtualizaListagem.emit($event);
	}

}
