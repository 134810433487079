import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { LoadingService } from "../../../../../shared/services/loading.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from "../../../../../shared/services/application.service";

@Component({
	selector: "app-etapas-ppb",
	templateUrl: "./etapas-ppb.component.html"
})

@Injectable()
export class ControleEtapasPPBComponent implements OnInit {

	gruposPPB : any ={};
	infoGrupos : any ={};
	form = this;
	path : string;
	listaAnterior : any = {};
	grid: any = { sort: {} };
	parametros: any = {};
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;
	isBuscaSalva : boolean = false;
	objetoSession : any = {} = null;
	_idRota : number;
	listaAntigaComparacao  = {};
	@Input() dataVigenciaInicialPPB : string;
	@Input() habilitarInputs: boolean;
	@Input() _isSempontuacao : boolean;
	@Input() _isPorPontuacao : boolean;
	@Input() _isPorGrupo: boolean;
	@Input() _isPPBMultiplo: boolean;
	@Input() isAlteracaoParaRevogacao : boolean;
	@Input() _fromTelaElaboracao : boolean;
	@Input() validacao : any = {} = null;
	@Input() isRetificacao : boolean;
	@Input() buscarVinculos: boolean;
	@Input() _isListarVinculados : boolean = false;
	servico = 'EtapasPPB';

	@Input() idPPB: number;
	@ViewChild('appModalIncluirEtapasPPB') appModalIncluirEtapasPPB : any;
	@ViewChild('appGridEtapasPPB') appGridEtapasPPB : any;

	constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private loadingService : LoadingService,
    private router: Router,
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idRota = Number(this.route.snapshot.params['idTipoProduto']);
	}

	ngOnInit() {
		 this.listar(this.idPPB);
	 }

	ocultar() { }

	onChangeSort($event : any)
	{
		this.grid.sort = $event;
		this.listar(this.idPPB);
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
    this.grid.page = 1;
		this.listar(this.idPPB);
	}

	async onChangePage($event : any) {
		this.grid.page = $event;
		var gridAlterado = this.validarAlteracaoGrid();
		if(await gridAlterado == 1){
			this.salvar();
		}
		this.listar(this.idPPB);
  }
  async validarAlteracaoGrid(){
	if(this.listaAntigaComparacao != null){
		var obj = {
			ListaAtualCondicionanteVM : this.grid.lista,
			ListaAntigaCondicionanteVM : this.listaAntigaComparacao
		}
		var retorno : any = await this.applicationService.post(this.servico+"/ValidarAlteracaoGrid", obj).toPromise();
		return retorno;
	}else{
		return 0;
	}
}

  salvar(){
	this.appGridEtapasPPB.salvar();
  }

	public listar(idPPB : number, getConsultaFromSessionStorage? : boolean){

		var objeto : any = {};

		objeto.idPPB = idPPB;

		if(getConsultaFromSessionStorage)
		{
			this.retornaValorSessao();
			if(this.isBuscaSalva){
				objeto.page = this.objetoSession.page || 1;
				objeto.size = this.objetoSession.size || 10;
				objeto.sort = this.objetoSession.sort || "NumeroSequencia";
				objeto.reverse = this.objetoSession.reverse || false;
			} else {
				objeto.page = 1;
				objeto.size = 10;
			}
		}
		else
		{
			objeto.page = this.grid.page || 1;
			objeto.size = this.grid.size || 10;
			objeto.sort = this.grid.sort.field || "NumeroSequencia";;
			objeto.reverse = this.grid.sort.reverse || false;
		}

		objeto.buscarVinculos = this.buscarVinculos;
		objeto.fromTelaElaboracao = this._fromTelaElaboracao;

		var vm = {
			idProduto : this._idRota,
			idPpb : idPPB,
			PPBEtapaEntityVM : objeto
		}

		let endpoint = this._isListarVinculados ? "/GetPaginadoVinculados" : "/GetPaginado" ;

		this.applicationService.get(this.servico + endpoint ,  vm).subscribe( (retorno : any) => {
			if(retorno.listagemPaginado) {
				if(this.listaAnterior.length > 0 && this.listaAnterior != null){
					this.listaAnterior.forEach(itemListaAnterior => {
						retorno.listagemPaginado.items.forEach(itemNovaLista => {
							if(itemListaAnterior.id == itemNovaLista.id && itemListaAnterior.idPPB == itemNovaLista.idPPB && itemListaAnterior.isChecked != itemNovaLista.isChecked){
								itemNovaLista.isChecked = itemListaAnterior.isChecked;
							}
						});
					});
				}
				this.gruposPPB = retorno.gruposEtapaPPB;
				this.infoGrupos = retorno.grupos;
				this.grid.lista = retorno.listagemPaginado.items;
				this.grid.total = retorno.listagemPaginado.total;
				this.listaAntigaComparacao = JSON.parse(JSON.stringify(retorno.listagemPaginado.items));
				this.gravarBusca(objeto);
			} else {
				this.grid = { sort: {} };
			}
		});
	}
	salvarListaAnterior($event){
		this.listaAnterior = $event;
	}

	incluirEtapaPPB(){

		this.appModalIncluirEtapasPPB.abrir(this.idPPB , "new");
	}

	atualizaListagem(idPPB){
		this.listar(idPPB, true);
	}

	gravarBusca(objeto : any) {
		sessionStorage.removeItem(this.router.url);
		sessionStorage.setItem("etapas-ppb-"+this.idPPB , JSON.stringify(objeto));
	}

	retornaValorSessao() {
		var router = sessionStorage.getItem("etapas-ppb-"+this.idPPB);
		if (router) {
			this.isBuscaSalva = true;
			this.objetoSession = JSON.parse(sessionStorage.getItem("etapas-ppb-"+this.idPPB));
		} else {
			this.isBuscaSalva = false;
			return null;
		}
	}


}
