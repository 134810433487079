
<div class="row form-group" style="margin-left: 2px;">

    <div>
<!--
        <div class="radio i-checks radio-sm" style="margin-right: 1px; margin-top: 10px;">
            <label class="input-sm" >
                <input
                    name="radio-todos"
                    id="radio-todos"
                    type="radio"
                    (change)="filtrarLista()"
                    [(ngModel)]="parametros.statusTipo"
                    value="3"><i></i> <b>TODOS</b>
            </label>
            <label class="input-sm" >
                <input
                    (change)="filtrarLista()"
                    name="radio-ativo"
                    id="radio-ativo"
                    type="radio"
                    [(ngModel)]="parametros.statusTipo"
                    value="1"><i></i> <b>ATIVOS</b>
            </label>
            <label class="input-sm" >
                <input
                    (change)="filtrarLista()"
                    name="radio-inativo"
                    id="radio-inativo"
                    type="radio"
                    [(ngModel)]="parametros.statusTipo"
                    value="2"><i></i> <b>INATIVOS</b>
            </label>
        </div> -->

        <div [ngClass]="(path == 'finalizar' && validacao.pendenciaTipoProduto) ? 'pendencia-validacao' : '' ">
            <a
                *ngIf="habilitarInputs"
                style="margin-right: 10px; margin-top: 5px;"
                class="btn btn-primary btn-sm pull-right"
                data-toggle="tooltip"
                title=""
                (click)="incluir()"
                data-original-title="">
                    <i class="fa fa-plus"></i> Incluir tipo
            </a>

            <app-grid-tipo-produto
                [(habilitarAlteracaoStatus)]="habilitarAlteracaoStatus"
                [(idProduto)]="id"
                (atualizaListagem)="atualizaListagem($event)"
                [(habilitarInputs)]="habilitarInputs"
                [(lista)]="grid.lista"
                [(total)]="grid.total"
                [(size)]="grid.size"
                [(page)]="grid.page"
                [(parametros)]="parametros"
                (onChangeSort)="onChangeSort($event)"
                (onChangeSize)="onChangeSize($event)"
                (onChangePage)="onChangePage($event)"
                (onChangeFiltro)="filtrarLista()"
                >
            </app-grid-tipo-produto>
        </div>
    </div>

</div>

<app-modal-manter-tipo-produto
    (atualizaListagem)="atualizaListagem($event)"
    #appModalManterTipoproduto
    [(idProduto)]="id"
    [(habilitarInputs)]="habilitarInputs">
</app-modal-manter-tipo-produto>
