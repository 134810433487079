import { Injectable, ViewChild } from '@angular/core';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { getLogoRelatorio } from '../mock/getLogoRelatorio';

export type InforFiltroRelatorioGrid = {
  label: string,
  text:string
}
@Injectable()
export class PDFService {

	constructor() {
	}

	static toExportFileName(excelFileName: string): string {
		return `${excelFileName}_listagem_${new Date().getTime()}.pdf`;
	}
  calcularMeioJsPDF(doc,text){
		return Math.ceil( (doc.internal.pageSize.getWidth() / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2) );
	}

	public exportAsPDFFile(
    columns: any[],
    rows: any[],
    width: {},
    pdfFileName: string,
    titulo: string,
		informacoesFiltro: InforFiltroRelatorioGrid[] = []): void {

		/** Orientação: landscape
		 *  Unidade: pt
		 *  Unidade: pt
		 *  formato: a4
		 * */
		let doc = new jsPDF('l', 'pt', 'a4');

		/** Título
		 * */

		//doc.addFont('Comic Sans', 'normal', 'Arial', 'Arimo');
		doc.setFont('Arial','bold');
		doc.setFontSize(10);
		doc.text("MINISTÉRIO DO DESENVOLVIMENTO, INDÚSTRIA, COMÉRIO E SERVIÇOS - MDIC", 270, 85);

		/**SubTítulo
		 * */
		doc.setFont('Arial','normal');
		doc.text("Superintendência da Zona Franca de Manaus", 340, 95);
		doc.text("SUFRAMA", 405, 105);
		doc.setTextColor(0, 60, 0);

		/**Logo
		 * */
		// #region logo
		var imgData =  getLogoRelatorio();
		// #endregion
		doc.addImage(imgData, 'JPEG', 400, 10, 60, 60); //position: x, y, | width: x, y

		/**Titulo do documento
		 * */
		doc.setFont('Arial','bold');
		doc.text(titulo, this.calcularMeioJsPDF(doc,""), 125, {align: 'center'});

    let yMark = 125;
    yMark += 20;
    informacoesFiltro.forEach((filtro)=>{
      doc.text( `${filtro.label} : ${filtro.text || "--"}`, 40, yMark);
      yMark += 20;
    })

    yMark +=20;


		/**AutoTable
		 * */
		autoTable(doc, {
			//Recebe o tamanho das colunas passado por parâmetros
			// columnStyles: width,
			body: rows,
			columns: columns,
			startY: yMark, // false (indicates margin top value) or a number
			//margin: // a number, array or object
			//pageBreak: 'auto', // 'auto', 'avoid' or 'always'
			tableWidth: 'auto', // 'auto', 'wrap' or a number,
			//showHeader: 'everyPage' // 'everyPage', 'firstPage', 'never',
			//tableLineColor: 200, // number, array (see color section below)
			//tableLineWidth: 0

		  headStyles: {
        fillColor: [0, 85, 43]
			},
			styles: {
				//cellPadding: 7, // a number, array or object (see margin below)
				//fontSize: 10,
				font: "helvetica", // helvetica, times, courier
				//lineColor: 200,
				//lineWidth: 5,
				fontStyle: 'normal', // normal, bold, italic, bolditalic
				overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
				//fillColor: false, // false for transparent or a color as described below
				//textColor: 20,
				//halign: 'left', // left, center, right
				valign: 'middle', // top, middle, bottom


			},
			columnStyles: width
		});

		//Footer

		const addFooters = doc => {
			const pageCount = doc.internal.getNumberOfPages()

			doc.setFont('helvetica', 'italic')
			doc.setFontSize(8)
			for (var i = 1; i <= pageCount; i++) {
			  doc.setPage(i)
			  doc.text('Página ' + String(i) + ' de ' + String(pageCount), //texto
			  			doc.internal.pageSize.width / 2, //largura
						  doc.internal.pageSize.height - 10, //altura
						  {align: 'center'});
			}
		  }

		  addFooters(doc);
		//EndFooter

		doc.save(PDFService.toExportFileName(pdfFileName));
	}






}
