<app-grid
    [(titulo)] ="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

    <div class="table-responsive no-margin-bottom no-border">
      <table class="table">
        <thead class="table-header-color">
            <tr>
                <th class="th-sortable unset-nowrap text-left">
                    <app-ordenacao field="numeroCodigo" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                      Código
                    </app-ordenacao>
                </th>
                <th class="th-sortable unset-nowrap text-left">
                    <app-ordenacao field="DescProduto" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                        Descrição Produto </app-ordenacao>
                </th>

            </tr>
        </thead>
        <tbody *ngIf="lista.length > 0">
            <tr *ngFor="let item of lista">
                <td class="text-left">
                    {{item.numeroCodigo}}
                </td>
                <td class="text-left">
                    {{item.descProduto}}
                </td>
            </tr>
        </tbody>
    </table>
    </div>
</app-grid>
