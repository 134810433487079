<div class="modal-dialog center">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <button [mat-dialog-close]="false" type="button" class="close"><b style="color: white;">&times;</b></button>
        <h4 class="modal-title">{{data.title || 'Informação'}}</h4>
      </div>
      <div class="modal-body text-justified">
        <p  style="color: black;" [innerHTML]="data.message"></p>
      </div>
      <div class="modal-footer" style="padding: 8px 15px;">
        <button         
            style="cursor: pointer;"                
            [mat-dialog-close]="true"
            color="primary"
            type="button" 
            class="btn btn-primary">
                <i class="fa fa-check" style="color: white;"></i> Ciente
        </button>
      </div>
    </div>
   
  </div>
</div>
<div class="modal-backdrop fade in"></div>
