import { TipoDocumentoVM } from "./TipoDocumentoVM";

export class PPBVM{
    id : number;
    dataInicioVigencia : any;
    dataPublicacao : any;
    descricaoTitulo : string;
    numeroDocumento : number;
    dataDocumento : any;
    descricaOrgao : string;
    descricaResumoTecnico : string;
    documento: string;
    idTipoDocumento : number;
    tipoEtapa : any;
    statusPPB : number;
    tipoPublicacao : number;
    tipoDocumento: TipoDocumentoVM;
}