import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IndexComponent } from "../views/index/index.component";

//Componentes PRJ - Mpbb
import { ControleSolicitacoesComponent } from '../views/manter-ppb/solicitacoes-ppb/controle-solicitacoes-ppb.component';
import { ManterProdutoPadraoComponent } from "../views/manter-produto-padrao/manter-produto-padrao.component";
import { SolicitacoesProdutoPadraoComponent } from "../views/manter-produto-padrao/solicitacoes-produto-padrao/solicitacoes-produto-padrao.component";
import { ManterNCMComponent } from "../views/manter-ncm/manter-ncm.component";
import { ManterSubsetorComponent } from "../views/manter-subsetor/manter-subsetor.component";
import { IncluirSubsetorComponent } from "../views/manter-subsetor/incluir-subsetor/incluir-subsetor.component";
import { SolicitacoesNCMComponent } from "../views/manter-ncm/solicitacoes-ncm/solicitacoes-ncm.component";
import { ManterUnidadeComponent } from "../views/manter-unidade/manter-unidade.component";
import { IncluirUnidadeComponent } from "../views/manter-unidade/incluir-unidade/incluir-unidade.component";
import { RelatorioProdutoPadronizadoComponent } from "../views/relatorio-produto-padronizado/relatorio-produto-padronizado.component";
import { RelatorioTipoProdutoPadraoComponent } from "../views/relatorio-tipo-de-produto-padrao/relatorio-tipo-de-produto-padrao";
import { ConsultarAlteracoesComponent } from "../views/consultar-alteracoes/consultar-alteracoes.component";
import { VisualizarAlteracoesComponent } from "../views/consultar-alteracoes/visualizar/visualizar-alteracoes.component";

const routes: Routes = [

  { path: "relatorio-produto-padronizado", component: RelatorioProdutoPadronizadoComponent},
  { path: "relatorio-tipos-produto-padronizado", component : RelatorioTipoProdutoPadraoComponent},
	{ path: "manter-produto-padrao", component: ManterProdutoPadraoComponent},
	{ path: "solicitacoes-produto-padrao/novo", component: SolicitacoesProdutoPadraoComponent},
	{ path: "solicitacoes-produto-padrao/:idTipoProduto",
		children : [
			{path: 'editar', component: SolicitacoesProdutoPadraoComponent},
			{path: 'visualizar', component: SolicitacoesProdutoPadraoComponent},
			{path: 'finalizar', component: SolicitacoesProdutoPadraoComponent}
		]
	},

	{ path: "manter-ncm", component: ManterNCMComponent},
	{ path: "solicitacoes-ncm/novo", component: SolicitacoesNCMComponent},
	{ path: "solicitacoes-ncm/:id",
		children : [
			{path: 'editar', component: SolicitacoesNCMComponent},
			{path: 'visualizar', component: SolicitacoesNCMComponent}
		]
	},


	{ path: "manter-subsetor", component: ManterSubsetorComponent},

	{ path: "manter-subsetor/incluir", component: IncluirSubsetorComponent},

	{ path: "manter-subsetor/:id",
		children : [
			{path :'editar',component:IncluirSubsetorComponent},
			{path :'visualizar', component :IncluirSubsetorComponent}
		]
	},

	{path: "manter-unidade", component: ManterUnidadeComponent},

	{path: "manter-unidade/incluir", component: IncluirUnidadeComponent},

	{ path: "manter-unidade/:id",
		children : [
			{path :'editar',component:IncluirUnidadeComponent},
			{path :'visualizar', component :IncluirUnidadeComponent}
		]
	},

	{ path: "consultar-alteracoes", component: ConsultarAlteracoesComponent},
	{ path: "visualizar-alteracoes/:id",
		children : [
			{path :'produto',component:VisualizarAlteracoesComponent},
			{path :'unidade', component :VisualizarAlteracoesComponent},
			{path :'subsetor',component:VisualizarAlteracoesComponent},
			{path :'ncm', component :VisualizarAlteracoesComponent}
		]
	},

	{ path: "**", redirectTo: "" }

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
