import { ProdutoPPBEntityVM } from "./ProdutoPPBEntityVM";
import {ArtigoVM} from "./ArtigoVM";

export class ProdutoPPBVM extends ProdutoPPBEntityVM{


    //**************** CAMPOS PARA TRATATIVAS DO FRONT */
    tituloPPB: string;
    count: number;
    isDocsMostrados: boolean;
    docsRelacionados: string;
    isPpbPorGrupo : boolean;
    mostrarVinculos: boolean;
    EhGrupoPontOrMultiploSemPont : boolean;
    isPpbMultiplo: boolean;
    artigos: Array<ArtigoVM> = new Array<ArtigoVM>();

}
