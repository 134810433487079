import { PagedItems } from "./PagedItems";
import { UnidadeMedidaVM } from "./UnidadeMedidaVM";

export class NcmVM extends PagedItems{
	unidadeMedida: UnidadeMedidaVM = new UnidadeMedidaVM();
    id : number;
    numeroCodigo: string;
    descricaoNcm: string;
    idUnidadeMedida: number;
    statusNcm: number;
    aliquotaII: number;
    aliquotaIPI: number;
    aliquotaIIVigenciaInicial: Date;
    aliquotaIIVigenciaFinal: Date;
    aliquotaIPIVigenciaInicial: Date;
    aliquotaIPIVigenciaFinal: Date;
    aliquotaIIVigenciaInicialString: string;
    aliquotaIIVigenciaFinalString: string;
    aliquotaIPIVigenciaInicialString: string;
    aliquotaIPIVigenciaFinalString: string;
    descricaoJustificativa: string;	
}

export class NcmAlteracoesVM{    
    ncmAntes: NcmVM = new NcmVM();
    ncmDepois: NcmVM = new NcmVM();
    numeroUsuario: string;
    nomeUsuario: string;
    dataHoraString: string;
}