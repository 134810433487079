import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { PagedItems } from "../../../../view-model/PagedItems";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { Location } from '@angular/common';
import { ListagemEtapaPPBVM } from '../../../../view-model/ListagemEtapaPPBVM';
import { ChangeListagemTipoProdutoService } from '../../../../shared/services/changeListagemTipoProduto.service';

enum EnumStatusTipo {
	ATIVO = 1 , 
	INATIVO = 2 , 
	TODOS = 3
}
@Component({
	selector: "app-tipo-produto",
	templateUrl: "./tipo-produto.component.html"
})

@Injectable()
export class TipoProdutoComponent implements OnInit {

	grid: any = { sort: {} };
	parametros: any = {};
	servico = 'TipoProduto';
    path : string;
	isBuscaSalva : boolean = false;
	objetoSession : any = {} = null;

    @Input() id: number;
	@Input() habilitarInputs : boolean;
	@Input() validacao : any = {}
	@Input() habilitarAlteracaoStatus : boolean = false;
	@Output() atualizaNcm: EventEmitter<any> = new EventEmitter(); 
	@Output() exibeNcm: EventEmitter<any> = new EventEmitter();
	@ViewChild('appModalManterTipoproduto') appModalManterTipoproduto : any;

	constructor(
		private ChangeListagemTipoProdutoService : ChangeListagemTipoProdutoService,
        private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private msg: MessagesService,
        private validation: ValidationService,
        private router: Router,
        private _location: Location
	) { 
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
	}

	ngOnInit() { 
		this.listar(true);

		this.ChangeListagemTipoProdutoService.AtualizaListagemGrupoPPB.subscribe(
			getSessionStorage => this.listar(getSessionStorage)
		);
	}

	ocultar() { }

	onChangeSort($event : any) 
	{
		this.grid.sort = $event;
		this.listar(false);
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
		this.listar(false);
	}

	onChangePage($event : any) {
		this.grid.page = $event;
		this.listar(false);
    }

	filtrarLista(){
		this.listar();
	}

	listar(getConsultaFromSessionStorage? : boolean){

        let objeto : any = {};
		objeto.idProduto = this.id;

		if(getConsultaFromSessionStorage)
		{			
			this.retornaValorSessao();
			if(this.isBuscaSalva){				
				objeto.page = this.objetoSession.page ? this.objetoSession.page : 1,
				objeto.size = this.objetoSession.size ? this.objetoSession.size : 10,
				objeto.sort = this.objetoSession.sort ? this.objetoSession.sort.field : undefined,
				objeto.reverse = this.objetoSession.reverse ? this.objetoSession.reverse : undefined,
				objeto.statusTipo = this.objetoSession.statusTipo ? this.objetoSession.statusTipo : undefined,
				
				this.parametros.statusTipo = this.objetoSession.statusTipo ? this.objetoSession.statusTipo : 3
			} else {
				objeto.page = 1;
				objeto.size = 10;
				this.parametros.statusTipo = '3';
			}
		} 		
		else 
		{			
			objeto.page = this.grid.page ? this.grid.page : 1;
			objeto.size = this.grid.size ? this.grid.size : 10;
			objeto.sort = this.grid.sort.field;
			objeto.reverse = this.grid.sort.reverse;
			if(this.parametros.statusTipo == EnumStatusTipo.ATIVO || this.parametros.statusTipo == EnumStatusTipo.INATIVO){
				objeto.statusTipo = this.parametros.statusTipo;
			};
		}		
		//this.grid = { sort: {} };

		this.applicationService.get(this.servico+"/GetPaginado", objeto).subscribe((retorno : PagedItems) => {				
			if(retorno){
				this.grid.lista = retorno.items;
				this.grid.total = retorno.total;
				this.gravarBusca(objeto);
			} else {
				this.grid = { sort: {} };
			}
		});
	}

	incluir(){
		this.appModalManterTipoproduto.abrir("new");
	}

	atualizaListagem($event : boolean){
		this.listar($event);
	}

	exibeNcms($event){

	}

	gravarBusca(objeto : any) {
		sessionStorage.removeItem("tipo-produto-"+this.id);
		sessionStorage.setItem("tipo-produto-"+this.id , JSON.stringify(objeto));
	}

	retornaValorSessao() {
		var router = sessionStorage.getItem("tipo-produto-"+this.id); 
		if (router) {		
			this.isBuscaSalva = true;
			this.objetoSession = JSON.parse(sessionStorage.getItem("tipo-produto-"+this.id));
		} else {
			this.isBuscaSalva = false;
			return null;
		}
	}

}