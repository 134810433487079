<!-- /194553192/01_suframa -->

<section class="vbox">
	<header class="bg-primary header header-md navbar navbar-fixed-top-xs box-shadow">
		<div class="navbar-header aside-md bg-white">
			<a class="btn btn-link visible-xs" data-toggle-class="nav-off-screen" data-target="#nav">
				<i class="fa fa-bars"></i>
			</a>
			<a href="#" class="navbar-brand text-center">
				<figure class="inline m-r-xs">
					<img src="../../assets/images/logo-login.png" alt="SUFRAMA">
				</figure>
			</a>
			<a class="btn btn-link visible-xs" data-toggle="dropdown" data-target=".user">
				<i class="fa fa-cog"></i>
			</a>
		</div>

		<ul class="nav navbar-nav hidden-xs">
			<li>
				<a href="#">PRJ - Mcpp</a>
			</li>
		</ul>

		<div *ngIf="isExibirRepresentante">
			<ul  class="nav navbar-nav hidden-xs">
				<li>
					<label class="text-info-dk" style="padding-left: 15px; padding-top: 15px; padding-bottom: 5px; color:#fdc302">Empresa
						Representada:</label>
					<label class="text-info-dk" style="padding-left: 15px; padding-top: 15px; padding-bottom: 5px; color:white">{{username}}
						| {{razaoSocial}} </label>
				</li>
			</ul>
		</div>

		<ul class="nav navbar-nav navbar-right m-n hidden-xs nav-user user">
			<li class="hidden-sm">
				<label class="text-info-dk" style="padding-left: 15px; padding-top: 15px; padding-bottom: 5px; color:#fdc302; font-weight: normal;">
					<!-- PRODUÇÃO -->
          <!--v1.3.0-->
          <!-- HOMOLOGAÇÃO -->
          v1.4.1
				</label>
			</li>
			<li>
				<a
					(click)="logout()"
					style="cursor: pointer;">
					<span class="hidden-sm">
						Sair
					</span>
					<i class="i i-logout"></i>
				</a>
			</li>
		</ul>
	</header>

	<section>
		<section class="hbox stretch">
			<aside class="bg-white aside-md hidden-print" id="nav" [ngClass]="{'nav-xs': isSidebar}">
				<section class="vbox">
					<section class="w-f scrollable">
						<app-menu></app-menu>
					</section>

					<footer class="footer hidden-xs no-padder text-center-nav-xs">
						<a
							(click)="logout()"
							class="btn btn-icon icon-muted btn-inactive pull-right m-l-xs m-r-xs hidden-nav-xs"
							data-toggle="tooltip"
						 	title="Sair"
							style="cursor: pointer;">
								<i class="i i-logout"></i>
						</a>
					</footer>
				</section>
			</aside>

			<section id="content">
				<section class="hbox stretch">
					<section>
						<section class="vbox">
							<section class="scrollable">
								<router-outlet></router-outlet>
							</section>
						</section>
					</section>
				</section>
				<a href="#" class="hide nav-off-screen-block" data-toggle-class="nav-off-screen" data-target="#nav"></a>
			</section>
		</section>
	</section>
</section>
