import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../shared/services/application.service";
import { MessagesService } from "../../shared/services/messages.service";
import { ModalService } from "../../shared/services/modal.service";
import { ValidationService } from "../../shared/services/validation.service";
import { PagedItems } from "../../view-model/PagedItems";
import { NcmEntityVM } from "../../view-model/NcmEntityVM";
import { ChangeIniciaPaginacao } from "../../shared/services/changeIniciaPaginacao.service";
import { InforFiltroRelatorioGrid } from "../../shared/services/pdf.service";
import { LocalStorageFiltroPesquisaService, StoragePesquisaPaginasEnum } from "../../shared/services/localStorageFiltroPesquisa.service";

declare var html2pdf: any;

@Component({
  selector: 'app-manter-ncm',
  templateUrl: './manter-ncm.component.html',
})
@Injectable()
export class ManterNCMComponent implements OnInit {
  form = this;
  grid: any = { sort: {field:"NumeroCodigo"} };
  parametros: NcmEntityVM = new NcmEntityVM();
  ocultarFiltros: boolean = false;
  flagAlterouFiltro: boolean = false;
  isBuscaSalva: boolean = false;
  servico = 'ManterNcm';
  objetoConsulta: any = ({} = null);
  eUsuarioSuframa: boolean;

  listaUnidadeDeMedida = new Array<any>();

  @ViewChild('relatorioPDF') htmlData: ElementRef;

  constructor(
    private changeIniciaPaginacao: ChangeIniciaPaginacao,
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private modal: ModalService,
    private msg: MessagesService,
    private router: Router,
    private route: ActivatedRoute,
    private validation: ValidationService,
    private localStorageFiltroPesquisas: LocalStorageFiltroPesquisaService
  ) {
    if (
      sessionStorage.getItem(this.router.url) == null &&
      sessionStorage.length > 0
    ) {
      sessionStorage.clear();
    }
    this.eUsuarioSuframa = JSON.parse(localStorage.getItem('usuarioSuframa'));
  }

  ngOnInit() {
    // this.parametros.statusNcm = 99;
    // this.buscar();
    this.buscarInformacoesStorage();
  }

  buscarInformacoesStorage() {
    const key = StoragePesquisaPaginasEnum.CodigoNcm;
    this.localStorageFiltroPesquisas.limparStorageExceto(key);
    const storage = this.localStorageFiltroPesquisas.obter(key);
    if (
      storage &&
      (storage.numeroCodigo != null ||
        storage.descricaoNcm != null ||
        storage.statusNcm != null ||
        storage.idUnidadeMedida != null)
    ) {
      this.parametros = storage;
      this.buscar();
    }
  }

  onChangeSort($event: any) {
    this.grid.sort = $event;
    this.buscar();
  }

  onChangeSize($event: any) {
    this.grid.size = $event;
    this.buscar();
  }

  onChangePage($event: any) {
    this.grid.page = $event;
    this.buscar();
  }

  criarNovo() {
    this.modal
      .confirmacao('Deseja Cadastrar um Novo NCM?')
      .subscribe((isConfirmado: any) => {
        if (isConfirmado) {
          this.router.navigate(['/solicitacoes-ncm/novo']);
        }
      });
  }

  salvarDadoFiltroNoStorage(){
    let salvar = false;
    if((this.parametros.numeroCodigo || "").length){
      salvar = true;
    }
    if((this.parametros.descricaoNcm ||"").length){
      salvar = true;
    }
    if(this.parametros.statusNcm != 99){
      salvar = true;
    }
    if(this.parametros.idUnidadeMedida){
      salvar = true;
    }
    if(salvar){
      this.localStorageFiltroPesquisas.salvar(StoragePesquisaPaginasEnum.CodigoNcm,this.parametros);
    }

  }

  buscar() {
    if (this.flagAlterouFiltro) {
      this.parametros.page = 1;
      this.parametros.size = 10;
      this.grid.page = 1;
      this.grid.size = 10;

      //reinicia a paginacao dentro de app-grid
      this.changeIniciaPaginacao.Reinicia(true);
    } else {
      this.parametros.page = this.grid.page;
      this.parametros.size = this.grid.size;
    }
    this.parametros.sort = this.grid.sort.field;
    this.parametros.reverse = this.grid.sort.reverse;
    this.flagAlterouFiltro = false;
    this.applicationService
      .get(this.servico, this.parametros)
      .subscribe((response: PagedItems) => {
        if (response.total != null && response.total > 0) {
          this.salvarDadoFiltroNoStorage();
          this.prencheParametrosDeExportacao();
          this.grid.lista = response.items;
          this.grid.total = response.total;
        } else {
          this.grid = { sort: {} };
          this.modal.infoError('Nenhum Registro Encontrado', 'Informação');
        }
      });
  }

  limpar() {
    this.parametros = new NcmEntityVM();
    this.parametros.statusNcm = 99;
    this.grid = { sort: {} };
  }

  obterStatusFiltroParaRelatorio() {
    if (this.parametros.statusNcm) {
      switch (Number.parseInt(this.parametros.statusNcm.toString())) {
        case 99:
          return 'Todos';
        case 1:
          return 'Ativas';
        case 0:
          return 'Inativas';
        default:
          return null;
      }
    }
    return null;
  }
  obterUnidadeMedidaFiltroParaRelatorio() {
    let unidadeMedidaDs = $('#unidade option:selected').text();
    if (unidadeMedidaDs.includes('Selecione uma opção')) {
      return null;
    }
    return unidadeMedidaDs;
  }

  prencheParametrosDeExportacao() {
    this.parametros.exportarListagem = true;
    this.parametros.exportarPdf = true;
    this.parametros.servico = this.servico;
    this.parametros.page = 1;
    this.parametros.size = 10000000;
    this.parametros.sort = this.grid.sort.field;
    this.parametros.titulo = 'Relatório de NCM';

    this.parametros.informacoesExtrasRelatorio = [
      {
        label: 'Código',
        text: this.parametros.numeroCodigo,
      },
      {
        label: 'Nomenclatura',
        text: this.parametros.descricaoNcm,
      },
      {
        label: 'Situação',
        text: this.obterStatusFiltroParaRelatorio(),
      },
      {
        label: 'Unidade de Medida',
        text: this.obterUnidadeMedidaFiltroParaRelatorio(),
      },
    ] as Array<InforFiltroRelatorioGrid>;

    this.parametros.columns = [
      'Código',
      'Descrição',
      'Unidade',
      'Alíquota II',
      'Data Início',
      'Data Final',
      'Alícota IPI',
      'Data Início',
      'Data Final',
      'Situação',
    ];

    this.parametros.fields = [
      'numeroCodigo',
      'descricaoNcm',
      'unidadeMedida.Descricao',
      'aliquotaIIString',
      'aliquotaIIVigenciaInicialString',
      'aliquotaIIVigenciaFinalString',
      'aliquotaIPIString',
      'aliquotaIPIVigenciaInicialString',
      'aliquotaIPIVigenciaFinalString',
      'descSituacao',
    ];

    this.parametros.width = {
      0: { cellWidth: 60 },
      1: { cellWidth: 220 },
      2: { cellWidth: 50 },
      3: { cellWidth: 60 },
      4: { cellWidth: 70 },
      5: { cellWidth: 70 },
      6: { cellWidth: 70 },
      7: { cellWidth: 70 },
      8: { cellWidth: 70 },
      9: { cellWidth: 70 },
    };
  }
}
