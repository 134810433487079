import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { ApplicationService } from '../../../shared/services/application.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as html2pdf from 'html2pdf.js';
import { PDFService } from '../../../shared/services/pdf.service';
import { pdfServiceProdutoPadrao } from '../../../shared/services/pdfRelatorioProduto.service';

@Component({
	selector: 'app-emitir-relatorio-produto-padronizado',
	templateUrl: './emitir-relatorio-produto-padronizado.component.html',
})

@Injectable()
export class EmitirRelatorioProdutoPadronizadoComponent implements OnInit {

    @Input() _isSempontuacao : boolean;
	@Input() _isPorPontuacao : boolean;
	@Input() _isPorGrupo: boolean;
	@Input() objetoExportacao: any = {};
  @Input() parametrosExportacao: any = {};
	@Output() DownloadFinalizado: EventEmitter<any> = new EventEmitter();
  parametrosFormatado: any = {};
  listaProdutoPadrao: any = {};
	terminouConsulta : boolean = true;
	AtivaRelatorio : boolean = true;
  esconderRelatorio : boolean = true;
    config: AngularEditorConfig = {
		minHeight: '100px',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};
	constructor(
		private applicationService: ApplicationService,
    private pdfService : PDFService,
    private pdfServiceProdutoPadrao : pdfServiceProdutoPadrao

	) { }

	ngOnInit() {

  }

  start(){

    setTimeout(() => {
      // this.exportPDF();
      this.gerarRelatorio();
  }, 1200);
  }

	public PreencherParametros(){
    this.parametrosFormatado = this.parametrosExportacao;
    this.parametrosExportacao.idCoeficienteReducao == 99 ? this.parametrosFormatado.coeficienteReducao ="Todos":
    this.parametrosExportacao.idCoeficienteReducao == 1 ? this.parametrosFormatado.coeficienteReducao ="CRA":
    this.parametrosExportacao.idCoeficienteReducao == 2 ? this.parametrosFormatado.coeficienteReducao ="88%":
    this.parametrosExportacao.idCoeficienteReducao == 3 ? this.parametrosFormatado.coeficienteReducao ="CRA+5%":
    this.parametrosExportacao.idCoeficienteReducao == 4 ? this.parametrosFormatado.coeficienteReducao ="Sem redução%":"-";

    this.parametrosExportacao.sitComponente == 99 ? this.parametrosFormatado.situacaoComponente = "Todos":
    this.parametrosExportacao.sitComponente == 0 ? this.parametrosFormatado.situacaoComponente = "Não":
    this.parametrosExportacao.sitComponente == 1 ? this.parametrosFormatado.situacaoComponente = "Sim":"-";

    this.parametrosExportacao.sitBemInformativa == 99 ? this.parametrosFormatado.situacaoBemInformativa = "Todos":
    this.parametrosExportacao.sitBemInformativa == 0 ? this.parametrosFormatado.situacaoBemInformativa = "Não":
    this.parametrosExportacao.sitBemInformativa == 1 ? this.parametrosFormatado.situacaoBemInformativa = "Sim":"-";

    this.parametrosExportacao.sitProduto == 99 ? this.parametrosFormatado.situacaoProduto = "Todos":
    this.parametrosExportacao.sitProduto == 2 ? this.parametrosFormatado.situacaoProduto = "Ativo":
    this.parametrosExportacao.sitProduto == 3 ? this.parametrosFormatado.situacaoProduto = "Inativo":"-";

		this.AtivaRelatorio = false;
	}

  gerarRelatorio()
  {
    this.prencheParametrosDeExportacao();
    this.PreencherParametros();
    var lista = this.objetoExportacao;
    let rows = Array<any>();
    var file = window.location.href.split("#")[1].replace("/", "");
    for (var i = 0; i < lista.length; i++)
    {
      let r = Array<any>();
      let valor: any; for (var j = 0; j < this.objetoExportacao.fields.length; j++) {
        var item = this.objetoExportacao.fields[j].split("|"); valor = item.length > 0 ? Object.values(lista)[i][item[0].trim()] : Object.values(lista)[i][this.objetoExportacao.fields[j].trim()];
        if (this.objetoExportacao.fields[j].trim() == "sitProduto")
          r[j] = (valor == 2 ? "ATIVO" : "INATIVO");
        else {
          r[j] = valor;
        }
      }
      rows.push(r);
    }


    this.pdfServiceProdutoPadrao.exportAsPDFFileProdutoPadrao(this.parametrosFormatado ,this.objetoExportacao.columns, rows, this.objetoExportacao.width, file,);
    this.DownloadFinalizado.emit(true);
    this.AtivaRelatorio = true;
  }

  prencheParametrosDeExportacao()
	{
		this.objetoExportacao.columns = [
									"Código",
									"Produto",
									"Situação",
		];
		this.objetoExportacao.fields = [
									"numeroCodigo",
									"descProduto",
                  "sitProduto"
		];
		this.objetoExportacao.width = {
									0: { cellWidth: 100 },
									1: { cellWidth: 550 },
									2: { cellWidth: 100 },
		};
	}















  exportPDF(){
    this.objetoExportacao;
    this.parametrosExportacao;
		new Promise<void>(resolve=>{
			resolve();
		}).then(()=>{

			const elements = document.getElementById('conteudoProdutoPadronizadoPdf');

			const options = {
				margin: [0.6, 0.03, 0.7, 0.03], // [top, left, bottom, right]
				filename: 'RELATÓRIO CONSOLIDADO DO CREDENCIAMENTO.pdf',
				image: { type: 'jpeg', quality: 1 },
				html2canvas: {
					scale: 2,
					dpi: 300,
					letterRendering: true,
					useCORS: true
				},
				jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
				pagebreak : { after: ['#grid'] }
			};
      html2pdf().from(elements).set(options).toPdf().get('pdf').then(function (pdf) {
				var totalPages = pdf.internal.getNumberOfPages();

				for (var i = 1; i <= totalPages; i++) {
					pdf.setPage(i);
					pdf.setFontSize(10);
					pdf.setTextColor(150);

					var dateObj = new Date();
					var month = dateObj.getUTCMonth().toString().length <= 1 ? '0' + (dateObj.getUTCMonth() + 1).toString() : dateObj.getUTCMonth() + 1;
					var day = dateObj.getUTCDate();
					var year = dateObj.getUTCFullYear();

					var hh = dateObj.getHours();
					var mm = dateObj.getMinutes();
					var ss = dateObj.getSeconds();

					var newhr = hh + ":" + mm + ":" + ss;

					var newdate = day + "/" + month + "/" + year;

					pdf.text('Data/Hora de Emissão: ' + newdate + " " + newhr
						+ '                                                                                                           Página ' + i + ' de ' + (totalPages), .2, 11.5);
				}
			}).save();


		}).then(()=>{
			setTimeout(() => {
				this.AtivaRelatorio = true;
				this.DownloadFinalizado.emit(true);
			}, 800);
		});
	}
}
