import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { PagedItems } from "../../../../view-model/PagedItems";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { Location } from '@angular/common';
import { CondicionanteVM } from "../../../../view-model/CondicionanteVM";


@Component({
	selector: "app-condicionantes-ppb",
	templateUrl: "./condicionantes-ppb.component.html"
})
@Injectable()

export class CondicionantesPPBComponent implements OnInit {

	form = this;
	grid: any = { sort: {} };
	parametros: CondicionanteVM = new CondicionanteVM();
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;	
	path: string;
	servico = "CondicionantesPPB";

	@Input() dataVigenciaInicialPPB : string;
	@Input() habilitarInputs: boolean;
	@Input() idPPB: number;
	@Input() validacao : any = {};

	@ViewChild('appModalIncluirCondicionantesPPB') appModalIncluirCondicionantesPPB : any;

	constructor(
        private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private msg: MessagesService,
        private validation: ValidationService,
        private router: Router,
        private _location: Location
	) { 
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
	}

	ngOnInit() { 
		this.listar(this.idPPB);
	}

	ocultar() { }

	onChangeSort($event : any) {
		this.grid.sort = $event;
		this.listar(this.idPPB);
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
		this.listar(this.idPPB);
	}

	onChangePage($event : any) {
		this.grid.page = $event;
		this.listar(this.idPPB);
    }

    Voltar(){
    }

	adicionarCondicionantes(){
		this.appModalIncluirCondicionantesPPB.abrir(this.idPPB, "new");
	}

	atualizaListagem($event){
        this.listar($event);
	}

	listar(idPPB : number){

        let objeto : CondicionanteVM = new CondicionanteVM();
		objeto.idPPB = idPPB;
		objeto.page = this.grid.page ? this.grid.page : 1;
        objeto.size = this.grid.size ? this.grid.size : 10;
        objeto.sort = this.grid.sort.field;
        objeto.reverse = this.grid.sort.reverse;
		
		this.applicationService.get(this.servico+"/GetPaginado", objeto).subscribe((retorno : PagedItems) => {				
			if(retorno.total > 0){
				this.grid.lista = retorno.items;
				this.grid.total = retorno.total;
			} else {
				this.grid = { sort: {} };
			}
		});
	}

}