import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class ChangeEtapaPPBService {

    AtualizaListagemEtapaPPB : EventEmitter<any> = new EventEmitter()

	constructor() {
	}

	public atualizaListagemPPB(idPPB : any) {
		this.AtualizaListagemEtapaPPB.emit(idPPB);
	}

    //ASSISTA https://www.youtube.com/watch?v=R9afVKty3Dg&ab_channel=LoianeGroner

}
