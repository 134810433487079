<div class="row form-group" style="margin-left: 2px;">

    <div>
        <app-grid-condicionantes-ppb
            [(idPPB)]="idPPB"
            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
            [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
            (atualizaListagemGrid)="atualizaListagem($event)"
            [(habilitarInputs)]="habilitarInputs"
            [(lista)]="grid.lista"
            [(listaAntigaComparacao)]="listaAntigaComparacao"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)"
            (listaAnterior)="salvarListaAnterior($event)"
            [(isRetificacao)]="isRetificacao"
            [_exibirCheck]="!_isListarVinculados"
            #appGridCondicionantesPPB>
        </app-grid-condicionantes-ppb>
    </div>
</div>
