import { Component, OnInit, ViewEncapsulation, Input,ViewChild, Output, EventEmitter } from "@angular/core";
import { ApplicationService } from "../shared/services/application.service";
import { AuthenticationService } from "../shared/services/authentication.service";
import { MenuComponent } from "./menu/menu.component";
import { environment } from '../../environments/environment';
import { EnumPerfil } from '../shared/enums/EnumPerfil';
import { ModalService } from "../shared/services/modal.service";


@Component({
	selector: "app-layout",
	templateUrl: "./layout.component.html"
})
export class LayoutComponent implements OnInit {
	versao!: string;
	isSidebar = false;
	username!: string;
	razaoSocial!: string;
	logoutUrl!: string;
	isUsuarioInterno = false;
	isExibirRepresentante: boolean = false;

	isUsuarioSuframa = false;

	@ViewChild(MenuComponent) menutt!:MenuComponent

	@Input()
	authenticated!: boolean;

	@Input()
	intranet!: boolean;

	@Output() changeUserAutentication: EventEmitter<any> = new EventEmitter();

	constructor(//
		private applicationService: ApplicationService,
		private authenticationService: AuthenticationService,
		private menu: MenuComponent,
		private modal : ModalService
	) {}

	ngOnInit() {
		this.logoutUrl = environment.logout
		this.applicationService.get("UsuarioLogado").subscribe((result: any) => {
			if(result.usuCpfCnpj.length == 11) { // É CPF
				this.username = result.usuCpfCnpj;
				this.razaoSocial = result.usuNomeVistoriador;
				this.isUsuarioInterno = true;
        this.isUsuarioSuframa = true;
        localStorage.setItem("usuarioSuframa","true");
			}
			else if(result.usuCpfCnpj.length == 14) { // É UM CNPJ
				this.username = result.usuCpfCnpj;
				this.razaoSocial = result.usuNomeVistoriador;
				this.isUsuarioInterno = false;
        this.isUsuarioSuframa = true;
        localStorage.setItem("usuarioSuframa","false");
			} else {
				this.username = result.usuCpfCnpj;
				this.razaoSocial = result.usuNomeVistoriador;
				this.isUsuarioInterno = false;
			}

		});
		document.body.style.backgroundImage = ""; // remover o fundo verde do login
	}

	logout(){
		this.modal.confirmacao("Deseja Sair?").subscribe(
			(isConfirmado : any)=>{
				if(isConfirmado){
					this.applicationService.removeToken();
          localStorage.clear();
					this.authenticated = false;
					this.changeUserAutentication.emit(false);
				}
			}
		);
	}

	/*
	logout(){

		this.applicationService.logout().subscribe((result: any) => {
			window.location.replace(this.logoutUrl);
		});

		this.applicationService.removeToken();
		this.authenticated = false
		window.location.replace(this.logoutUrl);
	} */

	onLogon() {
		this.authenticated = true;
	}

	recarregarLayout(){
		this.applicationService.get("UsuarioLogado").subscribe((result: any) => {
			if(result.usuCpfCnpj != result.usuCpfRepresentanteLogado){
				this.username = result.usuCpfCnpj;
				this.razaoSocial = result.usuNomeVistoriador;
				this.isExibirRepresentante = true;
			}else{
				this.isExibirRepresentante = false;
			}

			if (result &&
					result.perfis.includes(EnumPerfil.coordenadorCovis) ||
					result.perfis.includes(EnumPerfil.coordenadorCodoc) ||
					result.perfis.includes(EnumPerfil.coordenadorDescentralizado) ||
					result.perfis.includes(EnumPerfil.coordenadorGeral) ||
					result.perfis.includes(EnumPerfil.vistoriador) ||
					result.perfis.includes(EnumPerfil.superintendenteAdjunto)) {
						this.isUsuarioSuframa = true;
					}
					else {
						this.isUsuarioSuframa = false;
					}
		});
		this.menutt.recuperarMenu();
	}

}
