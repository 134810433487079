import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../../shared/services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ValidationService } from '../../../../../../shared/services/validation.service';

enum EnumControllPPB{
	OK = 1,
	ERR_DATA_VIGENCIA_VENCIDA = 2,
	ERR_EXISTE_COPIA_EM_ANDAMENTO = 3,
	ERR_ITEM_MARCADO_COMO_EXCLUIDO = 4
}

@Component({
	selector: 'app-grid-condicionantes-ppb',
	templateUrl: './grid.component.html'
})

export class GridCondicionantesPPBComponent {

	ocultarTituloGrid = true;
	path : string;
	flagHabilitaComponenteTextual : boolean = false;
	_idRota : number;
	constructor(
		private validation : ValidationService,
		private route: ActivatedRoute,
		private modal : ModalService,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private router: Router,
		//private ordenarGrid: OrderGrid
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idRota = Number(this.route.snapshot.params['idTipoProduto']);
	}

	_idPPBRota : number;

	@Input() dataVigenciaInicialPPB : string;
	@Input() lista: any = {};
	@Input() listaAntigaComparacao: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() habilitarInputs: boolean;
	@Input() exportarListagem!: boolean;
	@Input() isAlteracaoParaRevogacao : boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() idPPB: any;
	@Input() isRetificacao : boolean;

	@Input()_exibirCheck : boolean = true;

	@Input() isUsuarioInterno: boolean = false;
	@Output() listaAnterior : EventEmitter<any[]> = new EventEmitter();
	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() atualizaListagemGrid: EventEmitter<any> = new EventEmitter();

	servico = "CondicionantesPPB";
	tituloGrid = 'Condicionantes do PPB';

	config: AngularEditorConfig = {
		height: '200px',
		minHeight: '100px',
		width: '100%',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};

	changeSize($event : any) {
		this.listaAnterior.emit(this.lista);
		this.onChangeSize.emit($event);
	}

	changeSort($event : any) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event : any) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	atualizaListagem($event){
		this.atualizaListagemGrid.emit($event);
	}


  validacaoIsRetificacao(item : any) : boolean{
    if(this.isRetificacao){
      if(item.idPPB == this.idPPB){
        return true
      }
      else{
        return false
      };
    }
    return true;
  }

  async salvar(){
	var obj = {
		ListCondicionanteVM : this.lista,
		IdPpb : this.idPPB,
		IdProduto : this._idRota
	}
	if(this.lista.length > 0 && this.lista != null){
        await this.applicationService.post(this.servico, obj).toPromise();
      };
  }

}
