export const environment = {
  production: false,
  developmentMode: false,
  serviceUrl: "https://appmcppprj.hom.suframa.gov.br/",
  captchaSiteKey: "6LcS10AUAAAAALMez324a5YeL8MEC9PVcDubeWvB",
  menuPssUrl: "https://appmcppprj.hom.suframa.gov.br/Menu",
  pssUrl: "https://pss.hom.suframa.gov.br/cas/login?service=https://mcppprj.hom.suframa.gov.br",
  mppbUrl: "https://appmcppprj.hom.suframa.gov.br/sessao",
  logout: "https://appmcppprj.hom.suframa.gov.br/logout",
  psslogout: "https://pss.hom.suframa.gov.br/backend-pss-autorizacao/rest/usuario/logout",
  prjGeral: "https://appgeralprj.hom.suframa.gov.br",
  identificadorModuloPRJ: 2 //MÓDULO MCPP
};
