<div class="row form-group" style="margin-left: 2px;">	
 
    <div class="row form-group" *ngIf="habilitarInputs">
        <div class="col-lg-12">
            <a 
                style="margin-right: 10px; margin-bottom: -10px;"
                class="btn btn-primary btn-sm pull-right" 
                data-toggle="tooltip" 
                title="" 
                (click)="incluirGrupoPPB()"
                data-original-title="">
                    <i class="fa fa-plus"></i> Incluir na Lista
            </a>
        </div>
    </div>

    <div [ngClass]="(path == 'validacao' && validacao.pendenciaGrupoPPB) ? 'pendencia-validacao' : '' ">
        <!-- <b *ngIf="path == 'validacao' && validacao.pendenciaGrupoPPB" style="color: red">*</b>    -->

        <app-grid-grupos-ppb
            [(idPPB)]="idPPB"
            [(habilitarInputs)]="habilitarInputs"
            (atualizaListagemGrid)="atualizaListagem($event)" 
            [(lista)]="grid.lista"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)">
        </app-grid-grupos-ppb>   

    </div>

</div>

<app-modal-incluir-grupos-ppb    
    [(idPPB)]="idPPB"
    (atualizaListagem)="atualizaListagem($event)" 
    #appModalIncluirGruposPPB
    [(habilitarInputs)]="habilitarInputs">
</app-modal-incluir-grupos-ppb>