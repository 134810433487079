import { PagedItems } from './PagedItems';

export class ProdutoTipoVM extends PagedItems{
    produto : any = {}
    id : number;
    idProduto : number;
    numeroCodigo : string;
    descricao : string;
    descProduto : string;
    tipoAgregacao : any;
    statusTipo : any= null;
    statusVendaDireta : any;
    idSetor : any;
    subSetor : any = {};
    statusNCM: any;
    descricaoJustificativa : string;
    tipoBuscaProdutoDropdown : number
}
