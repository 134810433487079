import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from "../../../../shared/services/application.service";
import { PagedItems } from '../../../../view-model/PagedItems';

@Component({
	selector: 'app-modal-historico',
	templateUrl: './modal-historico.component.html'
})

export class ModalHistoricoComponent implements OnInit {

	//----GRID ITENS-----
	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	grid: any = { sort: {} };
	tituloGrid = "Histórico Produto Padronizado"
	//--------------------a

	_flagCampoVazio : boolean = false;
	_historico : string = "";
	parametros: any = {};
	servico = "ProdutoHistorico";

	@Input() data: any = {} = null;
	@Output() changeOperation: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalHistorico') appModalHistorico : any;
	@ViewChild('appModalHistoricoBackground') appModalHistoricoBackground : any;
	@ViewChild('btnRelatorio') btnRelatorio : any;


	@ViewChild('close') close!: ElementRef;
	@ViewChild('ok') ok!: ElementRef;

	constructor(
		private applicationService : ApplicationService
	) {
	}

	ngOnInit() {

	}

	onChangeSort($event: any) {
		this.grid.sort = $event;
		this.listar();
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
		this.listar();
	}

	onChangePage($event: any) {
		this.grid.page = $event;
		this.listar();
	}

	abrir(objeto : any){
		this.data = objeto;

		this.listar();
		this.appModalHistoricoBackground.nativeElement.style.display = 'block';
		this.appModalHistorico.nativeElement.style.display = 'block';
	}

	fechar(){
		this.data = null;
		this.lista = {}
		this.grid = { sort: {} }

		this.size = 10;
		this.page = 1;

		this.grid.page = 1;
		this.grid.size = 10;

		this.parametros = {};

		this.total = null;
		this.appModalHistoricoBackground.nativeElement.style.display = 'none';
		this.appModalHistorico.nativeElement.style.display = 'none';
	}

	listar(){
		this.parametros.idProduto = this.data.id;
		this.parametros.page = this.grid.page ? this.grid.page : 1;
		this.parametros.size = this.grid.size ? this.grid.size : 10;
		this.parametros.sort = this.grid.sort.field;
		this.parametros.reverse = this.grid.sort.reverse;


		this.applicationService.get("ProdutoHistorico", this.parametros).subscribe((retorno : PagedItems) => {
			if(retorno){
				this.lista = retorno.items;
				this.total = retorno.total;
				this.preencherParametrosExportacao();
			} else {
				this.lista = {}
				this.total = null;
			}
		});
	}

	preencherParametrosExportacao() {
		this.parametros.exportarListagem = true;
		this.parametros.exportarPdf = true;
		this.parametros.servico = this.servico;
    	this.parametros.page = 1;
    	this.parametros.size = 100000;
		this.parametros.titulo = "Relatório Histórico de Produto Padronizado"

		this.parametros.columns = [
			"Data/Hora",
			"Login",
			"Responsável",
			"Ação",
			"Justificativa"
		];

		this.parametros.fields = [
			"dataAcaoString",
			"numeroUsuario",
			"nomeUsuario",
			"objetoDescricao",
			"descricaoJustificativa"
		];

		this.parametros.width = {
			0: {  cellWidth: 100  },
			1: {  cellWidth: 110 },
			2: {  cellWidth: 125  },
			3: {  cellWidth: 250  },
			4: {  cellWidth: 175  }
		};
		// this.btnRelatorio.exportar(1);
	}


}
