import { Component, Injectable, OnInit, ViewChild } from "@angular/core";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ActivatedRoute, Router} from "@angular/router";
import { VwMppbPpbEntityVM } from "../../../../view-model/VwMppbPpbEntityVM";
import { ModalService } from "../../../../shared/services/modal.service";
import { trackByHourSegment } from "angular-calendar/modules/common/util";


enum EnumTipoEtapa{
  SemPontuacao = 1,
  PorPontuacao = 2,
  PorGrupo = 3,
  PPBMultiplo = 4
}

@Component({
	selector: 'app-modal-detalhe-ppb',
	templateUrl: './modal-detalhe-ppb.component.html'
})
export class ModalDetalhePPBComponent implements OnInit {


	exibirGrids: boolean = false;
	idPPB = 1;
	tipoEtapa = 0;
	habilitarInputs = false;
	listaDadosAtuacao: any;
	ocultarInputAnexo = false;
	parametros: any = {};
	isDesabilitado: boolean = true;
	ocultaCombos: boolean = true;
	desabilitarInputs: boolean = true;
	titulo : string = "";
	_isSempontuacao: boolean = false;
	_isPorPontuacao : boolean = false;
	_isPorGrupo : boolean = false;
	_isPPBMultiplo : boolean = false;
	fromTelaElaboracao : boolean = false;
	buscarVinculos = false;
  _resumoTecnico : string = "";

  infoPpb : VwMppbPpbEntityVM = new VwMppbPpbEntityVM();


  _idPpb : number;
  
  @ViewChild('appModalDetalhePPB') appModalDetalhePPB : any;

  @ViewChild('appCondicionantesPPB') appCondicionantesPPB : any;
  @ViewChild('appEtapasPPB') appEtapasPPB : any;
  @ViewChild('appPPBMultiplo') appPPBMultiplo : any;
  @ViewChild('appMetasPPB') appMetasPPB : any;

	@ViewChild('appModalDetalhePPBackground') appModalDetalhePPBackground : any;

	constructor(
    private modal : ModalService,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute
	) {
    this._idPpb = Number(this.route.snapshot.params['idPpb']);
  }

    ngOnInit() {
	}

  abrir(idPPB: number){
    this._idPpb = idPPB;
		this.applicationService.get("BuscarInfoPpb",this._idPpb).subscribe((result:VwMppbPpbEntityVM) => {
      if(result){
        this.infoPpb = result;
        this._resumoTecnico = result.descricaResumoTecnico;
        this.tipoEtapa = result.tipoEtapa;
        this.titulo = result.descricaoTitulo;
        if(this.infoPpb.tipoEtapa > 0){
          if(this.infoPpb.tipoEtapa  == EnumTipoEtapa.SemPontuacao){
            this._isSempontuacao = true;
          } else if(this.infoPpb.tipoEtapa  == EnumTipoEtapa.PorPontuacao){
            this._isPorPontuacao = true;
          } else if(this.infoPpb.tipoEtapa  == EnumTipoEtapa.PorGrupo){
            this._isPorGrupo = true;
          } else if(this.infoPpb.tipoEtapa  == EnumTipoEtapa.PPBMultiplo){
            this._isPPBMultiplo = true;
          }
        }
        this.exibirGrids = true;
      }
    });
    this.appModalDetalhePPBackground.nativeElement.style.display = 'block';
		this.appModalDetalhePPB.nativeElement.style.display = 'block';
  }

  fechar(){
    this.exibirGrids = false;
    this._isSempontuacao = false;
    this._isPorPontuacao = false;
    this._isPorGrupo = false;
    this._isPPBMultiplo = false;
    this._resumoTecnico = null;
    this.appModalDetalhePPBackground.nativeElement.style.display = 'none';
    this.appModalDetalhePPB.nativeElement.style.display = 'none';
  }
   async salvar(){
   
    if(this._isPPBMultiplo || this._isPorGrupo || this._isPorPontuacao || this._isSempontuacao){
      await this.appEtapasPPB.salvar();
    }

    if(this._isPPBMultiplo){
      await this.appPPBMultiplo.salvar();
    }

    await this.appCondicionantesPPB.salvar();

    if( this._isPorPontuacao || this._isPorGrupo ){
      await this.appMetasPPB.salvar();
    }

   this.fechar();
   this.modal.infoSucess("Operação realizada com Sucesso!", "Informação");
  }


}
