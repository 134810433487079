import { Injectable } from "@angular/core";


export enum StoragePesquisaPaginasEnum {
  ProdutoPadronizado = "storagefiltro-produto-padronizado",
  Subsetores = "storagefiltro-subsetores",
  Unidade = "storagefiltro-unidade",
  CodigoNcm = "storagefiltro-codigoncm"
}

interface IItemDsValidar{
  key:string,
  extra?:any
}

@Injectable()
export class LocalStorageFiltroPesquisaService{

  limparStorage(){
    let keys = Object.keys(localStorage).filter( (key)=> key.startsWith("storagefiltro-") );
    keys.forEach(k => localStorage.removeItem(k));
  }
  limparStorageExceto(key:StoragePesquisaPaginasEnum){
    let keys = Object.keys(localStorage).filter( (key)=> key.toString().startsWith("storagefiltro-") );
    keys.forEach(k =>{
      if(k != key){
        localStorage.removeItem(k);
      }
    });
  }
  salvar(key:StoragePesquisaPaginasEnum,obj:any){
    localStorage.setItem(key.toString(), JSON.stringify(obj));
  }
  obter(key:StoragePesquisaPaginasEnum){
    let str = localStorage.getItem(key.toString());
    let item = JSON.parse(str);
    return item;
  }

  validarObjExisteFiltro(obj:any, validator:IItemDsValidar[]){

    validator.forEach(
      v =>{
        let it = obj[v.key];
        if( it != null){
          if(typeof(it) == "number" && it != 0){
            return true;
          }
          if(typeof(it) == "string" && it != ""){
            if(v.extra["isStt"]==true && it != "99"){

            }
            return true;
          }
        }
      }
    )

  }

}
