/* IMPORTAÇÃO DE MODULOS */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AngularEditorModule } from '@kolkov/angular-editor';
//import { DropListSelect2Component } from '../../shared/components/drop-list-2/drop-list-2.component';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* COMPONENTES CADASTRO PPB HISTORIA */
import { GridManterPPBComponent } from './grid/grid.component';
import { ManterPPBComponent } from './manter-ppb.component';
import { ControleSolicitacoesComponent } from './solicitacoes-ppb/controle-solicitacoes-ppb.component'; 
import { CondicionantesPPBComponent } from './solicitacoes-ppb/condicionantes-ppb/condicionantes-ppb.component'; 
import { GridCondicionantesPPBComponent } from './solicitacoes-ppb/condicionantes-ppb/grid/grid.component'; 
import { ControleEtapasPPBComponent } from './solicitacoes-ppb/etapas-ppb/etapas-ppb.component';
import { GridEtapasPPBComponent } from './solicitacoes-ppb/etapas-ppb/grid/grid.component'; 
import { ModalIncluirEtapasPPBAtuacao } from './solicitacoes-ppb/etapas-ppb/modal/modal-incluir-etapas-ppb.component';
import { ModalIncluirCondicionantesPPBAtuacao } from './solicitacoes-ppb/condicionantes-ppb/modal/modal-incluir-condicionantes-ppb.component';
import { VisualizarCadastrosDePPBComponent } from './visualizar-cadastros-de-ppb/visualizar-cadastros-de-ppb.component';
import { ProdutosVinculadosPPBComponent } from './visualizar-cadastros-de-ppb/produtos-vinculados-ppd/produtos-vinculados-ppb.component';
import { GridProdutosVinculadosAoPPBComponent } from './visualizar-cadastros-de-ppb/produtos-vinculados-ppd/grid/grid.component';
import { ModalEtapasCondicoesPPBAtuacao } from './modais/etapas-condicoes/modal-etapas-condicoes-ppb.component';
import { ModalFinalizar } from './modais/finalizar/modal-finalizar.component';
import { ModalVinculos } from './modais/vinculos/modal-vinculos.component';
import { GruposPPBComponent } from './solicitacoes-ppb/grupos-ppb/grupos-ppb.component';
import { ModalIncluirGruposPPBComponent } from './solicitacoes-ppb/grupos-ppb/modal/modal-incluir-grupos-ppb.component';
import { GridGruposPPBComponent } from './solicitacoes-ppb/grupos-ppb/grid/grid.component';
import { ModalEscolherOrgaos } from './modais/escolher-orgaos/modal-escolher-orgaos.component';
import { GridVinculosPPBComponent } from './modais/vinculos/grid/grid.component';
import { RelatorioListagensPPBComponent } from './solicitacoes-ppb/relatorio-listagens-ppb/relatorio-listagens-ppb.component';
import { NgxMaskModule, IConfig } from "ngx-mask";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		SharedModule,
		AngularEditorModule,
		NgbModule,
		NgxMaskModule.forChild()
	],
	declarations: [
		//DropListSelect2Component,
		GridManterPPBComponent,
        ManterPPBComponent,
		ControleSolicitacoesComponent,
		CondicionantesPPBComponent,
		GridCondicionantesPPBComponent,
		ControleEtapasPPBComponent,
		GridEtapasPPBComponent,
		ModalIncluirEtapasPPBAtuacao,
		ModalIncluirCondicionantesPPBAtuacao,
		VisualizarCadastrosDePPBComponent,
		ProdutosVinculadosPPBComponent,
		GridProdutosVinculadosAoPPBComponent,
		ModalEtapasCondicoesPPBAtuacao,
		ModalFinalizar,
		ModalVinculos,
		GruposPPBComponent,
		ModalIncluirGruposPPBComponent,
		GridGruposPPBComponent,
		ModalEscolherOrgaos,
		GridVinculosPPBComponent,
		RelatorioListagensPPBComponent
	],
})
export class ManterPBModule { }
