import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../shared/services/application.service";
import { MessagesService } from "../../shared/services/messages.service";
import { ModalService } from "../../shared/services/modal.service";
import { ValidationService } from "../../shared/services/validation.service";
import { ConsultarProdutoVM } from "../../view-model/ConsultarProdutoVM";
import { PagedItems } from "../../view-model/PagedItems";
import { ChangeIniciaPaginacao } from "../../shared/services/changeIniciaPaginacao.service";
import { ModalVerificarInconssistenciaComponent } from "./modal-verificar-inconssistencia/modal-verificar-inconssistencia.component";
import { InforFiltroRelatorioGrid } from "../../shared/services/pdf.service";
import { LocalStorageFiltroPesquisaService, StoragePesquisaPaginasEnum } from "../../shared/services/localStorageFiltroPesquisa.service";

declare var html2pdf: any;

@Component({
	selector: "app-manter-produto-padrao",
	templateUrl: "./manter-produto-padrao.component.html"
})
@Injectable()
export class ManterProdutoPadraoComponent implements OnInit {
    form = this;
	grid: any = { sort: {field:"NumeroCodigo"} };
	parametros = new ConsultarProdutoVM();
	flagAlterouFiltro : boolean = false;
	ocultarFiltros: boolean = false;
	isBuscaSalva: boolean = false;
  eUsuarioSuframa:boolean ;
  visto: boolean;
	servico = 'ConsultarProdutoPadrao';
	objetoConsulta: any = {} = null;
	listaUnidadeDeMedida = new Array<any>();


	@ViewChild("relatorioPDF") htmlData: ElementRef;
	@ViewChild("Ncm") Ncm;
	@ViewChild("BaseLegal") BaseLegal;
  @ViewChild('appModalVerificarInconssistencia') appModalVerificarInconssistencia : ModalVerificarInconssistenciaComponent;


	constructor(
		private changeIniciaPaginacao : ChangeIniciaPaginacao,
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute,
		private validation: ValidationService,
    private localStorageFiltroPesquisas:LocalStorageFiltroPesquisaService
	) {

		if (sessionStorage.getItem(this.router.url) == null && sessionStorage.length > 0)
			sessionStorage.clear();
	}

	ngOnInit() {

    this.eUsuarioSuframa =JSON.parse(localStorage.getItem("usuarioSuframa"));
    this.buscarInformacoesStorage()
	}

  buscarInformacoesStorage(){
    const key = StoragePesquisaPaginasEnum.ProdutoPadronizado;
    this.localStorageFiltroPesquisas.limparStorageExceto(key);
    const storage = this.localStorageFiltroPesquisas.obter(key);
    if(storage &&
      (storage.produto ||
        storage.formatoBuscaProduto||
        (storage.sitProduto != null && storage.sitProduto != "99"  ) ||
        storage.idUnidadeMedida ||
        storage.idPPB ||
        storage.ncm
      )){
        this.parametros = storage;
        this.buscar()
    }

  }

	onChangeSort($event: any) {
		this.grid.sort = $event;
		this.buscar();
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
		this.buscar();
	}

	onChangePage($event: any) {
		this.grid.page = $event;
		this.buscar();

	}

  inconsistenciaVisualizada(){
    localStorage.setItem("visualizado", "visto")

  }

  abrirModal(){
    this.appModalVerificarInconssistencia.abrir();
  }


  salvarDadoFiltroNoStorage(){
    let salvar = false;
    if((this.parametros.produto || "").length){
      salvar = true;
    }
    if((this.parametros.formatoBuscaProduto||"").length){
      salvar = true;
    }
    if(this.parametros.sitProduto != 99){
      salvar = true;
    }
    if(this.parametros.idUnidadeMedida){
      salvar = true;
    }
    if(this.parametros.idPPB){
      salvar = true;
    }
    if((this.parametros.ncm ||"").length){
      salvar = true;
    }
    if(salvar){
      this.localStorageFiltroPesquisas.salvar(StoragePesquisaPaginasEnum.ProdutoPadronizado,this.parametros);
    }

  }

	validar(): boolean {

		if((this.parametros.produto == undefined || this.parametros.produto == null || this.parametros.produto.trim().length == 0)
			&& (this.parametros.tipo == undefined || this.parametros.tipo == null || this.parametros.tipo.trim().length == 0)
			&& (this.parametros.ncm == undefined || this.parametros.ncm == null || this.parametros.ncm.trim().length == 0)
			&& (this.parametros.sitProduto < 0 || this.parametros.sitProduto == 0)
			&& (this.parametros.idUnidadeMedida == undefined || this.parametros.idUnidadeMedida == null || this.parametros.idUnidadeMedida == 0)
			&& (this.parametros.idPPB == undefined || this.parametros.idPPB == null || this.parametros.idPPB == 0)
		){
			this.modal.infoError("Informe pelo menos um filtro para pesquisa", "Alerta");
			return false;
		}

		if(this.parametros.produto != undefined && this.parametros.produto != null && this.parametros.produto.length > 0){
			if(this.parametros.formatoBuscaProduto){
				return true;
			}else{
				this.modal.infoError("Ao preencher o campo Produto ou Tipo. Selecione o metodo de busca por código ou descrição", "Alerta");
				return false;
			}
		}

		if(this.parametros.tipo != undefined && this.parametros.tipo != null && this.parametros.tipo.length > 0){
			if(this.parametros.formatoBuscaTipo){
				return true;
			}else{
				this.modal.infoError("Ao preencher o campo Produto ou Tipo. Selecione o metodo de busca por código ou descrição", "Alerta");
				return false;
			}
		}

        return true;
	}

	buscar() {
		var objeto: any = {};

		if (this.validar()) {
			objeto = {
				page: this.grid.page ? this.grid.page : 1,
				size: this.grid.size ? this.grid.size : 10,
				sort: this.grid.sort.field ,
				reverse: this.grid.sort.reverse,
				produto: this.parametros.produto,
				formatoBuscaProduto: this.parametros.formatoBuscaProduto,
				tipo: this.parametros.tipo,
				formatoBuscaTipo: this.parametros.formatoBuscaTipo,
				ncm: this.parametros.ncm,
				sitProduto: this.parametros.sitProduto,
				descricao: this.parametros.descricao,
				idPPB: this.parametros.idPPB,
				idUnidadeMedida: this.parametros.idUnidadeMedida
			};

			if (this.flagAlterouFiltro)
			{
				objeto.page = 1;
				objeto.size = 10;
				//reinicia a paginacao dentro de app-grid
				this.changeIniciaPaginacao.Reinicia(true);
			}
			else
			{
				objeto.page = this.grid.page;
				objeto.size = this.grid.size;
			}

		}else{
			return false;
		}
		this.flagAlterouFiltro = false;
 		this.applicationService.get(this.servico, objeto).subscribe((response: PagedItems) => {
			if (response.total != null && response.total > 0) {
				this.grid.lista = response.items;
				this.grid.total = response.total;

        this.salvarDadoFiltroNoStorage()
				this.prencheParametrosDeExportacao();
				this.gravarBusca(objeto);
			} else {
				this.grid = { sort: {} };
				this.parametros.exportarListagem = false;
				this.modal.infoError(this.msg.NENHUM_REGISTRO_ENCONTRADO, "Alerta");
			}
		});
	}

	AlteraNcm($event){
		if(this.Ncm.model.text != null && this.Ncm.model.text != undefined && this.Ncm.model.text != ""){
			let codigo = this.Ncm.model.text.split(" | ")[0];
			this.parametros.ncm = codigo;
		}
	}

	limpar() {
		this.parametros = new ConsultarProdutoVM();
		this.Ncm.clear();
		this.BaseLegal.clear();
		this.prencheParametrosDeExportacao();
		localStorage.removeItem(this.router.url);
		this.grid = { sort: {field:"NumeroCodigo"}  };

	}

  obterDsSelecionadoDropdown(id:string){
    let selected = $( `#${id} option:selected` ).text();
    if(selected.includes("Selecione uma opção")){
      return null;
    }
    return selected;
  }
  obterDsBaseLegal(){
    const baseLegal = this.BaseLegal.getSelectedValue()
    if(!baseLegal){
      return null;
    }
    return baseLegal.text
  }
  obterDsNcm(){
    const ncm = this.Ncm.getSelectedValue();
    if(!ncm){
      return null;
    }
    return ncm.text
  }

	prencheParametrosDeExportacao() {
		this.parametros.exportarListagem = true;
		this.parametros.exportarPdf = true;
		this.parametros.servico = this.servico;
    	this.parametros.page = 1;
    	this.parametros.size = 100000;
      this.parametros.sort = this.grid.sort.field;
      this.parametros.reverse =  this.grid.sort.reverse;
		this.parametros.titulo = "Relatório de Produtos Padronizados"

    this.parametros.informacoesExtrasRelatorio = [
      {
        label:"Produto Padronizado",
        text:this.parametros.produto
      },
      {
        label:"Situação",
        text: this.obterDsSelecionadoDropdown("situacao")
      },
      {
        label:"Unidade de Medida",
        text:this.obterDsSelecionadoDropdown("unidade")
      },
      {
        label:"Base Legal",
        text:this.obterDsBaseLegal()
      },
      {
        label:"NCM",
        text:this.obterDsNcm()
      }
    ] as Array<InforFiltroRelatorioGrid>;

		this.parametros.columns = [
			"Código",
			"Descrição",
			"Situação",
			"Coeficiente de Redução",
			"Unidade de Medida",
			"Componente",
			"Bem de Informática",
		];

		this.parametros.fields = [
			"numeroCodigo",
			"descProduto",
			"situacaoProduto",
			"coeficienteReducao",
			"unidadeDeMedida",
			"situacaoComponente",
			"situacaoBemInformatica"
		];

		this.parametros.width = {
			0: { cellWidth: 50 }, 1: { cellWidth: 100 },
			2: { cellWidth: 100 }, 3: { cellWidth: 100 },
			4: { cellWidth: 100 }, 5: { cellWidth: 150 },
			6: { cellWidth: 200 },
		};

	}


	formatDate(date: Date) {
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date)
	}

	retornaValorSessao(): any {
		var router = localStorage.getItem(this.router.url);
		if (router) {
			this.isBuscaSalva = true;
			this.objetoConsulta = JSON.parse(localStorage.getItem(this.router.url));
			return this.objetoConsulta;
		} else {
			this.isBuscaSalva = false;
			return null;
		}
	}

	gravarBusca(objeto: any) {
    this.localStorageFiltroPesquisas.salvar(StoragePesquisaPaginasEnum.ProdutoPadronizado,objeto)
		// localStorage.removeItem(this.router.url);
		// localStorage.setItem(this.router.url, JSON.stringify(objeto));
	}

	gerarPDF(el){
		let renderizarHtml = new Promise (resolve=>{

			const elements = document.getElementById('relatorioPDF');
				const options = {

				margin: [0.5, 0.03, 0.5, 0.03], // [top, left, bottom, right]
				filename: 'relatorio-produto-padrao.pdf',
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: {
					scale: 2,
					dpi: 300,
					letterRendering: true,
					useCORS: true
				},
				jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
				pagebreak : { before: ['#grid'] }
				};
				html2pdf().from(el).set(options).toPdf().get('pdf').then(function (pdf) {
					var totalPages = pdf.internal.getNumberOfPages();

					for (var i = 1; i <= totalPages; i++) {
					  pdf.setPage(i);
					  pdf.setFontSize(10);
					  pdf.setTextColor(150);

					  var dateObj = new Date();
					  var month = dateObj.getUTCMonth().toString().length <= 1 ? '0' + (dateObj.getUTCMonth() + 1).toString() : dateObj.getUTCMonth() + 1;
					  var day = dateObj.getUTCDate();
					  var year = dateObj.getUTCFullYear();

					  var hh = dateObj.getHours();
					  var mm = dateObj.getMinutes();
					  var ss = dateObj.getSeconds();

					  var newhr = hh + ":" + mm + ":" + ss;

					  var newdate = day + "/" + month + "/" + year;

					  pdf.text('Data/Hora de Emissão: '+ newdate + " " + newhr
					  + '                                                                                                           Página ' + i + ' de ' + (totalPages), .2, 11.5);
					}
				  }).save();

				resolve(null);
		});

		let liberarTela = new Promise (resolve=>{
			setTimeout(() => {
				// this.ocultarPdf = true;
			}, 1000);
			resolve(null);
		});

		Promise.all([renderizarHtml,liberarTela]);
	}

	criarNovo(){
		this.modal.confirmacao("Deseja Cadastrar um Novo Produto Padrão?").subscribe((isConfirmado: any) => {
			if (isConfirmado) {
				this.router.navigate(['/solicitacoes-produto-padrao/novo']);
			}
		});
	}
  abrirModalInconssistencia(){
    setTimeout(() => {
      this.abrirModal();
  }, 1000);
	}


}

