<app-grid 
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
			<thead class="table-header-color">
				<tr>
					<th class="th-100 text-left">Base Legal</th>
				</tr>
			</thead>
			<tbody *ngIf="total > 0">				
				<tr *ngFor="let item of lista">
                    <td class="text-left">{{item.tituloPPB}}</td>                
				</tr>
			</tbody>
			<h2 *ngIf="total == 0" class="panel-title h5">Nenhuma base legal vinculado</h2>

		</table>
	</div>
</app-grid>
