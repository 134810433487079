import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { PagedItems } from "../../../../../view-model/PagedItems";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../../shared/services/application.service";
import { Location } from '@angular/common';
//import { PPBGrupoVM } from "../../../../view-model/PPBGrupoVM";
//import { ChangeGrupoPPBService } from '../../../../shared/services/changeGrupoPPB.service';

@Component({
	selector: "app-ppb-multiplo",
	templateUrl: "./ppb-multiplo.component.html"
})
@Injectable()

export class PPBMultiploComponent implements OnInit {

	form = this;
	grid: any = { sort: {} };
	listaAnterior : any = {};
	//parametros: PPBGrupoVM = new PPBGrupoVM();
	parametros: any = {};
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;
	path: string;
	servico = "PPBMultiplo";
	listaAntigaComparacao  = {};
	isBuscaSalva : boolean = false;
	objetoSession : any = {} = null;
	_idRota : number;
	
	@Input() _isListarVinculados : boolean = false;
	@Input() isAlteracaoParaRevogacao : boolean;
	@Input() habilitarInputs: boolean;
	@Input() idPPB: number;
	@Input() validacao : any = {} = null;
	@Input() dataVigenciaInicialPPB : string;
	@Input() buscarVinculos: boolean;
	@Input() isPorGrupoSemPontuacao : boolean;
	@Input() _fromTelaElaboracao : boolean;
	@Input() isRetificacao : boolean;
	@ViewChild('appGridPPBMultiplo') appGridPPBMultiplo : any;

	constructor(
		//private ChangeGrupoPPBService : ChangeGrupoPPBService,
        private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private msg: MessagesService,
        private validation: ValidationService,
        private router: Router,
        private _location: Location
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idRota = Number(this.route.snapshot.params['idTipoProduto']);
	}

	ngOnInit() {
		this.listar(this.idPPB);

		// this.ChangeGrupoPPBService.AtualizaListagemGrupoPPB.subscribe(
		// 	idPPB => this.listar(idPPB, true)
		// );
	 }

	ocultar() { }

	salvar(){
		this.appGridPPBMultiplo.salvar();
	}

	onChangeSort($event : any) {
		this.grid.sort = $event;
		this.listar(this.idPPB);
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
    this.grid.page = 1;
		this.listar(this.idPPB);
	}

	async onChangePage($event : any) {
		this.grid.page = $event;
		var gridAlterado = this.validarAlteracaoGrid();
		if(await gridAlterado == 1){
			this.salvar();
		}
		this.listar(this.idPPB);
    }
	async validarAlteracaoGrid(){
		if(this.listaAntigaComparacao != null){
			var obj = {
				ListaAtualCondicionanteVM : this.grid.lista,
				ListaAntigaCondicionanteVM : this.listaAntigaComparacao
			}
			var retorno : any = await this.applicationService.post(this.servico+"/ValidarAlteracaoGrid", obj).toPromise();
			return retorno;
		}else{
			return 0;
		}
	}
    Voltar(){
    }

	

	public listar(idPPB : Number, getConsultaFromSessionStorage? : boolean){

		//var objeto : PPBGrupoVM = new PPBGrupoVM();
		var objeto : any = {};
		objeto.idPPB = idPPB;
		objeto.fromGridPorGrupo = false;

		if(getConsultaFromSessionStorage)
		{
			this.retornaValorSessao();
			if(this.isBuscaSalva){
				objeto.page = this.objetoSession.page || 1,
				objeto.size = this.objetoSession.size || 10,
				objeto.sort = this.objetoSession.sort || "NumeroSequencia",
				objeto.reverse = this.objetoSession.reverse || false;
			} else {
				objeto.page = 1;
				objeto.size = 10;
			}
		}
		else
		{
			objeto.page = this.grid.page || 1;
			objeto.size = this.grid.size || 10;
			objeto.sort = this.grid.sort.field || "NumeroSequencia";
			objeto.reverse = this.grid.sort.reverse || false;
		}
		objeto.buscarVinculos = this.buscarVinculos;
		objeto.fromTelaElaboracao = this._fromTelaElaboracao;

		
		var vm = {
			idProduto : this._idRota,
			idPpb : idPPB,
			PBMultiploEntityVm : objeto
		}

		let endpoint = this._isListarVinculados ? "/GetPaginadoVinculados" : "/GetPaginado" ;

		this.applicationService.get(this.servico + endpoint, vm).subscribe((retorno : PagedItems) => {
			if(retorno.total!= null && retorno.total > 0){
				if(this.listaAnterior.length > 0 && this.listaAnterior != null){
					this.listaAnterior.forEach(itemListaAnterior => {
						retorno.items.forEach(itemNovaLista => {
							if(itemListaAnterior.id == itemNovaLista.id && itemListaAnterior.idPPB == itemNovaLista.idPPB && itemListaAnterior.isChecked != itemNovaLista.isChecked){
								itemNovaLista.isChecked = itemListaAnterior.isChecked;
							}
						});
					});
				}		
				this.grid.lista = retorno.items;
				this.listaAntigaComparacao =JSON.parse(JSON.stringify(retorno.items));
				this.grid.total = retorno.total;
				this.gravarBusca(objeto);
			} else {
				this.grid = { sort: {} };
			}
		});
	}

	atualizaListagem(idPPB){
		this.listar(idPPB, true);
	}

	salvarListaAnterior($event){
		this.listaAnterior = $event;
}

	gravarBusca(objeto : any) {
		sessionStorage.removeItem(this.router.url);
		sessionStorage.setItem("ppb-multiplo-"+this.idPPB , JSON.stringify(objeto));
	}

	retornaValorSessao() {
		var router = sessionStorage.getItem("ppb-multiplo-"+this.idPPB);
		if (router) {
			this.isBuscaSalva = true;
			this.objetoSession = JSON.parse(sessionStorage.getItem("ppb-multiplo-"+this.idPPB));
		} else {
			this.isBuscaSalva = false;
			return null;
		}
	}

}
