import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "../../../shared/services/application.service";
import { MessagesService } from "../../../shared/services/messages.service";
import { ModalService } from "../../../shared/services/modal.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { ProdutoVM } from "../../../view-model/ProdutoVM";
import { ManterProdutoPadraoComponent } from '../manter-produto-padrao.component';

@Component({
	selector: 'app-grid-manter-produto-padrao',
	templateUrl: './grid.component.html'
})

export class GridManterProdutoPadraoComponent {

	ocultarTituloGrid = true;

	constructor(
		private manterProdutoPadraoComponent : ManterProdutoPadraoComponent,
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,

	) {

	}

	disable : boolean =false

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;
  @Input() eUsuarioSuframa:boolean = true;


	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@ViewChild("modalJustificativa") modalJustificativa;
	@ViewChild("modalHistorico") modalHistorico;
	@ViewChild("modalJustificativaAtivacaoReativacao") modalJustificativaAtivacaoReativacao;
	@ViewChild('ModalListagemPPB') modalListagemPPB;

	tituloGrid = "Resultado da Pesquisa"
	servico = "Validar";
	servicoFinalizar = "FinalizarPPB";
	servicoExcluir = 'ManterProdutoPadrao';
	servicoAlteracaoDeStatus = "AlteracaoStatus/PostInativarProduto";
	servicoAlteracaoDeStatusAtivarReativar = "AlteracaoStatus/PostAtivarReativarProduto";

	_dataJustificativa: any = {};


	changeSize($event) {
		this.onChangeSize.emit($event);
		this.changePage(this.page);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	formatDate(date: Date){
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date)
	}

	irVisualizar(){
		this.router.navigate(['/solicitacoes-produto-padrao/1/visualizar']);
   	}

	abrirHistorico(item){
			this.modalHistorico.abrir(item);
	}

	receberAlteracaoJustificativaInativacao($event){
		if($event){
			var object = {
				idProduto: $event.objetoAlteracao.id,
				justificativa: $event.txt
			}

			this.applicationService.post(this.servicoAlteracaoDeStatus,object).subscribe((response: any) => {
				if(response.success){
					this.modal.infoSucess(response.message, "Success");
					this.manterProdutoPadraoComponent.buscar();
				}else{
					this.modal.infoSucess(response.message, "Error");
				}
				//this.onChangePage.emit(this.page);
			});
			this.fecharModalJustificativa();
		}
	}

	fecharModalJustificativa(){
		this.modalJustificativa.fechar();
		this.modalJustificativaAtivacaoReativacao.fechar();
	}

	abrirFinalizar(id){
		this.modal.confirmacao("Deseja Confirmar a Operação?").subscribe( (isConfirmado : any) =>{
			if (isConfirmado) {
				this.router.navigate(["solicitacoes-produto-padrao/" + id + "/finalizar"]);
			}
		});
	}

	change(operacao: string, objetoAlteracao: ProdutoVM){
		this._dataJustificativa.msgModal = 'Deseja ' + operacao + ' o Produto Padrão. Confirme a Operação?'
		this._dataJustificativa.tituloModal = operacao + ' Produto Padrão'
		this._dataJustificativa.maxLengthInput = 1000;
		this._dataJustificativa.labelName = 'Insira a Justificativa da Alteração';
		this._dataJustificativa.isObrigatorioPreencherCampo = true;
		this._dataJustificativa.objetoAlteracao = objetoAlteracao;
		this.modalJustificativa.abrir();
	}

	// Ativar ou Reativar Produto

	AtivarReativarProduto(operacao: string, objetoAlteracao: ProdutoVM){
		this._dataJustificativa.msgModal = 'Deseja ' + operacao + ' o Produto Padrão. Confirme a Operação?'
		this._dataJustificativa.tituloModal = operacao + ' Produto Padrão'
		this._dataJustificativa.maxLengthInput = 1000;
		this._dataJustificativa.labelName = 'Insira a Justificativa da Alteração';
		this._dataJustificativa.isObrigatorioPreencherCampo = true;
		this._dataJustificativa.objetoAlteracao = objetoAlteracao;
		this.modalJustificativaAtivacaoReativacao.abrir();
	}

	receberAlteracaoJustificativaAtivacao($event){
		if($event){
			var object = {
				idProduto: $event.objetoAlteracao.id,
				justificativa: $event.txt
			}

			this.applicationService.post(this.servicoAlteracaoDeStatusAtivarReativar, object).subscribe(
				(response: any) => {
					if(response.success){
						this.modal.infoSucess(response.message, "Success");
						this.manterProdutoPadraoComponent.buscar();
					}else{
						this.modal.infoSucess(response.message, "Error")
					}
					//this.onChangePage.emit(this.page);
				}
			);
			this.fecharModalJustificativa();
		}
	}

	// Excluir
	excluirProduto(idproduto: number){
		this.modal.confirmacao("Deseja realmente excluir o Produto ?").subscribe( (isConfirmado : any) =>{
			if (isConfirmado) {
				this.applicationService.delete(this.servicoExcluir, idproduto).subscribe(
					(response: any) => {
						if(response){
							this.modal.infoSucess("Operação realizada com Sucesso","Success");
						}else{
							this.modal.infoError("Erro ao realizar a operação","Error");
							return false;
						}
						this.onChangePage.emit(this.page);
					}
				);
			}
		});
	}

	// Listar PPBs
	abrirListagem(idProduto: number, descricao: string){
		this.modalListagemPPB.abrir(idProduto,descricao);
	}
}
