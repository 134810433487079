<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
     data-railopacity="0.2">
  <div class="row m-b-md">
    <div class="col-lg-12">
      <app-titulo>{{ getTituloPagina() }}</app-titulo>
      <!-- <app-sub-titulo *ngIf=" path == 'novo' "  >Incluir Solicitações</app-sub-titulo>
      <app-sub-titulo *ngIf=" path == 'editar' "  >Alterar Solicitações</app-sub-titulo> -->
    </div>
  </div>


  <section class="panel panel-default">
    <header class="panel-heading">
      <div class="pull-right">
        <a id="btn-voltar-solicitacoes-ppb" name="btn-voltar-solicitacoes-ppb" (click)="voltar()"
           class="btn btn-default btn-sm">
          <i class="fa fa-arrow-left"></i> Voltar
        </a>
        <a *ngIf=" path != 'visualizar' " id="btn-salvar-solicitacoes-ppb" name="btn-salvar-solicitacoes-ppb"
           (click)="validar()" class="btn btn-primary btn-sm">
          <i class="fa fa-save"></i> Salvar
        </a>
      </div>
      <h2 class="panel-title h5">Formulário</h2>
    </header>
    <div class="panel-body">

      <div class="row form-group">
        <div class="col-lg-12">

          <div class="row form-group">
            <div class="col-lg-1">
              <label for="descricao" class="">Código:
                <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaNumeroCodigo"
                   style="color: red">*</b>
              </label>
              <input type="text" onlyNumber="true" name="codigo" id="codigo" class="form-control"
                     maxlength="5" [(ngModel)]="parametros.numeroCodigo" value="0001"
                     [disabled]="desabilitarCodigo ? true : null"/>
            </div>
            <div class="col-lg-11">
              <label for="codigo" class="required">Descrição:
                <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaDescProduto"
                   style="color: red">*</b>
              </label>
              <textarea class="form-control ng-pristine ng-valid ng-touched" name="descricao"
                        style="resize: none;" id="descricao" [(ngModel)]="parametros.descProduto"
                        maxlength="250" name="Objetivo" rows="3" (change)="usuarioAlterouItem = true;"
                        [disabled]="disable ? true : null">
                            </textarea>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-lg-4" style="margin-top:7px;">
              <label for="descricao" class="">Unidade Medida:
                <b *ngIf="(path == 'finalizar' && objetoValidacao.pendenciaIdUnidadeMedida) || (path=='novo')"
                   style="color: red">*</b>
              </label>
              <app-drop-list
                id="analista"
                name="analista"
                servico="UnidadeMedida"
                (change)="usuarioAlterouItem = true;"
                [(model)]="parametros.idUnidadeMedida"
                [(ngModel)]="parametros.idUnidadeMedida"
                [isDisabled]="disable ? true : false">
              </app-drop-list>
              <!-- placeholder="Selecione um item ..." -->
            </div>
            <div class="col-lg-4" style="margin-top: 7px;">
              <label for="descricao" class="">Coeficiente de Redução:
                <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaIdCoeficienteReducao"
                   style="color: red">*</b>
                <b *ngIf="path == 'novo'"
                   style="color: red">*</b>
              </label>
              <app-drop-list
                id="analista"
                name="analista"
                servico="CoefcienteReducao"
                (change)="usuarioAlterouItem = true;"
                [(model)]="parametros.idCoeficienteReducao"
                [(ngModel)]="parametros.idCoeficienteReducao"
                [isDisabled]="disable ? true : false">
              </app-drop-list>
            </div>

          </div>
          <div class="row form-group">
            <div class="col-lg-3" style="margin-top:7px;">
              <label for="descricao" class="">Componente:
                <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaSitComponente"
                   style="color: red">*</b>
                <b *ngIf="path == 'novo'"
                   style="color: red">*</b>
              </label>
              <div class="radio i-checks radio-sm">
                <label class="input-sm">
                  <input
                    (change)="usuarioAlterouItem = true;"
                    name="radio-componente"
                    id="radio-componente-sim"
                    type="radio"
                    value="1"
                    [attr.disabled]="!_habilitaInputs ? true : null"
                    [(ngModel)]="parametros.sitComponente"><i></i> Sim
                </label>
                <label class="input-sm">
                  <input
                    (change)="usuarioAlterouItem = true;"
                    name="radio-componente"
                    id="radio-componente-nao"
                    type="radio"
                    value="0"
                    [attr.disabled]="!_habilitaInputs ? true : null"
                    [(ngModel)]="parametros.sitComponente"><i></i> Não
                </label>
              </div>
            </div>
            <div class="col-lg-3" style="margin-top:7px;">
              <label for="descricao" class="">Bem de Informática:
                <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaSitBemInformativa"
                   style="color: red">*</b>
                <b *ngIf="path == 'novo'"
                   style="color: red">*</b>
              </label>
              <div class="radio i-checks radio-sm">
                <label class="input-sm">
                  <input
                    (change)="usuarioAlterouItem = true;"
                    name="radio-bem-informatica"
                    type="radio"
                    id="radio-bem-informatica-sim"
                    value="1"
                    [attr.disabled]="!_habilitaInputs ? true : null"
                    [(ngModel)]="parametros.sitBemInformativa"><i></i> Sim
                </label>
                <label class="input-sm">
                  <input
                    (change)="usuarioAlterouItem = true;"
                    name="radio-bem-informatica"
                    type="radio" id="radio-bem-informatica-nao" value="0"
                    [attr.disabled]="!_habilitaInputs ? true : null"
                    [(ngModel)]="parametros.sitBemInformativa"><i></i> Não
                </label>
              </div>
            </div>
            <div class="col-lg-3" style="margin-top:7px;">
              <label for="descricao" class="">Nº Dígitos:
                <b *ngIf="path == 'novo'"
                   style="color: red">*</b>
              </label>
              <div class="radio i-checks radio-sm">
                <label class="input-sm">
                  <input
                    (change)="usuarioAlterouItem = true;"
                    name="radio-numero-digitos"
                    type="radio"
                    id="radio-bem-numero-digitos-sim"
                    value="6"
                    [attr.disabled]="!_habilitaInputs ? true : null"
                    [(ngModel)]="parametros.numeroDigitos"
                  >
                  <i></i> 6
                </label>
                <label class="input-sm">
                  <input
                    (change)="usuarioAlterouItem = true;"
                    name="radio-numero-digitos"
                    type="radio"
                    id="radio-bem-numero-digitos-nao"
                    value="9"
                    [attr.disabled]="!_habilitaInputs ? true : null"
                    [(ngModel)]="parametros.numeroDigitos"><i></i> 9
                </label>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-lg-4" style="margin-top: 7px;">
              <label for="descricao" class="">Protocolo de Solicitação:
                <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaDescProtocolo"
                   style="color: red">*</b>
              </label>
              <input (change)="usuarioAlterouItem = true;" [disabled]="disable ? true : null" type="text"
                     maxlength="50" [(ngModel)]="parametros.descProtocolo" name="protocolo-solicitacao"
                     id="protocolo-solicitacao" class="form-control"/>
            </div>
            <div class="col-lg-4" style="margin-top: 7px;">
              <label for="descricao" class="">Subsetor:
                <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaIdSubsetor"
                   style="color: red">*</b>
                <b *ngIf="path == 'novo'"
                   style="color: red">*</b>
              </label>
              <app-drop-list (change)="usuarioAlterouItem = true;" id="drop-list-subsetor"
                             name="drop-list-subsetor" servico="SubSetor" [isDisabled]="disable ? true : false"
                             [(ngModel)]="parametros.idSubsetor">
              </app-drop-list>
            </div>
            <div *ngIf="path == 'visualizar'" class="col-lg-4" style="margin-top: 7px;">
              <label for="situacao-produto" class="">Status:</label>
              <input (change)="usuarioAlterouItem = true;" [disabled]="disable ? true : null" type="text"
                     maxlength="50" [(ngModel)]="parametros.situacaoProduto" name="situacao-produto"
                     id="situacao-produto" class="form-control"/>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-sm-8" style="margin-top: 7px;">
              <label for="descricao" class="required">Base Legal:
                <b *ngIf="path == 'finalizar' && objetoValidacao.pendenciaIdPPB"
                   style="color: red">*</b>
              </label>
              <app-drop-list-select2
                #DocumentoVinculado
                [isDisabled]="disable ? true : false"
                (change)="usuarioAlterouItem = true;"
                [InputValue]="parametros.descricaoBaseLegal"
                id="base-legal"
                name="base-legal"
                (valorModificado)="parametros.idPPB = $event; preencheArrayDocsDeReferencia($event);"
                servico="BaseLegal"
                placeholder="Pesquisar Base Legal"
                selMinimumInputLength="0"
                [(ngModel)]="parametros.idPPB"
                (click)="buscarDocumentosVinculados()">
              </app-drop-list-select2>

            </div>

            <div *ngIf="_existsGrupo && !disable " class="col-sm-3" style="margin-top: 11px;">

              <label for="descricao" class=""></label>
              <span class="input-group-btn">
                                <button (click)="abrirModalVinculo()" class="btn btn-default">
                                    <i class="fa fa-link"></i> Vincular
                                    <b
                                      *ngIf="(path == 'finalizar' && objetoValidacao.pendenciaIdGrupo) ? 'pendencia-validacao' : '' "
                                      style="color: red">*</b>
                                </button>
                            </span>
            </div>
          </div>

          <div class="row form-group" *ngFor="let item of arrayDocumentosVinculados; let i = index">
            <hr style="margin-top: 2px;margin-bottom: 2px;">
            <div class="col-lg-12 text-nowrap">
              <label for="descricao"></label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control input"
                  [placeholder]="item.tituloPPB"
                  disabled
                >

                <span
                  class="input-group-btn"
                  [isDisabled]="disable ? true : false"
                  *ngIf="_habilitaInputs"
                  (click)="excluirDocumento(i)">
                                    <span

                                      data-toggle="modal" data-backdrop="static" data-keyborad="false">
                                        <a class="btn btn-default" data-toggle="tooltip" title="Excluir">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </span>
                                </span>

                <span
                  [hidden]="!_mostrarDocumentosRelacionados"
                  class="input-group-btn"
                  (click)="mostrarDocumentosVinculados(item, i)"
                >
                                    <span
                                      [hidden]="!_mostrarDocumentosRelacionados"
                                      data-toggle="modal"
                                      data-backdrop="static"
                                      data-keyborad="false">
                                        <a class="btn btn-default" data-toggle="tooltip" title="Exibir">
                                            <i [hidden]="item.isDocsMostrados" class="fa fa-plus-circle"></i>
                                            <i [hidden]="!item.isDocsMostrados" class="fa fa-minus-circle"></i>
                                        </a>
                                    </span>
                                </span>
                <span
                  class="input-group-btn"
                  [isDisabled]="disable ? true : false"
                  *ngIf="_mostrarBotaoVincular"
                  (click)="abrirModalDetalhePpb(item)">
                                    <span

                                      data-toggle="modal" data-backdrop="static" data-keyborad="false">
                                        <a class="btn btn-default" data-toggle="tooltip" title="Vincular">
                                            <i class="fa fa-plus-square"></i>
                                        </a>
                                    </span>
                                </span>
              </div>
              <app-vinculos-ppb
                [_idPpb]="item.idPPB"
                *ngIf="item.isDocsMostrados">
              </app-vinculos-ppb>
              <div class="row form-group" *ngIf="_mostrarDocumentosRelacionados && item.isDocsMostrados">
                <div
                  class="col-lg-12 text-nowrap"
                  style="margin-top: 7px;"
                  *ngFor="let item2 of arrayDocumentosRelacionados"
                >
                  <div *ngIf="item2.idPPB == item.idPPB">
                    <label for="codigo">
                      Documentos Relacionado {{ item2.tituloPPB }} :
                    </label>
                    <textarea
                      style="resize: vertical;"
                      class="form-control ng-pristine ng-valid ng-touched"
                      name="Objetivo"
                      [rows]="item2.count + 1"
                      disabled>{{ item2.docsRelacionados }}
                                        </textarea>
                  </div>

                </div>
              </div>
              <div class="row form-group" style="margin: 10px 3px 3px;">
                <div class="col-sm-8" style="padding-left: 0px;">

                  <app-artigo-drop-list-select
                    [isDisabled]="item.EhGrupoPontOrMultiploSemPont != null && !item.EhGrupoPontOrMultiploSemPont"
                    [PpbId]="item.idPPB"
                    [artigosSelecionados]="item.artigos"
                    (onSelected)="OnArtigoSelect($event, item.idPPB)"
                    (onDelete)="OnArtigoDelete($event, item.idPPB)"
                    [tela]="path"
                    id="artigos"
                    placeholder="Pesquisar Artigos"
                    [(isMultiplo)]="item.isPpbMultiplo"
                    habilitarInputs="false">
                  </app-artigo-drop-list-select>

                </div>
                <!-- && path != 'novo' -->
                <div *ngIf="_existsGrupo && !disable " class="col-sm-3" style="margin-top: 11px;">

                  <label for="descricao" class=""></label>
                  <span class="input-group-btn">
                                <button (click)="abrirModalVinculo()" class="btn btn-default">
                                    <i class="fa fa-link"></i> Vincular
                                    <b
                                      *ngIf="(path == 'finalizar' && objetoValidacao.pendenciaIdGrupo) ? 'pendencia-validacao' : '' "
                                      style="color: red">*</b>
                                </button>
                            </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row form-group" *ngIf="path != 'novo'">
            <div class="col-lg-12 text-nowrap" style="margin-top: 7px;">
              <label for="codigo">Texto da Base Legal SIS:</label>
              <textarea
                class="form-control ng-pristine ng-valid ng-touched"
                style="resize: none;"
                rows="3"
                disabled>{{ parametros.descBaseLegalExistente }}</textarea>
            </div>
          </div>


          <div class="row form-group">
            <div class="col-sm-12" style="margin-top: 7px;">
              <label for="codigo">Observação:</label>
              <textarea class="form-control ng-pristine ng-valid ng-touched" name="Observacao"
                        style="resize: none;" id="Observacao" [(ngModel)]="parametros.observacao"
                        maxlength="500" rows="3" (change)="usuarioAlterouItem = true;"
                        [disabled]="disable ? true : null">
                            </textarea>
            </div>
          </div>

        </div>
      </div>


      <div class="row form-group" *ngIf="path != 'novo'">
        <div class="col-lg-12">
          <app-tipo-produto [(validacao)]="objetoValidacao" [(id)]="_idRota"
                            [(habilitarInputs)]="_habilitaInputs"
                            [(habilitarAlteracaoStatus)]="_flagHabilitaAlteracaoStatus"
                            (atualizaNcm)="recebeInfoNCM($event)">
          </app-tipo-produto>
        </div>
      </div>

      <div class="row form-group" *ngIf="path != 'novo' ">
        <div class="col-lg-12">
          <app-ncm [(validacao)]="objetoValidacao" [(id)]="_idRota" [(idTipoProduto)]="_idTipoProduto"
                   [(habilitarAlteracaoStatus)]="_flagHabilitaAlteracaoStatus"
                   [(habilitarInputs)]="_habilitaInputs">
          </app-ncm>
        </div>
      </div>

    </div>

  </section>

  <p *ngIf="habInforCamposObrigatorios()" style="color: red">Os campos com o sinal (*) são de preenchimento
    obrigatório.</p>

</div>

<app-modal-justificativa-produto
  (changeOperation)="recebeAlteracaoJustificativa($event)"
  [(data)]="_dataJustificativa"
  #modalJustificativa>
</app-modal-justificativa-produto>

<app-modal-vincular
  (grupoSelecionado)="recebeGrupoSelecionado($event)"
  #appModalVincular
  (fecharEmit)="excluirDocumento($event)"
>
</app-modal-vincular>
<app-modal-detalhe-ppb #appModalDetalhePPB>

</app-modal-detalhe-ppb>
