import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../../shared/services/application.service";
import { MessagesService } from "../../../shared/services/messages.service";
import { ModalService } from "../../../shared/services/modal.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { subsetorVM } from "../../../view-model/SubsetorVM";

@Component({
	selector: "incluir-subsetor",
	templateUrl: "./incluir-subsetor.component.html"
})

@Injectable()
export class IncluirSubsetorComponent implements OnInit {
	desabilitarCodigo : boolean = true;
    grid: any = { sort: {} };
	servico = 'Subsetor';
	objetoSalvar = new subsetorVM()
	path :any
	_idRota : number =0
	_habilitaInputs: boolean = true;

	alterouItem: boolean = false;

	disable : boolean
	@ViewChild('appModalIncluirSubsetor') appModalIncluirSubsetor : any;
	@ViewChild('appModalIncluirSubsetorBackground') appModalIncluirSubsetorBackground : any;
	constructor(
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute,
		private validation: ValidationService
	) {
		if (sessionStorage.getItem(this.router.url) == null && sessionStorage.length > 0)
			sessionStorage.clear();

		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;

		if(this.path !="incluir"){
			this._idRota = Number(this.route.snapshot.params['id']);
			this._habilitaInputs = true;
			this.buscar(this._idRota);
		}

		if(this.path == "editar"){
			this._habilitaInputs = true;
		}

		if(this.path == "visualizar"){
			this._habilitaInputs = false;
		}
	}

  habInforCamposObrigatorios(){
    const habs = ['incluir','editar']
    if(habs.includes(this.path)){
      return true
    }
    return false
  }

  tituloPagina(){
    if(this.path){
      switch(this.path){
        case "incluir":
          return "Incluir Subsetor";
        case "editar":
          return "Alterar Subsetor";
        case "visualizar":
          return "Visualizar Subsetor";
      }
    }
    return "Subsetor"
  }
	ngOnInit() { }

    validar(){

		// if(!this.objetoSalvar.numeroCodigo){
		// 	this.modal.infoError("Campo Obrigatório não Informado: <b>Código</b>", "Atenção");
		// 	return false;
		// }

		if(!this.objetoSalvar.descSubsetor){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Descrição</b>", "Atenção");
			return false;
		}

		if(this.alterouItem && this.path == "editar"){
			this.validarAlteracao();
		}else{
			this.salvar();
		}
    }

	salvar(){
		this.applicationService.post(this.servico, this.objetoSalvar).subscribe((result :any)=>{
			if(result == 200){
				this.modal.sucesso("Registro salvo com sucesso","Sucesso");
				this.voltar();
			} else if(result == 0){
				this.modal.infoError("Atenção, ja Existe um registro com o mesmo <b>Código</b> cadastrado.","Erro");
				return false;
			}else if(result == 1){
				this.modal.infoError("Atenção, ja Existe um registro com a mesma <b>Descrição</b> cadastrada.","Erro");
				return false;
			}
		});
	}

	voltar(){
		this.router.navigate(['manter-subsetor'])
	}

    limpar(){

    }

    buscar(id: number){
		this.applicationService.get(this.servico, id).subscribe((result: any)=>{
			this.objetoSalvar.id = result.id;
			this.objetoSalvar.numeroCodigo = result.numeroCodigo;
			this.objetoSalvar.descSubsetor = result.descSubsetor;
			this.objetoSalvar.situacao = result.situacao;
		});
	}

	validarAlteracao(){
		this.modal.justificativa("Descrição da justificativa de alteração").subscribe((justificativa: string) => {
			if(justificativa.length > 0){
				this.objetoSalvar.descricaoJustificativa = justificativa;
				this.applicationService.put(this.servico+"/ValidarAlteracao",this.objetoSalvar).subscribe((response: number) => {
					if(response == 200){
						this.modal.infoSucess("Operação Realizada com Sucesso!","Sucess");
						this.router.navigate(['/manter-subsetor']);
					} else {
						this.modal.infoError("Ocorreu um erro ao alterar o resgistro.","Sucesso");
						return false;
					}
				});
			}
		});
	}
}

