<div #appModalIncluirSubsetor class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true"
    data-distance="0" data-size="10px" data-railOpacity="0.2">

    <div class="row m-b-md">
        <div class="col-lg-12">
            <app-titulo *ngIf=" path == 'incluir' ">Cadastrar Unidade</app-titulo>
            <app-titulo *ngIf=" path == 'editar' ">Alterar Unidade</app-titulo>
            <!-- <app-sub-titulo *ngIf=" path == 'incluir' "  >Incluir</app-sub-titulo>
            <app-sub-titulo *ngIf=" path == 'editar' "  >Alterar</app-sub-titulo>         -->
        </div>
    </div>

    <section class="panel panel-default">
        <header class="panel-heading">
            <div class="pull-right">
                <a (click)="voltar()" class="btn btn-default btn-sm">
                    <i class="fa fa-arrow-left"></i> Voltar
                </a>
                <a (click)="validar()" *ngIf=" path != 'visualizar' " class="btn btn-primary btn-sm">
                    <i class="fa fa-save"></i> Salvar
                </a>
            </div>
            <h2 class="panel-title h5">Formulário</h2>
        </header>

        <div class="panel-body">
            <div class="row form-group">
                <div class="col-lg-12">
                    <div class="row form-group" style="margin-left: 3px;">
                        <div class="col-lg-2">
                            <label for="descricao">Código:</label>
                            <input
                                [attr.disabled]="!_habilitaInputs ? true : null"
                                type="text"
                                name="codigo"
                                [(ngModel)]="parametros.numeroCodigo"
                                id="codigo"
                                onlyNumber="true"
                                class="form-control"
                                maxlength="5"
                                value=""
                                [disabled]="desabilitarCodigo ? true : null"
                            />
                        </div>

                        <div class="col-lg-3">
                            <label for="descricao" class="required">Sigla:</label>
                            <input
                                [attr.disabled]="!_habilitaInputs ? true : null"
                                type="text"
                                name="codigo"
                                [(ngModel)]="parametros.sigla"
                                id="sigla"
                                class="form-control"
                                maxlength="20"
                                (change)="alterouItem = true;"
                                value=""
                            />
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="col-lg-11">
                        <label for="codigo"  class="required">Descrição:</label>
                        <textarea
                            class="form-control ng-pristine ng-valid ng-touched"
                            id="Objetivo"
                            style="resize: none;"
                            [attr.disabled]="!_habilitaInputs ? true : null"
                            [(ngModel)]="parametros.descricao"
                            maxlength="250"
                            (change)="alterouItem = true;"
                            rows="2">
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <p *ngIf="habInforCamposObrigatorios()" style="color: red">Os campos com o sinal (*) são de preenchimento obrigatório.</p>

</div>
