<app-grid 
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table table">

            <!-- ##################################################### -->
            <!-- ################ TABELA POR GRUPO ################### -->
            <!-- ##################################################### -->

            <thead class="table-header-color" *ngIf="_isPorGrupo">
                <tr>
                    <th class="th-100 text-left" *ngIf="habilitarInputs">Ações</th>
                    <th class="th-sortable unset-nowrap text-left">
                        <!-- <app-ordenacao field="DescricaTopico" [sorted]="sorted" (onChangeSort)="changeSort($event)"> Etapa </app-ordenacao> -->
                        Etapa
                    </th>					
                    <th class="th-sortable unset-nowrap text-left"> 
                        <app-ordenacao field="DataInicioVigenciaString" [sorted]="sorted" (onChangeSort)="changeSort($event)"> 
                            Vigência 
                        </app-ordenacao> 
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DescricaEtapa" [sorted]="sorted" (onChangeSort)="changeSort($event)"> 
                            Descrição das Etapas 
                        </app-ordenacao>  
                    </th>						
                    <th class="th-sortable unset-nowrap text-left"> 
                        <app-ordenacao field="DescricaoDocumento" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                            Documento 
                        </app-ordenacao> 
                    </th>	
                    <th *ngFor="let item of infoGrupos.letrasGruposPPB; let i = index ;">
                        <app-ordenacao field="{{i}}" [sorted]="sorted" (onChangeSort)="changeSort($event)"> 
                            <span > Grupo {{ item }} </span> 
                        </app-ordenacao>   
                    </th>	
                </tr>
            </thead>
            <tbody *ngIf="_isPorGrupo">		
                <tr *ngFor="let item of lista">    
                    <td class="text-left" *ngIf="habilitarInputs" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <a 
                            *ngIf="!item.indicadorParaTaxarItem"
                            (click)="validarSePermiteAbrirModal(item)"
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip" 
                            title="Alterar" 
                            data-original-title="Alterar">
                                <i class="fa fa-pencil"></i>
                        </a>
                        <a 
                            *ngIf="!item.indicadorParaTaxarItem"
                            (click)="excluir(item)"
                            class="btn btn-danger btn-sm" 
                            data-toggle="tooltip" 
                            title="Excluir" 
                            data-original-title="Excluir">
                                <i class="fa fa-trash-o"></i>
                        </a>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.descricaTopico" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.dataInicioVigenciaString" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.descricaEtapa" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                        <app-taxa-texto 
                            [(texto)]="item.descricaoDocumento" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto> 
                    </td>
                    <td class="text-left" *ngFor="let indice of item.gruposEtapaPPB" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                        {{ indice.totalPontuacao ? indice.totalPontuacao : 0 }}
                    </td>
                    
                </tr>
            </tbody>      


            <!-- ##################################################### -->
            <!-- ################ TABELA PADRÃO ################### -->
            <!-- ##################################################### -->

			<thead class="table-header-color" *ngIf="!_isPorGrupo">
				<tr>
					<th class="th-100 text-left" *ngIf="habilitarInputs">Ações</th>
					<th class="th-sortable unset-nowrap text-left">
                        <!-- <app-ordenacao field="DescricaTopico" [sorted]="sorted" (onChangeSort)="changeSort($event)"> Etapa </app-ordenacao> -->
                        Etapa
                    </th>					
					<th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DataInicioVigenciaString" [sorted]="sorted" (onChangeSort)="changeSort($event)"> Vigência </app-ordenacao> 
                    </th>
					<th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DescricaEtapa" [sorted]="sorted" (onChangeSort)="changeSort($event)"> Descrição das Etapas </app-ordenacao>  
                    </th>						
					<th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="DescricaoDocumento" [sorted]="sorted" (onChangeSort)="changeSort($event)"> Documento </app-ordenacao> 
                    </th>	
                    <th class="th-sortable unset-nowrap text-left" *ngIf="_isPorPontuacao || _isPorGrupo">
                        <app-ordenacao field="ValorPontuacao" [sorted]="sorted" (onChangeSort)="changeSort($event)"> Pontuação </app-ordenacao>  
                    </th>	   
				</tr>
			</thead>
            <tbody *ngIf="!_isPorGrupo">		
                <tr *ngFor="let item of lista" >    
                    <td class="text-left" *ngIf="habilitarInputs" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                        <a 
                            *ngIf="!item.indicadorParaTaxarItem"
                            (click)="validarSePermiteAbrirModal(item)"
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip" 
                            title="Alterar" 
                            data-original-title="Alterar">
                                <i class="fa fa-pencil"></i>
                        </a>
                        <a 
                            *ngIf="!item.indicadorParaTaxarItem"
                            (click)="excluir(item)"
                            class="btn btn-danger btn-sm" 
                            data-toggle="tooltip" 
                            title="Excluir" 
                            data-original-title="Excluir">
                                <i class="fa fa-trash-o"></i>
                        </a>
                    </td>
					<td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.descricaTopico" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.dataInicioVigenciaString" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.descricaEtapa" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                    </td>
                    <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.descricaoDocumento" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto> 
                    </td>
                    <td class="text-left" *ngIf="_isPorPontuacao || _isPorGrupo" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                        <app-taxa-texto 
                            [(texto)]="item.valorPontuacao" 
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto> 
                    </td>
				</tr>
			</tbody>   

		</table>
	</div>


</app-grid>


<!-- ##################################################### -->
<!-- ############## PONTUAÇÃO GERAL ###################### -->
<!-- ##################################################### -->

<div style="margin-top: 10px;" class="table-responsive no-margin-bottom no-border" *ngIf="_isPorPontuacao">
    <table class="table" _ngcontent-c2="">
        <thead style="background-color: #00552b; color: white;">
        <tr>
        </tr>
        </thead>
        <tbody> 
            <tr>
                <td class="text-center">Pontuação Total</td>
                <td  class="text-center">{{ infoGrupos.valorPontuacaoGeral ? infoGrupos.valorPontuacaoGeral : 0 }}</td>
            </tr>
        </tbody>
    </table> 
</div>      

<!-- ##################################################### -->
<!-- ########## TABELA PONTUACAO DOS GRUPOS ############## -->
<!-- ##################################################### -->

<div style="margin-top: 10px;" class="table-responsive no-margin-bottom no-border" *ngIf="_isPorGrupo">
    <table class="table table-striped" _ngcontent-c2="">
        <thead style="background-color: #00552b; color: white;">
            <tr>
            </tr>
        </thead>
        <tbody>           
            <tr *ngFor="let item of infoGrupos.valoresTotaisGrupoMPPB">
                <td  class="text-center">Total do Grupo {{ item.letraGrupo }}</td>
                <td  class="text-center">Total {{ item.totalPontuacao ? item.totalPontuacao : 0}}</td>
            </tr>
        </tbody>
    </table>					 
</div>

<app-modal-incluir-etapas-ppb 
    #appModalIncluirEtapasPPB 
    [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
    (atualizaListagem)="atualizaListagem($event)" 
    [(_isPorPontuacao)] = "_isPorPontuacao">
</app-modal-incluir-etapas-ppb>