import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit  } from "@angular/core";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { ValidationService } from "../../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { ControleEtapasPPBComponent } from "../etapas-ppb.component";
import { Validators, FormBuilder } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EtapaVM } from '../../../../../view-model/EtapaVM';
import { EnumRestResponse } from '../../../../../shared/enums/EnumRestResponse';
import { ModalService } from '../../../../../shared/services/modal.service';
import { ValoresPorGrupoVM } from '../../../../../view-model/ValoresPorGrupoVM';
import { PPBEtapaEntityVM } from '../../../../../view-model/PPBEtapaEntityVM'
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
@Component({
	selector: 'app-modal-incluir-etapas-ppb',
	templateUrl: './modal-incluir-etapas-ppb.component.html'
})
export class ModalIncluirEtapasPPBAtuacao implements OnInit {

	listaDadosAtuacao: any;
	ocultarInputAnexo = false;
	parametros: EtapaVM = new EtapaVM();
	isDesabilitado: boolean = true;
	ocultaCombos: boolean = true;

    _flagTrouxeItensGrupo : boolean = false;
	_flagMostraTxtPontuacao : boolean = false;
	flagCadastroContinuo : boolean = false;
	_flagDesabilitaTopico : boolean = false;
	_flagErroPontuacaoNaoInformada : boolean = false;

	_idPPBRota : number;

	_errorDataFinalMaiorInicial : boolean = false;
	_errorDataInicioNaoPreenchida : boolean = false;
	_errorDataFinalMenorDataCorrente : boolean = false;

	servico = 'EtapasPPB';
	name = 'Angular 12.1.2';
	idEtapa;
	htmlContent = '';

	@Input() dataVigenciaInicialPPB : string;
	@Input() _isPorPontuacao: boolean;	
	@Input() habilitarInputs: boolean;
	@Output() atualizaListagem: EventEmitter<any> = new EventEmitter();

    @ViewChild('appModalIncluirEtapasPPB') appModalIncluirEtapasPPB : any;
	@ViewChild('appModalIncluirEtapasPPBBackground') appModalIncluirEtapasPPBBackground : any;

	constructor(
		private modal : ModalService,
		private ControleEtapasPPBComponent: ControleEtapasPPBComponent,
		private applicationService: ApplicationService,
		private fb: FormBuilder,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
    }

	/************PROPRIEDAS FORM GROUP*************/
	formGroup = this._initializeValidations();
	get topico(){
		return this.formGroup.get('topico');
	}
	get etapa(){
		return this.formGroup.get('etapa');
	}
	get dataInicial(){
		return this.formGroup.get('dataInicial');
	}
	get dataFinal(){
		return this.formGroup.get('dataFinal');
	}
	get pontuacao(){
		return this.formGroup.get('pontuacao');
	}
	get pontuacaoGrupo(){
		return this.formGroup.get('pontuacaoGrupo');
	}
	get checkEmPontuacao(){
		return this.formGroup.get('checkEmPontuacao');
	}
	_initializeValidations(){
		return this.fb.group({
			topico : ['', null],
			etapa : ['', null],
			dataInicial : [ '', [Validators.required]],
			dataFinal : [ '', null],
			pontuacao : ['' , [Validators.minLength(1), Validators.maxLength(7)]],
			pontuacaoGrupo : ['', null],
			checkEmPontuacao : [ '', null]
		});
	}
	/**********************************************/

    ngOnInit() {
	}

	abrir(idPPB : number, operation : string, idEtapa? : number){	

		this.iniciaFlags();

		this.parametros.idPPB = idPPB;
		this.idEtapa = idEtapa;

		this.formGroup = this._initializeValidations();

		if(operation == 'new'){
			this.parametros.dataInicioVigenciaString = this.dataVigenciaInicialPPB;
			this.getGrupos(idPPB);
		} else {
			this.buscar(this.idEtapa);
		}

        this.appModalIncluirEtapasPPBBackground.nativeElement.style.display = 'block';
		this.appModalIncluirEtapasPPB.nativeElement.style.display = 'block';
    }

    fechar(){
		this.iniciaFlags();
		this.formGroup.reset();
        this.appModalIncluirEtapasPPBBackground.nativeElement.style.display = 'none';
		this.appModalIncluirEtapasPPB.nativeElement.style.display = 'none';
    }

	buscar(idEtapa : number){

		var objeto : any = {};
		objeto.id = idEtapa;

		this.applicationService.get(this.servico, objeto).subscribe((retorno : EtapaVM) => {	

			this.parametros = retorno;

			if(this.parametros.valorPontuacao > 0){
				this._flagMostraTxtPontuacao = true;
			}		

			if(this.parametros.gruposEtapaPPB.length){
				this._flagTrouxeItensGrupo = true;
			}		
			
			if(retorno.statusPontuacao != this._idPPBRota){
				this._flagDesabilitaTopico = true;
			}

			if(retorno.statusEtapa == 2){ //SUBSTITUTO
				this._flagDesabilitaTopico = true;
			}

		});	
	}

	validar(){

		var dataInicioString = this.formGroup.get('dataInicial').value;
		var dataFimString = this.formGroup.get('dataFinal').value;

		if(!dataInicioString){

			this._errorDataInicioNaoPreenchida = true;
			this.formGroup.get('dataInicial').reset();
			this.formGroup.get('dataFinal').reset();

			return false;
		} else{
			this._errorDataInicioNaoPreenchida = false;
		}
		

		if(dataInicioString && dataFimString){

			if(this.validation.validaDataMenorDataVigente(dataFimString)){

				this._errorDataFinalMenorDataCorrente = true;
				this.formGroup.get('dataInicial').reset();
				this.formGroup.get('dataFinal').reset();
	
				return false;
			} else {
				this._errorDataFinalMenorDataCorrente = false;
			}

			if(this.validation.validaDataInicialMaiorDataFinal(dataInicioString , dataFimString) == true){

				this._errorDataFinalMaiorInicial = true;
				this.formGroup.get('dataInicial').reset();
				this.formGroup.get('dataFinal').reset();

				return false;
			} else {
				this._errorDataFinalMaiorInicial = false;
			}				
		}	
				
		this.parametros.valorPontuacao ? 
			this.parametros.valorPontuacao = Number(this.parametros.valorPontuacao) : 
				null;

		this.salvar()
	}

	salvar(){
		var idPPB = this.parametros.idPPB;
		this.applicationService.post(this.servico, this.parametros).subscribe((retorno : number) => {		
			if(retorno > 0) {				
				this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success");	
				if(!this.flagCadastroContinuo){
					this.fechar();
				} else {	
					this.iniciaFlags();
					this.parametros.idPPB = idPPB;
					this.flagCadastroContinuo = true;
					this.formGroup.reset();		
					this.getGrupos(idPPB);			
				}
				this.atualizaListagem.emit(idPPB);
			}	
		});	
	}

	getGrupos(idPPB : number){

		var objeto : EtapaVM = new EtapaVM();
		objeto.idPPB = idPPB;

		this.applicationService.get(this.servico+"/GetGrupos", objeto).subscribe( (retorno : []) =>{
			if(retorno){
				this.parametros.gruposEtapaPPB = retorno;
				this._flagTrouxeItensGrupo = true;
			}			
		});
	}

	iniciaFlags(){
		this.parametros = new EtapaVM();
		this._flagDesabilitaTopico = false;
		this._flagTrouxeItensGrupo = false;
		this._flagMostraTxtPontuacao = false;
		this.flagCadastroContinuo = false;
		this._errorDataFinalMaiorInicial = false;
		this._flagErroPontuacaoNaoInformada = false;
	}

	trackByIndex(index: number, obj: any): any {
		return index;
	}


}
