<app-grid
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)"
    style="height: 500px; overflow-y: auto !important; min-height: 200px;"
    >

  <div class="table-responsive no-margin-bottom no-border">
    <table class="table">
      <thead class="table-header-color">
        <tr>
          <th class="th-100 text-left">
            <app-ordenacao field="Id" [sorted]="sorted" (onChangeSort)="changeSort($event)">Ações</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="NumeroCodigo" [sorted]="sorted" (onChangeSort)="changeSort($event)">Código	</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="Descricao" [sorted]="sorted" (onChangeSort)="changeSort($event)">Descrição</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="NumeroUsuario" [sorted]="sorted" (onChangeSort)="changeSort($event)">Login</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="NomeUsuario" [sorted]="sorted" (onChangeSort)="changeSort($event)">Responsável</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Data/Hora
          </th>
          
            
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of lista">
                    <td class="text-left">
                        <a
                           *ngIf="item.tipo == 1"
                            (click)="navigate(item, 'produto')"
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            title="Visualizar"
                            data-original-title="Visualizar">
                                <i class="fa fa-file-text-o"></i>
                        </a>
                        <a
                           *ngIf="item.tipo == 2"
                            (click)="navigate(item, 'unidade')"
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            title="Visualizar"
                            data-original-title="Visualizar">
                                <i class="fa fa-file-text-o"></i>
                        </a>
                        <a
                           *ngIf="item.tipo == 3"
                            (click)="navigate(item, 'subsetor')"
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            title="Visualizar"
                            data-original-title="Visualizar">
                                <i class="fa fa-file-text-o"></i>
                        </a>
                        <a
                           *ngIf="item.tipo == 4"
                            (click)="navigate(item, 'ncm')"
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            title="Visualizar"
                            data-original-title="Visualizar">
                                <i class="fa fa-file-text-o"></i>
                        </a>
                    </td>
                    <td class="text-left">{{ item.codigo }}</td>
                    <td class="text-left">{{ item.descricao }}</td>
                    <td class="text-left">{{ item.login }}</td>
                    <td class="text-left">{{ item.responsavel }}</td>
                    <td class="text-left">{{ item.dataString }}</td>
                    
        </tr>
      </tbody>
    </table>
  </div>
</app-grid>
