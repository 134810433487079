import { Component, Injectable, Input, OnInit, ViewChild } from "@angular/core";
import { MessagesService } from "../../../shared/services/messages.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { ApplicationService } from "../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { PagedItems } from "../../../view-model/PagedItems";
import { OverlayRef } from "@angular/cdk/overlay";

@Component({
	selector: 'app-modal-ncms',
	templateUrl: './modal-ncm.component.html'
})
export class ModalNcm implements OnInit {
	page: number=1;
	size: number=1;
	total: number=1;
	sorted: number=1;
  lista : {}
  grid: any = { sort: {} };
	listaDadosAtuacao: any;
	ocultarInputAnexo = false;
	isDesabilitado: boolean = true;
	ocultaCombos: boolean = true;
  itemProdutoTipo : any
  tituloGrid="Resultado da Pesquisa"

  servico ="RelatorioTipoProdutoPadronizado"
  @Input() statusNCM: any
  parametros: any = {};
  @ViewChild('appModalNcm') appModalNcm : any;
	@ViewChild('appModalNcmBackground') appModalNcmBackground : any;

	constructor(
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
    }

	changeSize($event : any) {
		//this.onChangeSize.emit($event);
		this.grid.size= $event
    this.changePage(this.page);
    this.buscar()
	}

	changeSort($event : any) {
		this.grid.sort = $event;
		//this.onChangeSort.emit($event);
		this.changePage(this.page);
    this.buscar()
	}

	changePage($event : any) {
		this.grid.page = $event;
		//this.onChangePage.emit($event);
    this.buscar()
	}

    ngOnInit() {
	}

	abrir(item : any){

        this.parametros.id = item.id;
        this.itemProdutoTipo= item
        this.buscar()
        this.appModalNcmBackground.nativeElement.style.display = 'block';
	    	this.appModalNcm.nativeElement.style.display = 'block';
    }

    fechar(){
        this.appModalNcmBackground.nativeElement.style.display = 'none';
	    	this.appModalNcm.nativeElement.style.display = 'none';
    }

    buscar(){
      this.parametros.sort = this.grid.sort.field;
      this.parametros.reverse = this.grid.sort.reverse;
      this.parametros.page =this.grid.page;
      this.parametros.size =this.grid.size
      this.parametros.statusNCM= this.statusNCM
      this.applicationService.get(this.servico, this.parametros).subscribe((response: PagedItems) => {
        if (response.total != null && response.total > 0) {
          this.grid.lista = response.items
          this.grid.total = response.total

          this.prencheParametrosDeExportacao()
        }else {
          this.grid = { sort: {} };
        }
      });
    }
    prencheParametrosDeExportacao()
  	{
		this.parametros.exportarListagem = true;
		this.parametros.exportarPdf = true;
		this.parametros.servico = this.servico;
    this.parametros.sort = this.grid.sort.field;
		this.parametros.titulo = "Relatório Tipos de um Produto Padrão"
    var objeto = [{
      "id":this.itemProdutoTipo.id,
			"descricao":this.itemProdutoTipo.descricao,
			"statusTipo":this.itemProdutoTipo.statusTipo == 0? 'INATIVO' : 'ATIVO',
      "ncm":this.retornarNCMConcatenadas(),
      "statusNCM": this.retornarSituacaoNCMConcatenadas()
    }]
    this.parametros.lista= objeto

		this.parametros.columns = [
			"Código",
      "Tipo Produto",
      "Situação(Tipo)",
       "Ncm",
       "Situação(Ncm)"
		];

		this.parametros.fields = [
			"id",
			"descricao",
			"statusTipo",
      "ncm",
      "statusNCM"

		];

		this.parametros.width = {
			0: { cellWidth: 100 },
			1: { cellWidth: 220 },
			2: { cellWidth: 220 }
		};
	}

  retornarNCMConcatenadas(){
    var resultado = ""
    this.grid.lista.map(item=>{
        resultado+= item.descricaoNcm +"\n\n"
    })
    return resultado;
  }

  retornarSituacaoNCMConcatenadas(){
    var resultado = ""
    this.grid.lista.map(item=>{
        resultado+= item.statusNcm===0 ?"INATIVO\n\n\n" : "ATIVO\n\n\n"
    })
    return resultado;
  }


}
