import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { PagedItems } from "../../../../view-model/PagedItems";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { Location } from '@angular/common';
import { ListagemEtapaPPBVM } from '../../../../view-model/ListagemEtapaPPBVM';
import { ChangeEtapaPPBService } from '../../../../shared/services/changeEtapaPPB.service';

@Component({
	selector: "app-etapas-ppb",
	templateUrl: "./etapas-ppb.component.html"
})

@Injectable()
export class ControleEtapasPPBComponent implements OnInit {

	gruposPPB : any ={};
	infoGrupos : any ={};
	form = this;
	path : string;
	grid: any = { sort: {} };
	parametros: any = {};
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;	

	@Input() dataVigenciaInicialPPB : string;
	@Input() habilitarInputs: boolean;	
	@Input() _isSempontuacao : boolean;
	@Input() _isPorPontuacao : boolean;
	@Input() _isPorGrupo: boolean;	
	@Input() validacao : any = {} = null;

	servico = 'EtapasPPB';

	@Input() idPPB: number;	
	@ViewChild('appModalIncluirEtapasPPB') appModalIncluirEtapasPPB : any;

	constructor(
		private ChangeEtapaPPBService : ChangeEtapaPPBService,
        private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private msg: MessagesService,
        private validation: ValidationService,
        private router: Router,
        private _location: Location
	) { 
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
	}

	ngOnInit() { 
		this.listar(this.idPPB);

		this.ChangeEtapaPPBService.AtualizaListagemEtapaPPB.subscribe(
			idPPB => this.listar(idPPB)
		);
	 }

	ocultar() { }

	onChangeSort($event : any) 
	{
		this.grid.sort = $event;
		this.listar(this.idPPB);
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
		this.listar(this.idPPB);
	}

	onChangePage($event : any) {
		this.grid.page = $event;
		this.listar(this.idPPB);
    }

    Voltar(){
    }

	public listar(idPPB : number){

		var objeto : any = {};
		objeto.idPPB = idPPB;

		objeto.page = this.grid.page ? this.grid.page : 1;
		objeto.size = this.grid.size ? this.grid.size : 10;
		objeto.sort = this.grid.sort.field;
		objeto.reverse = this.grid.sort.reverse;
		
		this.grid = { sort: {} };

		this.applicationService.get(this.servico+"/GetPaginado" ,  objeto).subscribe( (retorno : any) => {
			if(retorno.listagemPaginado) {

				this.gruposPPB = retorno.gruposEtapaPPB;
				this.infoGrupos = retorno.grupos;
				this.grid.lista = retorno.listagemPaginado.items;
				this.grid.total = retorno.listagemPaginado.total;
			} else {
				this.grid = { sort: {} };
			}		
		});
	}

	incluirEtapaPPB(){
		this.appModalIncluirEtapasPPB.abrir(this.idPPB , "new");
	}

	atualizaListagem(idPPB){
		this.listar(idPPB);
	}

}