import { Component, Injectable, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { ModalService } from '../../../../shared/services/modal.service';

@Component({
	selector: 'app-modal-escolher-orgaos',
	templateUrl: './modal-escolher-orgaos.component.html'
})
export class ModalEscolherOrgaos implements OnInit {

    objetoOrgaos : any = {};
    _flagTerminouConsulta : boolean = false;
    
    isMct : boolean;
    isMir : boolean;
    isMict : boolean;
    isSepec : boolean;
    isMe : boolean;
    isSuframa : boolean; 
   
    @ViewChild('Mct') Mct : any;
    @ViewChild('Mir') Mir : any;
    @ViewChild('Mict') Mict : any;
    @ViewChild('Sepec') Sepec : any;
    @ViewChild('Me') Me : any;
    @ViewChild('Suframa') Suframa : any;

    @ViewChild('appModalEscolherOrgaos') appModalEscolherOrgaos : any;
	@ViewChild('appModalEscolherOrgaosBackground') appModalEscolherOrgaosBackground : any;

    @Output() finalizouEscolha : EventEmitter<string> = new EventEmitter();

	constructor(
        private modal: ModalService,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
    }

    ngOnInit() {
	}

	public abrir(){	
        this.desabilita();	
        this.listarOrgaos();             
    }

    public fechar(){
        this.desabilita();
        this.appModalEscolherOrgaosBackground.nativeElement.style.display = 'none';
		this.appModalEscolherOrgaos.nativeElement.style.display = 'none';
    }

    listarOrgaos(){
        this.applicationService.get("OrgaoPPB").subscribe(
            (result : any) => {
                if(result.length){
                    this.appModalEscolherOrgaosBackground.nativeElement.style.display = 'block';
                    this.appModalEscolherOrgaos.nativeElement.style.display = 'block';
                    this.objetoOrgaos = result;
                    this._flagTerminouConsulta = true;
                }            
            }
        );
    }

    validar(){
        
        let _stringRetorno = "";
        let _qtdItensSelecionados = 0;

        this.objetoOrgaos.forEach( (element : any) => {           
            if(element.isSelecionada){
                _qtdItensSelecionados++;
                _stringRetorno += element.descricaSigla + "/";
            }
        });

        if(_qtdItensSelecionados == 0){
            this.modal.infoError("Selecione um Orgão!", "Atenção");
            return false;
        }

        this.finalizouEscolha.emit(_stringRetorno.slice(0, -1)); //removendo a '/' do ultimo orgão
        this.fechar();
    }

    desabilita(){
        this. _flagTerminouConsulta = false;
        this.objetoOrgaos = {};
    }

}
