import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-grid-relacao-tipo-ncm-depois',
  templateUrl: './grid-relacao-tipo-ncm-depois.component.html'
})
export class GridRelacaoTipoNcmDepoisComponent implements OnInit {
  constructor() { }
  @Input() lista: any = {};
  @Input() total: number;
  @Input() size: number;
  @Input() page: number;
  @Input() sorted: string;
  @Input() isUsuarioInterno: boolean = false;
  @Input() exportarListagem: boolean;
  @Input() parametros: any = {};
  @Input() formPai: any;
  @Input() somenteLeitura: boolean;

  ngOnInit() {

  }

}
