import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ConsultarAlteracoesComponent } from './consultar-alteracoes.component';
import { GridConsultarAlteracoes } from './grid/grid.component';
import { VisualizarAlteracoesComponent } from './visualizar/visualizar-alteracoes.component';
import { RelatorioAlteracoesComponent } from './relatorios/relatorio.component';
import { GridRelacaoTipoNcmAntesComponent } from './grid/grid-relacao-tipo-ncm-antes/grid-relacao-tipo-ncm-antes.component';
import { GridRelacaoTipoNcmDepoisComponent } from './grid/grid-relacao-tipo-ncm-depois/grid-relacao-tipo-ncm-depois.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,

  ],
	declarations: [
    ConsultarAlteracoesComponent,
    GridConsultarAlteracoes,
    VisualizarAlteracoesComponent,
    RelatorioAlteracoesComponent,
    GridRelacaoTipoNcmAntesComponent,
    GridRelacaoTipoNcmDepoisComponent
  ]
})
export class ConsultarAlteracoesModule { }
