import { EmitirRelatorioProdutoPadronizadoComponent } from './../relatorio-produto-padronizado/relatorio-produto-padronizado/emitir-relatorio-produto-padronizado.component';
import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../shared/services/application.service";
import { MessagesService } from "../../shared/services/messages.service";
import { ModalService } from "../../shared/services/modal.service";
import { ValidationService } from "../../shared/services/validation.service";
import { ConsultarProdutoVM } from "../../view-model/ConsultarProdutoVM";
import { PagedItems } from "../../view-model/PagedItems";
import { ChangeIniciaPaginacao } from "../../shared/services/changeIniciaPaginacao.service";
import { ProdutoTipoVM } from "../../view-model/ProdutoTipoVM";
import { RelatorioEmitirTipoProdutoPadraoComponent } from "./relatorio-emitir-tipo-produto-padrao/relatorio-emitir-tipo-produto-padrao.component";
import { InforFiltroRelatorioGrid } from '../../shared/services/pdf.service';

declare var html2pdf: any;

@Component({
	selector: "app-relatorio-produto-padronizado",
	templateUrl: "./relatorio-tipo-de-produto-padrao.component.html"
})
@Injectable()
export class RelatorioTipoProdutoPadraoComponent implements OnInit {
  form = this;
	grid: any = { sort: { field:"numeroCodigo"} };
	parametros = new ProdutoTipoVM();
	flagAlterouFiltro : boolean = false;
	ocultarFiltros: boolean = false;
	isBuscaSalva: boolean = false;
	objetoConsulta: any = {} = null;
  servico= "RelatorioTipoProdutoPadronizado"
  variavel2 : any;
  listaProdutoTipoPadrao : any = {};
  exibeRelatorio: boolean = false;
  exibirRelatorio2: boolean=false
  mostraComponenteRelatorio: boolean
  ocultarGrid: boolean = true;
  eUsuarioSuframa:boolean;

	listaUnidadeDeMedida = new Array<any>();

	@ViewChild("relatorioPDF") htmlData: ElementRef;
	@ViewChild("Produto") Produto;
	@ViewChild("BaseLegal") BaseLegal;
  @ViewChild('appRelatorioEmitirTipoProdutoPadrao') appRelatorioEmitirTipoProdutoPadrao : RelatorioEmitirTipoProdutoPadraoComponent;


	constructor(
		private changeIniciaPaginacao : ChangeIniciaPaginacao,
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute,
		private validation: ValidationService
	) {
		if (sessionStorage.getItem(this.router.url) == null && sessionStorage.length > 0){
			sessionStorage.clear();
    }
    this.eUsuarioSuframa =JSON.parse(localStorage.getItem("usuarioSuframa"));

	}

	ngOnInit() {
    this.parametros.statusNCM = 99;
    this.parametros.statusTipo = 99;
    this.parametros.id = 99;
    this.ocultarGrid = true;
	}

  buscar(){
    if(!this.parametros.numeroCodigo || !this.parametros.descProduto){
      this.modal.infoError("Campo obrigatório não Informado: <b>Produto</b>","Atenção");
	    return false;
    }

    this.parametros.sort = this.grid.sort.field || "numeroCodigo";
  	this.parametros.reverse = this.grid.sort.reverse;
    this.parametros.page =this.grid.page || 1;
    this.parametros.size =this.grid.size || 10;
		this.flagAlterouFiltro = false;

		this.applicationService.get(this.servico+'/GetProdutosTipos', this.parametros).subscribe((response: PagedItems) => {
			if (response.total != null && response.total > 0) {
				this.grid.lista = response.items;
        this.grid.total = response.total;
        this.prencheParametrosDeExportacao();
        this.ocultarGrid = false;
			}else {
				this.grid = { sort: {} };
        this.parametros.exportarListagem = false;
		    this.parametros.exportarPdf = false;
        this.modal.infoError("Nenhum registro encontrado","Atenção");
			}
		});
  }

  buscarDadosRelatorio(){
    this.applicationService.get(this.servico+'/GetListaRelatorio', this.parametros).subscribe((response: any) => {
      if(response){
        this.listaProdutoTipoPadrao = response;
        this.exibeRelatorio = true;
        this.appRelatorioEmitirTipoProdutoPadrao.start();
      }else{
        this.exibeRelatorio = false;
      }
		});

  }

	onChangeSort($event: any) {
		this.grid.sort = $event;
   this.buscar()
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
     this.buscar()
	}

	onChangePage($event: any) {
		this.grid.page = $event;
    this.buscar()
	}

  obterDsSelecionadoDropdown(id:string){
    let selected = $( `#${id} option:selected` ).text();
    if(selected.includes("Selecione uma opção")){
      return null;
    }
    return selected;
  }
  obterDsProduto(){
    const produto = this.Produto.getSelectedValue()
    if(!produto){
      return null;
    }
    return produto.text
  }
  prencheParametrosDeExportacao()
	{
		this.parametros.exportarListagem = true;
		this.parametros.exportarPdf = true;
		this.parametros.servico = this.servico+"/GetProdutosTipos";
		this.parametros.titulo = "Relatório Tipos de um Produto Padrão";
    this.parametros.page = 1;
    this.parametros.sort = this.grid.sort.field;
    this.parametros.size = 100000;


    this.parametros.informacoesExtrasRelatorio = [
      {
        label:"Produto",
        text: this.obterDsProduto()
      },
      {
        label:"Situação (Tipo)",
        text:this.obterDsSelecionadoDropdown("situacaoTipo")
      },
      {
        label:"Situação (NCM)",
        text:this.obterDsSelecionadoDropdown("situacaoNcm")
      },

    ] as Array<InforFiltroRelatorioGrid>;



		this.parametros.columns = [
			"Código",
      "Tipo Produto",
      "Situação(Tipo)",
		];
		this.parametros.fields = [
			"numeroCodigo",
			"descricao",
			"statusTipoFormatado",
		];
		this.parametros.width = {
			0: { cellWidth: 100 },
			1: { cellWidth: 220 },
			2: { cellWidth: 220 }
		};
	}

  gerarRelatorio(){
    this.buscarDadosRelatorio()
  }

  AlteraProduto($event){
		if(this.Produto.model.text != null && this.Produto.model.text != undefined && this.Produto.model.text != ""){
			let codigo = this.Produto.model.text.split(" | ")[0];
     		let descricao = this.Produto.model.text.split(" | ")[1];
		   	this.parametros.numeroCodigo = codigo;
    		this.parametros.descProduto = descricao;
    		this.parametros.idProduto = $event;
		}
	}

  limparFiltros(){
    this.ocultarGrid = true;
    this.Produto.clear();
    this.startFiltros();
  }

  startFiltros(){
    this.parametros.statusTipo = 99;
    this.parametros.statusNCM = 99;
    this.parametros.id= 99;
    this.parametros.numeroCodigo =null
    this.parametros.descProduto=null
    this.grid = { sort: { field:"numeroCodigo"} };
  }

}

