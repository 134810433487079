<div #appModalListagemPPB 
     class="modal modal-wide-2 fade in centro" 
     tabindex="-1" 
     role="dialog" 
     aria-hidden="false"
     style="margin-left: 30%; width: 800px; display: auto; overflow: scroll; overflow-y: auto;">
     
  <div class="modal-dialog modal-sm conteudo">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title">Listagem de Bases Legais</h4>
      </div>

      <div class="modal-body">
        <h2 class="panel-title h5" style="margin-bottom: 10px;"> Descrição produto: {{descricaoProduto}}</h2>
        <app-grid-listagem-ppb
          [(lista)]="grid.lista"
          [(total)]="grid.total"
          [(size)]="grid.size"
          [(page)]="grid.page"
          [(parametros)]="parametros"
          (onChangeSort)="onChangeSort($event)"
          (onChangeSize)="onChangeSize($event)"
          (onChangePage)="onChangePage($event)"
        >
        </app-grid-listagem-ppb>
      </div>
      <div class="modal-footer" style="padding: 8px 15px;">
        <button 
            type="button" 
            class="btn btn-default btn-sm" 
            (click)="fechar()"
            #close>
              <span class="fa fa-times m-r-xs"></span>Fechar
        </button>
      </div>
    </div>
  </div>
</div>
<div #appModalListagemPPBBackground class="modal-backdrop fade in" style="display: none;"></div>

