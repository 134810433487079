
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// ORDEM ALFABÉTICA
import { ApplicationService } from './application.service';
import { ArrayService } from './array.service';
import { AuthenticationService } from './authentication.service';
import { ConverterService } from './converter.service';
import { DateService } from './date.service';
import { ExtractNumberService } from './extract-number.service';
import { GeneratorService } from './generator.service';
import { InformationService } from './information.service';
import { LoadingService } from './loading.service';
import { MessagesService } from './messages.service';
import { ObjectService } from './object.service';
import { SharedDataService } from './shared-data.service';
import { StringService } from './string.service';
import { ValidationService } from './validation.service';
import { ExcelService } from './excel.service';
import { Excel2Service } from './excel2.service';
import { DataSharedService } from './data-shared.service';
import { PssService } from './pss.service';
import { DiffDateDaysService } from './diff-date-days.service';
import { PDFService } from './pdf.service';
import { pdfServiceProdutoPadrao } from './pdfRelatorioProduto.service';
import { pdfServiceTipoProdutoPadrao } from './pdfRelatorioTipoProduto.service';
import { ModalService } from './modal.service';
import { ChangeEtapaPPBService } from './changeEtapaPPB.service';
import { ChangeNcmService } from './changeListagemNcm.service';
import { ChangeListagemTipoProdutoService } from './changeListagemTipoProduto.service';
import { ChangeIniciaPaginacao } from './changeIniciaPaginacao.service';
import { LocalStorageFiltroPesquisaService } from './localStorageFiltroPesquisa.service';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [],
	providers: [
		ChangeIniciaPaginacao,
		ApplicationService,
		ArrayService,
		AuthenticationService,
		ConverterService,
		DateService,
		ExtractNumberService,
		GeneratorService,
		InformationService,
		LoadingService,
		MessagesService,
		ObjectService,
		PssService,
		ModalService,
		SharedDataService,
		StringService,
		ValidationService,
		ExcelService,
		Excel2Service,
		PDFService,
    pdfServiceProdutoPadrao,
    pdfServiceTipoProdutoPadrao,
		DataSharedService,
		DiffDateDaysService,
		ChangeEtapaPPBService,
		ChangeNcmService,
		ChangeListagemTipoProdutoService,
    LocalStorageFiltroPesquisaService
	]
})
export class ServicesModule { }
