/* IMPORTAÇÃO DE MODULOS */
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from "ngx-mask";
import { SharedModule } from "../../shared/shared.module";
import { ManterUnidadeComponent } from "./manter-unidade.component";
import { GridManterUnidadeComponent } from "./grid/grid.component";
import { IncluirUnidadeComponent } from "./incluir-unidade/incluir-unidade.component";
import { ModalJustificativaUnidadeComponent } from "./modal-justificativa/modal-justificativa.component";
import { ModalHistoricoUnidadeComponent } from "./modal-historico/modal-historico.component";


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		SharedModule,
		AngularEditorModule,
		NgbModule,
		NgxMaskModule.forChild()
	],
	declarations: [
        ManterUnidadeComponent,
        GridManterUnidadeComponent,
		IncluirUnidadeComponent,
		ModalJustificativaUnidadeComponent,
		ModalHistoricoUnidadeComponent
	],
})
export class ManterUnidadeModule {}