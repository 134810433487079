<app-grid
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">



    <div class="radio i-checks radio-sm" style="margin-right: 1px; margin-left: 10px;">
      <label class="input-sm" >
          <input
              name="radio-todos-ncm"
              id="radio-todos-ncm"
              type="radio"
              (change)="filtrarLista()"
              [(ngModel)]="parametros.statusNcm"
              value="3"><i></i> <b>TODAS</b>
      </label>
      <label class="input-sm" >
          <input
              (change)="filtrarLista()"
              name="radio-ativo-ncm"
              id="radio-ativo-ncm"
              type="radio"
              [(ngModel)]="parametros.statusNcm"
              value="1"><i></i> <b>ATIVAS</b>
      </label>
      <label class="input-sm" >
          <input
              (change)="filtrarLista()"
              name="radio-inativo-ncm"
              id="radio-inativo-ncm"
              type="radio"
              [(ngModel)]="parametros.statusNcm"
              value="2"><i></i> <b>INATIVAS</b>
      </label>
  </div>

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
			<thead class="table-header-color">
				<tr>
					<th class="th-100 text-left" *ngIf="habilitarInputs">Ações</th>
					<th class="th-sortable unset-nowrap text-left">Código</th>
					<th class="th-sortable unset-nowrap text-left">Descrição</th>
					<th class="th-sortable unset-nowrap text-left">Unidade</th>
                    <th class="th-sortable unset-nowrap text-left">Situação</th>
				</tr>
			</thead>
			<tbody *ngIf="total > 0">
				<tr *ngFor="let item of lista">
                    <td class="text-left" *ngIf="habilitarInputs">
                        <!-- <a
                            class="btn btn-default btn-sm"
                            data-toggle="tooltip"
                            title="Excluir"
                            data-original-title="Alterar"
                            (click)="excluir(item)">
                                <i class="fa fa-pencil"></i>
                        </a>  -->
                        <a
                            *ngIf=" !habilitarAlteracaoStatus "
                            class="btn btn-danger btn-sm"
                            data-toggle="tooltip"
                            title="Excluir"
                            data-original-title="Excluir"
                            (click)="excluir(item)">
                                <i class="fa fa-trash-o"></i>
                        </a>
                        <a
                            *ngIf="(path =='editar' && habilitarAlteracaoStatus) "
                            (click)="excluirNcmAtivo(item)"
                            class="btn btn-danger btn-sm"
                            data-toggle="tooltip"
                            title="Excluir"
                            data-original-title="Excluir">
                                <i class="fa fa-trash-o"></i>
                        </a>
                        <a
                            (click)="changeStatus('Ativar', item)"
                            *ngIf=" habilitarAlteracaoStatus  && item.statusNcm === 0 "
                            class=" btn btn-rounded btn-sm btn-icon btn-danger"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Inativo">
                            <i class="fa fa-times"></i>
                        </a>
                        <a
                            (click)="changeStatus('Inativar', item)"
                            *ngIf=" habilitarAlteracaoStatus  && item.statusNcm === 1 "
                            class="btn btn-rounded btn-sm btn-icon btn-primary"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Ativo">
                            <i class="fa fa-check"></i>
                        </a>
                    </td>
					<td class="text-left"> {{item.numeroCodigo}} </td>
                    <td class="text-left"> {{item.descricaoProdutoTipoNcm}} </td>
                    <td class="text-left"> {{item.unidadeMedida.descricao}} </td>
                    <td class="text-left" *ngIf="item.statusNcm==0" ><b style="color:red;">Inativo</b></td>
                    <td class="text-left" *ngIf="item.statusNcm==1" ><b style="color:green;">Ativo</b></td>
				</tr>
			</tbody>
		</table>
	</div>
</app-grid>

<app-modal-justificativa-produto
    (changeOperation)="recebeAlteracaoJustificativa($event)"
    [(data)]="_dataJustificativa"
    #modalJustificativa>
</app-modal-justificativa-produto>

<app-modal-manter-ncm
    #appModalMnaterNcm
    [(idTipoProduto)]="idTipoProduto"
    (atualizaListagem)="atualizacaoListagem($event)"
    [(habilitarInputs)]="habilitarInputs">
</app-modal-manter-ncm>
