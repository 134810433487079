import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../../shared/services/application.service";
import { MessagesService } from "../../../shared/services/messages.service";
import { ModalService } from "../../../shared/services/modal.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { UnidadeMedidaVM } from "../../../view-model/UnidadeMedidaVM";

@Component({
	selector: "incluir-unidade",
	templateUrl: "./incluir-unidade.component.html"
})

@Injectable()
export class IncluirUnidadeComponent implements OnInit {
	desabilitarCodigo : boolean = true;
    grid: any = { sort: {} };
	servico = 'ManterUnidade';
	parametros = new UnidadeMedidaVM()
	path :any
	_idRota : number = 0
	_habilitaInputs: boolean = true
	disable : boolean;
	alterouItem: boolean = false;

    constructor(
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute,
		private validation: ValidationService
	) {
		if (sessionStorage.getItem(this.router.url) == null && sessionStorage.length > 0)
			sessionStorage.clear();

		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;

		if(this.path !="incluir"){
			this._idRota = Number(this.route.snapshot.params['id']);
			this.buscar(this._idRota);
		}

		if(this.path == "editar")
		this._habilitaInputs = true;

		if(this.path == "visualizar")
			this._habilitaInputs = false;


	}

	ngOnInit() {
		if(this.path == 'visualizar'){
			this._habilitaInputs = false;
		}
		if(this.path == 'editar'){
			this._habilitaInputs = true;
		}
    }

    validar(){

		if(!this.parametros.sigla){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Sigla</b>", "Atenção");
			return false;
		}
		if(!this.parametros.descricao){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Descrição</b>", "Atenção");
			return false;
		}

		if(this.path == 'editar' && this.alterouItem){
			this.validarAlteracao();
		}else{
			this.salvar();
		}
    }

	salvar(){
		this.applicationService.post(this.servico, this.parametros).subscribe((result :any)=>{
			if(result == 200){
				this.modal.sucesso("Operação Realizada com Sucesso","Sucesso");
				this.router.navigate(['manter-unidade']);
			} else if(result == 0){
				this.modal.infoError("Atenção, ja Existe um registro com o mesmo <b>Código</b> cadastrado.","Sucesso");
				return false;
			}else if(result == 1){
				this.modal.infoError("Atenção, ja Existe um registro com a mesma <b>Sigla</b> cadastrada.","Sucesso");
				return false;
			}
		  	 else if(result ==2){
				this.modal.infoError("Atenção, ja Existe um registro com a mesma <b>Descrição</b> cadastrada.","Sucesso")
			}
		});
	}

  habInforCamposObrigatorios(){
    const habs = ['incluir','editar']
    if(habs.includes(this.path)){
      return true
    }
    return false
  }

	voltar(){
		this.router.navigate(['manter-unidade']);
	}

    limpar(){

    }
    buscar(id: number){
		this.applicationService.get(this.servico, id).subscribe((result: any)=>{
			this.parametros.id = result.id;
			this.parametros.numeroCodigo =result.numeroCodigo;
			this.parametros.descricao = result.descricao;
			this.parametros.sigla = result.sigla;
			this.parametros.situacao = result.situacao;
		});
	}


	validarAlteracao(){
		this.modal.justificativa("Descrição da justificativa de alteração").subscribe((justificativa: any) => {

			if(justificativa){
				this.parametros.descricaoJustificativa = justificativa;
				this.applicationService.put(this.servico+"/ValidarAlteracao",this.parametros).subscribe((response: number) => {
					if(response == 200){
						this.modal.infoSucess("Operação Realizada com Sucesso!","Sucess");
						this.router.navigate(['/manter-unidade']);
					} else {
						this.modal.infoError("Ocorreu um erro ao alterar o resgistro.","Sucesso");
						return false;
					}
				});
			}
		});
	}
}

