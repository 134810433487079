import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class ChangeListagemTipoProdutoService {

    AtualizaListagemGrupoPPB : EventEmitter<any> = new EventEmitter()

	constructor() {
	}

	public atualizaListagemPPB(getSessionStorage : boolean) {
		this.AtualizaListagemGrupoPPB.emit(getSessionStorage);
	}

    //ASSISTA https://www.youtube.com/watch?v=R9afVKty3Dg&ab_channel=LoianeGroner

}