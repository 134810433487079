<div #appModalVincular class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll; overflow-y: auto;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Vincular Etapas</h4>
            </div>

            <div class="modal-body" style="overflow-y: auto;" *ngIf="total > 0">

                <!-- <button
                    style="margin-left: 14px;"
                    (click)="limparChecks()"
                    #btnlimpar
                    class="btn btn-sm btn-default"
                    type="reset">
                        <i class="fa fa-eraser"></i> Limpar Checks
                </button> -->

                <form class="form form-horinzontal needs-validation" role="form" novalidate>

                    <div class="row form-group" style="margin-left: 15px; margin-top: 15px;">

                        <div *ngIf=" _errorNenhumGrupoSelecionado " class="alert-danger">
                            <b style="margin-left: 15px;">
                                <button type="button" class="close" (click)=" _errorNenhumGrupoSelecionado = false; "><b style="color:black;">&times;</b></button>
                                <b style="margin-left: 15px;"> SELECIONE UM GRUPO!! </b>
                            </b>
                        </div>

                        <app-grid
                            [(lista)] = "lista"
                            [(titulo)] = "tituloGrid"
                            [(page)] = "page"
                            [(size)] = "size"
                            [(total)] = "total"
                            [(parametros)] = "parametros"
                            (onChangeSize) = "onChangeSize($event)"
                            (onChangePage) = "onChangePage($event)"
                            (onChangeSort) = "onChangeSort($event)">

                            <div class="table-responsive no-margin-bottom no-border">
                                <table class="table">
                                    <thead class="table-header-color">
                                        <tr>
                                            <th class="th-100 text-left">Grupo</th>
                                            <th class="th-sortable unset-nowrap text-left">
                                                <app-ordenacao field="DescricaoProdutos" [sorted]="sorted" >Produto</app-ordenacao>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of lista ; let i = index ;" [attr.data-index]="i">
                                            <td class="text-left">
                                                <input
                                                    id = "{{i}}"
                                                    name = "radio-grupos-ppb"
                                                    type = "radio"
                                                    [checked] = "item.isChecked ? true : false"
                                                    value = "{{item.idGrupo}}"
                                                    (change) = " idGrupoSelecionado = item.idGrupo ; descricaoGrupoSelecionado = item.descricaoGrupo ; "
                                                >
                                                <i></i>{{ item.descricaoGrupo }}
                                            </td>
                                            <td class="text-left"> {{ item.descricaoProdutos }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </app-grid>

                        <div *ngIf=" _errorNenhumGrupoSelecionado " class="alert-danger">
                            <b style="margin-left: 15px;">
                                <button type="button" class="close" (click)=" _errorNenhumGrupoSelecionado = false; "><b style="color:black;">&times;</b></button>
                                <b style="margin-left: 15px;"> SELECIONE UM GRUPO!! </b>
                            </b>
                        </div>

                    </div>

                </form>

<!--
                <button
                    style="margin-left: 14px;"
                    (click)="limparChecks()"
                    #btnlimpar
                    class="btn btn-sm btn-default"
                    type="reset">
                        <i class="fa fa-eraser"></i> Limpar Checks
                </button> -->

            </div>

            <div class="modal-footer" style="padding: 10px 15px;">
                <button
                    id="fechar-ncm"
                    name="fechar-ncm"
                    (click)="fechar()"
                    class="btn btn-sm btn-default"
                    data-dismiss="modal">
                        <i class="fa fa-times"></i> Fechar
                </button>
                <button
                    id="salvar-ncm"
                    name="salvar-ncm"
                    data-toggle="tooltip"
                    title="salvar"
                    data-original-title="salvar"
                    (click)="validar()"
                    type="submit"
                    class="btn btn-sm btn-primary"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-backdrop="static"
                    data-keyboard="false">
                        <i class="fa fa-save m-r-xs"></i> Salvar
                </button>
            </div>

        </div>

    </div>
</div>
<div #appModalVincularBackground class="modal-backdrop fade in" style="display: none;"></div>
