import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class ChangeNcmService {

    atualizaListagem : EventEmitter<any> = new EventEmitter()

	constructor() {
	}

	public atualizaListagemNcm(idPPB : any) {
		this.atualizaListagem.emit(idPPB);
	}

    //ASSISTA https://www.youtube.com/watch?v=R9afVKty3Dg&ab_channel=LoianeGroner

}
