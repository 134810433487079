import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from '../../../../shared/services/application.service';
import { PagedItems } from '../../../../view-model/PagedItems';


@Component({
	selector: 'app-modal-listagem-ppb',
	templateUrl: './modal-listagem-ppb.component.html'
})

export class ModalListagemPPBComponent implements OnInit {

	_flagCampoVazio : boolean = false;
	_justificativa : string = "";
	grid: any = { sort: {} };

	parametros: any = {};
	servico = "PpbVinculados/ProdutoPPBPaginado";
	idProduto: number;
	descricaoProduto: string;

	@Input() data: any = {};


	@ViewChild('appModalListagemPPB') appModalListagemPPB : any;
	@ViewChild('appModalListagemPPBBackground') appModalListagemPPBBackground : any;

	@ViewChild('close') close!: ElementRef;
	@ViewChild('ok') ok!: ElementRef;

	constructor(
		private applicationService: ApplicationService,
	) {}

	ngOnInit() {

	}
	
	onChangeSort($event: any) {
		this.grid.sort = $event;
		this.buscar();
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
		this.buscar();
	}

	onChangePage($event: any) {
		this.grid.page = $event;
		this.buscar();
	}

	abrir(idProduto: number, descricaoProduto: string){
		this.idProduto = idProduto;
		this.descricaoProduto = descricaoProduto;

		this.buscar();

		this.appModalListagemPPBBackground.nativeElement.style.display = 'block';
		this.appModalListagemPPB.nativeElement.style.display = 'block';
	}

	fechar(){
		this.appModalListagemPPBBackground.nativeElement.style.display = 'none';
		this.appModalListagemPPB.nativeElement.style.display = 'none';

		this.grid = { sort: {} };
		this.parametros = {};

		this.idProduto = 0;
		this.descricaoProduto = null;
	}

	buscar(){
		this.parametros.sort = this.grid.sort;
		this.parametros.page = this.grid.page;
		this.parametros.size = this.grid.size;
		this.parametros.reverse = this.grid.reverse;
		this.parametros.id = this.idProduto;

		this.applicationService.get(this.servico, this.parametros).subscribe((result: PagedItems) => {
			 if(result.total > 0){
				this.grid.lista = result.items;
				this.grid.total = result.total;
			 }else{
				this.grid = { sort: {} };
			 }
		});

	}

}
