import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { PagedItems } from "../../view-model/PagedItems";
import { ModalService } from '../../shared/services/modal.service';
import { MessagesService } from "../../shared/services/messages.service";
import { ValidationService } from "../../shared/services/validation.service";
import { ApplicationService } from "../../shared/services/application.service";
import { Router, ActivatedRoute } from '@angular/router';
import { ConsultarPPBVM } from "../../view-model/ConsultarPPBVM";
import { EnumStatusPPB } from "../../shared/enums/EnumStatusPPB";
import { PPBVM } from "../../view-model/PPBVM";

declare var html2pdf: any;
@Component({
	selector: "app-manter-ppb",
	templateUrl: "./manter-ppb.component.html"
})

@Injectable()
export class ManterPPBComponent implements OnInit {
	form = this;
	grid: any = { sort: {} };
	parametros: ConsultarPPBVM = new ConsultarPPBVM();
	ocultarFiltros: boolean = false;
	isBuscaSalva: boolean = false;
	servico = 'ConsultarPPB/ConsultarPPB';
	objetoConsulta: any = {} = null;
	listaTipoDocumento = new Array<any>();

	@ViewChild("relatorioPDF") htmlData: ElementRef;

	constructor(
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute,
		private validation: ValidationService
	) {
		if (sessionStorage.getItem(this.router.url) == null && sessionStorage.length > 0)
			sessionStorage.clear();

	}

	ngOnInit() {

		this.preencheComboboxTipoDocumento();
		this.retornaValorSessao();

		if (this.isBuscaSalva) {
			this.buscar(true);
		}

		this.parametros.exportarListagem = false;
	}

	onChangeSort($event: any) {
		this.grid.sort = $event;
		this.buscar(false);
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
		this.buscar(false);
	}

	onChangePage($event: any) {
		this.grid.page = $event;
		this.buscar(false);
	}

	validar(): boolean {

		var params = this.parametros;

		if (params.PalavraChave) {
			if (params.PosicaoPalavra == 0) {
				this.modal.informacao("Para buscar por Palavra Chave, é preciso escolher uma das opções abaixo", "Alerta");
				return false;
			} else {
				params.PosicaoPalavra = Number(params.PosicaoPalavra);
				return true;
			}
		} else {
			return true;
		}
	}

	buscar(getSessionStorage: boolean) {

		var objeto: any = {};

		if (getSessionStorage) {
			objeto = {
				page: this.objetoConsulta.page ? this.objetoConsulta.page : 1,
				size: this.objetoConsulta.size ? this.objetoConsulta.size : 10,
				TipoPublicacao: this.objetoConsulta.TipoPublicacao ? this.objetoConsulta.TipoPublicacao : null,
				TipoDocumento: this.objetoConsulta.TipoDocumento ? this.objetoConsulta.TipoDocumento : null,
				PosicaoPalavra: this.objetoConsulta.PosicaoPalavra ? this.objetoConsulta.PosicaoPalavra : null,
				PalavraChave: this.objetoConsulta.PalavraChave ? this.objetoConsulta.PalavraChave : null,
				Situacao: this.parametros.Situacao ? this.parametros.Situacao : null,
				Numero: this.objetoConsulta.Numero ? this.objetoConsulta.Numero : null,
				Ano: this.objetoConsulta.Ano ? this.objetoConsulta.Ano : null
			};
			this.parametros.TipoPublicacao = objeto.tipoPublicacao ? objeto.tipoPublicacao : 0;
			this.parametros.TipoDocumento = objeto.tipoDocumento ? objeto.tipoDocumento : 0;
			this.parametros.PosicaoPalavra = objeto.PosicaoPalavra ? objeto.PosicaoPalavra : 0;
			this.parametros.PalavraChave = objeto.PalavraChave ? objeto.PalavraChave : null;
			this.parametros.Situacao = objeto.Situacao ? objeto.Situacao : 0;
			this.parametros.Numero = objeto.Numero ? objeto.Numero : null;
			this.parametros.Ano = objeto.Ano ? objeto.Ano : null;

			this.grid.size = objeto.size;
			this.grid.page = objeto.page;
		}
		else if (this.validar()) {
			objeto = {
				page: this.grid.page ? this.grid.page : 1,
				size: this.grid.size ? this.grid.size : 10,
				sort: this.grid.sort.field,
				reverse: this.grid.sort.reverse,
				TipoPublicacao: this.parametros.TipoPublicacao,
				TipoDocumento: this.parametros.TipoDocumento,
				PosicaoPalavra: this.parametros.PosicaoPalavra,
				Situacao: this.parametros.Situacao,
				PalavraChave: this.parametros.PalavraChave,
				Numero: this.parametros.Numero,
				Ano: this.parametros.Ano
			};
		}

		this.applicationService.get(this.servico, objeto).subscribe((response: PagedItems) => {
			if (response.total != null && response.total > 0) {
				this.grid.lista = this.incluirCampoDocumento(response.items);
				this.grid.total = response.total;

				this.prencheParametrosDeExportacao();
				this.gravarBusca(objeto);
			} else {
				this.grid = { sort: {} };
				this.parametros.exportarListagem = false;
				this.modal.infoError(this.msg.NENHUM_REGISTRO_ENCONTRADO, "Alerta");
			}
		});
	}

	limpar() {
		this.parametros = new ConsultarPPBVM();
	}

	gerarNovoPPB() {
		this.modal.confirmacao("Deseja Cadastrar um Novo Processo Produtivo Básico?").subscribe((isConfirmado: any) => {
			if (isConfirmado) {
				this.router.navigate(['/solicitacoes-ppb/novo']);
			}
		});
	}

	prencheParametrosDeExportacao() {

		this.formatarItensDaLista(this.grid.lista);
		this.parametros.exportarListagem = true;
		this.parametros.exportarPdf = true;
		this.parametros.servico = this.servico;
		this.parametros.titulo = "Manter PPB"

		this.parametros.columns = [
			"Situação",
			"Documento",
			"Publicação",
			"Inicio Virgência",
			"Tipo de Vínculo",
			"Documento de Refêrencia"
		];

		this.parametros.fields = [
			"situacao",
			"doumento",
			"publicacao",
			"virgencia",
			"tipoVinculo",
			"documenoReferencia"
		];

		this.parametros.width = {
			0: { cellWidth: 100 }, 1: { cellWidth: 100 },
			2: { cellWidth: 100 }, 3: { cellWidth: 100 },
			4: { cellWidth: 100 }, 5: { cellWidth: 100 }
		};
	}

	incluirCampoDocumento(lista: Array<any>): Array<any> {
		lista.forEach(item => {
			item.documento = item.tipoDocumento.descricao + " " + item.descricaOrgao + " Nº" +
				+ item.numeroDocumento + ", DE " +
				+ new Date(item.dataPublicacao).getDate() + 1 + " DE " + (new Date(item.dataPublicacao).getMonth() + 1) + " DE " +
				+ new Date(item.dataPublicacao).getFullYear()
		});

		return lista;
	}

	formatarItensDaLista(parametros: Array<PPBVM>): void {
		this.parametros.lista = new Array<any>();
		parametros.forEach(item => {
			this.parametros.lista.push({
				situacao: this.definirStatus(item.statusPPB),
				doumento: item.documento,
				publicacao: this.formatDate(item.dataPublicacao),
				virgencia: this.formatDate(item.dataInicioVigencia),
				tipoVinculo: this.definirVinculo(item.tipoPublicacao),
				documenoReferencia: item.descricaoTitulo
			});
		});
	}

	transformaMes(mes: number): string {
		switch (mes) {
			case 1:

				return 'JANEIRO';

			case 2:
				return 'FEVEREIRO';

			case 3:
				return 'MARÇO';

			case 4:
				return 'ABRIL';

			case 5:
				return 'MAIO';

			case 6:
				return 'JUNHO';

			case 7:
				return 'JULHO';

			case 8:
				return 'AGOSTO';

			case 9:
				return 'SETEMBRO';

			case 10:
				return 'OUTUBRO';

			case 11:
				return 'NOVEMBRO';

			case 12:
				return 'DEZEMBRO';
		}
	}

	definirStatus(tipo: number): string {
		if (tipo == EnumStatusPPB.EM_ELABORACAO) {
			return "Em Elaboração";
		}

		if (tipo == EnumStatusPPB.REVOGADO) {
			return "Revogado";
		}

		if (tipo == EnumStatusPPB.VIGENCIA_FUTURA) {
			return "Vigencia Futura";
		}

		if (tipo == EnumStatusPPB.VIGENTE) {
			return "Vigente";
		}
	}

	definirVinculo(vinculo: number) {
		switch (vinculo) {
			case 1:
				return "FIXAÇÃO";
			case 2:
				return "ALTERAÇÃO PARA REVOGAÇÃO";
			case 3:
				return "ALTERAÇÃO PARCIAL";
			case 4:
				return "AGREGAÇÃO"
		}
	}

	formatDate(date: Date) {
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date)
	}

	retornaValorSessao(): any {
		var router = sessionStorage.getItem(this.router.url);
		if (router) {
			this.isBuscaSalva = true;
			this.objetoConsulta = JSON.parse(sessionStorage.getItem(this.router.url));
			return this.objetoConsulta;
		} else {
			this.isBuscaSalva = false;
			return null;
		}
	}

	gravarBusca(objeto: any) {
		sessionStorage.removeItem(this.router.url);
		sessionStorage.setItem(this.router.url, JSON.stringify(objeto));
	}

	preencheComboboxTipoDocumento() {
		this.applicationService.get("DocumentoMppb").subscribe((retorno: any) => {
			if (retorno != null) {
				this.listaTipoDocumento = retorno;
			}
		});
	}

	gerarPDF(el){
		let renderizarHtml = new Promise (resolve=>{

			const elements = document.getElementById('relatorioPDF');
				const options = {

				margin: [0.5, 0.03, 0.5, 0.03], // [top, left, bottom, right]
				filename: 'relatorio-parecer-tecnico.pdf',
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: {
					scale: 2,
					dpi: 300,
					letterRendering: true,
					useCORS: true
				},
				jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
				pagebreak : { before: ['#grid'] }
				};
				html2pdf().from(el).set(options).toPdf().get('pdf').then(function (pdf) {
					var totalPages = pdf.internal.getNumberOfPages();
	
					for (var i = 1; i <= totalPages; i++) {
					  pdf.setPage(i);
					  pdf.setFontSize(10);
					  pdf.setTextColor(150);
	
					  var dateObj = new Date();
					  var month = dateObj.getUTCMonth().toString().length <= 1 ? '0' + (dateObj.getUTCMonth() + 1).toString() : dateObj.getUTCMonth() + 1;
					  var day = dateObj.getUTCDate();
					  var year = dateObj.getUTCFullYear();
	
					  var hh = dateObj.getHours();
					  var mm = dateObj.getMinutes();
					  var ss = dateObj.getSeconds();
	
					  var newhr = hh + ":" + mm + ":" + ss;
	
					  var newdate = day + "/" + month + "/" + year;
	
					  pdf.text('Data/Hora de Emissão: '+ newdate + " " + newhr
					  + '                                                                                                           Página ' + i + ' de ' + (totalPages), .2, 11.5);
					}
				  }).save();

				resolve(null);
		});

		let liberarTela = new Promise (resolve=>{
			setTimeout(() => {
				// this.ocultarPdf = true;
			}, 1000);
			resolve(null);
		});

		Promise.all([renderizarHtml,liberarTela]);		
	}
	
}
