import { PagedItems } from "./PagedItems";

export class UnidadeMedidaVM extends PagedItems{    
    id: number;
    numeroCodigo: string;
    sigla: string;
    descricao: string;
    situacao?: number;
    descricaoJustificativa: string;	
}

export class UnidadeMedidaAlteracoesVM{    
    unidadeAntes: UnidadeMedidaVM = new UnidadeMedidaVM();
    unidadeDepois: UnidadeMedidaVM = new UnidadeMedidaVM();
    numeroUsuario: string;
    nomeUsuario: string;
    dataHoraString: string;
}