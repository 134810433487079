<div style="margin-left: -6px; width: 1024px; !important" [hidden]="AtivaRelatorio"
	id="conteudoPdf" class="panel-body" class="col-lg-12">
	<div style="margin-right: 210px;">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group m-n text-center">
					<img src="../../../../../assets/images/brasao.jpeg" alt="Logo Brasao"
						 style="width: 9%;" />
				</div>
			</div>
		</div>
	
		<div class="form-group m-n text-center">
			<h4 style="font-weight: bold">MINISTÉRIO DA INDÚSTRIA, COMÉRCIO EXTERIOR E SERVIÇOS</h4>
			<h5>Superintendência da Zona Franca de Manaus</h5>
			<h5>SUFRAMA</h5>
		</div>
	
		<div class="form-group m-n text-center">
			<label class="control-label">Listagens do PPB</label>
		</div>
	</div>
	<br>
	<div style="width: 1024px; font-size: 13px; !important">
		<div class="row">
			<div class="col-lg-10">
				<div>
					<label for="txtCnpj" class="control-label">TÍTULO PPB: </label> {{ objetoExportacao.tituloPPB }}
				</div>
			</div>
		</div>
	</div>
	<br>

    <!-- ##################################################### --> 
	<!-- ##################### GRUPOS PPB #################### --> 
    <!-- ##################################################### --> 

	<section *ngIf="objetoExportacao.grupoPPB">
		<div class="panel-body" style="height: 30px; background-color: lightgrey; !important">
			<div style="margin-top: -10px;">
				<b style="color: black">1. GRUPOS</b>
			</div>
		</div>
		<div style="margin-left: -600px; margin-top: -40px; width: 2000px; transform: scale(0.4, 0.4);">
            <div class="table-responsive no-margin-bottom no-border">
                <table class="table table">
                    <thead class="table-header-color">
                        <tr>
                            <th class="th-sortable unset-nowrap text-left">Grupo</th>					
                            <th class="th-sortable unset-nowrap text-left">Vigência</th>
                            <th class="th-sortable unset-nowrap text-left">Documento</th>
                            <th class="th-sortable unset-nowrap text-left">Produto</th>	
                        </tr>
                    </thead>
                    <tbody>			    
                        <tr *ngFor="let item of objetoExportacao.grupoPPB">   
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.descricaGrupo" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.dataInicioVigenciaString" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>                       
                            </td>
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.descricaoDocumento" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>       
                            </td>
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">                        
                                <app-taxa-texto 
                                    [(texto)]="item.descricaProdutos" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
		</div>
	</section>

    <!-- ##################################################### --> 
	<!-- ##################### ETAPAS PPB  ################### --> 
    <!-- ##################################################### --> 

	<section *ngIf="objetoExportacao.etapaPPB">
		<div class="panel-body" style="height: 30px; margin-top: -50px; background-color: lightgrey; !important">
			<div style="margin-top: -10px;">
				<b style="color: black">2. ETAPAS</b>
			</div>
		</div>
		<div style="margin-left: -600px; margin-top: -150px; width: 2000px; transform: scale(0.4, 0.4);">
            
            <div class="table-responsive no-margin-bottom no-border">
                <table class="table table">
        
                    <!-- ##################################################### -->
                    <!-- ################ TABELA POR GRUPO ################### -->
                    <!-- ##################################################### -->
        
                    <thead class="table-header-color" *ngIf="_isPorGrupo">
                        <tr>
                            <th class="th-sortable unset-nowrap text-left">Etapa</th>					
                            <th class="th-sortable unset-nowrap text-left">Vigência</th>
                            <th class="th-sortable unset-nowrap text-left">Descrição das Etapas</th>						
                            <th class="th-sortable unset-nowrap text-left">Documento</th>	
                            <th *ngFor="let item of objetoExportacao.etapaPPB.grupos.letrasGruposPPB; let i = index ;">
                                    <span > Grupo {{ item }} </span> 
                            </th>	
                        </tr>
                    </thead>
                    <tbody *ngIf="_isPorGrupo">		
                        <tr *ngFor="let item of objetoExportacao.etapaPPB.listagemPaginado.items">    
                            <td class="text-left" style="width:20px" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.descricaTopico" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                            <td class="text-left" style="width:50px" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.dataInicioVigenciaString" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                            <td class="text-left" style="width:50px" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.descricaEtapa" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                            <td class="text-left" style="width:50px" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                <app-taxa-texto 
                                    [(texto)]="item.descricaoDocumento" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto> 
                            </td>
                            <td class="text-left" 
                                style="width:20px"
                                *ngFor="let indice of item.gruposEtapaPPB" 
                                [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                {{ indice.totalPontuacao ? indice.totalPontuacao : 0 }}
                            </td>
                            
                        </tr>
                    </tbody>      
        
        
                    <!-- ##################################################### -->
                    <!-- ################ TABELA PADRÃO ################### -->
                    <!-- ##################################################### -->
        
                    <thead class="table-header-color" *ngIf="!_isPorGrupo">
                        <tr>
                            <th class="th-sortable unset-nowrap text-left">Etapa</th>					
                            <th class="th-sortable unset-nowrap text-left">Vigência</th>
                            <th class="th-sortable unset-nowrap text-left">Descrição das Etapas</th>						
                            <th class="th-sortable unset-nowrap text-left">Documento</th>	
                            <th class="th-sortable unset-nowrap text-left" *ngIf="_isPorPontuacao || _isPorGrupo">Pontuação</th>	   
                        </tr>
                    </thead>
                    <tbody *ngIf="!_isPorGrupo">		
                        <tr *ngFor="let item of objetoExportacao.etapaPPB.listagemPaginado.items" >   
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.descricaTopico" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.dataInicioVigenciaString" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.descricaEtapa" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.descricaoDocumento" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto> 
                            </td>
                            <td class="text-left" *ngIf="_isPorPontuacao || _isPorGrupo" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }"> 
                                <app-taxa-texto 
                                    [(texto)]="item.valorPontuacao" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto> 
                            </td>
                        </tr>
                    </tbody>   
        
                </table>
            </div>   

            <!-- ##################################################### -->
            <!-- ############## PONTUAÇÃO GERAL ###################### -->
            <!-- ##################################################### -->

            <div class="table-responsive no-margin-bottom no-border" *ngIf="_isPorPontuacao">
                <table class="table" _ngcontent-c2="">
                    <thead style="background-color: #00552b; color: white;">
                    <tr>
                    </tr>
                    </thead>
                    <tbody> 
                        <tr>
                            <td class="text-center">Pontuação Total</td>
                            <td  class="text-center">{{ objetoExportacao.etapaPPB.grupos.valorPontuacaoGeral ? 
                                                            objetoExportacao.etapaPPB.grupos.valorPontuacaoGeral : 
                                                                0 }}</td>
                        </tr>
                    </tbody>
                </table> 
            </div>      

            <!-- ##################################################### -->
            <!-- ########## TABELA PONTUACAO DOS GRUPOS ############## -->
            <!-- ##################################################### -->

            <div style="margin-top: 10px;" class="table-responsive no-margin-bottom no-border" *ngIf="_isPorGrupo">
                <table class="table table-striped" _ngcontent-c2="">
                    <thead style="background-color: #00552b; color: white;">
                        <tr>
                        </tr>
                    </thead>
                    <tbody>           
                        <tr *ngFor="let item of objetoExportacao.etapaPPB.grupos.valoresTotaisGrupoMPPB">
                            <td  class="text-center">Total do Grupo {{ item.letraGrupo }}</td>
                            <td  class="text-center">Total {{ item.totalPontuacao ? item.totalPontuacao : 0}}</td>
                        </tr>
                    </tbody>
                </table>					 
            </div>

		</div>
	</section>

    <!-- ##################################################### --> 
	<!-- ################ CONDICIONANTE PPB ################## --> 
    <!-- ##################################################### --> 

	<section *ngIf="objetoExportacao.condicionantePPB">
		<div class="panel-body" style="margin-top: -150px; height: 30px; background-color: lightgrey;">
			<div style="margin-top: -10px;">
				<b style="color: black">3. CONDICIONANTES</b>
			</div>
		</div>
		<div style="margin-left: -600px; margin-top: -400px; width: 2000px; transform: scale(0.4, 0.4);">
			<div class="table-responsive no-margin-bottom no-border">
                <table class="table">
                    <thead class="table-header-color">
                        <tr>
                            <th class="th-sortable unset-nowrap text-left">Condicionante</th>		
                            <th class="th-sortable unset-nowrap text-left">Data Vigência</th>			
                            <th class="th-sortable unset-nowrap text-left">Descrição da Condicionante</th>
                            <th class="th-sortable unset-nowrap text-left">Documento</th>                    
                        </tr>
                    </thead>
                    <tbody *ngFor=" let item of objetoExportacao.condicionantePPB ; let i = index ; " [attr.data-index]="i">						
                        <tr>  
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                <app-taxa-texto 
                                    [(texto)]="item.descricaTopico" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                <app-taxa-texto 
                                    [(texto)]="item.dataInicioVigenciaString" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">                        
                                <span *ngIf="!item.descricaCondicaoString"> -- </span>
                                              
                                <div *ngIf="item.descricaCondicaoString"> 
                                    <angular-editor [(ngModel)]="item.descricaCondicaoString" [config]="config"></angular-editor> 
                                </div>
                            </td>
                            <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                <app-taxa-texto 
                                    [(texto)]="item.descricaoDocumento" 
                                    [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                </app-taxa-texto>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
		</div>
	</section>

</div>
<!-- FIM CONTEUDO RELATORIO PDF -->