import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../../shared/services/messages.service';
import { ValidationService } from '../../../../../../shared/services/validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EtapaVM } from '../../../../../../view-model/EtapaVM';


enum EnumControllPPB{
	OK = 1,
	ERR_DATA_VIGENCIA_VENCIDA = 2,
	ERR_EXISTE_COPIA_EM_ANDAMENTO = 3,
	ERR_ITEM_MARCADO_COMO_EXCLUIDO = 4
}

@Component({
	selector: 'app-grid-etapas-ppb',
	templateUrl: './grid.component.html'
})

export class GridEtapasPPBComponent {

	ocultarTituloGrid = true;
	path : string;
	_idRota : number;
	constructor(
		private modal : ModalService,
		private validation : ValidationService,
		private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private msg: MessagesService,

	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
		this._idRota = Number(this.route.snapshot.params['idTipoProduto']);
	 }

	@Input() lista!: any[];
	@Input() total!: number;
	@Input() size!: number;
	@Input() page!: number;
	@Input() sorted!: string;
	@Input() listaAntigaComparacao: any = {};
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem!: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() dataVigenciaInicialPPB : string;
	@Input() habilitarInputs: boolean;
	@Input() _isSempontuacao : boolean;
	@Input() _isPorPontuacao : boolean;
	@Input() _isPorGrupo: boolean;
	@Input() _isPPBMultiplo: boolean;
	@Input() isAlteracaoParaRevogacao : boolean;
	@Input() idPPB: number;
	@Input() gruposPPB : any = {};
	@Input() infoGrupos : any = {} = null;
	@Input() isRetificacao : boolean;

	@Input()_exibirCheck : boolean = true;
	_idPPBRota : number;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() listaAnterior : EventEmitter<any[]> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@Output() atualizaListagemGrid: EventEmitter<any> = new EventEmitter();

	servico = 'EtapasPPB';
	tituloGrid = 'Etapas do PPB';

	@ViewChild('appModalIncluirEtapasPPB') appModalIncluirEtapasPPB : any;
	@ViewChild ('appModalMoverPorPosicao') appModalMoverPorPosicao : any;
  @ViewChild ('appModalHistoricoListagemEtapas') appModalHistoricoListagemEtapas : any;

	config: AngularEditorConfig = {
		height: '200px',
		minHeight: '100px',
		width: '100%',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};

	changeSize($event : any) {
		this.listaAnterior.emit(this.lista);
		this.onChangeSize.emit($event);
	}

	changeSort($event : any) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event : any) {
		this.page = $event;
		this.onChangePage.emit($event);

	}

	atualizaListagem($event){
		this.atualizaListagemGrid.emit($event);
	}

	// validarSePermiteAbrirModal(item : any){

	// 	///let serviceObject : ItensPPBVM = new ItensPPBVM();
	// 	let serviceObject : any ={};
	// 	serviceObject.idItemCorrente = Number(item.id);
	// 	serviceObject.idPPBCorrente = Number(this._idPPBRota);
	// 	serviceObject.idPPBVinculo = Number(item.idPPB);

	// 	this.applicationService.post(this.servico+"/PostValidacaoModalEdicao", serviceObject).subscribe((result : ItensPPBVM) => {

	// 		if(result.statusControllModal == EnumControllPPB.OK){

	// 			let obj : any = {};
	// 			obj.idPPB = Number(this._idPPBRota);
	// 			obj.id = result.idItemCorrente;
	// 			this.atualizaListagem(obj.idPPB);
	// 			this.abrirModal(obj);

	// 		} else if(result.statusControllModal == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
	// 			this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação");
	// 			return false;
	// 		} else if(result.statusControllModal == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO){
	// 			this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação");
	// 			return false;
	// 		}
	// 	});
	// }

	abrirModal(item : any){
		this.appModalIncluirEtapasPPB.abrir(item.idPPB , "edit", item.id);
	}

	formatDate(date : Date){
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date);
	}


	async buscarDescricaoEtapa(item : EtapaVM, indice : number){
		if(!item.descricaoEtapa){
			this.applicationService.get("EtapasPPB", {id : item.id}).subscribe((result : EtapaVM) => {
				this.lista[indice].descricaoEtapa = result.descricaoEtapa;
			});
		}
	}

//   ordenarItemGrid(item, tipoOperacao)
//   {
//     let objetoOrdenacao = new ItensPPBVM();
//     objetoOrdenacao.idItemCorrente = item.id;
//     objetoOrdenacao.idPPBCorrente = this._idPPBRota;
//     objetoOrdenacao.tipoOperacao = tipoOperacao;
//     this.applicationService.post("EtapasPPB/OrdenarEtapa",objetoOrdenacao).subscribe((result:RequestResponseVM)=>{
//         if(result.statusResponse==200)
//         {
//           this.atualizaListagem(this._idPPBRota);
//         }
//         else if(result.statusResponse == 300)
//         {
//           this.modal.informacao("Esse registro é o primeiro da lista");
//         }
//         else if(result.statusResponse == 403)
//         {
//           this.modal.informacao("Esse registro é o ultimo da lista");
//         }
//         else
//         {
//           this.modal.error(result.textResponse, "Error");
//         }
//     });
//   }

  validacaoIsRetificacao(item : any) : boolean{
	if(this.isRetificacao){
		if(item.idPPB == this.idPPB){
			return true
		}
		else{
			return false
		};
	}
	return true;
  }

  async salvar(){
	this._idRota;
	var obj = {
		listPPBEtapaEntityVM : this.lista,
		IdPpb : this.idPPB,
		IdProduto : this._idRota
	}

	if(this.lista.length > 0 && this.lista != null){
       await this.applicationService.post(this.servico, obj).toPromise();
	};
  }

}
