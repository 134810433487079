import { PagedItems } from './PagedItems';

export class ConsultarAlteracoesVM extends PagedItems {
    id: number;
    codigo: string;
    descricao: string;
    login: string;
    responsavel: string;
    data: any;
    dataString: string;
    tipo: number;
    
    //filtro de pesquisa
    check: number;
    check2: number;
    check3: number;
    check4: number;
    dscProduto: string;
    dscUnidade: string;
    dscSub: string;
    dscNcm: string;
    dataPeriodoStringInicio: string
    dataPeriodoStringFim: string
}
