<div>

  <div *ngIf="tela!='visualizar'">

    <div style="margin-bottom: 0px" class="form-group has-feedback" *ngIf="isMultiplo">
      <label for="descricao">
        Artigos :
      </label>
      <input
        id="valorInput"
        #valorInput
        [required]="isRequired"
        [attr.readonly]="isDisabled ? 'true': null"
        [ngStyle]="{'border-radius':artigos != undefined && artigos.length > 0 ? '5px': '5px 0 0 5px'}"
        type="search"
        class="form-control selection"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        (keyup.enter)="onKeyUp()"
        (focus)="onFocus()"
        maxlength="500"
        [placeholder]="placeholder"
      >
    </div>
    <span *ngIf="clearValue && !isDisabled && isCloseNormal" (click)="onClear(true)"
          style="cursor:pointer; right:60px; top: 0;" class="btn btn-link form-control-feedback">
		<i class="fa fa-times"></i>
	</span>

    <span *ngIf="clearValue && !isDisabled && !isCloseNormal" (click)="onClear(true)"
          style="cursor:pointer; right:15px" class="btn btn-link form-control-feedback">
		<i class="fa fa-times"></i>
	</span>
  </div>

  <div *ngIf="focused && !isDisabled" class="noSelectText" style="background-color:white; z-index:9999">
    <ul *ngIf="openDrop" class="heroes"
        style="border:1px solid #87CEFA; width:auto; overflow: auto; margin-bottom:0; height: auto;">
      <li *ngFor="let artigo of artigos"
          (click)="onSelect(artigo)">
        <span class="badge" style="vertical-align: middle;"><p
          style="position: relative; top: -5px;">{{ artigo.descricao.split('|')[0] }}</p></span> <span
        style="position: relative; top: -3px;">{{ artigo.descricao.split('|')[1] }}</span>
      </li>
    </ul>
    <div class="noSelectText">
      <span
        style="padding:.3em; height:2em;pointer-events:none; display: block; background-color:#00552b; color:white; border:1px solid #87CEFA; border-radius:0px 0px 5px 5px;">{{ mensagem }}</span>
    </div>
  </div>

  <label for="descricao" *ngIf="tela=='visualizar' && isMultiplo">
    Artigos :
  </label>
  <div class="row form-group m-t-md custom-margin" *ngFor="let artigo of artigosSelecionados">
    <div class="col-lg-12 text-nowrap" style="margin-top: 2px;">
      <div class="input-group">
        <input
          type="text"
          class="form-control input"
          [placeholder]="artigo.descricao"
          disabled>

        <span
          class="input-group-btn"
          *ngIf="habilitarInputs"
          (click)="OnClickRemoverArtigo(artigo.id)">
                                    <span

                                      data-toggle="modal" data-backdrop="static" data-keyborad="false">
                                        <a class="btn btn-default" data-toggle="tooltip" title="Excluir">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </span>
                                </span>

      </div>
    </div>

  </div>
</div>
