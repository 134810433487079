import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertaModelCiente {
	title: string;
	message: string;
}

@Component({
	selector: 'app-modal-informacao-cliente',
	templateUrl: './modal-informacao-ciente.component.html',
})

export class ModalInformacaoCienteComponent implements OnInit {

	constructor(@Inject(MAT_DIALOG_DATA) public data : AlertaModelCiente) { }

	ngOnInit():void {
	}

	confirm() {

	}

	/*
	 onClose(){
    	this.dialogRef.close();
  	}
	*/
}
