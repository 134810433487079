<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
    data-railopacity="0.2" id="relatorioPDF" #relatorioPDF>
    <div class="row m-b-md">
        <div class="col-sm-6">
            <app-titulo>Códigos NCM</app-titulo>
        </div>
    </div>
    <div class="row">

        <!-- <h2>Em Implementação ... </h2> -->
        <div class="col-md-12">
            <section class="panel panel-default">
                <header class="panel-heading">
                    <div class="pull-right">
                        <a *ngIf="eUsuarioSuframa" class="btn btn-primary btn-sm" (click)="criarNovo()">
                            <i class="fa fa-plus"></i> Novo
                        </a>

                     </div>
                     <h2 class="panel-title h5">{{ !ocultarFiltros ? 'Filtros de Pesquisa' : 'Exibir Filtros' }}</h2>
                </header>
                <article *ngIf="!ocultarFiltros" class="panel-body">
                    <form id="formBusca" class="form form-horinzontal" role="form" #formBusca="ngForm" ngNativeValidate>

                        <article class="panel-body">

                            <div class="row form-group">
                                <div class="col-lg-5 text-nowrap">
                                    <label for="produto">Código:</label>
                                    <input
                                        type="text"
                                        name="cod"
                                        onlyNumber="true"
                                        id="cod"
                                        class="form-control"
                                        [(ngModel)]="parametros.numeroCodigo"
                                        (change)="flagAlterouFiltro = true"/>
                                </div>
                                <div class="col-lg-4 text-nowrap">
                                    <label for="codigo">Nomenclatura:</label>
                                    <input
                                        type="text"
                                        name="codigo"
                                        id="codigo"
                                        class="form-control"
                                        [(ngModel)]="parametros.descricaoNcm"
                                        (change)="flagAlterouFiltro = true"/>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-lg-5">
                                    <label for="situacao" class="">Situação:</label>
                                    <select name="situacao" id="situacao" class="form-control"
                                        [(ngModel)]="parametros.statusNcm" (change)="flagAlterouFiltro = true">
                                        <option value="99">Todas</option>
                                        <option value="1">Ativas</option>
                                        <option value="0">Inativas</option>
                                    </select>
                                </div>

                                <div class="col-lg-6">
                                    <label for="unidadeDeMedida" class="">Unidade de Medida:</label>
                                    <app-drop-list id="unidade" name="unidade" servico="UnidadeMedida"
                                        [(ngModel)]="parametros.idUnidadeMedida" (change)="flagAlterouFiltro = true">
                                    </app-drop-list>
                                </div>
                            </div>
                        </article>
                    </form>
                </article>
                <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
                    <div class="pull-right">
                        <button
                            *ngIf="!ocultarFiltros"
                            class="btn btn-sm btn-primary"
                            type="submit"
                            (click)="buscar(false)"
                            disableOnClick>
                                <i class="fa fa-search"></i> Buscar
                        </button>
                        <button
                            *ngIf="!ocultarFiltros"
                            (click)="limpar()"
                            #btnlimpar
                            class="btn btn-sm btn-default"
                            type="button">
                                <i class="fa fa-eraser"></i> Limpar
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm btn-default"
                            (click)="ocultarFiltros ? ocultarFiltros = false : ocultarFiltros = true"
                            style="margin-left: -1px;">
                                <span class="fa fa-magic m-r-xs"></span>
                                {{ !ocultarFiltros ? 'Ocultar Filtros' : 'Exibir Filtros' }}
                        </button>
                    </div>
                </footer>
            </section>
        </div>
    </div>
    <div class="row" *ngIf="grid.total > 0" id="grid">
        <div class="col-lg-12">
            <app-grid-manter-ncm
                [(lista)]="grid.lista"
                [(total)]="grid.total"
                [(size)]="grid.size"
                [(page)]="grid.page"
                [(eUsuarioSuframa)]="eUsuarioSuframa"
                [(parametros)]="parametros"
                (onChangeSort)="onChangeSort($event)"
                (onChangeSize)="onChangeSize($event)"
                (onChangePage)="onChangePage($event)"
                (atualizarListagem)="buscar()">
			</app-grid-manter-ncm>
        </div>
    </div>
</div>
