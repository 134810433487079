<div #appModalJustificativa 
     class="modal modal-wide-2 fade in centro" 
     tabindex="-1" 
     role="dialog" 
     aria-hidden="false"
     style="margin-left: 30%; width: 800px; display: auto; overflow: scroll; overflow-y: auto;">
     
  <div class="modal-dialog modal-sm conteudo">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title">Deseja alterar esse registro</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-block" style="margin-bottom: 0;">
          <label>Portaria:
            <b *ngIf="_pendenciaPortaria"
                style="color: red">*</b>
          </label>
          <div>
            <input 
                class="form-control" 
                [(ngModel)]="_portaria"
            />
          </div>
          <label>Justificativa:
            <b *ngIf="_pendenciaJustificativa"
                style="color: red">*</b>
          </label>
          <div>
            <textarea 
                class="form-control" 
                id="txt-modal"                            
                name="txt-modal" 
                rows="5"
                style="resize: none;"
                [(ngModel)]="_justificativa">
            </textarea>
          </div>
        </div>        
      </div>
      <div class="modal-footer" style="padding: 8px 15px;">
        <button 
            type="button" 
            class="btn btn-primary btn-sm" 
            (click)="validar()">
              <span class="fa fa-save m-r-xs"></span>Salvar
        </button>
        <button 
            type="button" 
            class="btn btn-default btn-sm" 
            (click)="fechar()"
            #close>
              <span class="fa fa-times m-r-xs"></span>Fechar
        </button>
      </div>
    </div>
  </div>
</div>
<div #appModalJustificativaBackground class="modal-backdrop fade in" style="display: none;"></div>

