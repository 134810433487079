<div #appModalFinalizar class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Etapas Condições do PPB</h4>
            </div>

            <div class="modal-body">
                <form>		
                    <div class="row form-group" style="margin-left: 2px;">                    
                        <div style="margin-top: 5px;" class="col-sm-10">
                            <label for="descricao" class="">Produto para Vincular:</label>
                            <div class="input-group">
                                <input type="text" class="form-control input-sm" placeholder="Pesquisar Produto">                                             
                                    <span class="input-group-btn">
                                        <input type="text" class="form-control input-sm" placeholder="Pesquisar NCM">
                                        <button class="btn btn-sm btn-default" type="button"><i class="fa fa-search"></i> Buscar</button>
                                        <button class="btn btn-sm btn-default" type="reset"><i class="fa fa-link"></i> Vincular</button>
                                    </span>
                            </div>
                        </div>		
                        <div style="margin-top: 5px;" class="col-sm-10">
                            <span class="input-group-btn">
                                <button class="btn btn-sm btn-default" type="reset"><i class="fa fa-unlink"></i> Desvincular</button>
                            </span>	
                        </div>	
                    </div>	 

                    <app-grid 
                        [(page)]="page"
                        [(size)]="size"
                        [(total)]="total"
                        [(parametros)]="parametros"
                        (onChangeSize)="changeSize($event)"
                        (onChangePage)="changePage($event)">

                        <div class="table-responsive no-margin-bottom no-border">
                            <table class="table">
                                <thead class="table-header-color">
                                <tr>
                                    <th class="th-100 text-left">
                                        <label class="checkbox m-n i-checks">
                                            <input type="checkbox" class="checkall"><i></i>
                                        </label>
                                    </th>
                                    <th class="th-sortable unset-nowrap text-left">
                                        <app-ordenacao field="DescricaoStatus" [sorted]="sorted" (onChangeSort)="changeSort($event)">Situação</app-ordenacao>
                                    </th>	
                                </tr>
                                </thead>
                            <tbody>				
                            <!-- <tr *ngFor="let item of lista"> -->
                                <tr>    
                                    <td class="text-left">
                                        <label class="checkbox m-n i-checks">
                                            <input type="checkbox" class="checkall"><i></i>
                                        </label>
                                    </td>
                                    <td class="text-left">NOME PRODUTO 1</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        
                    </app-grid>
                </form>
            </div>
           
            <div class="modal-footer" style="padding: 10px 15px;">
                <a 
                    (click)="fechar()"
                    class="btn btn-sm btn-default" 
                    data-dismiss="modal">
                        <i class="fa fa-times"></i> Não há produto a ser Vinculado
                </a>
                <a 
                    class="btn btn-sm btn-primary" 
                    data-dismiss="modal" 
                    data-toggle="modal" 
                    data-backdrop="static" 
                    data-keyboard="false">
                        <i class="fa fa-save m-r-xs"></i> Salvar
                </a>
            </div>

        </div>

    </div>
</div>
<div #appModalFinalizarBackground class="modal-backdrop fade in" style="display: none;"></div>