<!-- INICIO CONTEUDO RELATORIO UNIDADE PDF -->
<div *ngIf="idRelatorio === 'unidade'">
  <article id="unidade" class="panel-body" [hidden]="ocultaRelatorio == true">
      <div class="row">
          <div class="col-md-12">
              <div class="form-group m-n text-center">
                  <img src="../../../../../assets/images/brasao.jpeg" alt="Logo Brasao"
                      style="width: 9%;" />
              </div>
          </div>
      </div>

      <div class="form-group m-n text-center">
          <h4 style="font-weight: bold">Ministério do Desenvolvimento, Indústria, Comércio e Serviços</h4>
          <h5 style="font-weight: bold">Superintendência da Zona Franca de Manaus</h5>
          <h5 style="font-weight: bold">SUFRAMA</h5>
      </div>

      <div class="form-group m-n text-center">
          <div style="margin-left: 40px;border-top: 1px solid black;margin-right: 40px"></div>
              <label class="control-label">RELATÓRIO DE UNIDADE</label>
          <div style="margin-left: 40px;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>

      <div class="form-group m-n text-center">
          <label style="margin-top: 25px;" class="control-label">DADOS ANTES DA ATUALIZAÇÃO</label>
          <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>
      <div class="form-group m-n text-left">
          <div style="margin-left: 60px">
              <div class="row">
                  <div class="col-lg-4">
                      <label class="control-label">
                          Código: <span style="font-weight: normal;">{{unidade.unidadeAntes.numeroCodigo}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Sigla: <span style="font-weight: normal;">{{unidade.unidadeAntes.sigla}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Descrição: <span style="font-weight: normal;">{{unidade.unidadeAntes.descricao}}</span>
                      </label>
                  </div>
              </div>
          </div>
      </div>

      <div class="form-group m-n text-center">
          <label style="margin-top: 25px;" class="control-label">DADOS DEPOIS DA ATUALIZAÇÃO</label>
          <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>
      <div class="form-group m-n text-left">
          <div style="margin-left: 60px">
              <div class="row">
                  <div class="col-lg-4">
                      <label class="control-label">
                          Código: <span style="font-weight: normal;">{{unidade.unidadeDepois.numeroCodigo}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Sigla: <span style="font-weight: normal;">{{unidade.unidadeDepois.sigla}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Descrição: <span style="font-weight: normal;">{{unidade.unidadeDepois.descricao}}</span>
                      </label>
                  </div>
              </div>
          </div>
      </div>


      <div class="form-group m-n text-center">
          <label style="margin-top: 25px;" class="control-label">RESPONSÁVEL PELA ALTERAÇÃO</label>
          <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>
      <div class="row" style="margin-top: 15px;margin-left: 40px;margin-right: 40px;">
          <table style="border: 1px solid;">
              <thead>
                  <tr style="border: 1px solid;">
                      <th class="col-sm-2" style="text-align: center; border: 1px solid;">Responsável</th>
                      <th class="col-sm-2" style="text-align: center; border: 1px solid;">Login</th>
                      <th class="col-sm-2" style="text-align: center; border: 1px solid;">Data/Hora</th>
                  </tr>
              </thead>
              <tbody>
                  <tr style="border: 1px solid;">
                      <td class="text-center" style="border: 1px solid;">{{unidade.nomeUsuario}}</td>
                      <td class="text-center" style="border: 1px solid;">{{unidade.numeroUsuario}}</td>
                      <td class="text-center" style="border: 1px solid;">{{unidade.dataHoraString}}</td>
                  </tr>
              </tbody>
          </table>
      </div>
  </article>
</div>
<!-- FIM CONTEUDO RELATORIO UNIDADE PDF -->

<!-- INICIO CONTEUDO RELATORIO SUBSETOR PDF -->
<div *ngIf="idRelatorio === 'subsetor'">
  <article id="subsetor" class="panel-body" [hidden]="ocultaRelatorio == true">
      <div class="row">
          <div class="col-md-12">
              <div class="form-group m-n text-center">
                  <img src="../../../../../assets/images/brasao.jpeg" alt="Logo Brasao"
                      style="width: 9%;" />
              </div>
          </div>
      </div>

      <div class="form-group m-n text-center">
          <h4 style="font-weight: bold">Ministério do Desenvolvimento, Indústria, Comércio e Serviços</h4>
          <h5 style="font-weight: bold">Superintendência da Zona Franca de Manaus</h5>
          <h5 style="font-weight: bold">SUFRAMA</h5>
      </div>

      <div class="form-group m-n text-center">
          <div style="margin-left: 40px;border-top: 1px solid black;margin-right: 40px"></div>
              <label class="control-label">RELATÓRIO DE SUBSETOR</label>
          <div style="margin-left: 40px;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>

      <div class="form-group m-n text-center">
          <label style="margin-top: 25px;" class="control-label">DADOS ANTES DA ATUALIZAÇÃO</label>
          <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>
      <div class="form-group m-n text-left">
          <div style="margin-left: 60px">
              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Código: <span style="font-weight: normal;">{{subsetor.subsetorAntes.numeroCodigo}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Descrição: <span style="font-weight: normal;">{{subsetor.subsetorAntes.descSubsetor}}</span>
                      </label>
                  </div>
              </div>
          </div>
      </div>

      <div class="form-group m-n text-center">
          <label style="margin-top: 25px;" class="control-label">DADOS DEPOIS DA ATUALIZAÇÃO</label>
          <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>
      <div class="form-group m-n text-left">
          <div style="margin-left: 60px">
              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Código: <span style="font-weight: normal;">{{subsetor.subsetorDepois.numeroCodigo}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Descrição: <span style="font-weight: normal;">{{subsetor.subsetorDepois.descSubsetor}}</span>
                      </label>
                  </div>
              </div>
          </div>
      </div>


      <div class="form-group m-n text-center">
          <label style="margin-top: 25px;" class="control-label">RESPONSÁVEL PELA ALTERAÇÃO</label>
          <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>
      <div class="row" style="margin-top: 15px;margin-left: 40px;margin-right: 40px;">
          <table style="border: 1px solid;">
              <thead>
                  <tr style="border: 1px solid;">
                      <th class="col-sm-2" style="text-align: center; border: 1px solid;">Responsável</th>
                      <th class="col-sm-2" style="text-align: center; border: 1px solid;">Login</th>
                      <th class="col-sm-2" style="text-align: center; border: 1px solid;">Data/Hora</th>
                  </tr>
              </thead>
              <tbody>
                  <tr style="border: 1px solid;">
                      <td class="text-center" style="border: 1px solid;">{{subsetor.nomeUsuario}}</td>
                      <td class="text-center" style="border: 1px solid;">{{subsetor.numeroUsuario}}</td>
                      <td class="text-center" style="border: 1px solid;">{{subsetor.dataHoraString}}</td>
                  </tr>
              </tbody>
          </table>
      </div>
  </article>
</div>
<!-- FIM CONTEUDO RELATORIO SUBSETOR PDF -->

<!-- INICIO CONTEUDO RELATORIO NCM PDF -->
<div *ngIf="idRelatorio === 'ncm'">
  <article id="ncm" class="panel-body" [hidden]="ocultaRelatorio == true">
      <div class="row">
          <div class="col-md-12">
              <div class="form-group m-n text-center">
                  <img src="../../../../../assets/images/brasao.jpeg" alt="Logo Brasao"
                      style="width: 9%;" />
              </div>
          </div>
      </div>

      <div class="form-group m-n text-center">
          <h4 style="font-weight: bold">Ministério do Desenvolvimento, Indústria, Comércio e Serviços</h4>
          <h5 style="font-weight: bold">Superintendência da Zona Franca de Manaus</h5>
          <h5 style="font-weight: bold">SUFRAMA</h5>
      </div>

      <div class="form-group m-n text-center">
          <div style="margin-left: 40px;border-top: 1px solid black;margin-right: 40px"></div>
              <label class="control-label">RELATÓRIO DE ALTERAÇÃO DE NCM</label>
          <div style="margin-left: 40px;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>

      <div class="form-group m-n text-center">
          <label style="margin-top: 25px;" class="control-label">DADOS ANTES DA ATUALIZAÇÃO</label>
          <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>
      <div class="form-group m-n text-left">
          <div style="margin-left: 60px">
              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Código: <span style="font-weight: normal;">{{ncm.ncmAntes.numeroCodigo}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-12">
                      <label class="control-label">
                          Descrição: <span style="font-weight: normal;">{{ncm.ncmAntes.descricaoNcm}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Unidades: <span style="font-weight: normal;">{{ncm.ncmAntes.unidadeMedida.descricao}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-3">
                      <label class="control-label">
                          Alíquota II(%): <span style="font-weight: normal;">{{ncm.ncmAntes.aliquotaII}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Data Início: <span style="font-weight: normal;">{{ncm.ncmAntes.aliquotaIIVigenciaInicialString}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Data Final: <span style="font-weight: normal;">{{ncm.ncmAntes.aliquotaIIVigenciaFinalString}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-3">
                      <label class="control-label">
                          Alíquota IPI(%): <span style="font-weight: normal;">{{ncm.ncmAntes.aliquotaIPI}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Data Início: <span style="font-weight: normal;">{{ncm.ncmAntes.aliquotaIPIVigenciaInicialString}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Data Final: <span style="font-weight: normal;">{{ncm.ncmAntes.aliquotaIPIVigenciaFinalString}}</span>
                      </label>
                  </div>
              </div>
          </div>
      </div>

      <div class="form-group m-n text-center">
          <label style="margin-top: 25px;" class="control-label">DADOS DEPOIS DA ATUALIZAÇÃO</label>
          <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>
      <div class="form-group m-n text-left">
          <div style="margin-left: 60px">
              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Código: <span style="font-weight: normal;">{{ncm.ncmDepois.numeroCodigo}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-12">
                      <label class="control-label">
                          Descrição: <span style="font-weight: normal;">{{ncm.ncmDepois.descricaoNcm}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-8">
                      <label class="control-label">
                          Unidades: <span style="font-weight: normal;">{{ncm.ncmDepois.unidadeMedida.descricao}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-3">
                      <label class="control-label">
                          Alíquota II(%): <span style="font-weight: normal;">{{ncm.ncmDepois.aliquotaII}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Data Início: <span style="font-weight: normal;">{{ncm.ncmDepois.aliquotaIIVigenciaInicialString}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Data Final: <span style="font-weight: normal;">{{ncm.ncmDepois.aliquotaIIVigenciaFinalString}}</span>
                      </label>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-3">
                      <label class="control-label">
                          Alíquota IPI(%): <span style="font-weight: normal;">{{ncm.ncmDepois.aliquotaIPI}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Data Início: <span style="font-weight: normal;">{{ncm.ncmDepois.aliquotaIPIVigenciaInicialString}}</span>
                      </label>
                  </div>
                  <div class="col-lg-4">
                      <label class="control-label">
                          Data Final: <span style="font-weight: normal;">{{ncm.ncmDepois.aliquotaIPIVigenciaFinalString}}</span>
                      </label>
                  </div>
              </div>
          </div>
      </div>


      <div class="form-group m-n text-center">
          <label style="margin-top: 25px;" class="control-label">RESPONSÁVEL PELA ALTERAÇÃO</label>
          <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
      </div>
      <div class="row" style="margin-top: 15px;margin-left: 40px;margin-right: 40px;">
          <table style="border: 1px solid;">
              <thead>
                  <tr style="border: 1px solid;">
                      <th class="col-sm-2" style="text-align: center; border: 1px solid;">Responsável</th>
                      <th class="col-sm-2" style="text-align: center; border: 1px solid;">Login</th>
                      <th class="col-sm-2" style="text-align: center; border: 1px solid;">Data/Hora</th>
                  </tr>
              </thead>
              <tbody>
                  <tr style="border: 1px solid;">
                      <td class="text-center" style="border: 1px solid;">{{ncm.nomeUsuario}}</td>
                      <td class="text-center" style="border: 1px solid;">{{ncm.numeroUsuario}}</td>
                      <td class="text-center" style="border: 1px solid;">{{ncm.dataHoraString}}</td>
                  </tr>
              </tbody>
          </table>
      </div>
  </article>
</div>
<!-- FIM CONTEUDO RELATORIO NCM PDF -->
<!-- INICIO CONTEUDO RELATORIO PRODUTO PDF -->
<article id="produto" class="panel-body" *ngIf="idRelatorio === 'produto'">
  <div class="row">
      <div class="col-md-12">
          <div class="form-group m-n text-center">
              <img src="../../../../../assets/images/brasao.jpeg" alt="Logo Brasao"
                   style="width: 9%;" />
          </div>
      </div>
  </div>

  <div class="form-group m-n text-center">
      <h4 style="font-weight: bold">Ministério do Desenvolvimento, Indústria, Comércio e Serviços</h4>
      <h5 style="font-weight: bold">Superintendência da Zona Franca de Manaus</h5>
      <h5 style="font-weight: bold">SUFRAMA</h5>
  </div>

  <div class="form-group m-n text-center">
      <div style="margin-left: 40px;border-top: 1px solid black;margin-right: 40px"></div>
          <label class="control-label">RELATÓRIO DE PRODUTO</label>
      <div style="margin-left: 40px;border-bottom: 1px solid black;margin-right: 40px"></div>
  </div>

  <div class="form-group m-n text-center">
      <label style="margin-top: 25px;" class="control-label">DADOS ANTES DA ATUALIZAÇÃO</label>
      <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
  </div>
  <div class="form-group m-n text-left">
      <div style="margin-left: 60px">
        <div class="row">
            <div class="col-lg-3">
                <label class="control-label">
                    Código: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.numeroCodigo}}</span>
                </label>
            </div>
            <div class="col-lg-9">
                <label class="control-label">
                    Descrição: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.descProduto}}</span>
                </label>
            </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
              <label class="control-label">
                  Unidade de Medida: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.unidadeMedidaFormatado}}</span>
              </label>
          </div>
          <div class="col-lg-6">
              <label class="control-label">
                Coeficiente de Redução: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.sitComponente == 1 ? "Sim" : "Não"}}</span>
              </label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
              <label class="control-label">
                Componente: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.numeroCodigo}}</span>
              </label>
          </div>
          <div class="col-lg-6">
              <label class="control-label">
                Bem de Informática: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.sitComponente == 1 ? "Sim" : "Não"}}</span>
              </label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
              <label class="control-label">
                Nº Dígitos: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.numeroDigitos}}</span>
              </label>
          </div>
          <div class="col-lg-6">
              <label class="control-label">
                Protocolo de Solicitação: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.descProtocolo}}</span>
              </label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
              <label class="control-label">
                Subsetor: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.subsetorFormatado}}</span>
              </label>
          </div>
          <div class="col-lg-6">
              <label class="control-label">
                Status: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.statusFormatado}}</span>
              </label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
              <label class="control-label">
                Base Legal: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.descBaseLegalExistente}}</span>
              </label>
          </div>
          <div class="col-lg-6">
              <label class="control-label">
                Observação: <span style="font-weight: normal;">{{produto.produtoAlteracoesAntes.observacao}}</span>
              </label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12" *ngFor="let produtoTipo of produto.produtoAlteracoesAntes.listaProdutoTipo; let i = index">
            <label class="control-label">
              Tipo: <span style="font-weight: normal;">{{produtoTipo.numeroCodigo}} | {{produtoTipo.descricao}} | {{produtoTipo.descricaoTipoAgregacao}} | {{produtoTipo.descricaoSubSetor}} | {{produtoTipo.statusTipo == 1 ? "Ativo" : "Inativo"}}</span>
            </label>
            <div class="col-lg-12" *ngFor="let produtoTipoNcm of produtoTipo.listaProdutoTipoNcm; let i = index">
              <label class="control-label">
                NCM: <span style="font-weight: normal;">{{produtoTipoNcm.numeroCodigo}} | {{produtoTipoNcm.descricaoProdutoTipoNcm}} | {{produtoTipoNcm.statusNcm == 1 ? "Ativo" : "Inativo"}}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div class="form-group m-n text-center">
      <label style="margin-top: 25px;" class="control-label">DADOS DEPOIS DA ATUALIZAÇÃO</label>
      <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
  </div>
  <div class="form-group m-n text-left">
    <div style="margin-left: 60px">
      <div class="row">
          <div class="col-lg-3">
              <label class="control-label">
                  Código: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.numeroCodigo}}</span>
              </label>
          </div>
          <div class="col-lg-9">
              <label class="control-label">
                  Descrição: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.descProduto}}</span>
              </label>
          </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
            <label class="control-label">
                Unidade de Medida: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.unidadeMedidaFormatado}}</span>
            </label>
        </div>
        <div class="col-lg-6">
            <label class="control-label">
              Coeficiente de Redução: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.sitComponente == 1 ? "Sim" : "Não"}}</span>
            </label>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
            <label class="control-label">
              Componente: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.numeroCodigo}}</span>
            </label>
        </div>
        <div class="col-lg-6">
            <label class="control-label">
              Bem de Informática: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.sitComponente == 1 ? "Sim" : "Não"}}</span>
            </label>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
            <label class="control-label">
              Nº Dígitos: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.numeroDigitos}}</span>
            </label>
        </div>
        <div class="col-lg-6">
            <label class="control-label">
              Protocolo de Solicitação: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.descProtocolo}}</span>
            </label>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
            <label class="control-label">
              Subsetor: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.subsetorFormatado}}</span>
            </label>
        </div>
        <div class="col-lg-6">
            <label class="control-label">
              Status: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.statusFormatado}}</span>
            </label>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
            <label class="control-label">
              Base Legal: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.descBaseLegalExistente}}</span>
            </label>
        </div>
        <div class="col-lg-6">
            <label class="control-label">
              Observação: <span style="font-weight: normal;">{{produto.produtoAlteracoesDepois.observacao}}</span>
            </label>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12" *ngFor="let produtoTipo of produto.produtoAlteracoesDepois.listaProdutoTipo; let i = index">
          <label class="control-label">
            Tipo: <span style="font-weight: normal;">{{produtoTipo.numeroCodigo}} | {{produtoTipo.descricao}} | {{produtoTipo.descricaoTipoAgregacao}} | {{produtoTipo.descricaoSubSetor}} | {{produtoTipo.statusTipo == 1 ? "Ativo" : "Inativo"}}</span>
          </label>
          <div class="col-lg-12" *ngFor="let produtoTipoNcm of produtoTipo.listaProdutoTipoNcm; let i = index">
            <label class="control-label">
              NCM: <span style="font-weight: normal;">{{produtoTipoNcm.numeroCodigo}} | {{produtoTipoNcm.descricaoProdutoTipoNcm}} | {{produtoTipoNcm.statusNcm == 1 ? "Ativo" : "Inativo"}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="form-group m-n text-center">
      <label style="margin-top: 25px;" class="control-label">RESPONSÁVEL PELA ALTERAÇÃO</label>
      <div style="margin-left: 40px;border-style:double;border-bottom: 1px solid black;margin-right: 40px"></div>
  </div>
  <div class="row" style="margin-top: 15px;margin-left: 40px;margin-right: 40px;">
      <table style="border: 1px solid;">
          <thead>
              <tr style="border: 1px solid;">
                  <th class="col-sm-2" style="text-align: center; border: 1px solid;">Responsável</th>
                  <th class="col-sm-2" style="text-align: center; border: 1px solid;">Login</th>
                  <th class="col-sm-2" style="text-align: center; border: 1px solid;">Data/Hora</th>
              </tr>
          </thead>
          <tbody>
              <tr style="border: 1px solid;">
                  <td class="text-center" style="border: 1px solid;">{{produto.produtoAlteracoesDepois.nomeUsuario}}</td>
                  <td class="text-center" style="border: 1px solid;">{{produto.produtoAlteracoesDepois.numeroUsuarioFormatado}}</td>
                  <td class="text-center" style="border: 1px solid;">{{produto.produtoAlteracoesDepois.dataFormatado}}</td>
              </tr>
          </tbody>
      </table>
  </div>
</article>
<!-- FIM CONTEUDO RELATORIO UNIDADE PDF -->


