<div class="row form-group" style="margin-left: 2px;">
        <app-grid-ppb-multiplo
            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
            [(idPPB)]="idPPB"
            [(habilitarInputs)]="habilitarInputs"
            (atualizaListagemGrid)="atualizaListagem($event)"
            [(lista)]="grid.lista"
            [(listaAntigaComparacao)]="listaAntigaComparacao"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)"
            (listaAnterior)="salvarListaAnterior($event)"
            [(isRetificacao)]="isRetificacao"
            [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
            [_exibirCheck]="!_isListarVinculados"
            #appGridPPBMultiplo>
        </app-grid-ppb-multiplo>
</div>