/* IMPORTAÇÃO DE MODULOS */
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from "ngx-mask";
import { SharedModule } from "../../shared/shared.module";

import { ManterProdutoPadraoComponent } from "./manter-produto-padrao.component";
import { GridManterProdutoPadraoComponent } from "./grid/grid.component";
import { GridTipoProdutoComponent } from "./solicitacoes-produto-padrao/tipo-produto/grid/grid-tipo-produto.component";
import { GridNcmComponent } from './solicitacoes-produto-padrao/ncm/grid/grid-ncm.component';
import { SolicitacoesProdutoPadraoComponent } from "./solicitacoes-produto-padrao/solicitacoes-produto-padrao.component";
import { ModalManterTipoProduto } from './solicitacoes-produto-padrao/tipo-produto/modal/modal-manter-tipo-produto.component';
import { TipoProdutoComponent } from './solicitacoes-produto-padrao/tipo-produto/tipo-produto.component';
import { NcmComponent } from './solicitacoes-produto-padrao/ncm/ncm.component';
import { ModalManterNcm } from './solicitacoes-produto-padrao/ncm/modal/modal-manter-ncm.component';
import { ModalVincular } from './solicitacoes-produto-padrao/modal-vincular/modal-vincular.component';
import { ModalJustificativaProdutoComponent } from './solicitacoes-produto-padrao/modal-justificativa/modal-justificativa.component';
import { ModalHistoricoComponent } from "./solicitacoes-produto-padrao/modal-historico/modal-historico.component";
import { ModalListagemPPBComponent } from "./solicitacoes-produto-padrao/modal-listagem-ppb/modal-listagem-ppb.component";
import { GridListagemPPBComponent } from "./solicitacoes-produto-padrao/modal-listagem-ppb/grid/grid-listagem-ppb.component";
import { ModalVerificarInconssistenciaComponent } from "./modal-verificar-inconssistencia/modal-verificar-inconssistencia.component";

import {ModalDetalhePPBComponent} from "./solicitacoes-produto-padrao/modal-detalhe-ppb/modal-detalhe-ppb.component";

import {VinculosPPBComponent} from "./solicitacoes-produto-padrao/vinculos-ppb/vinculos-ppb.component";

import {CondicionantesPPBComponent} from "./solicitacoes-produto-padrao/modal-detalhe-ppb/condicionantes-ppb/condicionantes-ppb.component";
import {GridCondicionantesPPBComponent} from "./solicitacoes-produto-padrao/modal-detalhe-ppb/condicionantes-ppb/grid/grid.component";
import {ControleEtapasPPBComponent} from "./solicitacoes-produto-padrao/modal-detalhe-ppb/etapas-ppb/etapas-ppb.component";
import {GridEtapasPPBComponent} from "./solicitacoes-produto-padrao/modal-detalhe-ppb/etapas-ppb/grid/grid.component";
import {MetasPPBComponent} from "./solicitacoes-produto-padrao/modal-detalhe-ppb/metas-ppb/metas-ppb.component";
import {GridMetasPPBComponent} from "./solicitacoes-produto-padrao/modal-detalhe-ppb/metas-ppb/grid/grid.component";
import {PPBMultiploComponent} from "./solicitacoes-produto-padrao/modal-detalhe-ppb/ppb-multiplo/ppb-multiplo.component";
import {GridPPBMultiploComponent} from "./solicitacoes-produto-padrao/modal-detalhe-ppb/ppb-multiplo/grid/grid.component";
import {ArtigoDropListComponent} from "../../shared/components/condicionante-drop-list/artigo-drop-list.component";
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		SharedModule,
		AngularEditorModule,
		NgbModule,
		NgxMaskModule.forChild()
	],
	declarations: [
		ManterProdutoPadraoComponent,
		GridManterProdutoPadraoComponent,
		SolicitacoesProdutoPadraoComponent,
		GridTipoProdutoComponent,
		GridNcmComponent,
		ModalManterTipoProduto,
		TipoProdutoComponent,
		NcmComponent,
		ModalManterNcm,
		ModalVincular,
		ModalJustificativaProdutoComponent,
		ModalHistoricoComponent,
		ModalListagemPPBComponent,
		GridListagemPPBComponent,
    	ModalVerificarInconssistenciaComponent,
		ModalDetalhePPBComponent,
		CondicionantesPPBComponent,
		GridCondicionantesPPBComponent,
		ControleEtapasPPBComponent,
		GridEtapasPPBComponent,
		MetasPPBComponent,
		GridMetasPPBComponent,
		PPBMultiploComponent,
		GridPPBMultiploComponent,
		VinculosPPBComponent,
    ArtigoDropListComponent
	],
})
export class ManterProdutoPadraoModule {}
