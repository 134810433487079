<div #appModalNcm class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
  style="display: auto; overflow: scroll;">
  <div class="modal-dialog">

    <div class="modal-content">

      <div class="modal-header bg-primary" style="padding: 10px 15px;">
        <h4 class="modal-title" style="color: White;" id="modalParecer">NCMS vinculadas ao produto</h4>
      </div>

      <div class="modal-body">
        <form>
          <app-grid
          [(page)]="grid.page"
          [(size)]="grid.size"
          [(total)]="grid.total"
          [(parametros)]="parametros"
          [(titulo)]="tituloGrid"
            (onChangeSize)="changeSize($event)" (onChangePage)="changePage($event)">
            <table class="table">
              <thead class="table-header-color">
                <tr>
                  <th class="th-sortable unset-nowrap text-left">
                    <app-ordenacao field="numeroCodigo" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                      NCM
                    </app-ordenacao>
                  </th>
                  <th class="th-sortable unset-nowrap text-left">
                    <app-ordenacao field="descSubsetor" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                      Situação(NCM) </app-ordenacao>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of grid.lista">

                  <td class="text-left">{{ item.descricaoNcm}}</td>
                  <td class="text-left">{{ item.statusNcm==0 ?"INATIVO":"ATIVO"}}</td>



              </tr>

              </tbody>
            </table>

          </app-grid>
        </form>
      </div>

      <div class="modal-footer" style="padding: 10px 15px;">

        <a class="btn btn-sm btn-primary" data-dismiss="modal" data-toggle="modal" data-backdrop="static"
          data-keyboard="false" (click)="fechar()">
          <i class="fa fa-times"></i> Fechar
        </a>
      </div>

    </div>

  </div>
</div>
<div #appModalNcmBackground class="modal-backdrop fade in" style="display: none;"></div>
