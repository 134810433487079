import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "../../../../../shared/services/application.service";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { ModalService } from "../../../../../shared/services/modal.service";
import { ValidationService } from "../../../../../shared/services/validation.service";



@Component({
	selector: 'app-grid-listagem-ppb',
	templateUrl: './grid-listagem-ppb.component.html'
})

export class GridListagemPPBComponent {

	ocultarTituloGrid = true;

	constructor(
	
	) { 
		
	}

	disable : boolean =false

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;


	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	tituloGrid = ""

	_dataJustificativa: any = {};


	changeSize($event) {
		this.onChangeSize.emit($event);
		this.changePage(this.page);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}


	
}