<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
     data-railopacity="0.2" id="relatorioPDF" #relatorioPDF>
  <div class="row m-b-md">
    <div class="col-sm-6">
      <!-- <app-titulo>Relatório de Produto Padronizado</app-titulo>
      <app-sub-titulo>Consultar Produto Padronizado</app-sub-titulo> -->
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <section class="panel panel-default">
        <header class="panel-heading">
          <!--
                    <div class="pull-right">
                      <a class="btn btn-primary btn-sm"  (click)="gerarRelatorio()">
                        <i class="fa fa-plus"></i> Emitir Relatorio
                      </a>
                    </div> -->

          <h2 class="panel-title h5">Filtros de Pesquisa</h2>
        </header>
        <article *ngIf="!ocultarFiltros" class="panel-body">
          <form id="formBusca" class="form form-horinzontal" role="form" #formBusca="ngForm" ngNativeValidate>

            <article class="panel-body">
              <div div class="row form-group">
                <div class="col-lg-5">
                  <label for="benscapital" class="">Bens de Informática:</label>
                  <select name="benscapital" id="benscapital" class="form-control"
                          [(ngModel)]="parametros.sitBemInformativa"
                          (change)="flagAlterouFiltro = true">
                    <option value="99">Todos</option>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>

                <div class="col-lg-6">
                  <label for="componente" class="">Componente:</label>
                  <select name="componente" id="componente" class="form-control"
                          [(ngModel)]="parametros.sitComponente"
                          (change)="flagAlterouFiltro = true">
                    <option value="99">Todos</option>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-lg-3 text-nowrap">
                  <label for="situacao" class="">Subsetor:</label>
                  <app-drop-list (change)="usuarioAlterouItem = true;" id="drop-list-subsetor"
                                 name="drop-list-subsetor" servico="SubSetor" #subSetor
                                 [isDisabled]="disable ? true : false"
                                 [(ngModel)]="parametros.idSubsetor">
                  </app-drop-list>
                </div>
                <div class="col-lg-6">
                  <label for="ncm" class="">NCM</label>
                  <app-drop-list-select2 #Ncm id="documentoReferencia-solicitacoes-ppb"
                                         name="documentoReferencia-solicitacoes-ppb"
                                         (valorModificado)="AlteraNcm($event)" servico="Ncm"
                                         placeholder="Pesquisar NCM" selMinimumInputLength="0">
                  </app-drop-list-select2>
                </div>
                <div class="col-lg-3">
                  <label for="crii" class="">CRII:</label>
                  <select name="crii" id="crii" class="form-control" [(ngModel)]="parametros.idCoeficienteReducao">
                    <option value="99">Todos</option>
                    <option value="1">CRA</option>
                    <option value="2">88%</option>
                    <option value="3">CRA+5%</option>
                    <option value="4">Sem Redução</option>
                  </select>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-lg-10">
                  <label for="descricao">Base Legal</label>
                  <app-drop-list-select2 #BaseLegal
                                         [InputValue]="parametros.descricaoTitulo"
                                         id="documentoReferencia-solicitacoes-ppb"
                                         name="documentoReferencia-solicitacoes-ppb"
                                         (valorModificado)="AlteraBaseLegal($event);
                    flagAlterouFiltro = true" servico="BaseLegal"
                                         placeholder="Pesquisar Base Legal" selMinimumInputLength="0"
                                         [(ngModel)]="parametros.descBaseLegalExistente">
                  </app-drop-list-select2>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-lg-5">
                  <label for="situacao" class="">Situação:</label>
                  <select name="situacao" id="situacao" class="form-control" [(ngModel)]="parametros.sitProduto"
                          (change)="flagAlterouFiltro = true">
                    <option value="99">Todos</option>
                    <option value="2">Ativos</option>
                    <option value="3">Inativos</option>
                  </select>
                </div>
              </div>
            </article>
          </form>
        </article>
        <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
          <div class="pull-right">
            <button *ngIf="!ocultarFiltros" class="btn btn-sm btn-primary" type="submit" (click)="buscar()"
                    disableOnClick>
              <i class="fa fa-search"></i> Buscar
            </button>
            <button *ngIf="!ocultarFiltros"
                    (click)="limparFiltros()" #btnlimpar class="btn btn-sm btn-default" type="reset">
              <i class="fa fa-eraser"></i> Limpar
            </button>
            <button type="button" class="btn btn-sm btn-default"
                    (click)=" ocultarFiltros ? ocultarFiltros = false : ocultarFiltros = true ">
              <i class="fa fa-magic"></i> {{ !ocultarFiltros ? 'Ocultar Filtros' : 'Exibir Filtros' }}
            </button>
          </div>
        </footer>
      </section>
    </div>
  </div>

  <div class="row" id="grid" *ngIf="!ocultarGrid">
    <div class="col-lg-12">
      <app-grid-relatorio-produto-padronizado
        [(lista)]="grid.lista"
        [(total)]="grid.total"
        [(size)]="grid.size"
        [(page)]="grid.page"
        [(parametros)]="parametros"
        (onChangeSort)="onChangeSort($event)"
        (onChangeSize)="onChangeSize($event)"
        (onChangePage)="onChangePage($event)">
      </app-grid-relatorio-produto-padronizado>
    </div>
  </div>
</div>

<app-emitir-relatorio-produto-padronizado
  #appRelatorioEmitirTipoProdutoPadrao
  (DownloadFinalizado)="exibeRelatorio = false"
  [(objetoExportacao)]="objetoExportacao"
  [(parametrosExportacao)]="parametrosExportacao">

</app-emitir-relatorio-produto-padronizado>
