<div class="modal-dialog center" style="margin-top: 100px;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title">{{data.title || 'Informação'}}</h4>
      </div>
      <div class="modal-body" style="overflow-y: auto;">
        <div class="alert alert-block alert-danger" style="margin-bottom: 0;" style="overflow-y: auto; height: 200px;">
          {{data.message}}
        </div>        
      </div>
      <div class="modal-footer" style="padding: 8px 15px;">
        <button 
            type="button" 
            class="btn btn-default btn-sm" 
            [mat-dialog-close]="false" 
            #ok>
              <span class="fa fa-times m-r-xs"></span>Fechar
        </button>
      </div>
    </div>
  </div>
</div>
 <div class="modal-backdrop fade in"></div>

