<div #appModalEtapasCondicoesPPB class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Listagens do PPB</h4>
            </div>

            <div class="modal-body">
                <!-- <app-grupos-ppb 
                        *ngIf="_isPorGrupo || (parametros.ppbVM.tipoPublicacao != FIXACAO)"
                        [(habilitarInputs)]="habilitarInputs" 
                        [(idPPB)]="idPPB">
                    </app-grupos-ppb> -->
                <app-grupos-ppb 
                *ngIf="exibirGrids && tipoEtapa == 3"
                [(habilitarInputs)]="habilitarInputs" [(idPPB)]="idPPB">
                </app-grupos-ppb>

                <app-etapas-ppb 
                *ngIf="exibirGrids"
                [(habilitarInputs)]="habilitarInputs" [(idPPB)]="idPPB">
                </app-etapas-ppb>

                <app-condicionantes-ppb 
                *ngIf="exibirGrids"
                [(habilitarInputs)]="habilitarInputs" [(idPPB)]="idPPB">
                </app-condicionantes-ppb>

                <!-- <produtos-vinculados-ppb [(habilitarInputs)]="habilitarInputs" [(idPPB)]="idPPB">
                </produtos-vinculados-ppb> -->
            </div>

            <div class="modal-footer" style="padding: 10px 15px;">
                <a (click)="fechar()" class="btn btn-sm btn-default" data-dismiss="modal">
                    <i class="fa fa-arrow-left"></i> Voltar
                </a>
            </div>

        </div>

    </div>
</div>
<div #appModalEtapasCondicoesPPBBackground class="modal-backdrop fade in" style="display: none;"></div>