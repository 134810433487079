 <div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
  data-railOpacity="0.2">

  <div class="e row">
    <div class="col-lg-12">
      <section class="panel panel-default">
        <header class="panel-heading FixPanelHeading">
          <h2 class="panel-title h5">Filtros de Pesquisa</h2>
        </header>
        <form class="form" role="form">
          <article class="panel-body" *ngIf="!ocultarFiltros">


            <div class="col-lg-12">
              <label for="" class="required">Produto:</label>
                  <app-drop-list-select2
                    #Produto
                    id="documentoReferencia-solicitacoes-ppb"
                    name="documentoReferencia-solicitacoes-ppb"
                    (valorModificado)="AlteraProduto($event)"
                    servico="Produto"
                    [parametroChave]="'id'"
                    [parametro]="parametros.id"
                    placeholder="Pesquisar Produto"
                    selMinimumInputLength="0">
                  </app-drop-list-select2>
                <div class="radio i-checks radio-sm " style="margin-left: 15px;">
                  <label class="input-sm">
                    <input name="radio[custom]" type="radio" [(ngModel)]="parametros.id" value="1"><i></i> Começa por
                  </label>
                  <label class="input-sm">
                    <input name="radio[custom]" type="radio" [(ngModel)]="parametros.id" value="2"><i></i> Qualquer Parte
                  </label>
                  <label class="input-sm">
                    <input name="radio[custom]" type="radio" [(ngModel)]="parametros.id" value="3"><i></i> Palavras Exatas
                  </label>
               </div>
              </div>



              <div class="col-lg-5">
                <label for="situacaoTipo" class="">Situação (Tipo):</label>
                <select name="situacaoTipo" id="situacaoTipo" class="form-control"
                [(ngModel)]="parametros.statusTipo">
                  <option value="99" selected>Todos</option>
                  <option value="0" >Inativos</option>
                  <option value="1">Ativos</option>
                </select>
              </div>
                <div class="col-lg-5">
                <label for="situacaoNcm" class="">Situação (NCM):</label>
                <select name="situacaoNcm" id="situacaoNcm" class="form-control"
                 [(ngModel)]="parametros.statusNCM">
                  <option value="99" >Todas</option>
                  <option value="0">Inativas</option>
                  <option value="1">Ativas</option>
                </select>
              </div>


          </article>
          <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
            <div class="pull-right">
              <button *ngIf="!ocultarFiltros" type="button"  (click)="buscar()" class="btn btn-sm btn-primary a"><span
                  class="fa fa-search m-r-xs"></span>Buscar</button>

                  <button *ngIf="!ocultarFiltros" (click)="limparFiltros()" type="button" class="btn btn-sm btn-default"><span
                  class="fa fa-eraser m-r-xs"></span>Limpar</button>

                  <button type="button" class="btn btn-sm btn-default"
                  (click)=" ocultarFiltros ? ocultarFiltros = false : ocultarFiltros = true " style="margin-left: -1px;">
                  <span class="fa fa-magic m-r-xs"></span>{{'Ocultar Filtros' }}
              </button>
            </div>
          </footer>
        </form>
      </section>
    </div>
  </div>

        <div class="row" id="grid" *ngIf="!ocultarGrid">
          <div class="col-lg-12">
            <app-grid-relatorio-produto-tipo-padrao
              [(lista)]="grid.lista"
              [(total)]="grid.total"
              [(size)]="grid.size"
              [(page)]="grid.page"
              [(parametros)]="parametros"
              [(statusNCM)]="parametros.statusNCM"
              (onChangeSort)="onChangeSort($event)"
              (onChangeSize)="onChangeSize($event)"
              (onChangePage)="onChangePage($event)"
              (emitAtualizaListagem)="buscar()">
            </app-grid-relatorio-produto-tipo-padrao>
          </div>
        </div>
</div>

<app-relatorio-emitir-tipo-produto-padrao
#appRelatorioEmitirTipoProdutoPadrao
*ngIf="!exibirRelatorio2"
(DownloadFinalizado)="exibeRelatorio = false"
[(listaProdutoTipoPadrao)] = "listaProdutoTipoPadrao">
</app-relatorio-emitir-tipo-produto-padrao>
