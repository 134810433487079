<div class="row form-group" style="margin-left: 2px;">	

    <div class="row form-group" *ngIf="habilitarInputs">
        <div class="col-lg-12">
            <a 
                style="margin-right: 10px; margin-bottom: -10px;"
                class="btn btn-primary btn-sm pull-right" 
                data-toggle="tooltip" 
                (click)="adicionarCondicionantes()"
                title="" 
                data-original-title="">
                    <i class="fa fa-plus"></i> Incluir na Lista
            </a>
        </div>
    </div>
       
    <div [ngClass]="(path == 'validacao' && validacao.pendenciaCondicionantePPB) ? 'pendencia-validacao' : '' ">
        <app-grid-condicionantes-ppb    
            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
            (atualizaListagemGrid)="atualizaListagem($event)"  
            [(habilitarInputs)]="habilitarInputs"
            [(lista)]="grid.lista"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)">
        </app-grid-condicionantes-ppb>    
    </div>
   

</div>

<app-modal-incluir-condicionantes-ppb 
    [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
    #appModalIncluirCondicionantesPPB
    (atualizaListagem)="atualizaListagem($event)">
</app-modal-incluir-condicionantes-ppb>