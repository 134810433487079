<div
  #appModalVerificarInconssistencia
  class="modal modal-wide-2 fade in centro" tabindex="-1" role="dialog" aria-hidden="false"
  style="display: none; overflow-y: auto;">

  <div class="modal-dialog modal-lg" style="max-width: 70%; width:auto !important; margin-top: 10px;">
    <div class="modal-content">
        <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
          <div class="row" style="padding: 12px;">
            <h4 class="modal-title" id="incluir_etapas">Inconsistência de dados (NCM, SubSetor e Unidade de Medida)
            <button style="float: right;" (click)="fechar()" class="btn btn-default btn-sm" data-dismiss="modal"><i class="fa fa-times"></i> Fechar</button></h4>
          </div>
        </div>

      <div class="modal-body">
        <form style="display: auto; overflow: scroll; overflow-y: scroll;">

          <div class="row form-group" style="margin-left: 2px;" *ngIf="data != null">
            <div class="col-lg-2">
              <label for="descricao" class="">Código: {{data.numeroCodigo}}</label>
            </div>
            <div class="col-lg-10">
              <label for="codigo">Descrição: {{data.descricao}}</label>
            </div>
          </div>
            <div class="table-responsive no-margin-bottom no-border">
                <table *ngIf="flagFinalizouConsulta" class="table table-striped">
                    <thead class="table-header-color">
                        <tr>
                            <th class="th-100 text-left">Codigo Produto</th>
                            <th class="th-100 text-left">Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of lista">
                            <td class="text-left">{{item.numeroCodigo}}</td>
                            <td class="text-left">{{item.tipoListaProdutoInconssistencia}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
      </div>

      <div class="modal-footer" style="padding: 10px 15px;">
      </div>
    </div>
  </div>
</div>

<div #appModalVerificarInconssistenciaBackground class="modal-backdrop fade in" style="display: none;"></div>
