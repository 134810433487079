<!-- visualizar produto -->
<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
  data-railopacity="0.2" *ngIf="path == 'produto'">

  <div class="row m-b-md">
    <div class="col-lg-12">
      <app-titulo>Visualizar Produto Padronizado</app-titulo>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <section class="panel panel-default">
        <header class="panel-heading">
          <div class="pull-right"><a (click)="voltar()" class="btn btn-default btn-sm"><i
                class="fa fa-long-arrow-left"></i>Voltar</a></div>

              <div class="pull-right">
                <div class="btn-group">
                  <button class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown"><i
                      class="fa fa-download"></i> Exportar <span class="caret"></span></button>
                  <ul class="dropdown-menu pull-right">
                    <li>
                      <a style="cursor:pointer" role="menuitem" (click)="abrirDownload(1)"><i class="fa fa-file-pdf-o"></i>
                        Salvar em PDF</a>
                    </li>
                  </ul>
                </div>
              </div>

          <h2 class="panel-title h5">Informações do Produto</h2>


        </header>

        <form>
          <article class="panel-body">
            <div class="form-group row">
              <div class="m-r-xs">
                <div class="col-lg-12">
                  <div class="alert alert-success"
                    style="background-color: #F0FFF0; border-color: #98FB98; color: #000; font-weight: bold;   padding: 8px 15px; margin-bottom: 10px; font-size: 14px;">
                    Dados antes da atualização
                  </div>
                </div>
              </div>

              <div class="row col-lg-12">

                <div class="col-lg-4">
                  <label class="m-r-xs">Código:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.numeroCodigo}}</span>
                </div>
                <div class="col-lg-8">
                  <label class="m-r-xs">Descrição:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.descProduto}}</span>
                </div>
              </div>
              <div class="col-lg-3">
                <label class="m-r-xs">Unidade de Medida:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.unidadeMedidaFormatado}}</span>
              </div>
              <div class="row col-lg-12">

                <div class="col-lg-8">
                  <label class="m-r-xs">Coeficiente de Redução:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.coeficienteReducaoFormatado}}</span>
                </div>
              </div>
              <div class="col-lg-8">
                <label class="m-r-xs">Componente:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.sitComponente == 1 ? "Sim" : "Não"}}</span><span></span>
                <label class="m-r-xs" style="margin-left: 40px;">Bem de Informática::</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.sitComponente == 1 ? "Sim" : "Não"}}</span>
                <label class="m-r-xs" style="margin-left: 40px;">Nº Dígitos:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.numeroDigitos}}</span>
              </div>
              <div class="col-lg-8">
                <label class="m-r-xs">Protocolo de Solicitação:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.descProtocolo}}</span>
              </div>

              <div class="col-lg-8">
                <label class="m-r-xs">Subsetor:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.subsetorFormatado}}</span>
              </div>

              <div class="col-lg-8">
                <label class="m-r-xs">Status:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.statusFormatado}}</span>
              </div>

              <div class="col-lg-8">
                <label class="m-r-xs">Base Legal:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.descBaseLegalExistente}}</span>
              </div>

              <div class="col-lg-8">
                <label class="m-r-xs">Observação:</label><span>{{produtoAlteracoes.produtoAlteracoesAntes.observacao}}</span>

              </div>
              <div class="col-lg-12">
                <label class="m-r-xs">Tipo:</label>
                <div *ngIf="produtoIsCarregado">
                  <app-grid-relacao-tipo-ncm-antes
                  [(lista)]="produtoAlteracoes.produtoAlteracoesAntes"
                  >
                  </app-grid-relacao-tipo-ncm-antes>
                </div>


              </div>

            </div>

            <div class="form-group row">
              <div class="m-r-xs">
                <div class="col-lg-12">
                  <div class="alert alert-success"
                    style="background-color: #F0FFF0; border-color: #98FB98; color: #000; font-weight: bold;   padding: 8px 15px; margin-bottom: 10px; font-size: 14px;">
                    Dados depois da atualização
                  </div>
                </div>
              </div>

              <div class="row col-lg-12">

                <div class="col-lg-4">
                  <label class="m-r-xs">Código:</label><span>{{produtoAlteracoes.produtoAlteracoesDepois.numeroCodigo}}</span>
                </div>
                <div class="col-lg-8">
                  <label class="m-r-xs">Descrição:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isDescricaoAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.descProduto}}</span>
                </div>
              </div>
              <div class="col-lg-3">
                <label class="m-r-xs">Unidade de Medida:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isIdUnidadeAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.unidadeMedidaFormatado}}</span>
              </div>

              <div class="row col-lg-12">

                <div class="col-lg-8">
                  <label class="m-r-xs">Coeficiente de Redução:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isIdCoeficienteAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.coeficienteReducaoFormatado}}</span>
                </div>
              </div>
              <div class="col-lg-8">
                <label class="m-r-xs">Componente:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isSitComponenteAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.sitComponente == 1 ? "Sim" : "Não"}}</span><span></span>
                <label class="m-r-xs" style="margin-left: 40px;">Bem de Informática::</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isSitBemInformaticaAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.sitComponente == 1 ? "Sim" : "Não"}}</span>
                <label class="m-r-xs" style="margin-left: 40px;">Nº Dígitos:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isNumeroDigitosAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.numeroDigitos}}</span>
              </div>
              <div class="col-lg-8">
                <label class="m-r-xs">Protocolo de Solicitação:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isDescProtocoloAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.descProtocolo}}</span>
              </div>

              <div class="col-lg-8">
                <label class="m-r-xs">Subsetor:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isIdSubsetorAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.subsetorFormatado}}</span>
              </div>

              <div class="col-lg-8">
                <label class="m-r-xs">Status:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isSitProdutoAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.statusFormatado}}</span>
              </div>

              <div class="col-lg-8">
                <label class="m-r-xs">Base Legal:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isBaseLegalAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.descBaseLegalExistente}}</span>
              </div>

              <div class="col-lg-8">
                <label class="m-r-xs">Observação:</label><span [ngStyle]="{'color':produtoAlteracoes.produtoAlteracoesDepois.isObservacaoAlterado == true ? 'red': ''}">{{produtoAlteracoes.produtoAlteracoesDepois.observacao}}</span>

              </div>
              <div class="col-lg-12">
                <label class="m-r-xs">Tipo:</label>
                <div *ngIf="produtoIsCarregado">
                  <app-grid-relacao-tipo-ncm-depois
                  [(lista)]="produtoAlteracoes.produtoAlteracoesDepois"
                  >
                  </app-grid-relacao-tipo-ncm-depois>
                </div>


              </div>

            </div>

            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="thead-cadsuf" style="background: #00552b!important; color: #fff!important;">
                  <tr>
                    <th>Responsável</th>
                    <th>CPF</th>
                    <th>Data/Hora</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{produtoAlteracoes.produtoAlteracoesDepois.nomeUsuario}}</td>
                    <td>{{produtoAlteracoes.produtoAlteracoesDepois.numeroUsuarioFormatado}}</td>
                    <td>{{produtoAlteracoes.produtoAlteracoesDepois.dataFormatado}}</td>


                  </tr>

                </tbody>
              </table>
            </div>

          </article>
        </form>

        <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
          <div class="pull-right">
            <a (click)="voltar()" class="btn btn-sm btn-default" data-dismiss="modal"><i
                class="fa fa-long-arrow-left"></i> Voltar</a>
          </div>
        </footer>
      </section>
    </div>
  </div>
</div>
<!-- visualizar produto -->
<!-- visualizar unidade -->
<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
  data-railopacity="0.2" *ngIf="path == 'unidade'">

  <div class="row m-b-md">
    <div class="col-lg-12">
      <app-titulo>Visualizar Unidade</app-titulo>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <section class="panel panel-default">
        <header class="panel-heading">
          <div class="pull-right"><a (click)="voltar()" class="btn btn-default btn-sm"><i
                class="fa fa-long-arrow-left"></i>Voltar</a></div>

          <div class="pull-right">
            <div class="btn-group">
              <button class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown"><i
                  class="fa fa-download"></i> Exportar <span class="caret"></span></button>
              <ul class="dropdown-menu pull-right">
                <li>
                  <a style="cursor:pointer" role="menuitem" (click)="abrirDownload(2)"><i class="fa fa-file-pdf-o"></i>
                    Salvar em PDF</a>
                </li>
              </ul>
            </div>
          </div>

          <h2 class="panel-title h5">Informações da Unidade</h2>


        </header>

        <form>
          <article class="panel-body">


            <div class="form-group row">
              <div class="m-r-xs">
                <div class="col-lg-12">
                  <div class="alert alert-success"
                    style="background-color: #F0FFF0; border-color: #98FB98; color: #000; font-weight: bold;   padding: 8px 15px; margin-bottom: 10px; font-size: 14px;">
                    Dados antes da atualização
                  </div>
                </div>
              </div>

              <div class="row col-lg-12">

                <div class="col-lg-4">
                  <label class="m-r-xs">Código:</label><span>{{unidadeAlteracoes.unidadeAntes.numeroCodigo}}</span>
                </div>
                <div class="col-lg-8">
                  <label class="m-r-xs">Sigla:</label><span>{{unidadeAlteracoes.unidadeAntes.sigla}}</span>
                </div>
              </div>
              <div class="col-lg-3">
                <label class="m-r-xs">Descrição:</label><span>{{unidadeAlteracoes.unidadeAntes.descricao}}</span>
              </div>
              <div class="row col-lg-12">

              </div>

            </div>

          </article>
        </form>

        <form>
          <article class="panel-body">


            <div class="form-group row">
              <div class="m-r-xs">
                <div class="col-lg-12">
                  <div class="alert alert-success"
                    style="background-color: #F0FFF0; border-color: #98FB98; color: #000; font-weight: bold;   padding: 8px 15px; margin-bottom: 10px; font-size: 14px;">
                    Dados depois da atualização
                  </div>
                </div>
              </div>

              <div class="row col-lg-12">

                <div class="col-lg-4">
                  <label class="m-r-xs">Código:</label><span [ngStyle]="{'color': unidadeAlteracoes.unidadeDepois.numeroCodigo != unidadeAlteracoes.unidadeAntes.numeroCodigo ? '#E21818' : ''}">{{unidadeAlteracoes.unidadeDepois.numeroCodigo}}</span>
                </div>
                <div class="col-lg-8">
                  <label class="m-r-xs">Sigla:</label><span [ngStyle]="{'color': unidadeAlteracoes.unidadeDepois.sigla != unidadeAlteracoes.unidadeAntes.sigla ? '#E21818' : ''}">{{unidadeAlteracoes.unidadeDepois.sigla}}</span>
                </div>
              </div>
              <div class="col-lg-3">
                <label class="m-r-xs">Descrição:</label><span [ngStyle]="{'color': unidadeAlteracoes.unidadeDepois.descricao != unidadeAlteracoes.unidadeAntes.descricao ? '#E21818' : ''}">{{unidadeAlteracoes.unidadeDepois.descricao}}</span>
              </div>
              <div class="row col-lg-12">

              </div>

            </div>



            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="thead-cadsuf" style="background: #00552b!important; color: #fff!important;">
                  <tr>
                    <th>Responsável</th>
                    <th>Login</th>
                    <th>Data/Hora</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{unidadeAlteracoes.nomeUsuario}}</td>
                    <td>{{unidadeAlteracoes.numeroUsuario}}</td>
                    <td>{{unidadeAlteracoes.dataHoraString}}</td>


                  </tr>

                </tbody>
              </table>
            </div>



          </article>
        </form>

        <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
          <div class="pull-right">
            <a (click)="voltar()" class="btn btn-sm btn-default" data-dismiss="modal"><i
                class="fa fa-long-arrow-left"></i> Voltar</a>
          </div>
        </footer>
      </section>
    </div>
  </div>
</div>
<!-- visualizar unidade -->
<!-- visualizar subsetor -->
<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
  data-railopacity="0.2" *ngIf="path == 'subsetor'">

  <div class="row m-b-md">
    <div class="col-lg-12">
      <app-titulo>Visualizar Subsetor</app-titulo>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <section class="panel panel-default">
        <header class="panel-heading">
          <div class="pull-right"><a (click)="voltar()" class="btn btn-default btn-sm"><i
                class="fa fa-long-arrow-left"></i>Voltar</a></div>

            <div class="pull-right">
              <div class="btn-group">
                <button class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown"><i
                    class="fa fa-download"></i> Exportar <span class="caret"></span></button>
                <ul class="dropdown-menu pull-right">
                  <li>
                    <a style="cursor:pointer" role="menuitem" (click)="abrirDownload(3)"><i class="fa fa-file-pdf-o"></i>
                      Salvar em PDF</a>
                  </li>
                </ul>
              </div>
            </div>

          <h2 class="panel-title h5">Informações do Subsetor</h2>


        </header>

        <form>
          <article class="panel-body">
            <div class="form-group row">
              <div class="m-r-xs">
                <div class="col-lg-12">
                  <div class="alert alert-success"
                    style="background-color: #F0FFF0; border-color: #98FB98; color: #000; font-weight: bold;   padding: 8px 15px; margin-bottom: 10px; font-size: 14px;">
                    Dados antes da atualização
                  </div>
                </div>
              </div>

              <div class="row col-lg-12">

                <div class="col-lg-4">
                  <label class="m-r-xs">Código:</label><span>{{ subsetorAlteracoes.subsetorAntes.numeroCodigo }}</span>
                </div>

                <div class="col-lg-3">
                  <label class="m-r-xs">Descrição:</label><span>{{ subsetorAlteracoes.subsetorAntes.descSubsetor }}</span>
                </div>
                <div class="row col-lg-12">

                </div>

              </div>
            </div>
          </article>
        </form>

        <form>
          <article class="panel-body">


            <div class="form-group row">
              <div class="m-r-xs">
                <div class="col-lg-12">
                  <div class="alert alert-success"
                    style="background-color: #F0FFF0; border-color: #98FB98; color: #000; font-weight: bold;   padding: 8px 15px; margin-bottom: 10px; font-size: 14px;">
                    Dados depois da atualização
                  </div>
                </div>
              </div>

              <div class="row col-lg-12">

                <div class="col-lg-4">
                  <label class="m-r-xs">Código:</label><span>{{ subsetorAlteracoes.subsetorDepois.numeroCodigo }}</span>
                </div>

                <div class="col-lg-3">
                  <label class="m-r-xs">Descrição:</label><span style="color: #E21818;">{{subsetorAlteracoes.subsetorDepois.descSubsetor}}</span>
                </div>
              </div>


              <div class="table-responsive">
                <table class="table table-striped">
                  <thead class="thead-cadsuf" style="background: #00552b!important; color: #fff!important;">
                    <tr>
                      <th>Responsável</th>
                      <th>Login</th>
                      <th>Data/Hora</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{subsetorAlteracoes.nomeUsuario}}</td>
                      <td>{{subsetorAlteracoes.numeroUsuario}}</td>
                      <td>{{subsetorAlteracoes.dataHoraString}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </article>
        </form>

        <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
          <div class="pull-right">
            <a (click)="voltar()" class="btn btn-sm btn-default" data-dismiss="modal"><i
                class="fa fa-long-arrow-left"></i> Voltar</a>
          </div>
        </footer>
      </section>
    </div>
  </div>
</div>
<!-- visualizar subsetor -->
<!-- visualizar ncm -->
<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
  data-railopacity="0.2" *ngIf="path == 'ncm'">

  <div class="row m-b-md">
    <div class="col-lg-12">
      <app-titulo>Visualizar Código NCM</app-titulo>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <section class="panel panel-default">
        <header class="panel-heading">
          <div class="pull-right"><a (click)="voltar()" class="btn btn-default btn-sm"><i
                class="fa fa-long-arrow-left"></i>Voltar</a></div>

                <div class="pull-right">
                  <div class="btn-group">
                    <button class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown"><i
                        class="fa fa-download"></i> Exportar <span class="caret"></span></button>
                    <ul class="dropdown-menu pull-right">
                      <li>
                        <a style="cursor:pointer" role="menuitem" (click)="abrirDownload(4)"><i class="fa fa-file-pdf-o"></i>
                          Salvar em PDF</a>
                      </li>
                    </ul>
                  </div>
                </div>

          <h2 class="panel-title h5">Informações da NCM</h2>
        </header>

        <form>
          <article class="panel-body">


            <div class="form-group row">
              <div class="m-r-xs">
                <div class="col-lg-12">
                  <div class="alert alert-success"
                    style="background-color: #F0FFF0; border-color: #98FB98; color: #000; font-weight: bold;   padding: 8px 15px; margin-bottom: 10px; font-size: 14px;">
                    Dados antes da atualização
                  </div>
                </div>
              </div>

              <div class="row col-lg-12">
                <div class="col-lg-8">
                  <label class="m-r-xs">Código:</label><span>{{ ncmAlteracoes?.ncmAntes?.numeroCodigo }}</span>
                </div>


                <div class="col-lg-8">
                  <label class="m-r-xs">Descrição:</label><span>{{ ncmAlteracoes?.ncmAntes?.descricaoNcm }}</span>
                </div>


                <div class="col-lg-8">
                  <label class="m-r-xs">Unidades:</label><span>{{ ncmAlteracoes?.ncmAntes?.unidadeMedida?.descricao }}</span>
                </div>

                <div class="row col-sm-12">
                  <div class="col-sm-2">
                    <label class="m-r-xs">Alíquota II (%):</label><span>{{ ncmAlteracoes?.ncmAntes?.aliquotaII }}</span>


                  </div>
                  <div class="col-sm-2">
                    <label class="m-r-xs">Data Inicío:</label><span>{{ ncmAlteracoes?.ncmAntes?.aliquotaIIVigenciaInicialString }}</span>
                  </div>
                  <div class="col-sm-2">
                    <label class="m-r-xs">Data Final:</label><span>{{ ncmAlteracoes?.ncmAntes?.aliquotaIIVigenciaFinalString }}</span>
                  </div>
                </div>

                <div class="row col-sm-12">
                  <div class="col-sm-2">
                    <label class="m-r-xs">Alíquota IPI (%):</label><span>{{ ncmAlteracoes?.ncmAntes?.aliquotaIPI }}</span>
                  </div>
                  <div class="col-sm-2">
                    <label class="m-r-xs">Data Inicío:</label><span>{{ ncmAlteracoes?.ncmAntes?.aliquotaIPIVigenciaInicialString }}</span>
                  </div>
                  <div class="col-sm-2">
                    <label class="m-r-xs">Data Final:</label><span>{{ ncmAlteracoes?.ncmAntes?.aliquotaIPIVigenciaFinalString }}</span>
                  </div>
                </div>

              </div>
            </div>
          </article>
        </form>

        <form>
          <article class="panel-body">
            <div class="form-group row">
              <div class="m-r-xs">
                <div class="col-lg-12">
                  <div class="alert alert-success"
                    style="background-color: #F0FFF0; border-color: #98FB98; color: #000; font-weight: bold;   padding: 8px 15px; margin-bottom: 10px; font-size: 14px;">
                    Dados depois da atualização
                  </div>
                </div>
              </div>

              <div class="row col-lg-12">

                <div class="col-lg-8">
                  <label class="m-r-xs">Código:</label><span>{{ ncmAlteracoes?.ncmDepois?.numeroCodigo }}</span>
                </div>


                <div class="col-lg-8">
                  <label class="m-r-xs">Descrição:</label>
                  <span [ngStyle]="{'color': ncmAlteracoes.ncmDepois.descricaoNcm != ncmAlteracoes.ncmAntes.descricaoNcm ? '#E21818' : ''}">
                    {{ ncmAlteracoes.ncmDepois.descricaoNcm }}
                  </span>
                </div>


                <div class="col-lg-8">
                  <label class="m-r-xs">Unidades:</label>
                  <span [ngStyle]="{'color': ncmAlteracoes.ncmDepois.idUnidadeMedida != ncmAlteracoes.ncmAntes.idUnidadeMedida ? '#E21818' : ''}">
                    {{ ncmAlteracoes?.ncmDepois?.unidadeMedida?.descricao }}
                  </span>
                </div>

                <div class="row col-sm-12">
                  <div class="col-sm-2">
                    <label class="m-r-xs">Alíquota II (%):</label>
                    <span [ngStyle]="{'color': ncmAlteracoes.ncmDepois.aliquotaII != ncmAlteracoes.ncmAntes.aliquotaII ? '#E21818' : ''}">
                      {{ ncmAlteracoes?.ncmDepois?.aliquotaII }}
                    </span>


                  </div>
                  <div class="col-sm-2">
                    <label class="m-r-xs">Data Inicío:</label>
                  <span [ngStyle]="{'color': ncmAlteracoes.ncmDepois.aliquotaIIVigenciaInicialString != ncmAlteracoes.ncmAntes.aliquotaIIVigenciaInicialString ? '#E21818' : ''}">
                    {{ ncmAlteracoes?.ncmDepois?.aliquotaIIVigenciaInicialString }}
                  </span>
                  </div>
                  <div class="col-sm-2">
                    <label class="m-r-xs">Data Final:</label>
                  <span [ngStyle]="{'color': ncmAlteracoes.ncmDepois.aliquotaIIVigenciaFinalString != ncmAlteracoes.ncmAntes.aliquotaIIVigenciaFinalString ? '#E21818' : ''}">
                    {{ ncmAlteracoes?.ncmDepois?.aliquotaIIVigenciaFinalString }}
                  </span>
                  </div>
                </div>
                <div class="row col-sm-12">
                  <div class="col-sm-2">
                    <label class="m-r-xs">Alíquota IPI (%):</label>
                  <span [ngStyle]="{'color': ncmAlteracoes.ncmDepois.aliquotaIPI != ncmAlteracoes.ncmAntes.aliquotaIPI ? '#E21818' : ''}">
                    {{ ncmAlteracoes?.ncmDepois?.aliquotaIPI }}
                  </span>


                  </div>
                  <div class="col-sm-2">
                    <label class="m-r-xs">Data Inicío:</label>
                    <span [ngStyle]="{'color': ncmAlteracoes.ncmDepois.aliquotaIPIVigenciaInicialString != ncmAlteracoes.ncmAntes.aliquotaIPIVigenciaInicialString ? '#E21818' : ''}">
                    {{ ncmAlteracoes?.ncmDepois?.aliquotaIPIVigenciaInicialString }}
                    </span>
                  </div>
                  <div class="col-sm-2">
                    <label class="m-r-xs">Data Final:</label>
                    <span [ngStyle]="{'color': ncmAlteracoes.ncmDepois.aliquotaIPIVigenciaFinalString != ncmAlteracoes.ncmAntes.aliquotaIPIVigenciaFinalString ? '#E21818' : ''}">
                    {{ ncmAlteracoes?.ncmDepois?.aliquotaIPIVigenciaFinalString }}
                    </span>
                  </div>
                </div>

                <div class="col-lg-8">



                </div>
              </div>

            </div>


            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="thead-cadsuf" style="background: #00552b!important; color: #fff!important;">
                  <tr>
                    <th>Responsável</th>
                    <th>Login</th>
                    <th>Data/Hora</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ncmAlteracoes?.nomeUsuario}}</td>
                    <td>{{ncmAlteracoes?.numeroUsuario}}</td>
                    <td>{{ncmAlteracoes?.dataHoraString}}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </article>
        </form>

        <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
          <div class="pull-right">
            <a (click)="voltar()" class="btn btn-sm btn-default" data-dismiss="modal"><i
                class="fa fa-long-arrow-left"></i> Voltar</a>
          </div>
        </footer>
      </section>
    </div>
  </div>
</div>
<!-- visualizar ncm -->
<app-relatorio-alteracoes
  #appRelatorio>
</app-relatorio-alteracoes>
